import { Injectable } from '@angular/core';

import { TeamsService } from 'src/app/services/core/teams.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
    providedIn: 'root'
})
export class TeamsPickerService {

    constructor(
        private chooser: ChooserService,
        private teams: TeamsService,
    ) {

    }

    pick(options: chooseConfig = null) {
        return new Promise(async (resolve, reject) => {

            const data: team[] = (await this.teams.getAll() || []) as team[];

            const chooseConfig: chooseConfig = Object.assign((options || {}), {
                data: data,
                labelKey: 'name',
                service: this,
                valueKey: 'uid',
            });

            this.chooser.choose(chooseConfig).then(resolve).catch(reject);
        });
    }

}