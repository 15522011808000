import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';
import { AiBridgeService } from '../../../services/ai/ai-bridge.service';

import { EventsService } from "src/app/services/core/events.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';

@Component({
  selector: 'pipeline-ai-image-to-video-card',
  standalone: false,
  templateUrl: './ai-image-to-video-card.component.html',
  styleUrls: ['./ai-image-to-video-card.component.scss']
})
export class AiImageToVideoCardComponent implements AfterViewInit, OnDestroy, OnInit {

  aiSettings: aiSettings = {};

  @Input() autostart: boolean = false;
  
  @Input() colSize: number | null = null;

  disabled: boolean = false;

  @Input() input: string = '';

  items: mediaItem[] = [];

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() size: number = 12;
  
  stateKeys: string[] = ['failed', 'waiting', 'validating', 'starting', 'done'];

  view: any = {
  };

  constructor(
    private aiBridge: AiBridgeService,
    public aiWorker: AiWorkerService,

    private events: EventsService,
    private media: MediaextendService,
    private sd: StablediffusionService,
    private userService: UserService,
    private viewService: ViewService,
  ) {

  }

  public add() {
    return this.uploadAttachment('photo');
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = this.colSize || this.view.colSize;
  }

  chooseMediaFromList(media: mediaItem) {

  }

  generate() {
    this.view.generatingImageToVideo = true;

    if (!this.items || !this.items.length) {
      return false;
    }

    const iAll: number = this.items.length;
    let iDone: number = 0;

    this.view.partLookupUids = [];

    this.items.forEach(async (item: any, index: number) => {

      if (!this.items[iDone]) {
        this.items[iDone] = { state: 'loading' };
      }

      this.items[iDone].state = 'loading';
      delete this.items[iDone].url;

      try {

        this.events.publish('toast', {
          icon: 'hourglass-outline',
          message: 'ai_image_to_video_process_started',
          color: 'primary',
        });

        const response: any = await this.sd.imageToVideo(item.guid || item.thumbnail, {
          //prompt: '',
        });

        console.log('image to video response', index, response);

        if (!!response && !!response.item && !!response.item.uid) {
          this.view.partLookupUids.push(response.item.uid);
        }

        /*
        if(!!response && !!response.images && !!response.images[0]) {
          this.items[iDone] = {
            disabled: false,
            guid: response.images[0],
            photo: response.images[0],
            state: 'done',
            thumbnail: response.images[0],
            type: 'image',
            url: response.images[0],
          };
        }
        */

        iDone++;
        this.view.generatingImageToVideo = (iAll > iDone);

        if (!this.view.generatingImageToVideo) {
          this.watchQueue();
        }

      } catch (e) {
        this.events.publish('error', e);
        iDone++;
        this.view.generatingImageToVideo = (iAll > iDone);
      }
    });
  }

  initEvents() {
    this.view.events = {};
  }

  loadQueue() {
    return new Promise(async (resolve, reject) => {

      // if still no part uids exist, throw error
      if (!this.view.partLookupUids || !this.view.partLookupUids.length) {
        reject('error_missing_part_lookup_uids');
      } else {
        // otherwise, queue + build parts

        //this.view.iCount = this.view.partLookupUids.length;
        this.view.iCount = this.items.length;
        this.view.phase = 'loading';

        this.aiBridge.getQueue(true, {
          filter: {
            user_uid: this.userService.getUid(),
          },
          lookup_uids: this.view.partLookupUids,
        })
          .then((queue: mediaQueueItem[]) => {

            console.log('queue', queue);

            try {

              // sort queue by states + url existence
              queue.sort((a, b) => this.stateKeys.indexOf(b.state) - this.stateKeys.indexOf(a.state))
                .sort((a, b) => {
                  if (a.url && b.url) {
                    return 0;
                  } else if (a.url) {
                    return -1;
                  } else {
                    return 1;
                  }
                });

              // apply results to sections
              if (!!queue && !!queue.length) {
                queue.forEach((queueItem: any) => {

                  let matchingSection: any | null = null,
                    matchingSectionIndex: number | null = null;

                  this.items.forEach((section: any, sectionIndex: number) => {
                    if (section.queue_item_uid === queueItem.uid) {
                      matchingSection = section;
                      matchingSectionIndex = sectionIndex;
                    }
                  });

                  if (!!queueItem.url && !!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video')))) {
                    matchingSection.loading = false;
                    matchingSection.loading_text = null;
                    matchingSection.state = queueItem.state || matchingSection.state;
                    matchingSection.type = 'video';
                    matchingSection.url = queueItem.url;
                    matchingSection.uid = matchingSection.uid || matchingSection.queue_item_uid;

                    if (!!matchingSection.url) {
                      matchingSection.state = 'done';
                    }

                    if (!!this.view.showSplineView && (matchingSectionIndex !== null)) {
                      //this.focusSection(matchingSection, matchingSectionIndex);
                    }

                    if (matchingSectionIndex !== null) {
                      this.items[matchingSectionIndex] = matchingSection;
                      this.view.iDone++;
                      this.view.progress = ((100 / this.view.iCount) * this.view.iDone) / 100;

                      if (this.view.iDone === this.view.iCount) {
                        this.onPartsCreated();
                      }
                    }
                  } else
                    if (!!matchingSection && (!!matchingSection.loading || (!matchingSection.url || (matchingSection.type !== 'video'))) && !!queueItem.state) {
                      matchingSection.state = queueItem.state;
                    }

                });
              }

              resolve(this.view);
            } catch (e) {
              console.warn('updating queue failed', e);
              resolve(this.view);
            }
          })
          .catch((error: any) => {
            reject(error);
          });
      }
    });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
      this.generate();
    }
  }

  onPartsCreated() {
    console.log('onPartsCreated', this.view);

    this.view.phase = 'presenting';
    this.view.progress = 0;

    this.stopWatcher();
  }

  removeMediaFromList(media: mediaItem, index: number | null = null) {

    this.view.mediaList = this.view.mediaList.filter((_mediaItem: mediaItem, _index: number) => {

      if (index !== null) {
        return index !== _index;
      }

      return (_mediaItem.ID !== media.ID) && (_mediaItem.thumbnail !== media.thumbnail);
    });
  }

  stopWatcher() {
    try {
      clearInterval(this.view.refreshInterval);
    } catch (e) {

    }

    this.view.loading = false;
    this.view.showSplineView = false;
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  uploadAttachment(key: string) {
    const blIsBackground: boolean = (key === 'bg_src' || key === 'photo');

    let params: any = {};
    params.mediaType = 'asset';
    params.multiple = true;
    params.services = ['media_library', 'database', 'upload'];

    this.media.applyFromWeb(null, params)
      .then((response: any) => {

        if (!!response && (typeof response === 'object') && !!response.items) {

          if (!!blIsBackground) {
            this.items = (this.items || []).concat(response.items || []).map((item: mediaItem) => {
              item.guid = item.guid || item.url;
              delete item.url;
              return item;
            });
          }

        }

        this.generate();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  watchQueue() {
    this.stopWatcher();

    this.view.loading = true;
    this.view.showSplineView = true;

    this.view.iDone = 0;

    this.watchQueue_Interval();

    this.view.refreshInterval = setInterval(() => {
      this.watchQueue_Interval();
    }, (5 * 1000));
  }

  watchQueue_Interval() {
    this.loadQueue()
      .then(() => {
        this.view.loadingQueue = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    if (this.view.queueTimeIndex < 5) {
      this.view.queueTimeIndex++;
    } else {
      this.view.queueTimeIndex = 1;
    }

  }

}
