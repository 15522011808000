import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { FoldersService } from 'src/app/services/utils/folders.service';

@Injectable({
    providedIn: 'root'
})
export class AiVideosService {

    _detailItem: aiVideoProject | null;

    constructor(
        private AppCMS: AppcmsService,
        private folders: FoldersService,
    ) {

    }

    create(project: aiVideoProject, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            settings: project,
        }), ['ai', 'videos', 'create']);
    }

    createFolder(folder: folder) {
        folder.location = folder.location || 'ai_videos';
        return this.folders.create(folder);
    }

    deleteFolder(folderId: number) {
        return this.folders.delete(folderId);
    }

    deleteProject(projectId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            video_project_uid: projectId,
        }, ['ai', 'videos', 'delete']);
    }

    detailItem(item: integration | null = null) {

        if (item !== null) {
            this._detailItem = item;
            return this;
        }

        return this._detailItem;
    }

    duplicateProject(projectId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            video_project_uid: projectId,
        }, ['ai', 'videos', 'duplicate']);
    }

    getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
        options.location = options.location || 'ai_videos';
        return this.folders.get(options, blForceRefresh, params);
    }

    getProjects(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'videos', 'projects'], {}, blForceRefresh);
    }

    update(project: aiVideoProject, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            settings: project,
        }), ['ai', 'videos', 'update']);
    }

    updateFolder(folder: folder) {
        return this.folders.update(folder);
    }

}