import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class TemplateEditorService {

  currentTemplate: mediaTemplate;

  layerTypes: any[] = [
    {
      icon: 'musical-notes-outline',
      name: 'audio',
      uid: 'audio',
    },
    {
      icon: 'text-outline',
      name: 'text',
      uid: 'text',
    },
    {
      icon: 'shapes-outline',
      name: 'rectangle',
      uid: 'rectangle',
    },
    {
      icon: 'image-outline',
      name: 'image',
      uid: 'image',
    },
    {
      icon: 'film-outline',
      name: 'video',
      uid: 'video',
    },
    {
      icon: 'extension-puzzle-outline',
      name: 'lottie',
      uid: 'lottie',
    }
  ]

  constructor(
    private chooser: ChooserService,
    private media: MediaextendService,
    private navCtrl: NavController,
    private tools: ToolsService,
  ) {

  }

  addAudio() {
    return new Promise((resolve, reject) => {

      let audio: any = {
        name: 'Audio',
        settings: {
          x: 0,
          y: 0,
          start: 0,
          end: 5,
          width: 0,
          height: 0,
          src: '',
        },
        type: 'audio',
      };

      resolve({
        layer: audio,
      });

    })
  }

  addImage() {
    return new Promise((resolve, reject) => {
      let method: string = (this.tools.isWeb() ? 'applyFromWeb' : 'apply');

      this.media.applyFromWeb(null, {
        multiple: false,
      })
        .then((response: any) => {
          let imageSrc: string = (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail ? response.items[0].thumbnail : null);

          if (!!imageSrc) {

            let image: any = {
              name: 'Image',
              settings: {
                start: 0,
                end: 15,
                width: 50,
                height: 50,
                x: 25,
                y: 25,
                src: imageSrc,
              },
              type: 'image',
            };

            resolve({
              layer: image,
            });

          } else {
            resolve({
              layer: null,
            });
          }
        })
        .catch(reject);
    })
  }

  addLayer(options: any = {}) {
    return new Promise((resolve, reject) => {

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: this.getLayerTypes(),
        labelKey: 'name',
        multiple: false,
        service: this,
        title: 'add_layer',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
        .then((response: any) => {
          const selection: any = (!!response && !!response.data && !!response.data.items && !!response.data.items[0] ? response.data.items[0] : null);
          const methodName: string | null = (!!selection ? `add${this.tools.capitalize(selection.uid)}` : null);

          if (!!methodName) {
            try {
              this[methodName]().then((response: any) => {

                if (!!response && !!response.layer && !!response.layer.settings && !response.layer.settings.id) {
                  response.layer.settings.id = this.calculateLayerId(response.layer, options);
                }

                resolve(response);
              }).catch(reject);
            } catch (e) {
              reject(e);
            }
          } else {
            reject('not_implemented');
          }

        })
        .catch(reject);
    })
  }

  addLottie() {
    return new Promise((resolve, reject) => {

      let lottie: any = {
        name: 'Lottie',
        settings: {
          'background-color': 'transparent',
          x: 0,
          y: 0,
          start: 0,
          end: 15,
          width: 50,
          height: 50,
        },
        type: 'lottie',
      };

      resolve({
        layer: lottie,
      });

    });
  }

  addRectangle() {
    return new Promise((resolve, reject) => {

      let rectangle: any = {
        name: 'Rectangle',
        settings: {
          'background-color': '#999999',
          x: 25,
          y: 25,
          start: 0,
          end: 15,
          width: 50,
          height: 50,
        },
        type: 'rectangle',
      };

      resolve({
        layer: rectangle,
      });

    });
  }

  addText() {
    return new Promise((resolve, reject) => {

      let text: any = {
        name: 'Text',
        settings: {
          'background-color': 'transparent',
          color: '#000000',
          'font-family': 'Arial',
          'font-size': '40px',
          'line-height': '50px',
          value: 'Enter your text here',
          start: 0,
          end: 15,
          width: 50,
          height: 50,
          x: 25,
          y: 25,
        },
        type: 'text',
      };

      resolve({
        layer: text,
      });

    });
  }

  addVideo() {
    return new Promise((resolve, reject) => {
      let method: string = (this.tools.isWeb() ? 'applyFromWeb' : 'apply');

      this.media.applyFromWeb(null, {
        multiple: false,
      })
        .then((response: any) => {
          let item: any = (!!response && !!response.items && !!response.items[0] ? response.items[0] : null),
            videoSrc: string = (!!item && !!item.videoSrc ? item.videoSrc : null);

          if (!!videoSrc) {

            let video: any = {
              name: 'Video',
              settings: {
                start: 0,
                end: 15,
                width: 50,
                height: 50,
                x: 25,
                y: 25,
                src: videoSrc,
              },
              type: 'video',
            };

            resolve({
              layer: video,
            });

          } else {
            resolve({
              layer: null,
            });
          }

        })
        .catch(reject);
    });
  }

  calculateLayerId(layer: any, options: any) {
    return `${layer.type || 'layer'}${(options.layers ? options.layers.length || 0 : 0) + 1}`;
  }

  cleanLayerCSS(css: string) {
    css = `${css}`.replace('undefined', '');
    return css;
  }

  editCustomTemplate(template: mediaTemplate) {
    this.setTemplate(template);
    this.navCtrl.navigateForward(`/media/templates/editor/${template.uid || 'create'}`);
  }

  getCurrentTemplate() {
    return this.currentTemplate;
  }

  getLayerTypes() {
    return this.layerTypes;
  }

  removeUnusedStylesAndScripts() {
    try {
      var elements: any = document.querySelectorAll('.dynamic-script-element, .dynamic-style-element');
      Array.from(elements).forEach((el: any) => el.remove());
    } catch (e) {
      console.warn('failed to remove unused styles', e);
    }
  }

  setTemplate(template: mediaTemplate) {
    this.currentTemplate = template;
    return this;
  }

}