import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { AiKnowledgebaseService } from 'src/app/services/ai/ai-knowledgebase.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiKnowledgebaseEntryPage } from '../ai-knowledgebase-entry/ai-knowledgebase-entry.page';

@Component({
  selector: 'app-ai-knowledgebase',
  standalone: false,
  templateUrl: './ai-knowledgebase.page.html',
  styleUrls: ['./ai-knowledgebase.page.scss'],
})
export class AiKnowledgebasePage implements OnDestroy, OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('headerPopover') headerPopover;

  appConfig: pipelineAppConfig;

  cards: any = {};

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  daniConfig: daniConfig = {
    chat: {
      messages: [
        {
          mode: 'message',
          input: `getgenius_ai_knowledgebase_test_effects_intro_message`,
          role: 'assistant',
        },
      ] as chatHistoryItem[],
    },
    hasChat: true,
    mini: true,
    uiMode: 'widget',
    userCanWrite: true, // false
    zoom: (window.outerWidth > 768 ? 0.4 : 0.25),
  };

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];

  @Input() inSetupEmbedded: boolean = false;

  introCard: introCardConfig = {
    uid: 'ai_knowledgebase_top_card',
    text: 'ai_knowledgebase_top_card_text',
    title: 'ai_knowledgebase_top_card_title',
  };

  isHeaderPopoverOpen: boolean = false;

  mode: string = 'view';

  paginationConfig: paginationConfig = {
    itemsKey: 'items',
    limit: 500,
  };

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['input', 'output', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
  ];

  state: state = {};

  view: any = {
    dani: {
      state: {
        inConversation: true,
        speaking: false,
      },
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    items: [],
    multiple: true,
    output: '',
    route: 'ai/knowledgebase',
    showMenuButton: true,
    showPagination: true,
    showProjectsSelect: true,
    title: 'ai_knowledgebase',
  };

  constructor(
    public aiTools: AiToolsService,

    private configService: ConfigService,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private importService: ImporterService,
    private knowledgeBase: AiKnowledgebaseService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async add(event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: AiKnowledgebaseEntryPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  calcTags() {
    let contexts: any[] = [], foundContexts: string[] = [];
    let tools: any[] = [], foundTools: string[] = [];

    if (!!this.view.items && !!this.view.items.length) {
      this.view.items.forEach((item: any) => {

        if (!!item && !!item.contexts) {
          item.contexts.forEach((context: string) => {
            if (foundContexts.indexOf(context) === -1) {
              foundContexts.push(context);

              contexts.push({
                photo: item.photo,
                title: context,
                uid: context,
              });
            }
          });
        }

        if (!!item && !!item.tools) {
          item.tools.forEach((tool: string) => {
            if (foundTools.indexOf(tool) === -1) {
              foundTools.push(tool);

              tools.push({
                photo: item.photo,
                title: tool,
                uid: tool,
              });
            }
          });
        }
      });
    }

    this.view.contexts = contexts;
    this.view.tools = tools;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      item: (window.innerWidth > 768 ? (this.view.expertMode ? 4 : 3) : 12),
      left: this.view.sidebarSize || (window.innerWidth > 768 ? 3 : 12),
      right: (!!this.view.sidebarSize ? (12 - this.view.sidebarSize) : (window.innerWidth > 768 ? 9 : 12)),
    };
  }

  delete(entry: any) {
    this.knowledgeBase.delete(entry.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSavedEntries(entry: any, index: number) {

  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'delete',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadEntries(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        if (!!error) {
          this.events.publish('error', error);
        }

      });
  }

  duplicateSelected() {

  }

  async edit(entry: any) {

    const modal: any = await this.modalService.create({
      component: AiKnowledgebaseEntryPage,
      componentProps: {
        entry: entry,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.items || []),
      service: this.aiTools,
      source_tool: 'ai_knowledgebase',
      source_type: 'tool',
    })
      .then((response: any) => {
        console.log('export response', response);

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  filterResults(blKeepHidden: boolean = false) {

    if (!this.view.items || !this.view.items.length || !this.view.filter) {
      return false;
    }

    this.view.items.forEach((item: aiKnowledgebaseEntry) => {

      const blMatch: boolean = (
        (!this.view.filter.context || (!!item.contexts && item.contexts.indexOf(this.view.filter.context) !== -1)) &&
        (!this.view.filter.tool || (!!item.tools && item.tools.indexOf(this.view.filter.tool) !== -1))
      );

      item.hidden = (!!blKeepHidden && !!item.hidden) || !blMatch;
      console.log(item.hidden, item);
    });
  }

  importEntries(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.knowledgeBase,
      target_tool: 'ai_knowledgebase',
      target_type: 'tool',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadEntries()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadEntries(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.items = [];
        this.view.items_backup = [];

        resolve(true);
      } else {
        this.view.loading = true;
        this.view.rebuild = true;

        this.knowledgeBase.getAll(blForceRefresh)
          .then((entries: aiKnowledgebaseEntry[]) => {
            this.view.loading = false;

            this.view.items = (entries || []);
            this.view.items_backup = (entries || []);

            setTimeout(() => {
              this.view.rebuild = false;
            }, 100);

            this.calcTags();

            resolve(this.view);
          })
          .catch(reject);
      }
    });
  }

  loadItemsByFolder(event: any | null = null) {

  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'knowledgebase')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadProject();
    this.loadCards();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  onAttributeFiltersChanged(event: any | null = null) {

  }

  onFolderLoaded(event: any | null = null) {

  }

  onItemCheckboxClicked(item: aiKnowledgebaseEntry) {
    this.view.rebuild = true;

    this.view.selectedItems = this.view.items.filter((_item: aiKnowledgebaseEntry) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    setTimeout(() => {
      this.view.rebuild = false;
    }, 500);
  }

  onSearchChanged(searchOptions: any | null = null) {
    console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectedContextChanged(event: any | null = null) {
    console.log('onSelectedContextChanged: event', event);

    if (!this.view.items || !this.view.items.length) {
      return false;
    }

    this.view.filter = this.view.filter || {};
    this.view.filter.context = event.uid;

    this.filterResults();
  }

  onSelectedToolChanged(event: any | null = null) {
    console.log('onSelectedToolChanged: event', event);

    if (!this.view.items || !this.view.items.length) {
      return false;
    }

    this.view.filter = this.view.filter || {};
    this.view.filter.tool = event.uid;

    this.filterResults();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'optimize':
        this.optimizeSelected();
        break;
    }

  }

  optimize(entry: any) {
    this.knowledgeBase.optimize(entry.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  optimizeSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'optimize',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  pick(event: any | null = null) {
    return this.dismiss({
      items: (this.view.selectedItems || []),
    });
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();

      this.filterResults(true);
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  toggleActive(entry: any) {
    entry.active = !entry.active;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}