<ion-header class="ion-no-border">

  <!-- Top header -->
  <pipeline-header-top-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-top-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Segment toolbar -->
  <ion-toolbar class="container ion-no-border second-toolbar"
    *ngIf="!!view.project && !!view.project.uid && !!view.integrations && !!view.integrations.length">

    <ion-segment [(ngModel)]="view.category" (ionChange)="categoryChanged()" scrollable value="all">

      <!-- Suggestions -->
      <ion-segment-button [value]="'recommended'" *ngIf="!!view.hasRecommendations">
        <ion-icon name="bulb-outline"></ion-icon>
        <ion-label [innerHTML]="'recommended'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>

      <!-- All -->
      <ion-segment-button [value]="'all'">
        <ion-icon name="apps-outline"></ion-icon>
        <ion-label [innerHTML]="'all'|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>

      <!-- Iterate categories -->
      <ion-segment-button *ngFor="let category of view.categories; trackBy:trackItems" [value]="category.uid">
        <ion-icon *ngIf="!!category.icon" [name]="category.icon"></ion-icon>
        <ion-label [innerHTML]="category.name|translate" class="ion-text-wrap"></ion-label>
      </ion-segment-button>

    </ion-segment>

  </ion-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!!view.expertMode" [(items)]="view.integrations" [options]="selectionOptions"
    [(paginationConfig)]="paginationConfig" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)">
  </pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Intro card -->
  <div class="container" [hidden]="introCard.hidden">
    <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
  </div>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- No entries card -->
    <pipeline-no-entries-card *ngIf="view.integrations && !view.integrations.length"></pipeline-no-entries-card>

    <!-- Loading bar -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- Integrations list -->
    <ion-grid *ngIf="!!view.integrations && !!view.integrations.length" [hidden]="!!view.loading">
      <ion-row>

        <!-- Single integration -->
        <ion-col *ngFor="let integration of view.integrations; trackBy:trackItems" [size]="view.colSize"
          [hidden]="integration.hidden">
          <ion-card>

            <ion-item lines="none">

              <ion-thumbnail slot="start" *ngIf="!!integration.photo">
                <ion-img [src]="integration.photo" (ionError)="thumbnailLoadingFailed(integration)"></ion-img>
              </ion-thumbnail>

              <ion-icon *ngIf="!integration.photo" [name]="integration.icon" color="primary" slot="start"></ion-icon>

              <ion-label [hidden]="!!view.expertMode" class="ion-text-wrap">
                <p [innerHTML]="integration.name|translate" class="ion-text-wrap"></p>

                <span class="active-span" [class.error]="integration.state === 'error'"
                  [class.success]="!!integration.enabled && (integration.state === 'success')"
                  [class.warning]="!integration.enabled || (integration.state === 'warning')">
                  <ion-icon
                    [name]="integration.enabled ? 'radio-button-on-outline' : 'radio-button-off-outline'"></ion-icon>
                  <span [hidden]="integration.enabled" [innerHTML]="'not_active'|translate"></span>
                  <span [hidden]="!integration.enabled" [innerHTML]="'active'|translate"></span>
                </span>

              </ion-label>

              <ion-checkbox [hidden]="!view.expertMode" [(ngModel)]="integration.checked" justify="space-between"
                labelPlacement="start" class="ion-text-wrap" (ionChange)="onIntegrationCheckboxClicked(integration)">
                <p [innerHTML]="integration.name|translate" class="ion-text-wrap"></p>

                <span class="active-span">
                  <ion-icon [name]="integration.enabled ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                    [color]="integration.enabled ? 'success' : 'danger'" slot="start"></ion-icon>
                  <span [hidden]="integration.enabled" [innerHTML]="'not_active'|translate"></span>
                  <span [hidden]="!integration.enabled" [innerHTML]="'active'|translate"></span>
                </span>

              </ion-checkbox>

              <!-- Simple mode buttons -->

              <ion-button [hidden]="!!view.expertMode || !!integration.enabled" fill="clear" slot="end" color="dark"
                (click)="enableIntegration(integration)">
                <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'enable'|translate"></ion-label>
              </ion-button>

              <ion-button [hidden]="!!view.expertMode || !integration.enabled" fill="clear" slot="end" color="dark"
                (click)="disableIntegration(integration)">
                <ion-icon name="hand-left-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'disable'|translate"></ion-label>
              </ion-button>

              <ion-button [hidden]="!!view.expertMode || !integration.enabled" fill="clear" slot="end" color="dark"
                (click)="editIntegration(integration)">
                <ion-icon name="settings-outline" slot="start"></ion-icon>
              </ion-button>

              <!-- End simple mode buttons -->

            </ion-item>

            <!-- Expert mode -->
            <ion-card-header [hidden]="!view.expertMode">
              <p [innerHTML]="integration.description|translate"></p>
            </ion-card-header>

            <ion-grid [hidden]="!view.expertMode">
              <ion-row>

                <ion-col [hidden]="!!integration.enabled">
                  <ion-button size="block" fill="clear" color="dark" (click)="enableIntegration(integration)">
                    <ion-icon name="checkmark-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'enable'|translate"></ion-label>
                  </ion-button>
                </ion-col>

                <ion-col [hidden]="!integration.enabled">
                  <ion-button size="block" fill="clear" color="dark" (click)="disableIntegration(integration)">
                    <ion-icon name="hand-left-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'disable'|translate"></ion-label>
                  </ion-button>
                </ion-col>

                <ion-col [hidden]="!integration.enabled">
                  <ion-button size="block" fill="clear" color="dark" (click)="editIntegration(integration)">
                    <ion-icon name="settings-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'settings'|translate"></ion-label>
                  </ion-button>
                </ion-col>

              </ion-row>

            </ion-grid>

          </ion-card>
        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!inSetupEmbedded">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>