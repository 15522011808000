import { Directive, Input, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { DropService } from 'src/app/services/core/drop.service';

@Directive({
    selector: '[drop]',
    standalone: false
})
export class DropDirective {
    @Input() dropItem: any;
    @Input() dropItemType: string;

    @Output() itemDropped = new EventEmitter<any>();

    constructor(
        private el: ElementRef,
        private renderer: Renderer2,
        private dropService: DropService
    ) {

    }

    /*
    @HostListener('mouseenter', ['$event'])
    onDragEnter(event: MouseEvent): void {
        event.preventDefault();

        if (this.drop) {
            this.renderer.setStyle(this.el.nativeElement, 'border', '1px dashed #475A68');
        }
    }
    */

    @HostListener('mouseleave', ['$event'])
    onDragLeave(event: MouseEvent): void {
        event.preventDefault();

        this.renderer.removeStyle(this.el.nativeElement, 'border');
    }

    @HostListener('mouseup', ['$event'])
    onDrop(event: MouseEvent): void {
        event.preventDefault();

        if (this.dropItem && this.dropService.draggedItem) {
            
            this.itemDropped.emit({
                dragItem: this.dropService.draggedItem,
                dropItem: this.dropItem,
                dropItemType: this.dropItemType,
            });

            //this.renderer.removeStyle(this.el.nativeElement, 'border');
            this.dropService.clearDraggedItem();
        }
    }

}