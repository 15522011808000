import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { IntroCardComponent } from './intro-card.component';

import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared.module';

/* Animations */
import { SplineViewerComponentModule } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component.module';

/* Custom Components */
import { MediaPickerCardComponentModule } from 'src/app/components/media/media-picker-card/media-picker-card.component.module';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    IntroCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    SplineViewerComponentModule,

    /* Custom Components */
    MediaPickerCardComponentModule,
  ],
  exports: [
    IntroCardComponent,
  ]
})
export class IntroCardComponentModule { }