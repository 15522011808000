import { Injectable } from '@angular/core';
import { from } from 'rxjs';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(
        private cache: CacheService,
        private events: EventsService,
    ) {
        this.initEvents();
    }

    clear(tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        return this.cache.remove(cacheKey);
    }

    async get(tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        const fromCache: cacheItem = await this.cache.get(cacheKey, -1);

        return (fromCache && !!fromCache.data ? fromCache.data : null);
    }

    getCacheKey(tokenName: string = 'auth_token') {
        return `pipeline_token_${tokenName}`;
    }

    initEvents() {
        this.events.subscribe('cache:clear', () => {
            setTimeout(async () => {
                const existingToken: string | null = await this.get();

                if (!!existingToken) {
                    this.set(existingToken);
                }
            });
        });
    }

    set(token: string, tokenName: string = 'auth_token') {
        const cacheKey: string = this.getCacheKey(tokenName);
        return from(this.cache.set(cacheKey, token));
    }

}