import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';

@Component({
  selector: 'pipeline-media-item-info-popover',
  standalone: false,
  templateUrl: './media-item-info-popover.component.html',
  styleUrls: ['./media-item-info-popover.component.scss'],
})
export class MediaItemInfoPopoverComponent implements AfterViewInit, OnDestroy, OnInit {

  event: any;

  @Input() item: any = {};

  @Input() open: boolean = false;

  @ViewChild('itemInfoPopover') popover: any;

  @Input() view: any = {};

  constructor(
    private events: EventsService,
  ) {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
  }

  public show(options: any) {
    console.log('media-iteminfo-popover: show', options);

    this.item = options.item;
    this.event = options.event;

    this.popover.event = this.event;
    this.open = true;
  }

}