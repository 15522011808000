import { Component, OnInit } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-shop-category',
  standalone: false,
  templateUrl: './shop-category.page.html',
  styleUrls: ['./shop-category.page.scss'],
})
export class ShopCategoryPage implements OnInit {

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    showMenuButton: true,
    showLanguagesSelect: false,
    showProjectsSelect: false,
    showViewModeSelect: false,
    title: 'shop_category',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private viewService: ViewService,
  ) {
  }

  add() {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  dismiss(data: any|null = null) {
    this.modalService.dismiss(data);
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.image = this.fallbackImg;
  }

}