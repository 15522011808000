import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';

import { MediaTextOverlayCardComponent } from './media-text-overlay-card.component';

import { MediaCreateFormAutomotiveComponentModule } from 'src/app/components/media/media-forms/media-create-form-automotive/media-create-form-automotive.component.module';
import { MediaCreateFormDefaultComponentModule } from 'src/app/components/media/media-forms/media-create-form-default/media-create-form-default.component.module';
import { MediaCreateFormDynamicComponentModule } from 'src/app/components/media/media-forms/media-create-form-dynamic/media-create-form-dynamic.component.module';
import { MediaCreateFormRealestateComponentModule } from 'src/app/components/media/media-forms/media-create-form-realestate/media-create-form-realestate.component.module';

@NgModule({
  declarations: [
    MediaTextOverlayCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    TagInputModule,
    MediaCreateFormAutomotiveComponentModule,
    MediaCreateFormDefaultComponentModule,
    MediaCreateFormDynamicComponentModule,
    MediaCreateFormRealestateComponentModule,
  ],
  exports: [
    MediaTextOverlayCardComponent,
  ]
})
export class MediaTextOverlayCardComponentModule { }