import { Injectable } from '@angular/core';

import { LoadingController } from '@ionic/angular';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { UserService } from 'src/app/services/core/user.service';
import { CitiesService } from 'src/app/services/geo/cities.service';
import { BlogService } from 'src/app/services/pipeline/blog.service';
import { PipelineService } from 'src/app/services/pipeline/pipeline.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class RegionalService {

  cameraPosition: any;
  
  mapOptions: any = window.localStorage.getItem('mapOptions') ? JSON.parse(window.localStorage.getItem('mapOptions')) : null;

  position: any = window.localStorage.getItem('pipeline_regional_position') ? JSON.parse(window.localStorage.getItem('pipeline_regional_position')) : null;

  regionalSources: source[];

  visibleMapMarkers: any;

  constructor(
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private chooser: ChooserService,
    private citiesService: CitiesService,
    private loadingCtrl: LoadingController,
    private pipeline: PipelineService,
    private sources: SourcesService,
    private userService: UserService,
  ) {

  }

  applyRegionsToPosts(posts: any) {
    return new Promise((resolve, reject) => {
      this.getAllSources()
      .then((sources: any) => {
        let sourcesByRegion = this.sortSourcesByRegion(sources);
        posts.forEach((post: post) => {
          if(post.host && sourcesByRegion[post.host]) {
            post.region = sourcesByRegion[post.host];
          }
        });
        resolve(posts);
      })
      .catch(reject);
    });
  }

  asCols() {
    return new Promise((resolve, reject) => {
      this.getAll().then(resolve).catch(reject);
    });
  }

  createSource(source: any) {
    return new Promise(async (resolve, reject) => {
      let url = source.url;
      const loading: any = await this.loadingCtrl.create({
        spinner: 'circular',
      });
      loading.present();

      if((url + '').substr(0, 4) !== 'http') {
        url = 'https://' + url;
      }
      
      let sourceData = {
        active: true,
        local: true,
        name: source.name,
        email: source.email,
        region: source.region,
        url: url,
      };

      this.sources.createSource(sourceData)
      .then((response: any) => {
        loading.dismiss();
        resolve(response);
      }).catch((error: any) => {
        loading.dismiss();
        reject(error);
      });
    });
  }

  deleteSource(sourceId: number) {
    return this.sources.deleteSource(sourceId);
  }

  getAll(filter: any = {}, blForceRefresh: boolean = false, config: any|null = null) {
    return new Promise((resolve, reject) => {
      config = config || {};
      this.blog.getSelfHosted(filter, blForceRefresh, config)
      .then((posts: any) => {
        posts = posts.filter((post: post) => {
          return post.local;
        });
        resolve(posts);
      })
      .catch(reject);
    });
  }

  getAllSources(blForceRefresh: boolean = false) {
    return this.pipeline.getRegionalSources(blForceRefresh);
  }

  getCachedPosition() {
    return this.position;
  }

  getCameraPosition() {
    return this.cameraPosition;
  }

  getCities(blForceRefresh: boolean = false, config: any = {}) {
    
    if(config.query && config.query.length < 3) {
      delete config.query;
    }

    return this.AppCMS.loadPluginData("pipeline", config, ['cities'], {}, blForceRefresh);
  }

  getByCity(
    cityName: string,
    blForceRefresh: boolean = false,
    config: any = {}
  ) {
    return new Promise(async (resolve, reject) => {

      let filter: any = {
        region: cityName,
      };

      if(config.filter) {
        filter = Object.assign(filter, config.filter);
        delete config.filter;
      }
      
      this.blog.getSelfHosted(filter, blForceRefresh, config)
      .then((postsByCity: any) => {
        resolve(postsByCity);
      })
      .catch(reject);
    });
  }

  getFullSource(source: any) {
    source.value = source.value || {};
    source.value.uid = source.uid;
    source.value.name = source.title || '(kein Name)';
    source.value.city = source.inAppLink;
    source.value.url = source.description;
    return source.value;
  }
  
  getMapOptions() {
    return this.mapOptions;
  }

  getRegionsBySources(sources: any) {
    let sourcesByRegion: any = {};
    sources.forEach((source: any) => {
      sourcesByRegion[source.city] = sourcesByRegion[source.city] || [];
      sourcesByRegion[source.city].push(source);
    });
    return sourcesByRegion;
  }

  getVisibleMapMarkers() {
    return new Promise((resolve, reject) => {
      resolve(this.visibleMapMarkers);
    });
  }

  pick() {
    return new Promise(async (resolve, reject) => {
      const chooseConfig: chooseConfig = {
        data: [],
        labelKey: 'name',
        service: this.citiesService,
        valueKey: 'uid',
      };
      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        resolve(chooseResponse);
      })
      .catch(reject);
    });
  }

  setCity(city: city) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser();
      user.city = city.title;
      this.userService.setUser(user, true).then(resolve).catch(reject);
    });
  }

  setVisibleMapMarkers(markers: any) {
    this.visibleMapMarkers = markers;
  }

  sortSourcesByRegion(sources: any) {
    let sourcesByRegion: any = {};
    sources.forEach((source: any) => {
      let url = source.url.replace('https://', '').replace('http://', '').replace('/');
      sourcesByRegion[url] = sourcesByRegion[url] || source.city;
    });
    return sourcesByRegion;
  }

}
