import { Injectable } from "@angular/core";

import { SettingsService } from "src/app/services/core/settings.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: "root",
})
export class ThemesService {

  scheme: any;

  constructor(
    private settings: SettingsService,
    private userService: UserService
  ) {

  }

  getPrefersMode(mode: string = "dark") {
    let match: any = window.matchMedia("(prefers-color-scheme: " + mode + ")");
    return match;
  }

  getScheme() {
    return this.scheme;
  }
  
  getUserpreferredTheme(user: user | null = null) {
    return this.settings.getSetting("colorScheme");
  }

  async init() {
    let userpreferredTheme: any | null = null,
      userId = this.userService.getUid(),
      prefersDark = this.getPrefersMode();

    try {
      userpreferredTheme = !!userId ? await this.getUserpreferredTheme() : null;
    } catch (e) {
      console.warn('detecting user theme failed', e);
    }

    if (!!userpreferredTheme && (userpreferredTheme !== "light") && (userpreferredTheme !== "dark")) {
      this.set(userpreferredTheme);
    } else {
      this.toggleDarkTheme(
        userpreferredTheme === "dark" || (prefersDark && !!prefersDark.matches)
      );
    }

    prefersDark.addListener((mediaQuery: any) =>
      this.toggleDarkTheme(mediaQuery.matches)
    );
  }

  set(scheme: any) {
    this.scheme = scheme;

    if (scheme === "light" || scheme === "dark") {
      return this.toggleDarkTheme(scheme === "dark");
    }

    if (scheme.indexOf("dark") === -1) {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    } else if (scheme.indexOf("light") === -1) {
      document.documentElement.classList.remove("light");
      document.documentElement.classList.add("dark");
    }

    document.documentElement.classList.add(scheme);
  }

  toggleDarkTheme(shouldAdd: boolean) {
    document.documentElement.classList.remove("dark_pipeline");
    document.documentElement.classList.remove("light_pipeline");

    if (shouldAdd) {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
    } else {
      document.documentElement.classList.remove("dark");
      document.documentElement.classList.add("light");
    }
  }

}
