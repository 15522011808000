import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ShopAdminService } from 'src/app/services/ecommerce/shop-admin.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { BrowserService } from "src/app/services/utils/browser.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { TableViewComponent } from 'src/app/components/generic/table-view/table-view.component';
import { ShortlinkGeneratorComponent } from 'src/app/components/shortlinks/shortlink-generator/shortlink-generator.component';

@Component({
  selector: 'app-data-exporter',
  standalone: false,
  templateUrl: './data-exporter.page.html',
  styleUrls: ['./data-exporter.page.scss'],
})
export class DataExporterPage implements AfterContentInit, OnInit {

  @ViewChild(ShortlinkGeneratorComponent) shortlinkGenerator: any;

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  buttons: button[] = [
  ];

  cards: any = {
    filters: { open: true },
    item_attributes: { open: true },
    output: { open: true },
    source: { open: true },
  };

  data: any; // provided by modalCtrl

  exportService: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  fields: any[];

  introCard: introCardConfig = {
    uid: 'data_exporter_top_card',
    lottieSrc: './assets/lottie/light_bulb.json',
    text: 'data_exporter_top_card_text',
    title: 'data_exporter_top_card_title',
  };

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    groups: [

      /* Step 1: */
      {
        buttons: [

        ],
        description: 'data_exporter_source_ai_helper_text',
        headline: 'data_exporter_ai_helper_text',
        inputs: [
          {
            placeholder: 'source_type',
            type: 'select',
          }
        ],
      },

      /* Step 2 */
      {
        buttons: [

        ],
        description: 'data_exporter_target_ai_helper_text',
        headline: 'data_exporter_target_ai_helper_title',
        inputs: [
          {
            placeholder: 'target_type',
            type: 'select',
          }
        ],
      },

    ],
    showAiCreate: false,
    showAiSettings: false,
    showInput: false,
    showIntegrations: true,
  };

  items: any[];

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  service: any; // provided by modalCtrl

  settings: any = {
    format: 'csv',
    seperator: ',',
  };

  shortlinkConfig: shortlinkConfig = {
    canInput: false,
    canRequest: true,
    hasInput: false,
  };

  source: any; // provided by modalCtrl
  source_object_type: string; // provided by modalCtrl
  source_type: string; // provided by modalCtrl
  source_tool: string; // provided by modalCtrl

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  @ViewChild(TableViewComponent) tableView: any;

  tableViewOptions: tableViewOptions = {
  };

  target: any; // provided by modalCtrl
  target_type: string; // provided by modalCtrl
  target_tool: string; // provided by modalCtrl

  tools: aiTool[] = [
    {
      "label": "all",
      "uid": "all"
    },
    {
      "icon": "cash-outline",
      "label": "ads",
      "uid": "ads"
    },
    {
      "icon": "phone-portrait-outline",
      "label": "apps",
      "uid": "apps"
    },
    {
      "icon": "chatbubbles-outline",
      "label": "comments",
      "uid": "comments"
    },
    {
      "icon": "images-outline",
      "label": "media_library",
      "uid": "media_library"
    },
    {
      "icon": "calendar-outline",
      "label": "campaigns",
      "uid": "ai_plans"
    },
    {
      "icon": "terminal-outline",
      "label": "ai_tasks",
      "uid": "ai_tasks"
    },
    {
      "icon": "file-tray-outline",
      "label": "mail_inbox",
      "uid": "mail_inbox"
    },
    {
      "icon": "mail-outline",
      "label": "newsletters",
      "uid": "newsletters"
    },
    {
      "icon": "list-outline",
      "label": "shop_categories",
      "uid": "shop_categories"
    },
    {
      "icon": "storefront-outline",
      "label": "shop_products",
      "uid": "shop_products"
    },
    {
      "icon": "funnel-outline",
      "label": "target_groups",
      "uid": "target_groups"
    },
  ];

  type: any; // provided by modalCtrl

  types: any[] = [
    {
      name: 'file',
      uid: 'file',
    },
    {
      name: 'integration',
      uid: 'integration',
    },
    {
      name: 'object_type',
      uid: 'object_type',
    },
    {
      name: 'tool',
      uid: 'tool',
    },
    {
      name: 'url',
      uid: 'url',
    }
  ];

  view: any = {
    buttonAction: 'export',
    canGenerate: false,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    multiple: true,
    output: '',
    outputType: 'static',
    phase: 'edit',
    progress: 0,
    route: 'ai/items',
    sections: [],
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    tab: 'item',
    title: 'data_exporter',
    viewType: 'table',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ],
  };

  constructor(
    private browser: BrowserService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private objects: ObjectsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private shopAdmin: ShopAdminService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    // apply data to intro overlay component
    if (!!this.introOverlayConfig && !!this.introOverlayConfig.groups) {

      if (!!this.introOverlayConfig.groups[0] && !!this.introOverlayConfig.groups[0].inputs) {
        this.introOverlayConfig.groups[0].inputs[0].values = this.types;
      }

      if (!!this.introOverlayConfig.groups[1] && !!this.introOverlayConfig.groups[1].inputs) {
        this.introOverlayConfig.groups[1].inputs[0].values = this.types;
      }

    }
  }

  calcExportUrl(params: any | null = null) {
    params = params || this.calcParams();

    this.view.exportUrl = this.exportService.calculateExportUrl(params);
  }

  calcFilters() {
    const blockKeys: string[] = ['checked', 'field_optimizations', 'image', 'photo', 'timestamp', 'uid', 'variations'];
    let filters: any[] = [], valuesByKey: any = {};

    if (!this.data || !this.data[0]) {
      return false;
    }

    const keys: string[] = Object.keys(this.data[0]).filter((key: string) => {
      return blockKeys.indexOf(key) === -1;
    });

    this.data.forEach((item: any) => {
      keys.forEach((key: string) => {
        valuesByKey[key] = valuesByKey[key] || [];
        if (!!item[key] && (valuesByKey[key].indexOf(item[key]) === -1)) {
          valuesByKey[key].push(item[key]);
        }
      });
    });

    keys.forEach((key: string) => {

      const values: any[] = (valuesByKey[key] || []).map((value: string) => {
        return { label: value, value: value };
      });

      if (!!values && !!values.length) {
        filters.push({
          label: key,
          name: key,
          type: 'select',
          values: values,
          uid: key,
        });
      }
    });

    this.view.filters = (filters || []);
  }

  calcParams() {
    return {
      data: this.data,
      mode: this.view.outputType,
      settings: this.settings,
      source: this.source,
      type: this.type,
    };
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    // skip intro view if data is provided
    if (!this.view.startManually && (!!this.service && !!this.items && !!this.items.length)) {
      this.view.startManually = true;
    }

    if (!!this.data && !!this.data[0] && (!this.fields || !this.fields.length)) {
      this.setFields(Object.keys(this.data[0]));
    }
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
  }

  export() {
    let params: dataExportOptions = this.calcParams();

    const items: any[] = (!!this.view.selectedItems ? this.view.selectedItems : this.data) || [];
    console.log('export: items', items);

    if (!items || !items.length) {
      return false;
    }

    switch (this.view.target_type) {
      case 'file':
        this.exportToFile(params);
        break;
      case 'object_type':
        this.exportToObjectType(params);
        break;
      case 'tool':
        this.exportToTool(params);
        break;
      case 'url':
        this.exportToUrl(params);
        break;
      default:
        this.events.publish('error', `unsupported target type: ${this.view.target_type}`);
        break;
    }
  }

  exportOffline() {
    this.exportService
      .setData(this.view.data)
      .setItemParser((item: any) => {
        return {
          uid: item.uid,
          connection: item.connection.name,
          type: item.type,
          route: item.route,
          response: `"${(!!item.response ? item.response : '')}"`.replace(/n/g, ''),
          platform: item.platform,
          timestamp: item.timestamp,
        };
      })
      .asCsv()
      .download();
  }

  exportToFile(params: any) {
    console.log('exportToFile: params', params);
  }

  exportToObjectType(params: any) {
    console.log('exportToObjectType: params', params);
    console.log('exportToObjectType: view.target_object_type', this.view.target_object_type);

    if (!this.view.target_object_type) {
      return false;
    }

    switch (this.view.target_object_type) {
      default:
        this.events.publish('error', `unsupported target object type: ${this.view.target_object_type}`);
    }
  }

  async exportToTool(params: any) {
    const items: any[] = (!!this.view.selectedItems ? this.view.selectedItems : this.data) || [];

    if ((!items || !items.length) || !this.view.target_tool) {
      return false;
    }

    try {
      switch (this.view.target_tool) {

        case 'shop_products':

          const configuredFields: any[] = (this.fields || []).filter((field: any) => {
            return !!field && !!field.uid && !!field.value;
          });

          if (!configuredFields || !configuredFields.length) {
            this.events.publish('error', 'error_missing_mapping_information');
            return false;
          }

          this.view.loading = true;
          this.view.progress_text = null;

          // parse items to product data
          const products: product[] = (items as any[]).map((item: any) => {
            let _product: product = {};

            configuredFields.forEach((field: any) => {
              if (!!item.value && (typeof item.value === 'object') && item.value.hasOwnProperty(field.uid)) {
                _product[field.value] = item.value[field.uid];
              }
            });

            return _product;
          });

          const importItems: any = await this.shopAdmin.importProducts(products as product[], {
            onPartialResult: (event: any) => {
              if (!!event && !!event.counts) {
                this.view.progress_text = parseInt(`${(100 / event.counts.all) * event.counts.done}`) + '%';
              }
            },
          });

          this.view.loading = false;

          this.dismiss();
          break;

        default:
          this.events.publish('error', `unsupported target tool: ${this.view.target_tool}`);
      }
    } catch (e) {
      this.events.publish('error', e);
      this.view.loading = false;
    }
  }

  exportToUrl(params: any) {

    if (!!this.view.exportUrl) {
      params.url = this.view.exportUrl;
    }

    this.view.loading = true;

    this.exportService.executeServerSideExport(params)
      .then((response: any) => {
        this.view.loading = false;
        this.view.smart_link = (!!response.smart_link ? response.smart_link : null);

        if (!!this.view.smart_link && !!this.view.smart_link.url) {
          this.browser.create(this.view.smart_link.url);
        }
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load() {
    this.view.loading = true;

    await this.loadProject();

    if (!!this.view.project) {
      await this.loadIntegrations();
      await this.loadObjectTypes();
    }

    if (!!this.data && !!this.data[0] && (!this.fields || !this.fields.length)) {
      this.setFields(Object.keys(this.data[0]));
    }

    this.view.source_object_type = this.source_object_type || this.view.source_object_type;
    this.view.source_type = this.source_type || this.view.source_type;
    this.view.source_tool = this.source_tool || this.view.source_tool;
    this.view.target_type = this.target_type || this.view.target_type;
    this.view.target_tool = this.target_tool || this.view.target_tool;

    this.source = this.view.source_type || this.source;
    this.type = (this.view.source_object_type || this.view.source_tool) || this.type;

    // if all params are provided, skip intro config view
    if (!!this.data && !!this.service && !!this.source && !!this.type) {

      // mark all data as checked by default
      this.data = this.data.map((item: any) => {
        item.checked = true;
        return item;
      });

      // calculate selected items variable
      this.view.selectedItems = (this.view.selectedItems || this.data) || [];

      this.calcExportUrl();
      this.calcFilters();
      this.onTargetOptionChanged();
      this.startManually();
    }

    this.view.loading = false;
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.integrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadObjectTypes(blForceRefresh: boolean = false) {
    try {
      const types: any = await this.objects.getTypes({}, blForceRefresh);

      this.view.object_types = (!!types && !!types.length ? types : []).map((type: any) => {

        if (!type.icon && (!!type.value && !!type.value.icon)) {
          type.icon = type.value.icon;
        }

        if (!type.name && (!!type.value && !!type.value.name)) {
          type.name = type.value.name;
        }

        return type;
      });
    } catch (e) {
      console.warn('loading object types failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.view.viewType = 'table';

    this.loadCards();
    this.calcViewVars();

    try {
      this.view.loading = true;
      this.load();
    } catch (e) {
      this.view.loading = false;
      this.events.publish('error', e);
    }
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
    this.calcViewVars();
  }

  onFormatChanged() {
    console.log('onFormatChanged', this.settings.format);

    switch (this.settings.format) {
      case 'csv':
        this.view.seperator = 'comma';
        break;
      case 'tsv':
        this.view.seperator = 'tab';
    }
  }

  async onIntegrationChanged(event: any | null = null) {
    console.log('onIntegrationChanged', event);

    this.view.integrationName = (!!this.view.integration ? `${this.view.integration.name}`.replace('integration_', '') : null);
    this.view.startManually = true;

    //await this.loadSettings();
    //await this.loadRoutes();
  }

  onItemsChanged(items: any[] = []) {
    this.view.selectedItems = (items || []);
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.calcExportUrl();

    if (!!this.shortlinkGenerator) {
      this.shortlinkGenerator.invalidateSmartLink(); //requestSmartLink();
    }
  }

  onLanguageChange(event: string | null = null) {
    this.view.language = event;
    this.saveFilters();
  }

  onOverlayInputChanged(event: any = null) {
    
    if (!event || !event.input || (!!event.input.name && (event.input.name !== 'input')) || !event.input.hasOwnProperty('value')) {
      return false;
    }

    this.view.aiCreateInput = event.input.value;
  }

  onSourceObjectTypeChanged(event: any | null = null) {
    console.log('data-exporter: onSourceObjectTypeChanged: view.source_object_type', this.view.source_object_type);
  }

  onSourceTypeChanged(event: any | null = null) {
    console.log('data-exporter: onSourceTypeChanged: view.source_type', this.view.source_type);

    switch (this.view.source_type) {
      case 'object_type':
        //this.startManually();
        break;
      case 'tool':
        //this.startManually();
        break;
    }
  }

  onTargetOptionChanged(event: any = null) {

    if (!this.view.target_type) {
      return false;
    }

    switch (this.view.target_type) {
      case 'file':
        break;
      case 'integration':
        break;
      case 'object_type':

        if (!this.view.target_object_type) {
          return false;
        }

        switch (this.view.target_object_type) {
          default:
            this.events.publish('error', `unsupported target object_type: ${this.view.target_object_type}`);
        }

        break;
      case 'tool':

        if (!this.view.target_tool) {
          return false;
        }

        switch (this.view.target_tool) {
          case 'shop_products':
            this.setFieldsOptions(['active', 'description', 'gtin', 'image', 'name', 'original_uid', 'price', 'sku', 'stock', 'url']);
            break;
          default:
            this.events.publish('error', `unsupported target tool: ${this.view.target_tool}`);
        }

        break;
      case 'url':
        break;
      default:
        this.events.publish('error', `unsupported target type: ${this.view.target_type}`);
    }
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      //this.media.saveFilters(this.view.filters);
    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  setFields(fields: any[]) {
    this.fields = (fields || []);

    try {
      this.tableView.setFields(this.fields);
    } catch (e) {
      console.warn('updating table view fields failed: ', e);
    }
  }

  setFieldsOptions(options: any[]) {

    if (!this.fields || !this.fields.length) {
      return false;
    }

    options = options || [];

    this.fields.forEach((field: any) => {

      if (typeof field !== 'object') {
        return;
      }

      field.placeholder = 'select';
      field.value = null;
      field.options = options;
    });
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}