<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="save()" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-grid>

      <ion-row>

        <ion-col [size]="view.isDesktop ? 6 : 12">

          <!-- Tool -->
          <ion-card *ngIf="!!tools && !!tools.length">
            <ion-item lines="none">

              <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

              <ion-select multiple interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="entry.tools" [label]="'tool'|translate">
                <ion-select-option *ngFor="let tool of tools" [innerHTML]="tool.label|translate"
                  [value]="tool.uid"></ion-select-option>
              </ion-select>

            </ion-item>
          </ion-card>

          <!-- Context -->
          <ion-card *ngIf="!!contexts && !!contexts.length"
            [hidden]="!entry.uid && (!entry.tools || !entry.tools.length)">
            <ion-item lines="none">

              <ion-icon name="funnel-outline" slot="start"></ion-icon>

              <ion-select multiple interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="entry.contexts" [label]="'contexts'|translate">
                <ion-select-option *ngFor="let context of contexts" [innerHTML]="context.label|translate"
                  [value]="context.uid"></ion-select-option>
              </ion-select>

            </ion-item>
          </ion-card>

          <!-- Input -->
          <ion-card>

            <ion-item lines="none">
              <ion-textarea rows="15" [(ngModel)]="entry.input"
                [placeholder]="'ai_knowledgebase_input_key_placeholder'|translate"></ion-textarea>
            </ion-item>

          </ion-card>

          <!-- Output -->
          <ion-card>

            <ion-item lines="none">
              <ion-textarea rows="15" [(ngModel)]="entry.output"
                [placeholder]="'ai_knowledgebase_output_value_placeholder'|translate"></ion-textarea>
            </ion-item>

          </ion-card>

        </ion-col>

        <ion-col [size]="view.isDesktop ? 6 : 12">

          <!-- DANI -->
          <getgenius-dani #daniComponent [(config)]="daniConfig" [state]="view.dani.state"></getgenius-dani>
          
        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="end">

      <!-- View mode -->
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>

    </ion-buttons>

  </ion-toolbar>
</ion-footer>