import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { AiModelsService } from 'src/app/services/ai/ai-models.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from "src/app/services/ai/ai-worker.service";

import { EventsService } from "src/app/services/core/events.service";
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

import { InfoPopoverComponent } from 'src/app/components/generic/info-popover/info-popover.component';

@Component({
  selector: 'pipeline-ai-settings-card',
  standalone: false,
  templateUrl: './ai-settings-card.component.html',
  styleUrls: ['./ai-settings-card.component.scss']
})
export class AiSettingsCardComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('chooserPopover') chooserPopover: any;
  @ViewChild(InfoPopoverComponent) infoPopover: any;

  @Input() cards: any = {};
  @Input() config: aiSettings;
  @Input() models: aiModel[] = [];

  @Input() options: aiSettingsOptions;

  @Input() providers: aiProvider[];
  @Input() view: any = {};

  @Output() changed = new EventEmitter();

  chooseConfig: chooseConfig = {
    labelKey: 'name',
    showPhoto: true,
    subLabelKey: 'provider',
    valueKey: 'uid',
  };

  fallbackImg: string = './assets/img/fallback.webp';

  isChooserPopoverOpen: boolean = false;

  operations: string[] = [
    'image_to_video',
    'text_generation',
    'text_to_image',
  ];

  precisions: string[] = [
    'bf16',
    'fp4',
    'fp8',
    'fp16',
    'fp32',
  ];

  samplers: any[];

  shouldShow: any = {};

  constructor(
    public aiTools: AiToolsService,
    private aiModels: AiModelsService,
    public aiWorker: AiWorkerService,

    private events: EventsService,
    private integrations: IntegrationsService,
    private sd: StablediffusionService,
    private sidebar: SidebarService,
  ) {
  }

  addModel(key: string = 'models', multiple: boolean = true, category: string | null = null) {

    if (key === 'models' && !multiple) {
      key = 'model';
    } else
      if (key === 'model' && !!multiple) {
        key = 'models';
      }

    if (!category && !!this.config.context) {
      category = this.aiTools.mapContextToCategory(this.config.context);
    }

    const chooseConfig: any = {
      filter: {
        category: category,
        provider: this.config.provider,
      },
      multiple: !!multiple,
    };

    this.aiModels.choose(chooseConfig)
      .then((response: any) => {
        const blSingleModelSelected: boolean = !!response.uid || (!!response.provider && !!response.name);
        const data: any[] = (blSingleModelSelected ? [response] : (!multiple ? [response.item] : (!!response && !!response.items ? response.items : (!!response.item ? [response.item] : []))));

        if (!!data) {
          this.config[key] = data;
          this.aiWorker.setConfig(this.config);

          this.loadModels();
          this.onChanged();
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  calcShouldShowOperationVars() {
    if (!!this.operations && !!this.operations.length) {
      this.operations.forEach((operation: string) => {
        const blOperationInOptions: boolean = (!this.options || !this.options.operations || (this.options.operations.indexOf(operation) !== -1));
        this.shouldShow[operation] = (!this.config.context || (this.config.context === operation)) && !!blOperationInOptions;
      });
    }
  }

  calcViewVars() {
    this.config = (this.config || {}) as aiSettings;
    this.config.provider = this.config.provider || 'getgenius';

    if (!this.config.hasOwnProperty('temperature')) {
      this.config.temperature = 1;
    }

    if (!this.view.hasOwnProperty('samplers')) {
      this.loadSamplers();
    }

    this.calcShouldShowOperationVars();
  }

  chooseModel(key: string = 'models', multiple: boolean = true, category: string | null = null, event: any = null) {

    // if no preferred models found, open AI models store
    if (!this.models || !this.models.length) {
      return this.addModel(key, multiple, category);
    }

    // else, open chooser popver
    this.chooseConfig.multiple = multiple;
    this.chooseConfig.subLabelKey = 'provider';
    this.chooserPopover.event = event;

    this.view.chooseModelCategory = category;
    this.view.chooseModelMultiple = multiple;
    this.view.chooserKey = key;

    this.view.data = this.models || [];

    this.isChooserPopoverOpen = true;
  }

  chooseProvider(event: any) {
    this.chooseConfig.multiple = false;
    this.chooseConfig.subLabelKey = null;
    this.chooserPopover.event = event;

    this.view.chooserKey = 'provider';
    this.view.data = this.providers || [];

    this.isChooserPopoverOpen = true;
  }

  filterModels(models: aiModel[] | null = null) {
    models = models || this.models;

    if (!models || !models.length) {
      return [];
    }

    const category: string | null = (!!this.config.context ? this.aiTools.mapContextToCategory(this.config.context) : null);

    return models.filter((model: aiModel) => {
      return (
        (!category || (model.category === category)) &&
        (!this.config.provider || (model.provider === this.config.provider) || (this.config.provider === 'getgenius'))
      );
    });
  }

  filterProviders(providers: aiProvider[] | null = null) {
    providers = providers || this.providers;

    if (!providers || !providers.length) {
      return [];
    }

    const category: string | null = (!!this.config.context ? this.aiTools.mapContextToCategory(this.config.context) : null);

    return providers.filter((provider: aiProvider) => {
      return (
        (!category || (!!provider.operations && (provider.operations.indexOf(category) !== -1)) || (provider.uid === 'getgenius'))
      );
    });
  }

  async load() {
    const aiSettings: aiSettings = await this.aiWorker.getConfig();

    if (!!aiSettings) {
      aiSettings.sampler_name = aiSettings.sampler_name || 'auto';

      this.config = Object.assign(this.config || {}, aiSettings || {});
    }

    await this.loadProviders();
    await this.loadModels();
  }

  async loadModels() {
    let models: aiModel[] = [];

    try {
      const context: string = this.config.context || 'general';

      models = await this.aiModels.getPreferred(context) as aiModel[];
      models = (!!models && !!models.length ? models : []) as aiModel[];

      // apply models from config if not in preferred list but found in config
      if ((!models || !models.length) && (!!this.config && !!this.config.models && !!this.config.models.length)) {
        models = this.config.models as aiModel[];
      }

    } catch (e) {
      console.warn('loading models failed', e);
    }

    if (!!models && !!models.length) {
      models = this.filterModels(models) as aiModel[];
    }

    this.models = models;

    return this.models;
  }

  async loadProviders() {
    let providers: aiProvider[] = [];

    try {
      providers = (await this.aiTools.getAvailableProviders() || []) as aiProvider[];
      providers = (!!providers && !!providers.length ? providers : []);

      if (!!providers && !!providers.length) {
        providers = this.filterProviders(providers) as aiProvider[];
      }
    } catch (e) {
      console.warn('loading providers failed', e);
    }

    this.providers = providers;

    return providers;
  }

  loadSamplers() {
    try {
      this.samplers = [
        {
          name: 'automatically',
          uid: 'auto',
        }
      ].concat(this.sd.getSamplers());
    } catch (e) {
      console.warn('loading samplers failed', e);
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.load();
    this.calcViewVars();
  }

  async onChanged(event: any | null = null, key: string | null = null) {
    this.aiWorker.setConfig(this.config);
    this.changed.emit(this.config || {});

    switch (key) {
      case 'context':
        this.onContextChanged();
      case 'lora':
        await this.loadModels();
      case 'model':
        await this.loadModels();
      case 'models':
        await this.loadModels();
      case 'provider':
        await this.loadModels();
    }
  }

  async onContextChanged() {
    await this.loadProviders();
    await this.loadModels();

    this.config.models = (this.models || []);
    this.aiWorker.setConfig(this.config);
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    
    this.infoPopover.show({
      content: message,
      event: e,
    });
  }

  async selectedItemsChanged(event: any = null) {

    if (!event) {
      return false;
    }

    if (!!event.multi && event.hasOwnProperty('items') && !!this.view.chooserKey) {
      // update multi select response
      this.config[this.view.chooserKey] = (event.items || []);
    } else
      if (!event.multi && event.hasOwnProperty('item') && !!this.view.chooserKey) {
        // update single select response

        if (!!this.chooseConfig.valueKey) {
          this.config[this.view.chooserKey] = (event.item[this.chooseConfig.valueKey] || null);
        } else {
          this.config[this.view.chooserKey] = (event.item || null);
        }

      }

    switch (this.view.chooserKey) {
      case 'provider':
        if (!this.config.provider || (this.config.provider === 'getgenius')) {
          // no provider validation required
          return false;
        } else
          if (this.config.provider === 'local') {
            // run device check
          } else {
            // run integration check
            try {
              const check: any = await this.integrations.validateIntegrationByUid(this.config.provider);
              console.log('check: ', check);
            } catch (e) {
              console.warn('check failed: ', e);
            }
          }
        break;
    }

    this.aiWorker.setConfig(this.config);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}