<ion-card class="sidebar-accordion-card">

    <ion-card-header [hidden]="!!options && !!options.hideHeader">

        <ion-card-title (click)="toggleCard('overlay')">
            <span [innerHTML]="'text_overlay'|translate"></span>
        </ion-card-title>

        <ion-button class="card-toggle" *ngIf="!!cards && !!cards.overlay" (click)="toggleCard('overlay')" icon-only
            color="dark" fill="clear">
            <ion-icon
                [name]="!!cards && !!cards.overlay && cards.overlay.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <div [hidden]="!!cards && !!cards.overlay && !cards.overlay.open">

        <ion-card-content [hidden]="!!options && !!options.hideHeader">
            <p [innerHTML]="'media_creator_text_overlay_text'|translate"></p>
        </ion-card-content>

        <pipeline-media-create-form-automotive [view]="view" (onViewChanged)="_onOverlayFormViewChanged($event)"
            *ngIf="view.industryUI === 'automotive'">
        </pipeline-media-create-form-automotive>

        <pipeline-media-create-form-default [view]="view" (onViewChanged)="_onOverlayFormViewChanged($event)"
            *ngIf="!view.industryUI || view.industryUI === 'default'">
        </pipeline-media-create-form-default>

        <pipeline-media-create-form-dynamic [view]="view" (onViewChanged)="_onOverlayFormViewChanged($event)"
            *ngIf="view.industryUI === 'dynamic'">
        </pipeline-media-create-form-dynamic>

        <pipeline-media-create-form-realestate [view]="view" (onViewChanged)="_onOverlayFormViewChanged($event)"
            *ngIf="view.industryUI === 'realestate'">
        </pipeline-media-create-form-realestate>

        <ion-grid>
            <ion-row>

                <!-- Add overlay section -->
                <ion-col size="6">
                    <ion-button color="dark" class="default-btn" (click)="addSection()" fill="clear">
                        <ion-icon name="add-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'add'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>
                </ion-col>

                <!-- Optimize overlay sections -->
                <ion-col size="6" [hidden]="!view.sections || !view.sections.length">
                    <ion-item lines="none" class="ion-text-wrap">
                        <ion-checkbox [(ngModel)]="view.media.blFineTuneInput" (ionChange)="toggleFineTune()"
                            justify="start" labelPlacement="end" [innerHTML]="'media_creator_fine_tune_input'|translate"
                            class="ion-text-wrap"></ion-checkbox>
                    </ion-item>
                </ion-col>

            </ion-row>
        </ion-grid>

    </div>

</ion-card>