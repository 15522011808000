import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AiToolsService } from 'src/app/services/ai/ai-tools.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { proxyUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class PolotnoService {

    apiKey: string = 'QdxAQBfP8gF-3fbZEQpH';

    constructor(
        private aiBridge: AiBridgeService,
        private aiTools: AiToolsService,

        private AppCMS: AppcmsService,
        private projects: ProjectsService,
        private tools: ToolsService,
    ) {

    }

    runAiPromptOnSingleTemplate(prompt: string, json: any, options: any = {}) {
        return new Promise(async (resolve, reject) => {

            let history: aiExecutionHistoryItem[] = [
                {
                    role: 'system',
                    input: `You manipulate a list of layers of a polotno template (as a JSON object).
                    Make sure that all provided system and user instructions are incorporated.
                    Translate everything based on the user language.
                    You answer exclusively in JSON, without any further comments or notes.`,
                },
            ];

            const project: project | null = await this.projects.getCurrent();

            if (!!project && !!project.uid) {
                history.push({
                    role: 'system',
                    input: `Some CI information:
                    - Customer / Project name: ${project.title || 'not provided'}
                    - Logo URL: ${project.photo || 'not provided'}
                    - Link to website: ${project.link || 'not provided'}`
                });
            }

            if (!!options.mediaUrls && !!options.mediaUrls.length) {
                history.push({
                    role: 'system',
                    input: `Media URLS to replace existing images (src) with:
                    ${options.mediaUrls.join("\n-")}`
                });
            }

            history.push({
                role: 'system',
                input: `IMPORTANT:
                        - Your output must be a flat JSON object (the "children" list) of polotno layers (as provided)
                        - An element can have one of these types: text, image, svg, line, figure, video, group
                        - Each element must have an id (string)
                        - Follow the polotno docs: https://polotno.com/docs/element-overview`,
            });

            let children: any[] = [];
            let cleanedChildren: any[] = [];

            if (!!json && !!json.pages && !!json.pages[0] && !!json.pages[0].children) {
                children = JSON.parse(JSON.stringify(json.pages[0].children));

                cleanedChildren = children.map((child: any) => {
                    let _child: any = JSON.parse(JSON.stringify(child));

                    delete _child.animations;
                    delete _child.draggable;
                    delete _child.id;
                    delete _child.removable;
                    delete _child.resizable;
                    delete _child.selectable;
                    delete _child.visible;

                    _child.src = '';

                    return _child;
                });

                history.push({
                    input: `${JSON.stringify(cleanedChildren)}`,
                    role: 'assistant',
                });
            }

            this.aiBridge.execute({
                history: history,
                post_content: `User input / requested changes: "${prompt || 'none'}"`,
            })
                .then((response: any) => {

                    if (!response || !response.output || !response.output.length) {
                        console.warn('runAiPromptOnSingleTemplate: failed!', response);
                    } else
                        if (!!response && !!response.output && (response.output[0] === '[' || response.output[0] === '{')) {
                            let updatedJson: any = this.tools.extractJson(response.output);

                            if (!!updatedJson && !!updatedJson.children) {
                                updatedJson = updatedJson.children;
                            }

                            // re-apply data after LLM json modification
                            updatedJson.forEach((child: any, index: number) => {

                                // re-apply animations
                                if (!!children[index] && !!children[index].animations && !updatedJson[index].animations) {
                                    updatedJson[index].animations = children[index].animations;
                                }

                                // re-apply id
                                if (!!children[index] && !!children[index].id) {
                                    updatedJson[index].id = children[index].id;
                                }

                                // re-apply source
                                if (!!updatedJson[index].src && (updatedJson[index].src.indexOf(proxyUrl) === -1) && (updatedJson[index].src.indexOf('api.getgenius.ai') !== -1)) {
                                    updatedJson[index].src = proxyUrl + updatedJson[index].src;
                                    console.log('proxied updatedJson[index].src', updatedJson[index].src);
                                } else
                                    if (!!children[index] && !!children[index].src && !updatedJson[index].src) {
                                        updatedJson[index].src = children[index].src;
                                    }
                            });

                            resolve({
                                before: json,
                                after: updatedJson,
                            });
                        } else
                            if (!!response && !!response.output) {
                                reject(response.output);
                            }
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    search(query: string, options: any = {}) {
        return new Promise(async (resolve, reject) => {
            try {

                // simplify user input
                if (!options.useRawUserInput) {

                    const simplifyResponse: any = await this.aiTools.convertUserInputToSearchQuery(query, {
                        language: 'en',
                    });

                    if (!!simplifyResponse && !!simplifyResponse.output) {
                        query = simplifyResponse.output;
                    }
                }

                const queries: string[] = [query];
                const querySplit: string[] = query.split('+');

                if (querySplit.length > 1) {
                    queries.push(querySplit[0]);
                }

                console.log('polotno search: queries', queries);

                options = options || {};
                options.limit = options.limit || 12;
                options.page = options.page || 1;
                options.height = options.height || 1080;
                options.width = options.width || 1080;

                let response: any = {
                    "hits": 0,
                    "totalPages": 0,
                    "items": [],
                };

                let foundJson: string[] = [];

                queries.forEach(async (query: string, index: number) => {
                    const searchUrl: string = `https://api.polotno.com/api/get-templates?size=${options.width}x${options.height}&query=${encodeURIComponent(query)}%20&per_page=${options.limit}&page=${options.page}&KEY=${this.apiKey}`;
                    const lookupResponse: any = await this.AppCMS.loadUrl(searchUrl);

                    if (!!lookupResponse && !!lookupResponse.items) {
                        lookupResponse.items.forEach((item: any) => {
                            if (!!item.json && (foundJson.indexOf(item.json) === -1)) {
                                foundJson.push(item.json);
                                response.items.push(item);
                            }
                        });
                    }

                    if (index === (queries.length - 1)) {
                        resolve(response);
                    }
                });
            } catch (e) {
                reject(e);
            }
        })
    }

}