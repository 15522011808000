import { Pipe, PipeTransform } from '@angular/core';

import { ViewService } from 'src/app/services/core/view.service';

interface VariableMapping {
  key: string;
  value: string | number | boolean;
}

@Pipe({
  name: 'fillVars',
  standalone: false
})
export class FillVarsPipe implements PipeTransform {

  private variableMappings: VariableMapping[] = [];

  constructor(
    private viewService: ViewService,
  ) {
    this.variableMappings = this.viewService.getVariableMappings();
  }

  public addVariableMapping(key: string, value: string | number | boolean): void {

    if (!key.startsWith('${') || !key.endsWith('}')) {
      throw new Error('Variable key must be in format ${variableName}');
    }

    const existingIndex = this.variableMappings.findIndex(m => m.key === key);

    if (existingIndex >= 0) {
      this.variableMappings[existingIndex].value = value;
    } else {
      this.variableMappings.push({ key, value });
    }

  }

  public removeVariableMapping(key: string): void {
    this.variableMappings = this.variableMappings.filter(m => m.key !== key);
  }

  transform(str: string): string {
    if (!str) return '';

    return this.variableMappings.reduce((result, mapping) => {
      return result.replaceAll(mapping.key, String(mapping.value));
    }, str);
  }
}