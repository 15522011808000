import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';

import { MediaInputInformationCardComponent } from './media-input-information-card.component';

import { InfoPopoverComponentModule } from 'src/app/components/generic/info-popover/info-popover.component.module';

@NgModule({
  declarations: [
    MediaInputInformationCardComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    TagInputModule,
    InfoPopoverComponentModule,
  ],
  exports: [
    MediaInputInformationCardComponent,
  ]
})
export class MediaInputInformationCardComponentModule { }