<ion-item button lines="none" [disabled]="!!view.loadingProjects"
  [drop]="!!view.isDesktop" [dropItem]="view.project" dropItemType="project"
  (itemDropped)="handleItemDropped($event)">

  <!-- Avatar -->
  <ion-avatar slot="start" *ngIf="!!view.project" (click)="editProject()">
    <ion-skeleton-text class="image" animated [hidden]="!view.loadingProjects"></ion-skeleton-text>
    <ion-img [src]="view.project.photo || fallbackImg" (ionError)="thumbnailLoadingFailed()"
      [hidden]="!!view.loadingProjects"></ion-img>
  </ion-avatar>

  <!-- Default icon (legacy) -->
  <ion-icon *ngIf="!view.project" name="briefcase-outline" slot="start" (click)="pick()"
    [hidden]="!!view.loadingProjects"></ion-icon>

  <!-- Label -->
  <ion-label (click)="pick()" [hidden]="!!view.loadingProjects || (!!view.project && !view.isDesktop)">
    <h3 [innerHTML]="'project'|translate"></h3>
    <p [innerHTML]="(!!view.project ? view.project.title : 'select'|translate)"></p>
  </ion-label>

  <!-- Mobile toggle button -->
  <ion-button slot="end" (click)="pick()" *ngIf="!view.isDesktop" [hidden]="!!view.loadingProjects">
    <ion-icon name="chevron-down" slot="end"></ion-icon>
  </ion-button>

  <!-- Spinner (if projects are refreshing) -->
  <ion-spinner slot="end" [hidden]="!view.loadingProjects"></ion-spinner>

</ion-item>