<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.startManually">

      <!-- Connect -->
      <ion-button color="primary" *ngIf="!source.uid" [disabled]="!view.success || !view.verifyOwnership || (!source.name)" shape="round"
        fill="solid" (click)="submit()">
        <ion-icon name="link-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'connect'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button color="primary" *ngIf="!!source.uid" [disabled]="!view.success || !view.verifyOwnership || (!source.name)" shape="round"
        fill="solid" (click)="update()">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!!view.startManually"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- Connect shop intro mode -->
  <pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" [loading]="view.loading" (onCreate)="aiCreate()"
    (onInputValidated)="onInputValidated($event)" (onSkip)="startManually()"
    (onSlideNext)="onIntroOverlaySlideNext($event)" [(view)]="view"></pipeline-intro-overlay>

  <div class="container">

    <!-- Success card -->
    <ion-card color="success" [hidden]="!view.success || !view.startManually">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline_success'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_shop_text_success'|translate"></p>
      </ion-card-content>
    </ion-card>

    <!-- Error card -->
    <ion-card color="danger" [hidden]="(view.success !== false) || !view.startManually">
      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline_failed'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'connect_shop_text_failed'|translate"></p>
      </ion-card-content>
    </ion-card>

    <!-- Connect shop card -->
    <ion-card>

      <ion-card-header>
        <ion-card-title [innerHTML]="'connect_shop_headline'|translate"></ion-card-title>
      </ion-card-header>

      <ion-card-content>
        <p [innerHTML]="'connect_shop_text'|translate"></p>
        <ion-searchbar type="url" [(ngModel)]="view.input" [placeholder]="'url'|translate"
          (ionInput)="onUrlChanged()"></ion-searchbar>
      </ion-card-content>

      <!-- Loading -->
      <ion-spinner [hidden]="!view.loading"></ion-spinner>

      <h3 [innerHTML]="'connect_shop_loading_text'|translate" [hidden]="!view.loading"></h3>

      <ion-list *ngIf="view.success">

        <ion-item>

          <ion-thumbnail slot="start" (click)="onSourcePhotoClick()">
            <ion-img [src]="source.photo || fallbackImg" (ionError)="thumbnailLoadingFailed()"></ion-img>
          </ion-thumbnail>

          <ion-input [(ngModel)]="source.name" [label]="'name'|translate" labelPlacement="floating"></ion-input>

        </ion-item>

        <!-- Language -->
        <pipeline-languages-select [(input)]="source.language"></pipeline-languages-select>

        <!-- Country -->
        <ion-item button (click)="pickCountry()">

          <ion-icon name="location-outline" slot="start"></ion-icon>

          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'country'|translate"></h3>
          </ion-label>

          <ion-note slot="end" [innerHTML]="(source.country || 'select')|translate"></ion-note>

        </ion-item>

        <!-- Shop-System -->
        <ion-item lines="none">

          <ion-icon name="hardware-chip-outline" slot="start"></ion-icon>
          
          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="'shop_system'|translate"></h3>
          </ion-label>

          <ion-note slot="end" [innerHTML]="(!!source.system ? 'shop_system_' + source.system : 'not_detected')|translate"></ion-note>

        </ion-item>

      </ion-list>

    </ion-card>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="ion-no-border container">
    <ion-item lines="none" [hidden]="!!view.loading">
      <ion-checkbox [disabled]="!view.success" [(ngModel)]="view.verifyOwnership" justify="start" labelPlacement="end"
        [innerHTML]="'verify_ownership_shop_text'|translate|fillVars" class="ion-text-wrap"></ion-checkbox>
    </ion-item>
  </ion-toolbar>
</ion-footer>