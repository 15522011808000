<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start" *ngIf="!inSetupEmbedded">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

      <!-- Options button -->
      <ion-button *ngIf="mode !== 'pick'" (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark"
        [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add button -->
      <ion-button *ngIf="mode !== 'pick'" (click)="add($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

      <!-- Pick selected -->
      <ion-button *ngIf="mode === 'pick'" (click)="pick($event)" shape="round" color="primary" fill="solid">
        <ion-icon slot="start" name="checkmark"></ion-icon>
        <ion-label [innerHTML]="'select'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [(items)]="view.items" [options]="selectionOptions" [(view)]="view"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importEntries()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.items && !!view.items.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Mobile intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
      [(view)]="introCard"></pipeline-intro-card>

    <ion-grid class="ai-knowledgebase-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [service]="aiTools" [(view)]="view"
            location="knowledgebase" (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadItemsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Attributes -->
          <!--<pipeline-attributes-filter-card [(cards)]="cards" [(view)]="view" location="knowledgebase"
            (filtersChanged)="onAttributeFiltersChanged($event)"></pipeline-attributes-filter-card>-->

          <!-- Tools -->
          <pipeline-tags-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [items]="view.tools"
            [service]="aiTools" [title]="'tools'" [(view)]="view"
            (selectionChanged)="onSelectedToolChanged($event)"></pipeline-tags-filter-card>

          <!-- Contexts -->
          <pipeline-tags-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [items]="view.contexts"
            [service]="aiTools" [title]="'contexts'" [(view)]="view"
            (selectionChanged)="onSelectedContextChanged($event)"></pipeline-tags-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <ion-grid>
            <ion-row>

              <!-- Knowledgebase entries -->
              <ion-col [size]="view.isDesktop && (mode !== 'pick') ? 8 : 12">

                <!-- Desktop intro card -->
                <div *ngIf="!!view.isDesktop" [hidden]="introCard.hidden">
                  <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
                </div>

                <!-- No entries -->
                <pipeline-no-entries-card [cta]="cta" [text]="'ai_knowledgebase_cta_text'|translate"
                  *ngIf="view.items && !view.items.length && !view.loading"></pipeline-no-entries-card>

                <!-- Loading spinner -->
                <ion-spinner name="circular" *ngIf="!!view.loading"></ion-spinner>

                <!-- Items list -->
                <div *ngIf="!!view.items && !!view.items.length">

                  <!-- Single knowledgebase entry -->
                  <ion-card class="entry-card" *ngFor="let entry of view.items; trackBy:trackItems"
                    [hidden]="entry.hidden" [draggable]="!!view.isDesktop" [dragItem]="entry"
                    [attr.data-location]="'knowledgebase'" [attr.drag-location]="'MEDIA_ADMIN'"
                    [attr.data-model]="view.items" [attr.data-type]="'mediaItem'" [attr.data-uid]="entry.uid">

                    <!-- Top item -->
                    <ion-item>

                      <ion-checkbox class="ion-text-wrap" justify="start" labelPlacement="end"
                        [(ngModel)]="entry.checked" (ionChange)="onItemCheckboxClicked(entry)"
                        [disabled]="entry.disabled">

                        <span class="active-span" (click)="toggleActive(entry)">
                          <ion-icon [name]="entry.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                            [color]="entry.active ? 'success' : 'danger'" slot="start"></ion-icon>
                          <span [hidden]="entry.active" [innerHTML]="'not_active'|translate"></span>
                          <span [hidden]="!entry.active" [innerHTML]="'active'|translate"></span>
                        </span>

                      </ion-checkbox>

                      <ion-button slot="end" icon-only (click)="edit(entry)" fill="clear" color="dark">
                        <ion-icon name="create-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'edit'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                      <ion-button slot="end" icon-only (click)="delete(entry)" fill="clear" color="danger">
                        <ion-icon name="trash-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'delete'|translate" [hidden]="!view.isDesktop"></ion-label>
                      </ion-button>

                    </ion-item>

                    <!-- Item input / output preview -->
                    <ion-grid>
                      <ion-row>

                        <!-- Input -->
                        <ion-col [size]="view.isDesktop ? 6 : 12">

                          <ion-item lines="none" class="ion-text-wrap">
                            <ion-label>
                              <p [innerHTML]="entry.input" class="ion-text-wrap"></p>
                            </ion-label>
                          </ion-item>

                        </ion-col>

                        <!-- Output -->
                        <ion-col [size]="view.isDesktop ? 6 : 12">

                          <ion-item lines="none">

                            <ion-label class="ion-text-wrap">
                              <h3 [innerHTML]="entry.output" class="ion-text-wrap"></h3>
                            </ion-label>

                          </ion-item>

                        </ion-col>

                      </ion-row>
                    </ion-grid>

                  </ion-card>

                </div>

              </ion-col>

              <!-- Preview -->
              <ion-col *ngIf="mode !== 'pick'" [size]="view.isDesktop ? 4 : 12">

                <ion-card class="test-card">

                  <ion-card-header>
                    <ion-card-title [innerHTML]="'test_changes_effects'|translate"></ion-card-title>
                  </ion-card-header>

                  <ion-spinner [hidden]="!view.loading && !view.rebuild"></ion-spinner>

                  <getgenius-dani *ngIf="!view.loading && !view.rebuild" #daniComponent [(config)]="daniConfig"
                    [state]="view.dani.state"></getgenius-dani>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!inSetupEmbedded">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>