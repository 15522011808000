<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start" *ngIf="!inSetupEmbedded">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="team && !!team.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!team.name || !team.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!team || !team.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!team.name || !team.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view" *ngIf="!inSetupEmbedded"></pipeline-header-toolbar>

  <!-- Segments -->
  <ion-toolbar class="container second-toolbar" *ngIf="!inSetupEmbedded">

    <ion-segment name="title" [(ngModel)]="view.segment" (ionChange)="onSegmentChange($event)"
      [disabled]="!team.name || !team.name.length">

      <!-- General -->
      <ion-segment-button value="general">
        <ion-icon name="information-outline"></ion-icon>
        <ion-label [innerHTML]="'general'|translate"></ion-label>
      </ion-segment-button>

      <!-- Permissions -->
      <ion-segment-button value="permissions">
        <ion-icon name="lock-closed-outline"></ion-icon>
        <ion-label [innerHTML]="'permissions'|translate"></ion-label>
      </ion-segment-button>

      <!-- Billing -->
      <ion-segment-button value="billing">
        <ion-icon name="card-outline"></ion-icon>
        <ion-label [innerHTML]="'billing'|translate"></ion-label>
      </ion-segment-button>

    </ion-segment>

  </ion-toolbar>

  <!-- Searchbar -->
  <ion-toolbar class="ion-no-border container second-toolbar"
    *ngIf="!!team && !!team.uid && (view.segment === 'members' || view.segment === 'projects')">
    <ion-searchbar [(ngModel)]="search.query" [placeholder]="'search_something'|translate"
      (ionInput)="runSearch()"></ion-searchbar>
  </ion-toolbar>

  <!-- Members actions -->
  <ion-toolbar *ngIf="!view.loading && (view.segment === 'members')">
    <ion-buttons slot="start">

      <!-- Add member -->
      <ion-button color="primary" (click)="addMember()">
        <ion-icon name="person-add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_member'|translate"></ion-label>
      </ion-button>

      <!-- Create new user -->
      <ion-button color="primary" fill="clear" (click)="createUser()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create_user'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

  <!-- Projects actions -->
  <ion-toolbar *ngIf="!view.loading && (view.segment === 'projects')">
    <ion-buttons slot="start">

      <!-- Add project -->
      <ion-button color="primary" (click)="addProject()">
        <ion-icon name="folder-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

      <!-- Create new project -->
      <ion-button color="primary" fill="clear" (click)="createProject()">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'create_project'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Abonnement overlay -->
  <getgenius-abo-overlay [requiresOne]="['premium', 'professional', 'enterprise']" [view]="view"
    [hidden]="view.aboVerified"></getgenius-abo-overlay>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!!view.introCard" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- General -->
    <div [hidden]="!!view.loading || (view.segment != 'general')">
      <ion-card>
        <ion-list [hidden]="view.segment != 'general'">

          <ion-item>

            <ion-thumbnail slot="start" (click)="uploadPhoto()">
              <ion-img [src]="team.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(team)"></ion-img>
            </ion-thumbnail>

            <ion-input type="text" [label]="'name'|translate" [autofocus]="true" labelPlacement="stacked"
              [(ngModel)]="team.name" [placeholder]="'team_name'|translate"></ion-input>

          </ion-item>

        </ion-list>
      </ion-card>
    </div>

    <!-- Permissions -->
    <div [hidden]="view.segment !== 'permissions'">

      <ion-grid>
        <ion-row>

          <!-- General -->
          <ion-col>
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
              </ion-card-header>

              <ion-list>

                <!-- Is team active? -->
                <ion-item>

                  <ion-icon [color]="team.active ? 'success' : 'danger'"
                    [name]="!!team.active ? 'checkmark-outline' : 'warning-outline'" slot="start"></ion-icon>

                  <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="team.active">
                    <ion-label [innerHTML]="'state_active'|translate"></ion-label>
                  </ion-checkbox>

                </ion-item>

              </ion-list>

            </ion-card>
          </ion-col>

          <!-- Members -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'members'|translate"></ion-card-title>
              </ion-card-header>

              <ion-spinner *ngIf="!team.members && !!view.loading"></ion-spinner>

              <!-- No members found -->
              <pipeline-no-entries-card
                *ngIf="!view.loading && (!team.members || !team.members.length)"></pipeline-no-entries-card>

              <ion-card-content>

                <ion-button color="primary" (click)="addMember()">
                  <ion-icon name="person-add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_to_team'|translate"></ion-label>
                </ion-button>

                <ion-button color="primary" (click)="createUser()" fill="clear">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_team'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <ion-list *ngIf="!!team.members && !!team.members.length">

                <div class="cdk-wrapper">
                  <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
                    [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
                    <div *cdkVirtualFor="let member of team.members" [hidden]="member.hidden">

                      <ion-item-sliding>

                        <ion-item button (click)="viewMember(member)">
                          <ion-avatar slot="start">
                            <ion-img [src]="member.photo && member.photo.length ? member.photo : avatarUrl"
                              (ionError)="member.photo=avatarUrl"></ion-img>
                          </ion-avatar>

                          <ion-label>
                            <h3
                              [innerHTML]="((!!member.firstname || !!member.lastname) ? (member.firstname + ' ' + member.lastname) : member.email)">
                            </h3>
                            <p
                              [innerHTML]="(member.classifications && member.classifications.type ? member.classifications.type : (member.nickname || member.email))">
                            </p>
                          </ion-label>

                          <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                            (click)="removeMemberFromTeam(member)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-button>
                        </ion-item>

                        <ion-item-options slot="end" *ngIf="!view.isDesktop">
                          <ion-item-option color="danger" (click)="removeMemberFromTeam(member)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-item-option>
                        </ion-item-options>
                      </ion-item-sliding>

                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>

              </ion-list>

            </ion-card>

          </ion-col>

          <!-- Projects -->
          <ion-col>

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'projects'|translate"></ion-card-title>
              </ion-card-header>

              <ion-spinner *ngIf="!team.projects && !!view.loading"></ion-spinner>

              <pipeline-no-entries-card *ngIf="!view.loading && !team.projects"></pipeline-no-entries-card>

              <ion-card-content>

                <!-- Add project -->
                <ion-button color="primary" (click)="addProject()">
                  <ion-icon name="person-add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'add_project'|translate"></ion-label>
                </ion-button>

                <!-- Create new project -->
                <ion-button color="primary" (click)="createProject()" fill="clear">
                  <ion-icon name="add-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'create_project'|translate"></ion-label>
                </ion-button>

              </ion-card-content>

              <ion-list *ngIf="!!team.projects">

                <div class="cdk-wrapper">
                  <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
                    [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
                    <div *cdkVirtualFor="let project of team.projects" [hidden]="team.hidden">

                      <ion-item-sliding>

                        <ion-item button (click)="viewProject(project)">

                          <ion-thumbnail slot="start">
                            <ion-img [src]="project.photo && project.photo.length ? project.photo : fallbackImg"
                              (ionError)="project.photo=fallbackImg"></ion-img>
                          </ion-thumbnail>

                          <ion-label>
                            <h3 [innerHTML]="project.title"></h3>
                          </ion-label>

                          <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                            (click)="removeProjectFromTeam(project)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-button>
                        </ion-item>

                        <ion-item-options slot="end" *ngIf="!view.isDesktop">
                          <ion-item-option color="danger" (click)="removeProjectFromTeam(project)">
                            <ion-icon name="trash-outline" slot="start"></ion-icon>
                            <ion-label [innerHTML]="'remove_from_team'|translate"></ion-label>
                          </ion-item-option>
                        </ion-item-options>
                      </ion-item-sliding>

                    </div>
                  </cdk-virtual-scroll-viewport>
                </div>

              </ion-list>

            </ion-card>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

    <!-- Billing -->
    <div [hidden]="!!view.loading || (view.segment != 'billing')">

      <ion-grid>

        <ion-row>

          <!-- Left Side: Wallets, Refill behaviour, Payments + Invoices -->
          <ion-col [size]="view.isDesktop ? 8 : 12">

            <ion-grid>
              <ion-row>

                <!-- Wallets -->
                <ion-col [size]="view.isDesktop ? 6 : 12">
                  <ion-card class="half-size">

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'team_billing_wallets_title'|translate"></ion-card-title>
                    </ion-card-header>

                    <ion-card-content *ngIf="wallets && !!wallets.length">
                      <p [innerHTML]="'team_billing_wallets_text'|translate"></p>
                    </ion-card-content>

                    <!-- List wallets -->
                    <ion-list *ngIf="!!wallets && !!wallets.length">

                      <ion-item button *ngFor="let wallet of wallets; trackBy:trackItems"
                        [disabled]="!!view.loading || !!view.loadingWallet">
                        <ion-radio justify="start" labelPlacement="end" [value]="wallet.uid" class="ion-text-wrap">
                          <ion-label class="ion-text-wrap">
                            <h3 [innerHTML]="wallet.name|translate"></h3>
                            <p [innerHTML]="wallet.balance"></p>
                          </ion-label>
                        </ion-radio>
                      </ion-item>

                    </ion-list>

                    <!-- Setup wallet -->
                    <ion-card-content *ngIf="!!wallets && !wallets.length">
                      <p [innerHTML]="'team_billing_wallets_setup_text'|translate"></p>

                      <ion-button (click)="setupWallet()" fill="outline" color="primary" class="setup-wallet-btn">
                        <ion-icon name="add-outline" slot="start"></ion-icon>
                        <ion-label [innerHTML]="'setup_wallet'|translate"></ion-label>
                      </ion-button>

                    </ion-card-content>

                  </ion-card>

                </ion-col>

                <!-- Refill behaviour -->
                <ion-col [size]="view.isDesktop ? 6 : 12">

                  <ion-card class="half-size">

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'team_billing_wallet_auto_refill_title'|translate"></ion-card-title>
                    </ion-card-header>

                    <ion-card-content>
                      <p [innerHTML]="'team_billing_wallet_auto_refill_text'|translate"></p>
                    </ion-card-content>

                    <ion-radio-group [(ngModel)]="view.refill_behaviour">

                      <ion-item>
                        <ion-icon name="sync-outline" slot="start"></ion-icon>
                        <ion-radio value="refill" slot="start"></ion-radio>
                        <ion-label class="ion-text-wrap"
                          [innerHTML]="'team_billing_wallet_auto_refill_option_refill'|translate"></ion-label>
                      </ion-item>

                      <ion-item lines="none">
                        <ion-icon name="hand-left-outline" slot="start"></ion-icon>
                        <ion-radio value="pause" slot="start"></ion-radio>
                        <ion-label class="ion-text-wrap"
                          [innerHTML]="'team_billing_wallet_auto_refill_option_pause'|translate"></ion-label>
                      </ion-item>

                    </ion-radio-group>

                  </ion-card>

                </ion-col>

                <!-- Payment -->
                <ion-col [size]="view.isDesktop ? 6 : 12">

                  <ion-card class="half-size">

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'team_billing_payment_title'|translate"></ion-card-title>
                    </ion-card-header>

                    <ion-card-content>
                      <p [innerHTML]="'team_billing_payment_text'|translate"></p>
                    </ion-card-content>

                    <pipeline-no-entries-card
                      *ngIf="view.payment_methods && !view.payment_methods.length"></pipeline-no-entries-card>

                  </ion-card>

                </ion-col>

                <!-- Invoices -->
                <ion-col [size]="view.isDesktop ? 6 : 12">

                  <ion-card class="half-size">

                    <ion-card-header>
                      <ion-card-title [innerHTML]="'team_billing_invoices_title'|translate"></ion-card-title>
                    </ion-card-header>

                    <ion-card-content>
                      <p [innerHTML]="'team_billing_invoices_text'|translate"></p>
                    </ion-card-content>

                    <!-- No entries -->
                    <pipeline-no-entries-card *ngIf="view.invoices && !view.invoices.length"></pipeline-no-entries-card>

                  </ion-card>

                </ion-col>

              </ion-row>
            </ion-grid>
          </ion-col>

          <!-- Right Side: Abonnements -->
          <ion-col [size]="view.isDesktop ? 4 : 12">

            <ion-col>

              <ion-card>

                <ion-card-header>
                  <ion-card-title [innerHTML]="'abonnements'|translate"></ion-card-title>
                </ion-card-header>

                <ion-card-content>

                  <ion-button color="primary" (click)="addAbonnement()">
                    <ion-icon name="add-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'add_abonnement'|translate"></ion-label>
                  </ion-button>

                </ion-card-content>

                <ion-spinner *ngIf="!view.abonnements && !!view.loading"></ion-spinner>

                <pipeline-no-entries-card *ngIf="!view.loading && !view.abonnements"></pipeline-no-entries-card>

                <ion-list *ngIf="!!view.abonnements">

                  <div class="cdk-wrapper">
                    <cdk-virtual-scroll-viewport [itemSize]="view.itemSize" [minBufferPx]="view.itemSize * 25"
                      [maxBufferPx]="view.itemSize * 25" class="ion-content-scroll-host">
                      <div *cdkVirtualFor="let abonnement of view.abonnements" [hidden]="abonnement.visible === false">
                        <ion-item-sliding>
                          <ion-item button (click)="viewAbo(abonnement)">

                            <ion-avatar slot="start">
                              <ion-img [src]="abonnement.photo || fallbackImg"
                                (ionError)="thumbnailLoadingFailed(abonnement)"></ion-img>
                            </ion-avatar>

                            <ion-label>
                              <h3 [innerHTML]="(abonnement.title || abonnement.name)|translate"></h3>
                              <p *ngIf="!!abonnement.price" [innerHTML]="abonnement.price"></p>
                            </ion-label>

                            <ion-button slot="end" fill="clear" color="danger" [hidden]="!view.isDesktop"
                              (click)="removeTeamFromAbo(abonnement)">
                              <ion-icon name="trash-outline" slot="start"></ion-icon>
                              <ion-label [innerHTML]="'delete'|translate"></ion-label>
                            </ion-button>

                          </ion-item>

                          <ion-item-options slot="end" *ngIf="!view.isDesktop">
                            <ion-item-option color="danger" (click)="removeTeamFromAbo(abonnement)">
                              <ion-icon name="trash-outline" slot="start"></ion-icon>
                              <ion-label [innerHTML]="'delete'|translate"></ion-label>
                            </ion-item-option>
                          </ion-item-options>
                        </ion-item-sliding>

                      </div>
                    </cdk-virtual-scroll-viewport>
                  </div>

                </ion-list>

              </ion-card>

            </ion-col>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>