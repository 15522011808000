import { Component, Input, OnInit } from '@angular/core';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsPickerService } from 'src/app/services/core/projects-picker.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SearchService } from 'src/app/services/core/search.service';
import { TeamsService } from 'src/app/services/core/teams.service';
import { ViewService } from 'src/app/services/core/view.service';
import { GetgeniusService } from 'src/app/services/getgenius/getgenius.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { IntroService } from 'src/app/services/utils/intro.service';

import { ProjectPage } from 'src/app/pages/core/projects/project/project.page';
import { CustomerPage } from 'src/app/pages/core/user/customers/customer/customer.page';

import { GetgeniusWalletPage } from 'src/app/pages/getgenius/wallet/getgenius-wallet.page';

@Component({
  selector: 'app-team',
  standalone: false,
  templateUrl: './team.page.html',
  styleUrls: ['./team.page.scss'],
})
export class TeamPage implements OnInit {

  avatarUrl: string = './assets/img/avatars/1.webp';

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() inSetupEmbedded: boolean = false;

  search: searchOptions = {
    itemsKey: 'projects',
    keys: ['title', 'email', 'firstname', 'lastname', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  team: team = {
    active: true,
  };

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      uid: 'team_admin_segment_general_top_card',
      text: 'team_admin_segment_general_top_card_text',
      title: 'team_admin_segment_general_top_card_title',
    },
    invoices: [],
    itemSize: 64,
    payment_methods: [],
    refill_behaviour: 'pause',
    route: 'team',
    segment: 'general',
    title: 'create_team',
    wallets: [],
  };

  wallets: wallet[];

  constructor(
    private abo: AbonnementService,
    private events: EventsService,
    private GetGenius: GetgeniusService,
    private introService: IntroService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private projectsPicker: ProjectsPickerService,
    private searchService: SearchService,
    private teamsService: TeamsService,
    private viewService: ViewService,
  ) {
    let team: team = (this.teamsService.detailItem() || {} as any);

    if (!!team) {
      this.team = team;
    }
  }

  addAbonnement() {
    this.abo.pick()
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.item && !!response.data.item.uid) {
          this.abo.updateTeamAbonnement(this.team.uid, response.data.item.uid)
            .then(() => {
              this.loadAbonnements(true);
            })
            .catch((error: any) => {
              this.events.publish('error', error);
            });
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  addMember() {
    this.teamsService.addUserToTeam(this.team.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  addProject() {
    this.projectsPicker.pick({
      multiple: true,
    })
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length) {

          response.data.items.forEach((project: project) => {
            this.projectsService.addToTeam(project.uid, this.team.uid);
          });

          setTimeout(() => {
            this.loadProjects(true);
          }, 1000);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.team && this.team.uid ? 'edit_team' : 'create_team');

    const introCardUid: string = `team_admin_segment_${this.view.segment}_top_card`;

    this.view.introCard = {
      hidden: await this.introService.isIntroCardHidden(introCardUid),
      uid: introCardUid,
      subtitle: `team_admin_segment_${this.view.segment}_top_card_subtitle`,
      text: `team_admin_segment_${this.view.segment}_top_card_text`,
      title: `team_admin_segment_${this.view.segment}_top_card_title`,
    };
  }

  async createProject() {

    const modal: any = await this.modalService.create({
      component: ProjectPage,
    });

    this.modalService.present(modal);

    modal.onWillDismiss().then(async (response: any) => {
      this.calcViewVars();

      try {
        if (!!this.team.uid) {
          if (!!response && !!response.uid) {
            await this.projectsService.addToTeam(response.uid, this.team.uid);
            this.doRefresh();
          } else
            if (!!response && !!response.project && !!response.project.uid) {
              await this.projectsService.addToTeam(response.project.uid, this.team.uid);
              this.doRefresh();
            }
        }
      } catch (e) {
        console.warn('linking new project failed', e);
      }

      this.doRefresh();
    });

  }

  async createUser() {

    const modal: any = await this.modalService.create({
      component: CustomerPage,
      componentProps: {
        mode: 'create',
      },
      cssClass: 'defaultModal',
      animated: true,
      presentingElement: await this.modalService.getTop(),
    });

    modal.onWillDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadTeam(true)
      .then(() => {
        this.loadProjects(true);

        if (event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    this.teamsService.detailItem({} as any);
  }

  async load() {
    try {
      await this.loadProjects();
      await this.loadWallet();
      await this.loadAbonnements();
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  async loadAbonnements(blForceRefresh: boolean = false) {

    if (!this.team || !this.team.uid) {
      return false;
    }

    this.view.loading = true;

    try {

      const extensionResponse: any = await this.abo.getSubscribedExtensions({
        teamId: this.team.uid,
      }, blForceRefresh);

      console.log('team: extensionResponse', extensionResponse);

      this.view.abonnements = (!!extensionResponse && !!extensionResponse.extensions ? extensionResponse.extensions : []);
      console.log('team: abonnements', this.view.abonnements);

      this.view.loading = false;
    } catch (e) {
      this.view.abonnements = [];
      this.view.loading = false;
      console.warn('loading plans failed', e);
    }

  }

  loadProjects(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.team || !this.team.uid) {
        resolve(this.team);
      } else {
        this.projectsService.getByTeamUid(this.team.uid, blForceRefresh)
          .then((projects: project[]) => {
            this.team.projects = projects;
            resolve(this.team);
          })
          .catch(reject);
      }
    });
  }

  loadTeam(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.teamsService.getByUid(this.team.uid, blForceRefresh)
        .then((team: team) => {
          this.team = team;
          resolve(this.team);
        })
        .catch(reject);
    });
  }


  async loadWallet(blForceRefresh: boolean = false, blBackground: boolean = false) {
    try {

      if (!blBackground) {
        this.view.loadingWallet = true;
      }

      const walletInfo: any = await this.GetGenius.getWalletInfo(blForceRefresh);
      console.log('walletInfo', walletInfo);

      if (!walletInfo || !walletInfo.wallets || !walletInfo.wallets.length) {

        // unset wallet if not in response
        await this.GetGenius.unsetWallet();

        // create wallet if not exists
        await this.GetGenius.setupWallet();

        // load wallet info again
        const walletInfo: any = await this.GetGenius.getWalletInfo(blForceRefresh);

        // update wallets list
        this.wallets = (!!walletInfo && !!walletInfo.wallets && !!walletInfo.wallets.length ? walletInfo.wallets : []);
      } else {

        // else, load wallets list
        this.wallets = (!!walletInfo && !!walletInfo.wallets && !!walletInfo.wallets.length ? walletInfo.wallets : []);
      }

      this.view.loadingWallet = false;
    } catch (e) {
      this.view.loadingWallet = false;
      console.warn('loading wallet info failed', e);
    }
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });

    this.load();
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSegmentChange(event: any) {
    this.calcViewVars();

    if (!!this.team && !!this.team.name && !this.team.uid) {
      this.save(false);
    }

  }

  removeMemberFromTeam(member: user) {
    this.teamsService.removeUserFromTeam(this.team.uid, member.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  removeProjectFromTeam(project: project) {
    this.projectsService.removeFromTeam(project.uid, this.team.uid)
      .then(() => {
        this.loadProjects(true);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  removeTeamFromAbo(abonnement: abonnement) {
    console.log('removeTeamFromAbo: abonnement', abonnement);
  }

  runSearch(event: any | null = null) {
    this.search.itemsKey = this.view.segment;

    switch (this.view.segment) {
      case 'members':

        if (!this.view.hasOwnProperty('_members')) {
          this.view._members = JSON.parse(JSON.stringify(this.team.members || []));
        }

        this.team.members = this.searchService.search(this.search, this.view._members);

        break;
      default:
        console.log('segment not implemented', this.view.segment);
        break;
    }
  }

  save(blDismiss: boolean = true) {
    this.team.active = true;
    this.view.loading = true;

    this.teamsService.create(this.team)
      .then((response: any) => {
        this.events.publish('team:created', response);

        if (!!response && !!response.team) {
          this.team = response.team;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  async setupWallet() {

    const modal: any = await this.modalService.create({
      component: GetgeniusWalletPage,
    });

    modal.onWillDismiss().then((response: any) => {
      this.calcViewVars();
    });

    this.modalService.present(modal);
  }

  thumbnailLoadingFailed(team: team) {
    team.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update(blDismiss: boolean = true) {
    this.view.loading = true;

    this.teamsService.update(this.team)
      .then((response: any) => {

        if (!!response && !!response.team) {
          this.team = response.team;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.team.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  viewAbo(abo: any) {

  }

  viewMember(member: user) {
    this.events.publish('view:profile', member);
  }

  viewProject(project: project) {
    console.log('team: viewProject: project', project);
  }

}