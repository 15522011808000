<pipeline-info-popover></pipeline-info-popover>

<ion-card class="sidebar-accordion-card">

    <ion-card-header [hidden]="!!options && !!options.hideHeader">

        <ion-card-title (click)="toggleCard('information')">
            <span [innerHTML]="'information'|translate"></span>
        </ion-card-title>

        <ion-button class="card-toggle" (click)="toggleCard('information')" icon-only color="dark" fill="clear">
            <ion-icon
                [name]="!!cards && !!cards.information && cards.information.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
        </ion-button>

    </ion-card-header>

    <!-- Description -->
    <ion-card-content [hidden]="(!!options && !!options.hideDescription) || (!!cards && !!cards.information && !cards.information.open)">
        <p [innerHTML]="'media_creator_information_text'|translate"></p>
    </ion-card-content>
    
    <ion-list [hidden]="!!cards && !!cards.information && !cards.information.open">

        <ion-item lines="none">
            <ion-icon name="information-outline" slot="start"></ion-icon>

            <ion-textarea [rows]="view.expertMode ? 5 : 3" [label]="'input'|translate" labelPlacement="stacked"
                (ionChange)="_onInputChanged($event)" [(ngModel)]="input" #positivePromptInput id="positivePromptInput"
                [placeholder]="'media_creator_ai_prompt_placeholder'|translate"></ion-textarea>

        </ion-item>

        <ion-item lines="none" [hidden]="!view.expertMode">
            <ion-icon name="eye-off-outline" slot="start"></ion-icon>

            <ion-textarea rows="5" [label]="'negative_prompt'|translate" labelPlacement="stacked"
                [(ngModel)]="view.negative_prompt" #negativePromptInput id="negativePromptInput"
                [placeholder]="'negative_prompt_placeholder'|translate"></ion-textarea>

        </ion-item>

        <ion-item lines="none">

            <ion-checkbox [(ngModel)]="view.blFineTuneInput" (ionChange)="toggleFineTune()" justify="start"
                labelPlacement="end" [innerHTML]="'media_creator_fine_tune_input'|translate"
                class="ion-text-wrap"></ion-checkbox>

            <ion-button (click)="presentInfoPopover($event, 'media_creator_fine_tune_input_infotext')" icon-only
                fill="clear" color="dark" slot="end">
                <ion-icon name="information-circle-outline"></ion-icon>
            </ion-button>

        </ion-item>

    </ion-list>

</ion-card>