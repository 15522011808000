import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';
import { CrawlerService } from "src/app/services/utils/crawler.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { proxyUrl } from 'src/config/variables';

@Component({
  selector: 'pipeline-ai-browser',
  standalone: false,
  templateUrl: './ai-browser.component.html',
  styleUrls: ['./ai-browser.component.scss']
})
export class AiBrowserComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() autostart: boolean = false;
  @Input() colSize: number | null = null;
  @Input() config: aiBrowserConfig;
  @Input() hidden: boolean = false;
  @Input() input: string = '';

  @Output() onPageDone = new EventEmitter();
  @Output() onPageLoaded = new EventEmitter();
  @Output() onPageRendered = new EventEmitter();

  @ViewChild('browserFrame') browserFrame: ElementRef | undefined;

  disabled: boolean = false;

  fallbackImg: string = './assets/img/fallback.webp';

  proxyUrl: string;

  rebuild: boolean = false;

  response: string = '';

  safe_url: any;

  srcDocument: any;

  @Input() size: number = 12;

  step: aiBrowserStep = {};
  steps: aiBrowserStep[] = [];

  view: any = {
  };

  constructor(
    private aiBridge: AiBridgeService,

    private crawler: CrawlerService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.proxyUrl = proxyUrl;
  }

  _onPageDone(event: any | null = null) {
    this.onPageDone.emit(event);
  }

  _onPageLoaded(event: any | null = null) {
    this.onPageLoaded.emit(event);
  }

  _onPageRendered(event: any | null = null) {
    this.onPageRendered.emit(event);
  }

  calcViewVars() {
    console.log('ai-browser: config', this.config);

    this.view = this.viewService.calcVars(this.view);
  }

  iFrameLoaded(event: any | null = null) {
    console.log('ai-browser: iFrameLoaded', event);

    this._onPageRendered(event);

    if (!this.steps || !this.steps.length) {
      console.warn('ai-browser: no steps, no iteration!');
      return false;
    }

    const history: any[] = [
      {
        role: 'system',
        content: `Please return an improved version of the provided JSON array that explains step-by-step how an AI browser automation can fulfill the user's request.
        Search the provided HTML response for information, links, inputs or buttons (css selectors) for the next navigation.`,
      },
      {
        role: 'user',
        content: `${this.response || ''}`,
      },
      {
        role: 'system',
        content: JSON.stringify(this.steps),
      },
      {
        role: 'system',
        content: `The response should look like this:
        {
            "description": ”What do we do at this step? (e.g: Navigate to XYZ, search for XYZ, ...)
            "query": "the query to insert into the searchbar after loading the url",
            "actions": [
              {
                "action": "click",
                "selector": "input[type=search]",
              },
              {
                "action": "input",
                "selector": "input[type=search]",
                "value": "the value to insert",
              },
              {
                "action": "click",
                "selector": "button[type=submit]",
              },
              {
                "action": "<click|input>",
                "selector": "...",
              },
              ...
            ],
            "url": "the url to navigate to"
        }`,
      },
    ];

    console.log('ai-browser: history', history);

    this.aiBridge.execute({
      context: 'ai_browser',
      history: history,
      post_content: `Only respond with the improved json object (optimized "actions", "url" and "metadata" keys) based on provided user input.
      Your output must start with "{".
      IMPORTANT: No further output allowed.`,
    })
      .then(async (response: any) => {

        let event: any = {
          response: response,
          step: this.step,
          steps: this.steps,
        };

        if (!!response && !!response.output) {
          event.json = this.tools.extractJson(response.output);

          if (!event.json) {
            console.log('ai-browser: failed exporting JSON: response.output: ', response.output);
          }
        }

        this._onPageDone(event);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};
  }

  public load() {

    if (!!this.steps && !!this.steps.length) {
      this.step = this.steps[0];
    }

    this.loadPreview();
  }

  async loadPreview() {

    if (!this.input || !this.input.length) {
      return false;
    }

    const url: string = (!!this.config && !!this.config.use_proxy ? this.proxyUrl + this.input : this.input);

    this.safe_url = this.domSanitizer.bypassSecurityTrustResourceUrl(url);

    try {
      const rawResponse: any = await this.crawler.fetch(url, {});

      if (!rawResponse || !rawResponse.length) {
        return false;
      }

      const cleanedResponse: string = (rawResponse as string)
        .replace(/^(\/\/\s*)?<!\[CDATA\[|(\/\/\s*)?\]\]>$/g, '')
        .replace(/<\!--.*?-->/g, "")
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/<iframe\b[^<]*(?:(?!<\/iframe>)<[^<]*)*<\/iframe\s*>/gi, '')
        .replace(/<img\b[^<]*(?:(?!<\/img>)<[^<]*)*<\/img\s*>/gi, '')
        .replace(/<meta\b[^<]*(?:(?!<\/meta>)<[^<]*)*<\/meta\s*>/gi, '')
        .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi, '')
        .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style\s*>/gi, '');

      this.response = cleanedResponse;
      this.srcDocument = this.domSanitizer.bypassSecurityTrustHtml(rawResponse);

      this._onPageLoaded({
        browserFrame: this.browserFrame,
        clean: cleanedResponse,
        raw: rawResponse,
        srcDocument: this.srcDocument,
      });

      return {
        success: !!this.response,
        response: this.response,
        srcDocument: this.srcDocument,
      };
    } catch (e) {
      console.warn('loading iframe response failed', e);

      return {
        error: e,
        success: false,
      };
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
      this.loadPreview();
    }
  }

  onUrlChanged(event: any | null = null) {
    this.loadPreview();
  }

  public setStep(index: number) {
    if (!!this.steps && !!this.steps[index]) {
      this.step = this.steps[index];
      console.log('[ AI BROWSER ] Set current step to: ', this.step);
    }
    return this;
  }

  public setSteps(steps: aiBrowserStep[]) {
    this.steps = steps;
    return this;
  }

  public setUrl(url: string) {
    this.input = url;
    this.safe_url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.input);
    return this;
  }

}