import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'pipeline-info-popover',
  standalone: false,
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss']
})
export class InfoPopoverComponent implements AfterViewInit, OnDestroy, OnInit {

  @Input() content: string;

  event: any;

  @Input() open: boolean = false;

  @ViewChild('infoPopover') popover: any;

  @Input() view: any = {};

  constructor(
  ) {

  }

  ngAfterViewInit() {

  }

  ngOnDestroy() {

  }

  ngOnInit() {
  }

  public show(options: any) {
    this.content = options.content;
    this.event = options.event;

    this.popover.event = this.event;
    this.open = true;
  }

}
