import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

@Component({
  selector: 'pipeline-dynamic-view-page',
  standalone: false,
  templateUrl: './dynamic-view-page.component.html',
  styleUrls: ['./dynamic-view-page.component.scss']
})
export class DynamicViewPageComponent implements OnDestroy, OnInit {
  @Input() buttons: button[];
  @Input() cta: any = {};
  @Input() headerOptions: selectionOption[];
  @Input() introCard: introCardConfig = {};
  @Input() method: string;
  @Input() pages: any;
  @Input() paginationConfig: paginationConfig = {};
  @Input() options: dynamicViewPageOptions;
  @Input() search: searchOptions;
  @Input() selectionOptions: selectionOption[];
  @Input() service: any;
  @Input() state: state;
  @Input() view: any;

  @Output() itemsChanged = new EventEmitter();
  @Output() onSelectionActionChanged = new EventEmitter();

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  user: user;

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser() || {};

    if (!!this.view) {
      this.view.viewItemsKey = (!!this.search && !!this.search.itemsKey ? this.search.itemsKey : 'items');
    }
  }

  async add() {

    if (!!this.options && !!this.options.hasOwnProperty('addAction')) {
      this.options.addAction();
      return false;
    }

    if (!this.pages.hasOwnProperty('add')) {
      return false;
    }

    const modal: any = await this.modalService.create({
      component: this.pages.add,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  addButtonHandlers() {

    if (!this.buttons || !this.buttons.length) {
      return false;
    }

    // add missing handler methods to buttons if missing
    this.buttons.forEach((button: button) => {
      if (!button.hasOwnProperty('handler')) {
        button.handler = (item: any | null = null, button: button = null) => {
          try {
            this[button.uid](item);
            return false;
          } catch (e) {
            console.warn('dynamic button action failed', e);
          }
        };
      }
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.forceProjectToBeSet = (this.view.forceProjectToBeSet !== false) && !!this.appConfig.forceProjectToBeSet;
    this.view.viewType = this.view.viewType || 'list';
  }

  delete(item: any, event: any | null = null) {
    let itemId: number = (!!item && !!item.uid ? item.uid : item);

    item.disabled = true;
    item.loading = true;

    this.service.delete(itemId)
      .then(() => {
        item.disabled = false;
        item.loading = false;

        this.doRefresh();
      })
      .catch((error: any) => {
        item.disabled = false;
        item.loading = false;

        this.events.publish('error', error);
      });
  }

  public doRefresh(event: any | null = null) {
    this.load(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async edit(item: any, event: any | null = null) {

    if (!this.pages.hasOwnProperty('edit')) {
      return false;
    }

    try {
      this.service.detailItem(item as any);
    } catch (e) {
      console.error('setting detail item failed', e);
    }

    const modal: any = await this.modalService.create({
      component: this.pages.edit,
      componentProps: {
        item: item,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.view.events.teamCurrentUpdated = this.events.subscribe("team:updated", (team: team) => {
      this.view.team = team;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  load(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!!this.view.forceProjectToBeSet && (!this.view.project || !this.view.project.uid)) {
        this.view.loading = false;
        this.view[this.view.viewItemsKey] = [];
        resolve(this.view);
      } else {

        if (this.view.hasOwnProperty('placeholders')) {
          this.view[this.view.viewItemsKey] = JSON.parse(JSON.stringify(this.view.placeholders));
        }

        try {
          const methodName: string = (this.method || 'getAll');
          const filter: any = (!!this.options && !!this.options.filter ? this.options.filter : {});

          if (typeof this.service[methodName] === 'function') {
            this.view.loading = true;

            this.service[methodName](filter, blForceRefresh)
              .then((response: any) => {
                const items: any[] = (!!response && !!response.data ? response.data : (!!response && !!response.length ? response : []));

                // automatically show add popop if defined in options + loaded items list is empty
                if (!!this.options && !!this.options.autoAddOnEmpty && (!items || !items.length)) {
                  this.options.autoAddOnEmpty = false;
                  this.add();
                }

                this.view[this.view.viewItemsKey] = items;
                this.view[`${this.view.viewItemsKey}_backup`] = items;

                if (!!this.paginationConfig) {
                  this.paginationConfig.backup = items;
                }

                this.view.loading = false;

                resolve(this.view[this.view.viewItemsKey]);
              })
              .catch((error: any) => {
                this.view.loading = false;
                reject(error);
              });
          } else {
            reject('error_method_not_implemented');
          }
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  loadNext(event: any) {
    console.log('loadNext: event', event);
    console.log('loadNext: paginationConfig', this.paginationConfig);
    console.log('loadNext: view', this.view);
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
    this.calcViewVars();
  }

  ngOnInit() {
    this.user = this.userService.getUser() || {};

    if (!!this.view && !this.view.introCard && !!this.introCard) {
      this.view.introCard = this.introCard;
    }

    this.view.viewItemsKey = (!!this.search && !!this.search.itemsKey ? this.search.itemsKey : 'items');
    this.view.viewType = this.view.viewType || 'list';

    if (!this.view.hasOwnProperty('viewTypes')) {
      this.view.viewTypes = [
        { icon: 'list', label: 'list', uid: 'list', expertMode: false },
        { icon: 'grid', label: 'grid', uid: 'grid', expertMode: false },
        { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
      ];
    }

    this.initEvents();
    this.loadProject();
    this.calcViewVars();

    this.load()
      .then(() => {
        this.calcViewVars();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    this.addButtonHandlers();
  }

  ngOnDestroy() {
    this.events.stop(this.view.events);
  }

  onFieldsChanged(event: any | null = null) {
  }

  onItemsChanged(items: any) {
    this.view[this.view.viewItemsKey] = (items || []);
    this.itemsChanged.emit(items);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  _onSelectionActionChanged(event: any | null = null) {
    this.onSelectionActionChanged.emit(event);
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any = null) {

    if (!this.headerOptions || !this.headerOptions.length) {
      return false;
    }

    this.view.options = this.headerOptions;

    this.selectionOptionsPicker.show({
      event: event,
    });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}