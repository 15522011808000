<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Create / update shortcode -->
      <ion-button (click)="create()" [disabled]="!shortcode.name || !shortcode.input" shape="round" color="primary"
        fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="(!!shortcode && !!shortcode.uid ? 'update' : 'create')|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- AI settings popover -->
  <ion-popover #aiSettingsPopover [isOpen]="isAiSettingsPopoverOpen" (didDismiss)="isAiSettingsPopoverOpen = false">
    <ng-template>

      <pipeline-ai-settings-card *ngIf="!!shortcode && !!aiSettings" [(cards)]="cards" [(config)]="aiSettings"
        [options]="aiSettingsOptions" [(view)]="view"
        (changed)="onAiSettingsChanged($event)"></pipeline-ai-settings-card>

    </ng-template>
  </ion-popover>

  <div class="container">

    <ion-grid>

      <ion-row>

        <ion-col>

          <!-- Intro card -->
          <div class="ion-padding" [hidden]="introCard.hidden">
            <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
          </div>

          <!-- General information -->
          <ion-card>

            <ion-list-header lines="none">
              <ion-label [innerHTML]="'general'|translate"></ion-label>
            </ion-list-header>

            <!-- Shortcode name -->
            <ion-item>
              <ion-icon slot="start" [name]="shortcode.icon" (click)="chooseIcon()"></ion-icon>
              <ion-input [label]="'ai_shortcode_name'|translate" labelPlacement="floating" [(ngModel)]="shortcode.name"
                [placeholder]="'ai_shortcode_name_placeholder'|translate"></ion-input>
            </ion-item>

            <!-- Shortcode type -->
            <ion-item lines="none">
              <ion-icon slot="start" name="build-outline"></ion-icon>

              <ion-select [label]="'prompt_type'|translate" labelPlacement="floating" interface="popover"
                [placeholder]="'select'|translate" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
                [(ngModel)]="shortcode.promptType" (ionChange)="onShortcodePromptTypeChanged()">
                <ion-select-option *ngFor="let promptType of view.promptTypes; trackBy:trackItems"
                  [innerHTML]="promptType.name|translate" [value]="promptType.uid"></ion-select-option>
              </ion-select>

            </ion-item>

          </ion-card>

          <!-- Input -->
          <ion-card>

            <ion-list-header lines="none">
              <ion-label [innerHTML]="'ai_shortcode_input_label'|translate"></ion-label>
            </ion-list-header>

            <!-- Task input textarea -->
            <ion-item lines="none" [hidden]="!!view.loadingInput">

              <ion-textarea rows="12" [(ngModel)]="shortcode.input" [disabled]="!shortcode.name"
                (ionChange)="onShortcodeInputChanged($event)" (ionInput)="onShortcodeInputChanged($event)"
                [placeholder]="'ai_shortcode_input_placeholder'|translate"></ion-textarea>

            </ion-item>

            <!-- Loading spinner -->
            <ion-spinner [hidden]="!view.loadingInput"></ion-spinner>

            <ion-item lines="none">

              <!-- Optimize input prompt -->
              <ion-button slot="end" (click)="optimizeTaskInputPrompt(shortcode, $event)"
                [disabled]="!shortcode.name || !shortcode.input || !!view.loadingInput" fill="clear" icon-only
                color="dark" [disabled]="!!view.loadingInput">
                <ion-icon name="sparkles-outline"></ion-icon>
              </ion-button>

              <!-- Task AI settings -->
              <ion-button slot="end" (click)="showAiSettingsPopover($event)" fill="clear" icon-only color="dark"
                [disabled]="!shortcode.name || !shortcode.input || !!view.loadingInput">
                <ion-icon name="settings-outline"></ion-icon>
              </ion-button>

            </ion-item>

          </ion-card>

        </ion-col>

        <!-- Variables -->
        <ion-col *ngIf="!!view.variables && !!view.variables.length" [hidden]="shortcode.promptType === 'function'">

          <ion-card>

            <ion-list-header lines="none">
              <ion-label [innerHTML]="'variables'|translate"></ion-label>
            </ion-list-header>

            <ion-card-content>
              <ion-grid>
                <ion-row *ngFor="let variable of view.variables; let iVariable = index; trackBy:trackItems">

                  <!-- Input variable -->
                  <ion-col [size]="!view.expertMode ? 6 : 3">
                    <ion-item lines="none">
                      <ion-input type="text" [(ngModel)]="variable.input_variable"
                        [placeholder]="'input_variable'|translate" [disabled]="!!variable.required"></ion-input>
                    </ion-item>
                  </ion-col>

                  <!-- Variable type -->
                  <ion-col [size]="!view.expertMode ? 5 : 3">
                    <ion-item lines="none">
                      <ion-select interface="popover" [(ngModel)]="variable.type"
                        [placeholder]="'variable_type'|translate" [okText]="'okay'|translate"
                        [cancelText]="'cancel'|translate">
                        <ion-select-option *ngFor="let type of types" [value]="type.uid"
                          [innerHTML]="type.label|translate"></ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>

                  <!-- Variable action -->
                  <ion-col size="3" [hidden]="!view.expertMode">
                    <ion-item lines="none">
                      <ion-select interface="popover" [(ngModel)]="variable.action"
                        [placeholder]="'variable_action'|translate" [okText]="'okay'|translate"
                        [cancelText]="'cancel'|translate">
                        <ion-select-option value="auto" [innerHTML]="'auto_detection'|translate"></ion-select-option>
                        <ion-select-option value="database" [innerHTML]="'database'|translate"></ion-select-option>
                        <ion-select-option value="fetch_url" [innerHTML]="'fetch_url'|translate"></ion-select-option>
                        <ion-select-option value="text_completion"
                          [innerHTML]="'text_completion'|translate"></ion-select-option>
                        <ion-select-option value="text_to_image"
                          [innerHTML]="'text_to_image'|translate"></ion-select-option>
                        <ion-select-option value="text_to_video"
                          [innerHTML]="'text_to_video'|translate"></ion-select-option>
                      </ion-select>
                    </ion-item>
                  </ion-col>

                  <!-- Output variable -->
                  <ion-col [size]="view.isDesktop ? 2 : 3" [hidden]="!view.expertMode">
                    <ion-item lines="none">
                      <ion-input type="text" [(ngModel)]="variable.output_variable"
                        [placeholder]="'output_variable'|translate" [disabled]="!variable.action"></ion-input>
                    </ion-item>
                  </ion-col>

                  <!-- Delete -->
                  <ion-col [size]="view.isDesktop ? 1 : 2">
                    <ion-button fill="clear" color="danger" size="block" icon-only (click)="deleteVariable(iVariable)"
                      [disabled]="!!variable.required">
                      <ion-icon name="trash-outline"></ion-icon>
                    </ion-button>
                  </ion-col>

                </ion-row>
              </ion-grid>
            </ion-card-content>

            <ion-card-content>

              <ion-button (click)="addVariable()" [disabled]="!shortcode.name" fill="clear">
                <ion-icon name="add-outline" slot="start"></ion-icon>
                <ion-label [innerHTML]="'add'|translate"></ion-label>
              </ion-button>

            </ion-card-content>

          </ion-card>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>