import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { AlertService } from "src/app/services/core/alert.service";
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { LoadingService } from 'src/app/services/utils/loading.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { TableViewComponent } from 'src/app/components/generic/table-view/table-view.component';

@Component({
  selector: 'app-data-importer',
  standalone: false,
  templateUrl: './data-importer.page.html',
  styleUrls: ['./data-importer.page.scss'],
})
export class DataImporterPage implements AfterContentInit, OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  buttons: button[] = [
  ];

  cards: any = {
    filters: { open: true },
    item_attributes: { open: true },
    output: { open: true },
    source: { open: true },
  };

  data: any; // provided by modalCtrl
  data_backup: any;

  exportService: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  fields: any[];

  introCard: introCardConfig = {
    uid: 'data_importer_top_card',
    lottieSrc: './assets/lottie/light_bulb.json',
    text: 'data_importer_top_card_text',
    title: 'data_importer_top_card_title',
  };

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    groups: [

      /* Step 1: */
      {
        buttons: [

        ],
        description: 'data_importer_source_ai_helper_text',
        headline: 'data_importer_ai_helper_text',
        inputs: [
          {
            placeholder: 'source_type',
            type: 'select',
          }
        ],
      },

      /* Step 2 */
      {
        buttons: [

        ],
        description: 'data_importer_target_ai_helper_text',
        headline: 'data_importer_target_ai_helper_title',
        inputs: [
          {
            placeholder: 'target_type',
            type: 'select',
          }
        ],
      },

    ],
    showAiCreate: false,
    showAiSettings: false,
    showInput: false,
    showIntegrations: true,
    showSources: true,
    sourceRequired: true,
  };

  items: any[];

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'chevron-up-outline',
      label: 'use_as_fields',
      uid: 'use_as_fields',
    },
  ];

  service: any; // provided by modalCtrl

  settings: any = {
    format: 'csv',
    proxy: true,
    quote_character: '"',
    quote_characters: false,
    seperator: ',',
    use_first_row_as_fields: true,
  };

  source: any; // provided by modalCtrl
  source_object_type: string; // provided by modalCtrl
  source_type: string; // provided by modalCtrl
  source_tool: string; // provided by modalCtrl

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  @ViewChild(TableViewComponent) tableView: any;

  tableViewOptions: tableViewOptions = {
    mode: 'edit',
  };

  target: any; // provided by modalCtrl
  target_object_type: string; // provided by modalCtrl
  target_type: string; // provided by modalCtrl
  target_tool: string; // provided by modalCtrl

  tools: aiTool[] = [
    {
      "label": "all",
      "uid": "all"
    },
    {
      "icon": "cash-outline",
      "label": "ads",
      "uid": "ads"
    },
    {
      "icon": "phone-portrait-outline",
      "label": "apps",
      "uid": "apps"
    },
    {
      "icon": "chatbubbles-outline",
      "label": "comments",
      "uid": "comments"
    },
    {
      "icon": "images-outline",
      "label": "media_library",
      "uid": "media_library"
    },
    {
      "icon": "calendar-outline",
      "label": "campaigns",
      "uid": "ai_plans"
    },
    {
      "icon": "terminal-outline",
      "label": "ai_tasks",
      "uid": "ai_tasks"
    },
    {
      "icon": "file-tray-outline",
      "label": "mail_inbox",
      "uid": "mail_inbox"
    },
    {
      "icon": "mail-outline",
      "label": "newsletters",
      "uid": "newsletters"
    },
    {
      "icon": "list-outline",
      "label": "shop_categories",
      "uid": "shop_categories"
    },
    {
      "icon": "storefront-outline",
      "label": "shop_products",
      "uid": "shop_products"
    },
    {
      "icon": "funnel-outline",
      "label": "target_groups",
      "uid": "target_groups"
    },
  ];

  type: any; // provided by modalCtrl

  types: any[] = [
    {
      name: 'file',
      uid: 'file',
    },
    {
      name: 'integration',
      uid: 'integration',
    },
    {
      name: 'object_type',
      uid: 'object_type',
    },
    {
      name: 'tool',
      uid: 'tool',
    },
    {
      name: 'url',
      uid: 'url',
    }
  ];

  view: any = {
    buttonAction: 'export',
    canGenerate: false,
    duplicate_behaviour: 'skip',
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    multiple: true,
    newItem: {
      name: 'create_new',
      uid: 'new',
    },
    output: '',
    phase: 'edit',
    progress: 0,
    quote_characters: [
      {
        indent: '"',
        label: '"',
      },
      {
        indent: "'",
        label: "'",
      },
    ],
    route: 'ai/items',
    rowMappings: [

    ],
    sections: [],
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    sourceType: 'integration',
    tab: 'item',
    title: 'data_importer',
    viewType: 'table',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ],
  };

  constructor(
    private aiTools: AiToolsService,

    private alert: AlertService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private loading: LoadingService,
    private media: MediaextendService,
    private modalService: ModalService,
    private objects: ObjectsService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private toolsService: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    // apply sources to view for intro overlay component
    this.view.sources = this.types;

    // apply data to intro overlay component
    if (!!this.introOverlayConfig && !!this.introOverlayConfig.groups) {

      if (!!this.introOverlayConfig.groups[0] && !!this.introOverlayConfig.groups[0].inputs) {
        this.introOverlayConfig.groups[0].inputs[0].values = this.types;
      }

      if (!!this.introOverlayConfig.groups[1] && !!this.introOverlayConfig.groups[1].inputs) {
        this.introOverlayConfig.groups[1].inputs[0].values = this.types;
      }

    }
  }

  calcFilters() {
    const blockKeys: string[] = ['checked', 'field_optimizations', 'image', 'photo', 'timestamp', 'uid', 'variations'];
    let filters: any[] = [], valuesByKey: any = {};

    if (!this.data || !this.data[0]) {
      return false;
    }

    const keys: string[] = Object.keys(this.data[0]).filter((key: string) => {
      return blockKeys.indexOf(key) === -1;
    });

    this.data.forEach((item: any) => {
      keys.forEach((key: string) => {
        const value: string = `${item[key] || ''}`;
        valuesByKey[key] = valuesByKey[key] || [];

        if (!!value && (valuesByKey[key].indexOf(value) === -1)) {
          valuesByKey[key].push(value);
        }
      });
    });

    keys.forEach((key: string) => {

      const values: any[] = (valuesByKey[key] || []).map((value: string) => {
        return { label: value, value: `${value || ''}` };
      });

      if (!!values && !!values.length) {
        filters.push({
          label: key,
          name: key,
          type: 'select',
          values: values,
          uid: key,
        });
      }
    });

    this.view.filters = (filters || []);
  }

  calcMappings() {
    this.calcViewVars();
  }

  calcParams() {
    return {
      data: this.data,
      settings: this.settings,
      //service: this.service,
      source: this.source,
      type: this.type,
    };
  }

  calcSelectedItems(item: any | null = null) {

    if (this.data && this.data.length) {
      this.view.selectedItems = this.data.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.calcViewVars();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canImport = !!this.view.target_type && !!this.view.target_object_type;

    // skip intro view if data is provided
    if (!this.view.startManually && (!!this.service && !!this.items && !!this.items.length)) {
      this.startManually();
    }
  }

  createObjectType(event: any | null = null) {
    return new Promise((resolve, reject) => {

      const type: any = {
        active: true,
        name: this.view.object_name,
        fields: (this.view.dataKeys || []),
      };

      console.log('createObjectType: type', type);

      this.view.loading = true;

      this.objects.createType(type)
        .then((response: any) => {
          this.events.publish('create-object-type: created', response);

          if (!!response && !!response.type && !!response.type.uid) {
            this.view.target_object_type = response.type.uid;
            this.view.target_object_type = response.type;
            console.log('this.view.target_object_type', this.view.target_object_type);
          }

          this.view.loading = false;
          resolve(response);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    })
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
  }

  import() {

    if (!this.view.target_type) {
      this.events.publish('error', 'error_missing_import_target');
    }

    switch (this.view.target_type) {
      case 'integration':
        return this.importToIntegration();
      case 'object_type':
        return this.importToObject();
      case 'tool':
        return this.importToTool();
      default:
        this.events.publish('error', 'error_unknown_import_target');
        break;
    }

    return false;
  }

  importToIntegration() {

  }

  importToObject() {
    const items: any[] = (this.view.selectedItems || []);

    if (!items || !items.length || !this.view.target_object_type) {
      return false;
    }

    this.view.loading = true;

    this.requestUpdateObjectFields()
      .then(async (bl: boolean) => {

        if (this.view.target_object_type === 'new') {
          // if new object type is requested, create it first
          await this.createObjectType();
        } else
          if (!!bl) {
            // update object type fields before import if should
            await this.updateObjectTypeFields();
          }

        new Promise(async (resolve, reject) => {
          let iAll: number = this.view.selectedItems.length;
          let iDone: number = 0;
          let iPercentage: number = 0;

          const loadingOptions: loadingOptions = {
            dismissable: false,
            headline: 'data_importer_loading_title',
            icon: null,
            progress: 0,
            text: `data_importer_loading_text`,
          };

          const loadingModal: any = await this.loading.present(loadingOptions);

          setTimeout(() => {
            items.forEach(async (_item: any, index: number) => {
              setTimeout(async () => {
                try {
                  let item: any | null = null;

                  if (!!this.view.mappingKeys && !!this.view.mappingKeys.length) {
                    this.view.mappingKeys.forEach((key: string, index: number) => {
                      if (_item.hasOwnProperty(index)) {
                        item = item || {};
                        item[key] = _item[index];
                      }
                    });
                  } else {
                    item = _item;
                  }

                  if (!!item) {
                    const exec: any = await this.objects.createObject(item, this.view.target_object_type)
                    console.log('importItem: exec', exec);
                  }

                  iDone++;
                  iPercentage = parseFloat(`${((100 / iAll) * iDone).toFixed(2)}`);

                  if (iDone === iAll) {
                    loadingModal.dismiss();
                    resolve(true);
                  } else {
                    loadingModal.updateConfig(Object.assign(loadingOptions, {
                      progress: iPercentage,
                      text: `${iPercentage}% (${iDone} / ${iAll})`,
                    }));
                  }
                } catch (e) {
                  iDone++;
                  iPercentage = parseFloat(`${((100 / iAll) * iDone).toFixed(2)}`);

                  console.log('importItem failed', e);

                  if (iDone === iAll) {
                    loadingModal.dismiss();
                    resolve(true);
                  } else {
                    loadingModal.updateConfig(Object.assign(loadingOptions, {
                      headline: 'error',
                      progress: iPercentage,
                      text: `${e}`,
                    }));
                  }
                }
              }, (index * 100));
            });
          }, 1000);
        })
          .then(() => {
            this.view.loading = false;
            this.dismiss();
          })
          .catch((error: any) => {
            this.view.loading = false;
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  importToTool() {
    const items: any[] = (this.view.selectedItems || []);
    console.log('importToTool: items', items);

    if (!items || !items.length || !this.view.target_object_type) {
      return false;
    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load() {
    this.view.loading = true;

    await this.loadProject();

    if (!!this.view.project) {
      await this.loadIntegrations();
      await this.loadObjectTypes();
      await this.loadTools();
    }

    this.view.source_object_type = this.source_object_type || this.view.source_object_type;
    this.view.source_type = this.source_type || this.view.source_type;
    this.view.source_tool = this.source_tool || this.view.source_tool;
    this.view.target_object_type = this.target_object_type || this.view.target_object_type;
    this.view.target_type = this.target_type || this.view.target_type;
    this.view.target_tool = this.target_tool || this.view.target_tool;

    this.source = this.view.source_type || this.source;
    this.type = this.view.source_tool || this.type;

    // if all params are provided, skip intro config view
    if (!!this.service && !!this.target_type && (!!this.target_object_type || !!this.target_tool)) {

      if (!this.data_backup) {
        this.data_backup = this.data;
      }

      this.calcFilters();
      this.calcMappings();
      this.startManually();
    }

    this.view.loading = false;
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.integrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadIntegrationSettings(blForceRefresh: boolean = false) {
    console.log('loadIntegrationSettings', this.view);
  }

  async loadObjectTypes(blForceRefresh: boolean = true) {
    try {
      const objectTypes: any = await this.objects.getTypes({}, blForceRefresh);

      this.view.object_types = (!!objectTypes && !!objectTypes.length ? objectTypes : []).map((type: any) => {

        if (!type.icon && (!!type.value && !!type.value.icon)) {
          type.icon = type.value.icon;
        }

        if (!type.name && (!!type.value && !!type.value.name)) {
          type.name = type.value.name;
        }

        return type;
      });
    } catch (e) {
      console.warn('loading object types failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  async loadTools(blForceRefresh: boolean = false) {
    try {
      const defaultAiTools: aiTool[] = (this.aiTools.getTools() || []) as aiTool[];

      this.view.tools = [
        {
          label: 'all',
          uid: 'all',
        },
      ].concat(defaultAiTools as any);
    } catch (e) {
      console.warn('loading object types failed', e);
    }
  }

  async loadUrl(url: string | null = null) {
    url = url || this.view.url;

    if (!url) {
      return false;
    }

    this.view.loading = true;
    this.view.url = url;

    const validate: any = this.toolsService.validateUrl(url);

    if (!validate.success) {
      return false;
    }

    try {

      const load: any = await this.service.loadUrl(url, this.settings);

      this.data = (!!load && !!load.data ? load.data : []);

      // mark all entries as selected by default
      this.data = this.data.map((item: any) => {
        item.checked = true;
        return item;
      });

      this.view.dataKeys = (!!load && !!load.dataKeys ? load.dataKeys : []);
      console.log('this.view.dataKeys ', this.view.dataKeys);

      this.view.mappingKeys = (!!load && !!load.mappingKeys ? load.mappingKeys : []);

      this.view.previewGridWidth = load.previewGridWidth || this.view.previewGridWidth;

      this.startManually();

      this.view.loading = false;
    } catch (e) {
      this.view.loading = false;
      console.warn('handling load url failed', e);
    }
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.view.viewType = 'table';

    this.loadCards();
    this.calcViewVars();

    try {
      this.view.loading = true;
      this.load();
    } catch (e) {
      this.view.loading = false;
      this.events.publish('error', e);
    }
  }

  objectTypeChanged(event: any | null = null) {

    if (!!this.view.target_object_type && !!this.view.target_object_type.uid) {
      this.view.target_object_type = this.view.target_object_type.uid;
    }

    this.calcMappings();
  }

  onFieldsChanged(event: any | null = null) {
    this.view.dataKeys = (event || []);

    this.view.mappingKeys = (this.view.dataKeys).map((field: any) => {
      return field.name || field.uid;
    });

    this.calcMappings();
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
  }

  onFormatChanged() {
    switch (this.settings.format) {
      case 'csv':
        this.view.seperator = 'comma';
        break;
      case 'tsv':
        this.view.seperator = 'tab';
    }
  }

  async onIntegrationChanged(event: any | null = null) {
    this.view.integrationName = (!!this.view.integration ? `${this.view.integration.name}`.replace('integration_', '') : null);

    if (!this.view.integrationName || !this.view.integrationName.length) {
      return false;
    }

    switch (this.view.integrationName) {
      case 'ftp':
        this.pickConnection();
        break;
    }

    this.startManually();

    await this.loadIntegrationSettings();
  }

  onItemsChanged(items: any[] = []) {
    this.view.selectedItems = (items || []);
    this.calcSelectedItems();
  }

  onLanguageChange(event: string | null = null) {
    this.view.language = event;
    this.saveFilters();
  }

  onOverlayInputChanged(event: any = null) {
    
    if (!event || !event.input || (!!event.input.name && (event.input.name !== 'input')) || !event.input.hasOwnProperty('value')) {
      return false;
    }

    this.view.aiCreateInput = event.input.value;
  }

  onSourceUrlChanged(event: any | null = null) {

    if (!this.view.sourceUrl || !this.view.sourceUrl.length) {
      return false;
    }

    const validate: any = this.toolsService.validateUrl(this.view.sourceUrl);

    if (!!validate && !!validate.success) {
      this.loadUrl(this.view.sourceUrl);
    }
  }

  onTargetOptionChanged(event: any = null) {

    if (!this.view.target_type) {
      return false;
    }

    switch (this.view.target_type) {
    }
  }

  pickConnection() {
    console.log('pickConnection', this.view.integrationName);
  }

  renderCsvContent() {
    this.loadUrl();
  }

  requestUpdateObjectFields() {
    return new Promise(async (resolve, reject) => {
      if (!!this.view && !!this.view.target_object_type && (this.view.target_object_type === 'new')) {
        resolve(false);
      } else {
        try {
          await this.alert.requestConfirm({
            headline: 'request_update_object_fields_headline',
            message: 'request_update_object_fields_text',
            buttons: [
              {
                handler: () => {
                  resolve(true);
                },
                role: 'submit',
                text: 'okay',
              },
              {
                handler: () => {
                  resolve(false);
                },
                role: 'cancel',
                text: 'cancel',
              },
            ],
          });
        } catch (e) {
          reject(e);
        }
      }
    });
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      //this.media.saveFilters(this.view.filters);
    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  seperatorChanged() {
    this.setFields(null);
    this.renderCsvContent();
  }

  setFields(fields: any[]) {
    this.fields = (fields || []);

    try {
      this.tableView.setFields(this.fields);
    } catch (e) {
      console.warn('updating table view fields failed: ', e);
    }
  }

  sourceTypeChanged(event: any | null = null) {
    let type: any = (!!event && !!event.detail && !!event.detail.hasOwnProperty('value') ? event.detail.value : event);

    if (!!type && !!type.uid) {
      type = type.uid;
    }

    if (!type || !type.length) {
      return false;
    }

    switch (type) {
      case 'file':
        this.upload();
        break;
      case 'object_type':
        //this.startManually();
        break;
      case 'tool':
        //this.startManually();
        break;
      case 'url':
        //this.startManually();
        break;
    }
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  targetChanged(event: any | null = null) {
    this.calcViewVars();
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updateObjectTypeFields() {
    return new Promise(async (resolve, reject) => {
      if (!this.view.object_types || !this.view.object_types.length) {
        reject('error_missing_object_types');
      } else
        if (!this.view.target_object_type) {
          reject('error_missing_object_type_uid');
        } else {

          const matchingTypes: any[] = this.view.object_types.filter((_type: any) => {
            return _type.uid === this.view.target_object_type;
          });

          let match: any = (!!matchingTypes && !!matchingTypes[0] ? matchingTypes[0] : null);
          console.log('updateObjectTypeFields: match', match);

          if (!match || !match.uid) {
            reject('error_missing_matching_object_type');
          } else {
            try {
              match.fields = (this.view.dataKeys || []);
              console.log('updateObjectTypeFields: match.fields', match.fields);

              const exec: any = await this.objects.updateType(match);
              console.log('updateObjectTypeFields: exec', exec);

              resolve(exec);
            } catch (e) {
              reject(e);
            }
          }
        }
    });
  }

  upload() {

    const params: any = {
      mediaType: 'document',
      multiple: false,
      services: ['media_library', 'upload'],
    };

    this.media.applyFromWeb(null, params)
      .then((response: any) => {

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].guid) {
          this.loadUrl(response.items[0].guid);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useFirstRowAsFieldsChanged(event: any | null = null) {
    this.renderCsvContent();
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}