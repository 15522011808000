// angular & ionic
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// global
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// http
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// jwt
import { JwtInterceptor } from './interceptors/jwt.interceptor';

// ionic-native extension
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { Brightness } from '@awesome-cordova-plugins/brightness/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';

// i18n
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { from } from 'rxjs';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

// dependencies / libraries
import { ColorPickerModule } from '@iplab/ngx-color-picker';
import { TagInputModule } from 'ngx-chips';
import { register } from 'swiper/element/bundle';

// angular calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// code editor
import { provideCodeEditor } from '@ngstack/code-editor';

// getgenius + pipeline
import { GetgeniusDaniComponentModule } from 'src/app/components/getgenius/getgenius-dani/getgenius-dani.component.module';
import { CrudService } from "src/app/services/core/crud.service";
//import { DragDirective } from './directives/drag.directive';

// electron
//import { NgxElectronModule } from 'ngx-electron';

// intro
//import { TourIonPopoverModule } from 'ngx-ui-tour-ionic';

// syntax highlighting
import { provideHighlightOptions } from 'ngx-highlightjs';

// Nebular:
import { NbThemeModule } from '@nebular/theme';

register();

class CustomLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [

    // Angular:
    BrowserModule,
    BrowserAnimationsModule,
    ScrollingModule,
    AppRoutingModule,

    // Nebular:
    NbThemeModule.forRoot(),

    // i18n:
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: CustomLoader,
        //useFactory: (createTranslateLoader),
      }
    }),

    // Intro:
    //TourIonPopoverModule,

    // Ionic:
    IonicModule.forRoot({
      scrollAssist: false,
      scrollPadding: true,
    }),

    IonicStorageModule.forRoot(),

    // Extensions:
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    ColorPickerModule,
    TagInputModule,

    // GetGenius:
    GetgeniusDaniComponentModule,

    // Custom directives:
    //DragDirective,
  ],
  providers: [
    Badge,
    Brightness,
    Camera,
    Crop,
    File,
    FileTransfer,
    FilePath,
    Geolocation,
    LocalNotifications,
    NativeGeocoder,
    Network,
    SocialSharing,
    TextToSpeech,
    CrudService,
    provideCodeEditor(),
    provideHighlightOptions({
      fullLibraryLoader: () => import('highlight.js')
    }),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }