import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';
import { ImporterService } from '../../../services/utils/importer.service';

@Component({
  selector: 'pipeline-table-view',
  standalone: false,
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})
export class TableViewComponent implements OnInit {

  @Input() activeField: any;

  @Input() buttons: button[];

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() fields: any[];

  @Input() loading: boolean;

  @Input() method: string;

  @Input() mode: string = 'view';

  @Input() options: tableViewOptions;

  @Input() selectionOptions: selectionOption[];

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  @Input() service: any;

  @Input() url: string;

  @Input() view: any;

  itemHasValueObject: boolean;

  @Input() items: any[];
  @Output() itemsChanged = new EventEmitter();
  @Output() itemsUpdated = new EventEmitter();

  @Output() fieldsChanged = new EventEmitter();

  fieldsHaveUid: boolean;

  @ViewChild('fieldTypePopover') fieldTypePopover;

  fieldTypes: any[] = [
    {
      name: 'boolean',
      uid: 'boolean',
    },
    {
      name: 'datetime',
      uid: 'datetime',
    },
    {
      name: 'json',
      uid: 'json',
    },
    {
      name: 'number',
      uid: 'number',
    },
    {
      name: 'field_type_select',
      uid: 'enum',
    },
    {
      name: 'field_type_text',
      uid: 'text',
    },
    {
      name: 'timestamp',
      uid: 'timestamp',
    },
    {
      name: 'url',
      uid: 'url',
    },
  ];

  isFieldTypePopoverOpen: boolean = false;

  itemSize: number = 45;

  paginationConfig: paginationConfig = {

  };

  @Input() urlSettings: any = {
    format: 'csv',
    proxy: true,
    quote_character: '"',
    quote_characters: false,
    seperator: ',',
    use_first_row_as_fields: true,
  };

  constructor(
    private alertService: AlertService,
    private events: EventsService,
    private importerService: ImporterService,
    private toolsService: ToolsService,
    private viewService: ViewService,
  ) {

  }

  addField(event: any | null = null) {
    this.fields = this.fields || [];

    this.fields.push({
      label: '',
      type: 'text',
    });

    this.onFieldsChanged();
    this.calcViewVars();
  }

  calcSelectedItems(item: any | null = null) {

    if (this.view.items && this.view.items.length) {
      this.view.selectedItems = this.view.items.filter((_item: any) => {
        return !!_item.checked || (!!item && (_item.uid === item.uid && !!item.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.itemsChanged.emit(this.view.selectedItems);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.fieldTypes = this.view.fieldtypes || this.fieldTypes;

    if (!this.view.hasOwnProperty('dataKeys') && !!this.items && !!this.items[0]) {
      this.view.dataKeys = Object.keys(this.items[0]);
    }

    if (!this.view.hasOwnProperty('mappingKeys') && !!this.view.dataKeys) {
      this.view.mappingKeys = this.view.dataKeys;
    }

    if (!this.view.hasOwnProperty('rowMappings') && !!this.view.dataKeys) {
      this.view.rowMappings = this.view.dataKeys;
    }

    this.itemHasValueObject = (!!this.items && !!this.items[0] && (typeof this.items[0].value === 'object'));

    if (!this.fields || !this.fields.length) {
      this.initFields();
    }

    this.fields = this.fields || [];
    this.fieldsHaveUid = (!!this.fields && !!this.fields[0] && (typeof this.fields[0] === 'object') && this.fields[0].hasOwnProperty('uid'));

    this.view.firstColWidth = (this.mode === 'edit' ? 70 : 50);
    this.view.colWidth = 200;
    this.view.iFields = (this.view.mode === 'view' ? (this.fields?.length || 0) : ((this.fields?.length || 0) + 1));

    this.view.previewGridWidth = (!!this.fields && !!this.fields.length ?
      ((this.view.iFields * this.view.colWidth) + this.view.firstColWidth) :
      (this.view.colWidth + this.view.firstColWidth)
    );
  }

  delete(item: any, index: number | null = null) {
    console.log('delete: item', item);
    console.log('delete: index', index);

    return this.deleteItem(item, index);
  }

  deleteField(field: any) {

    this.fields = this.fields.filter((_field: any) => {
      return (_field.uid || _field.name) !== (field.uid || field.name);
    });

    this.onFieldsChanged();
    this.isFieldTypePopoverOpen = false;
  }

  deleteItem(item: any, index: number | null = null) {
    console.log('deleteItem: item', item);
    console.log('deleteItem: index', index);
  }

  doRefresh(event: any | null = null) {
    try {
      this.loadData(true);
    } catch (e) {
      this.events.publish('error', e);
      console.warn('refreshing failed (1)', e);
    }
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  initFields() {
    this.itemHasValueObject = (!!this.items && !!this.items[0] && (typeof this.items[0].value === 'object'));

    if (!this.fields || !this.fields.length) {
      if (!!this.itemHasValueObject) {
        this.fields = Object.keys(this.items[0].value);
      } else
        if (!!this.items && !!this.items[0]) {
          this.fields = Object.keys(this.items[0]);
        } else
          if (!!this.view.dataKeys && !this.fields) {
            this.fields = this.view.dataKeys;
          }
    }
  }

  loadData(blForceRefresh: boolean = false) {

    if (!this.method) {
      console.warn('table-view: method not defined');
      return false;
    } else
      if (!this.service) {
        console.warn('table-view: service not defined');
        return false;
      }

    try {
      this.loading = true;

      let params: any = JSON.parse(JSON.stringify((!!this.options && !!this.options.params ? this.options.params || {} : {})));
      
      if (!this.service || !this.service.hasOwnProperty(this.method)) {
        return false;
      }

      this.service[this.method](params, blForceRefresh)
        .then((response: any) => {
          this.items = (response || []);
          this.loading = false;

          this.calcViewVars();
          this.onItemsChanged();
        })
        .catch((error: any) => {
          this.loading = false;
          this.events.publish('error', error);
        });
    } catch (e) {
      this.events.publish('error', e);
    }

  }

  async loadUrl(url: string | null = null) {
    url = url || this.url;

    if (!url) {
      return false;
    }

    this.loading = true;

    if (url !== this.url) {
      this.url = url;
    }

    const validate: any = this.toolsService.validateUrl(url);

    if (!validate.success) {
      return false;
    }

    try {
      const load: any = await this.importerService.loadUrl(url, this.urlSettings);
      console.log('load', load);

      const items = (!!load && !!load.data ? load.data : []);
      console.log('items', items);

      // mark all entries as selected by default
      this.setItems(items.map((item: any) => {
        item.checked = true;
        return item;
      }));

      //this.setDataKeys(!!load && !!load.dataKeys ? load.dataKeys : []);
      this.setFields(!!load && !!load.dataKeys ? load.dataKeys : []);

      this.view.dataKeys = (!!load && !!load.dataKeys ? load.dataKeys : []);
      this.view.mappingKeys = (!!load && !!load.mappingKeys ? load.mappingKeys : []);
      this.view.previewGridWidth = load.previewGridWidth || this.view.previewGridWidth;

      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.warn('handling load url failed', e);
    }
  }

  ngOnInit() {
    this.options = this.options || {};

    this.initEvents();
    this.initFields();

    this.calcViewVars();
    this.validateUIState();

    if (!!this.url && (!this.items || !this.items.length)) {
      this.loadUrl();
    }
  }

  onButtonClick(button: button, item: any) {

    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  onFieldsChanged(event: any = null) {
    this.calcViewVars();
    this.fieldsChanged.emit(this.fields);
  }

  onFieldTypeClick(type: any, event: any | null = null) {
    this.activeField.type = type.uid;
    this.isFieldTypePopoverOpen = false;

    /*
    if(!!event) {
      event.detail.complete();
    }
    */
  }

  onItemChecked(item: any, event: any | null = null) {
    this.calcSelectedItems();
  }

  onItemCheckboxClicked(item: any | null = null) {
    this.calcSelectedItems(item);
  }

  onItemInputChanged(event: any = null, index: number) {
    this.items[index].changed = true;
    this.itemsUpdated.emit(this.items);
  }

  onItemsChanged() {
    this.itemsChanged.emit(this.items);
  }

  pickFieldType(field: any, event: any | null = null) {
    this.fieldTypePopover.event = event;
    this.isFieldTypePopoverOpen = true;
    this.activeField = field;
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  selectAll(event: any | null = null) {

    if (!this.items || !this.items.length) {
      return false;
    }

    this.items.forEach((item: any) => {
      item._checked = event.detail.value === 'on';
    });

    this.calcSelectedItems();
  }

  public setDataKeys(keys: any[]) {
    this.view.dataKeys = keys || [];
    this.calcViewVars();
  }

  public setFields(fields: any[]) {
    this.fields = (fields || []);
    this.calcViewVars();
  }

  public setItems(items: any[]) {
    this.items = (items || []);
    this.calcViewVars();
  }

  public setMappingKeys(keys: any[]) {
    this.view.mappingKeys = keys || [];
    this.calcViewVars();
  }

  public setRowMappings(mappings: any[]) {
    this.view.rowMappings = mappings || [];
    this.calcViewVars();
  }

  showItemOptions(item: any, event: any = null) {
    this.view.optionsItem = item;

    this.selectionOptionsPicker.show({
      event: event,
      item: item,
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;

    if (!!this.options.photoKey) {
      item[this.options.photoKey] = item.photo;
    }
  }

  toggleActive(item: any, event: any | null = null) {
    console.log('toggleActive', item);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  use_as_fields() {
    console.log('use_as_fields: view.optionsItem', this.view.optionsItem);

    if (!this.view.optionsItem && !this.view.optionsItem.length) {
      return false;
    }

    let dataKeys: string[] = [];

    const fields: any[] = this.view.optionsItem.map((fieldName: string, index: number) => {
      fieldName = `${fieldName || ''}`;
      dataKeys.push(fieldName);

      return {
        name: fieldName,
        uid: fieldName, //index,
      };
    });

    const mappedItems: any[] = (this.items || []).map((item: any) => {
      let _item: any = { checked: !!item.checked };

      dataKeys.forEach((key: string, iKey: number) => {
        _item[key] = item[iKey];
      });

      return _item;
    });

    console.log('use_as_fields: mappedItems', mappedItems);

    this.setDataKeys(dataKeys);
    this.setFields(fields);
    this.setItems(mappedItems);

    this.onFieldsChanged();
    this.onItemsChanged();
  }

  validateUIState() {
    if (!this.items || !this.items.length) {
      this.loadData();
    }
  }

}