import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSearchbar, NavController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { TemplateEditorService } from 'src/app/services/media/template-editor.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { TemplateStorePage } from 'src/app/pages/core/media/templates/template-store/template-store.page';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';
import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

@Component({
  selector: 'app-media-templates',
  standalone: false,
  templateUrl: './media-templates.page.html',
  styleUrls: ['./media-templates.page.scss'],
})
export class MediaTemplatesPage implements OnInit {
  @ViewChild('searchInput') searchInput: IonSearchbar;
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  cards: any = {};

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'templates',
    keys: ['name', 'post_content', 'title', 'description', 'url', 'uid'],
    query: "",
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  view: any = {
    aspectRatio: '1x1',
    filters: [

      /* Type */
      {
        icon: 'images-outline',
        multiple: true,
        name: 'type',
        type: 'select',
        uid: 'type',
        value: ['image', 'video'],
        values: [
          {
            checked: true,
            icon: 'image-outline',
            uid: 'image',
            label: 'image',
            name: 'image',
          },
          {
            checked: true,
            icon: 'film-outline',
            uid: 'video',
            label: 'video',
            name: 'video',
          },
        ]
      },

      /* Color schemes */
      {
        icon: 'color-palette-outline',
        multiple: true,
        name: 'colors',
        type: 'select',
        uid: 'colors',
        value: ['light', 'dark'],
        values: [
          {
            checked: true,
            uid: 'light',
            label: 'color_scheme_light',
            name: 'color_scheme_light',
          },
          {
            checked: true,
            uid: 'dark',
            label: 'color_scheme_dark',
            name: 'color_scheme_dark',
          },
        ]
      },

      /* Format */
      {
        icon: 'layers-outline',
        multiple: true,
        name: 'provider',
        type: 'select',
        uid: 'provider',
        value: [
          'aep', 
          //'html5',
          'polotno'
        ],
        values: [
          {
            checked: true,
            icon: 'image-outline',
            uid: 'aep',
            label: 'media_template_format_aep',
            name: 'media_template_format_aep',
          },
          /*
          {
            checked: true,
            icon: 'film-outline',
            uid: 'html5',
            label: 'media_template_format_html5',
            name: 'media_template_format_html5',
          },
          */
          {
            checked: true,
            icon: 'film-outline',
            uid: 'polotno',
            label: 'media_template_provider_polotno',
            name: 'media_template_provider_polotno',
          },
        ]
      },

      /* Aspect ratios */
      {
        icon: 'expand-outline',
        multiple: true,
        name: 'aspect_ratio',
        type: 'select',
        uid: 'aspect_ratio',
        value: ['16x9', '1x1', '9x16'],
        values: [
          {
            uid: '16x9',
            label: '16 x 9',
            name: '16 x 9',
          },
          {
            uid: '1x1',
            label: '1 x 1',
            name: '1 x 1',
          },
          {
            uid: '9x16',
            label: '9 x 16',
            name: '9 x 16',
          },
        ]
      },

    ],
    introCard: {
      uid: 'media_templates_top_card',
      text: 'media_templates_top_card_text',
      title: 'media_templates_top_card_title',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 150,
    multiple: true,
    options: {
      filter: {
        active: true,
      }
    },
    placeholder: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    route: 'media/templates',
    scrollIndex: 0,
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    tags: [],
    templateView: {
      aspectRatio: '1x1',
      editable: false,
      running: false,
      maxHeight: 85,
      maxWidth: 85,
    },
    title: 'media_templates',
    viewType: 'grid',
  };

  user: user;

  constructor(
    private configService: ConfigService,
    private editor: TemplateEditorService,
    private events: EventsService,
    private folders: FoldersService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    public templates: TemplatesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.user = this.userService.getUser();
  }

  async add(event: any | null = null) {

    /*
    this.editor.setTemplate({
      type: 'image',
    });
    */

    this.navCtrl.navigateForward('/media/templates/editor/create');
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.canDelete = this.userService.isType(['Admin', 'Moderator']);
    this.view.canPublish = this.userService.isType(['Admin', 'Moderator']);
    this.view.canSubmitForReview = this.userService.isType(['Admin', 'Redakteur', 'Moderator']);

    this.view.colSize = {
      left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
      item: (!!this.view.isDesktop ? (!!this.view.expertMode ? 4 : 3) : 6),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };

    this.view.templateView.maxHeight = (this.view.viewType === 'grid' ? 300 : 85);
    this.view.templateView.maxWidth = (this.view.viewType === 'grid' ? 300 : 85);

    this.detectChanges();
  }

  delete(template: mediaTemplate) {

    if (!this.view.canDelete) {
      this.events.publish('error_permission_user_group_not_allowed');
      return false;
    }

    this.templates.delete(template.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.loading = true;

    this.tools.bulk({
      action: 'delete',
      items: this.view.selectedItems,
      service: this.templates,
    })
      .then(() => {
        this.view.loading = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.view.loading = false;
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
    */
  }

  doRefresh(event: any | null = null) {
    this.loadMediaTemplates(true)
      .then(() => {
        delete this.view.templates_backup;

        if (event) {
          event.target.complete();
        }

        this.runSearch();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (event) {
          event.target.complete();
        }
        this.detectChanges();
      });
  }

  duplicate(template: mediaTemplate) {
    this.templates.duplicate(template.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {
    console.log('duplicate selected: event', event);
    console.log('duplicate selected: view', this.view);
  }

  async edit(template: mediaTemplate) {

    if (!template || !template.provider) {
      return false;
    }

    switch (template.provider) {
      case 'aep':
        // @todo implement after effects template editing
        break;
      case 'html5':
        this.editor.editCustomTemplate(template);
        break;
      case 'polotno':
        this.editor.editCustomTemplate(template);
        break;
    }
  }

  filterChanged() {
    this.doRefresh();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
        item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
        right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.user = this.userService.getUser();

    this.initEvents();

    this.detectChanges();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadMediaTemplates(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {

      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.templates = [];
        resolve(this.view.templates);
        return false;
      }

      this.view.loading = true;
      this.view.templates = this.view.placeholder;

      this.detectChanges();

      this.templates.get(blForceRefresh, this.view.options)
        .then((templates: post[]) => {
          this.view.loading = false;

          this.view.templates = (templates || []).map((template: mediaTemplate) => {
            template.view = JSON.parse(JSON.stringify(this.view.templateView));
            return template;
          }).reverse();

          this.calcViewVars();
          this.detectChanges();

          resolve(templates);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadTemplatesByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  moveFolder(item: any) {
    this.folders.moveFolder([item], 'templates')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'templates')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();

    this.loadMediaTemplates()
      .catch((error: any) => {
        this.events.publish('error', error);
      });

    this.detectChanges();
  }

  onItemChecked(template: mediaTemplate) {

    this.view.selectedItems = this.view.templates.filter((_template: mediaTemplate) => {
      return _template.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  async onSelectedTagChanged(event: any | null = null) {
    this.view.tag = event;
    //await this.runSearch();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
      case 'set_active':
        this.updateSelected({ active: true });
        break;
      case 'set_public':
        this.updateSelected({ public: true });
        break;
      case 'unset_active':
        this.updateSelected({ active: false });
        break;
      case 'unset_public':
        this.updateSelected({ public: false });
        break;
    }

    this.view.selectedItems = [];
    this.view.hasSelectedItems = false;

    return true;

  }

  presentPopover(e: Event, item: any) {
    this.view.item = item;

    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  publishTemplate(post: post) {

    if (!this.view.canPublish) {
      this.events.publish('error_permission_user_group_not_allowed');
      return false;
    }

    return false;

    /*
    this.blogAdmin.accept(post)
    .then(() => {
      this.view.segment = 'publish';
      this.doRefresh();
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
    */
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  runSearch(event: any | null = null) {
    const key: string = 'templates';

    if (this.view.hasOwnProperty(key)) {

      let backupKey: string = `${key}_backup`,
        searchKeys: string[] = (this.search.keys || ['name', 'title', 'uid', 'url']);

      if (!this.view.hasOwnProperty(backupKey) && this.view[key] && this.view[key].length) {
        this.view[backupKey] = JSON.parse(JSON.stringify(this.view[key].map((item: any) => {
          delete item.view;
          return item;
        })));
      } else
        if (this.view[backupKey] && this.view[backupKey].length) {
          this.view[key] = JSON.parse(JSON.stringify(this.view[backupKey]));
        }

      let query: string = `${this.search.query}`.toLowerCase();

      if (this.view[key] && this.view[key].length) {
        this.view[key] = this.view[key].filter((item: any) => {
          let blShow: boolean = (!query || !query.length);

          if (!!query) {
            searchKeys.forEach((searchKey: string) => {
              if (item.hasOwnProperty(searchKey)) {
                blShow = (blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(query) !== -1);
              }
            });
          }

          return blShow;
        });
      }
    }
  }

  scrolledIndexChange(index: number) {
    this.view.scrollIndex = index;
  }

  segmentChanged() {
    this.view.segment = this.view.segment || 'automated';

    this.view.introCard.subtitle = `media_templates_segment_${this.view.segment}_top_card_subtitle`;
    this.view.introCard.text = `media_templates_segment_${this.view.segment}_top_card_text`;
    this.view.introCard.title = `media_templates_segment_${this.view.segment}_top_card_title`;

    this.detectChanges();
  }

  selectAll() {

    this.view.templates.forEach((template: mediaTemplate) => {
      template.checked = !template.hidden && !this.view.allSelected;
    });

    this.view.allSelected = !this.view.allSelected;

    this.view.selectedItems = this.view.templates.filter((template: mediaTemplate) => {
      return !!template.checked;
    });

    this.view.hasSelectedItems = !!this.view.selectedItems && !!this.view.selectedItems.length;

    this.events.publish('media:templates:selection:changed', {
      items: this.view.selectedItems,
      last: (!!this.view.selectedItems ? this.view.selectedItems[0] : null),
    });

    this.detectChanges();
  }

  setView(viewType: string) {
    this.view.viewType = viewType;
  }

  async templateStore() {

    const modal: any = await this.modalService.create({
      component: TemplateStorePage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  thumbnailLoadingfailed(template: mediaTemplate) {
    template.photo = this.fallbackImg;
  }

  toggleSearch() {
    this.search.visible = !this.search.visible;

    if (this.searchInput) {
      this.searchInput.setFocus();
    }

    this.detectChanges();
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  typeChanged() {
    this.doRefresh();
  }

  updateSelected(data: any) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

      this.view.selectedItems.forEach(async (template: mediaTemplate) => {
        try {
          template = Object.assign(template, data);

          if (!!template.uid) {
            this.templates.update(template)
              .then(() => {
                i++;
                if (i === (this.view.selectedItems.length)) {
                  resolve(true);
                }
              })
              .catch(() => {
                i++;
              });
          } else {
            i++;
          }
        } catch (e) {
          i++;
        }
      });
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}