import { AfterContentInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiConfigService } from "src/app/services/ai/ai-config.service";
import { AiLoaderService } from "src/app/services/ai/ai-loader.service";
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from "src/app/services/core/language.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { CrawlerService } from "src/app/services/utils/crawler.service";
import { ToolsService } from "src/app/services/utils/tools.service";

import { SpeechRecognition } from "@capacitor-community/speech-recognition";

import { SplineViewerComponent } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component';

@Component({
  selector: 'getgenius-dani',
  standalone: false,
  templateUrl: './getgenius-dani.component.html',
  styleUrls: ['./getgenius-dani.component.scss'],
})
export class GetgeniusDaniComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() assistant: aiAssistant | null;
  @Input() config: daniConfig = {};
  @Input() state: state;

  @Output() chatChanged = new EventEmitter();
  @Output() voicesChanged = new EventEmitter();

  @ViewChild('historyWrapper', { read: ElementRef }) private historyWrapper: ElementRef;
  @ViewChild('daniChatSettingsPopover') daniChatSettingsPopover: any;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  addActions: any[] = [
    {
      icon: 'musical-notes-outline',
      label: 'audio',
      uid: 'audio',
    },
    {
      icon: 'document-text-outline',
      label: 'document',
      uid: 'document',
    },
    {
      icon: 'image-outline',
      label: 'image',
      uid: 'image',
    },
    {
      disabled: true,
      icon: 'film-outline',
      label: 'movie',
      uid: 'movie',
    },
  ];

  allowedVoiceNames: string[] = ['Eddy', 'Flo', 'Reed', 'Martin', 'Rocko'];

  animationClass: string = 'full';

  blockUpdateChat: boolean = false;

  blBlockRunAiPrompt: boolean = false;

  blInitialized: boolean = false;

  blListening: boolean = false;

  blLoading: boolean = false;

  blSpeechRecognitionAvailable: boolean = false;

  browserConfig: aiBrowserConfig = {
    use_proxy: true,
  };

  @Input() chat: chat = {
    messages: [] as chatHistoryItem[],
  };

  iExecutionTry: number = 0;

  inputPrompt: string = '';

  @Input() introCard: introCardConfig = {
    uid: 'getgenius_dani_chat_top_card',
    text: 'getgenius_dani_chat_top_card_text',
    title: 'getgenius_dani_chat_top_card_title',
  };

  isSettingsPopoverOpen: boolean = false;

  @Input() size: number;

  splineOptions: splineOptions = {
    zoom: (window.outerWidth > 768 ? 0.55 : 0.25),
  };

  tableViewButtons: button[] = [];

  tableViewOptions: tableViewOptions = {
  };

  ttsPipe: any;

  urlsByState: any = {};

  user: user;

  view: any = {};

  voices: any[] = [];

  webRecognition: any;

  webSpeechSynthesis: any;

  constructor(
    private aiConfig: AiConfigService,
    private aiLoader: AiLoaderService,
    private aiTools: AiToolsService,

    //private changeDetectorRef: ChangeDetectorRef,
    private crawler: CrawlerService,
    public dani: DaniService,
    private events: EventsService,
    private language: LanguageService,
    private mediaService: MediaextendService,
    private navCtrl: NavController,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.urlsByState = this.dani.getUrlsByState();
    this.user = this.userService.getUser();
  }

  addChatMessage(message: chatHistoryItem) {

    if (!message) {
      return false;
    }

    message.mode = message.mode || "message";

    this.chat.messages = (this.chat.messages || []) as chatHistoryItem[];
    this.chat.messages = [...this.chat.messages as chatHistoryItem[], message as chatHistoryItem];

    this.setChat(this.chat as chat);

    return {
      index: (!!this.chat && !!this.chat.messages.length ? (this.chat.messages.length - 1) : 0),
    };
  }

  addFile(event: any | null = null) {
    this.daniChatSettingsPopover.event = event;
    this.isSettingsPopoverOpen = true;
  }

  async afterAiPromptExecution() {
    // store chat if not stored yet

    try {
      const store: any = await this.dani.storeChat(this.chat);

      if (!!store && !!store.chat && !!store.chat.uid) {
        this.events.publish('chats:reload');

        if (!this.chat || !this.chat.uid) {
          this.chat.uid = store.chat.uid;

          this.blockUpdateChat = true;

          this.events.publish('chats:current:updated', this.chat);

          setTimeout(() => {
            this.blockUpdateChat = false;
          }, 50);
        }
      }
    } catch (e) {
      console.warn('storing chat failed: ', e);
    }
  }

  public applyLoadingOptions() {
    if (!!this.config && !!this.config.zoom) {
      this.setZoom(this.config.zoom);
    }
  }

  audio() {
    return this.upload();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars();
    this.view.isLoggedIn = this.userService.isLoggedIn();

    this.chat = (this.chat || {}) as chat;

    this.state = this.state || {};
    this.state.hasMessages = !!(!!this.chat && !!this.chat.messages && !!this.chat.messages.length);
    this.state.inConversation = !!(!!this.chat && !!this.chat.messages && (this.chat.messages.length > 1));

    this.splineOptions = this.splineOptions || {};
    this.splineOptions.zoom = (!!this.view.isDesktop ? 0.55 : 0.25);

    try {
      if (!!this.blInitialized && !!this.splineOptions.zoom) {
        this.setZoom(this.splineOptions.zoom);
        //this.setSize(window.outerWidth, window.outerHeight);
      }
    } catch (e) {
      console.warn('setting dani size failed', e);
    }
  }

  public async checkPermissions() {
    return new Promise((resolve, reject) => {
      if (this.tools.isWeb()) {
        try {

          const SpeechRecognition: any = this.getWebSpeechRecognition();
          //const SpeechGrammarList = (window as any).SpeechGrammarList || (window as any).webkitSpeechGrammarList;
          //const SpeechRecognitionEvent = (window as any).SpeechRecognitionEvent || (window as any).webkitSpeechRecognitionEvent;

          resolve(!!SpeechRecognition);
        } catch (e) {
          reject(e);
        }
      } else {
        SpeechRecognition.checkPermissions()
          .then((response: any) => {
            resolve(!!response && !!response.speechRecognition && (response.speechRecognition === 'granted'));
          })
          .catch(reject);
      }
    })
  }

  createTicket() {

    const inputs: any[] = [
      {
        icon: 'eye-outline',
        name: 'subject',
        placeholder: 'subject',
        type: 'text',
        uid: 'subject',
      },
      {
        icon: 'text-outline',
        name: 'input',
        placeholder: 'message',
        type: 'textarea',
        uid: 'input',
      },
      {
        icon: 'person-outline',
        name: 'name',
        placeholder: 'name',
        type: 'text',
        uid: 'name',
      },
      {
        icon: 'mail-outline',
        name: 'email',
        placeholder: 'your_email',
        type: 'email',
        uid: 'email',
      },
      {
        icon: 'call-outline',
        name: 'phone',
        placeholder: 'phone',
        type: 'tel',
        uid: 'phone',
      },
    ];

    inputs.forEach((input: any, index: number) => {

      setTimeout(() => {

        this.addChatMessage({
          icon: input.icon,
          mode: 'input',
          input: `${input.name}`,
          placeholder: `${input.placeholder}`,
          role: 'system',
          type: input.type || 'text',
          uid: `${input.uid}`,
        });

        setTimeout(() => {
          this.scrollDown();
        }, 100);

      }, (index * 200));

    });

    this.inputPrompt = '';
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
    */
  }

  document() {
    return this.upload();
  }

  async getCurrentLanguage() {
    return (await this.language.getDisplayLanguage() || this.userService.getLanguage())
  }

  async getLangCode() {
    const lang: string = await this.getCurrentLanguage();
    //console.log('lang', lang);

    let langCode: string = `${lang}_${lang.toUpperCase()}`;
    //console.log('langCode (a)', langCode);

    if (langCode === 'en_EN') {
      langCode = 'en_US';
    }

    return langCode;
  }

  getWebSpeechRecognition() {
    return (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  }

  handleUploadResponse(response: any) {

    if (!response || !response.items) {
      return false;
    }

    response.items.forEach(async (item: mediaItem) => {
      // send + analyse every selected item

      try {

        await this.runAiPrompt({}, {
          input: item.guid,
          type: item.type,
        });

      } catch (e) {
        this.events.publish('error', e);
      }
    });

  }

  image() {
    return this.upload();
  }

  async init() {
    const lang: string = await this.getCurrentLanguage();
    this.config.language = this.config.language || lang;
  }

  async initAssistant() {
    let history: aiExecutionHistoryItem[] = this.chat.messages || [];

    if (!!this.assistant && !!this.assistant.config && !!this.assistant.config.knowledgebase) {
      this.assistant.config.knowledgebase.forEach((entry: aiKnowledgebaseEntry) => {
        history.push({
          role: 'system',
          content: `${entry.input}: ${entry.output}`,
        })
      });
    }

    if (!!this.assistant && !!this.assistant.config && !!this.assistant.config.urls) {
      this.assistant.config.urls.forEach(async (urlEntry: any) => {
        if (!!urlEntry.url) {
          try {

            const responseHtml = await this.crawler.fetch(urlEntry.url, {
              method: 'GET',
              proxy: true,
            });

            const temp = document.createElement('div');
            temp.innerHTML = `${responseHtml || ''}`;

            const scripts: any = temp.getElementsByTagName('script');
            const styles: any = temp.getElementsByTagName('style');
            while (scripts[0]) scripts[0].parentNode.removeChild(scripts[0]);
            while (styles[0]) styles[0].parentNode.removeChild(styles[0]);

            const responseText = this.tools.stripHtml(temp.textContent || temp.innerText).replace(/\s+/g, ' ').trim();

            history.push({
              role: 'system',
              content: `Important crawled content of url "${urlEntry.url}": "${responseText}"`,
            });

          } catch (e) {
            console.warn(`[ CRAWLER ] Fetching url ${urlEntry.url} failed: ${e}`);
          }
        }
      });
    }

    this.chat.messages = history;
  }

  public initChat() {

    if (!this.config.hasChat) {
      return false;
    }

    this.zone.run(() => {
      let chat: any = (this.chat || []);

      if (!!this.config && !!this.config.chat) {
        chat = this.config.chat;
      }

      this.setChat(chat);

      setTimeout(() => {

        const firstMessageInput: string = (!!this.assistant && !!this.assistant.config && !!this.assistant.config.chat && !!this.assistant.config.chat.intro_message ?
          this.assistant.config.chat.intro_message : `getgenius_dani_chat_intro_message`
        );

        if (!!this.config.userCanWrite && (!this.chat.messages || !this.chat.messages.length)) {
          this.addChatMessage({
            mode: 'message',
            input: firstMessageInput,
            role: 'assistant',
          });
        }

        if (!!this.assistant) {
          this.initAssistant();
        }

        this.calcViewVars();
      }, 250);
    });
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.chatsCurrentUpdated = this.events.subscribe('chats:current:updated', (chat: chat) => {
      if (!this.blockUpdateChat) {
        this.chat = chat;

        setTimeout(() => {
          this.scrollDown();
        }, 100);
      }
    });

    this.view.events.daniChatAdd = this.events.subscribe('getgenius:dani:chat:add', (message: inboxChatMessage) => {
      this.zone.run(() => {

        this.addChatMessage({
          input: message.description,
          mode: 'message',
        });

        this.animationClass = 'profile';
      });
    });

    this.view.events.daniSplineSet = this.events.subscribe('getgenius:dani:spline:set', (url: string) => {
      this.setSplineUrl(url);
    });

    this.view.events.daniWindowResized = this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  async initSpeechRecognition() {

    if (!this.config.hasChat || !this.config.userCanWrite) {
      return false;
    }

    try {
      if (this.tools.isWeb()) {
        try {
          const SpeechRecognition: any = this.getWebSpeechRecognition();
          const langCode: string = await this.getLangCode();

          this.webRecognition = new SpeechRecognition();
          //const speechRecognitionList = new SpeechGrammarList();
          //speechRecognitionList.addFromString(grammar, 1);
          //recognition.grammars = speechRecognitionList;
          this.webRecognition.continuous = false;
          this.webRecognition.lang = langCode;
          this.webRecognition.interimResults = false;
          this.webRecognition.maxAlternatives = 1;

          this.blSpeechRecognitionAvailable = !!SpeechRecognition;

          if (!this.webSpeechSynthesis) {
            this.webSpeechSynthesis = window.speechSynthesis;
          }

          // in Google Chrome the voices are not ready on page load
          if ("onvoiceschanged" in this.webSpeechSynthesis) {
            this.webSpeechSynthesis.onvoiceschanged = () => {
              this.loadVoices();
            };
          } else {
            this.loadVoices();
          }

          this.view.webSpeechSynthesisAvailable = !!this.webSpeechSynthesis;

        } catch (e) {
          console.warn('speech web init failed', e);
          this.blSpeechRecognitionAvailable = false;
        }
      } else {
        SpeechRecognition.available()
          .then((response: any) => {
            this.blSpeechRecognitionAvailable = !!response && !!response.available;
          })
          .catch((e: any) => {
            console.warn('init speech recognition failed (2)', e);
          });
      }
    } catch (e) {
      console.warn('init speech recognition failed (1)', e);
    }
  }

  async initTextToSpeechPipeline() {
    this.ttsPipe = await this.aiLoader.initTextToSpeechPipeline();
  }

  public listen() {

  }

  async loadVoices() {

    const allVoices: any[] = this.webSpeechSynthesis.getVoices();
    const langCode: string = (await this.getLangCode()).replace('_', '-');

    const voicesByLanguage = allVoices.filter((voice: any) => {
      return voice.lang === langCode;
    });

    const preferredVoices: any[] = voicesByLanguage.filter((voice: any) => {
      let bl: boolean = false;

      this.allowedVoiceNames.forEach((voicePart: string) => {
        bl = !!bl || (voice.name.indexOf(voicePart) !== -1);
      });

      return bl;
    });

    this.voices = (!!preferredVoices && !!preferredVoices.length ? preferredVoices : (!!voicesByLanguage && !!voicesByLanguage.length ? voicesByLanguage : allVoices));
    this.voicesChanged.emit(this.voices);
  }

  movie() {
    return this.upload();
  }

  ngAfterContentInit() {
    this.calcViewVars();

    this.initSpeechRecognition();
    this.initEvents();
    this.initChat();

    this.applyLoadingOptions();

    this.blInitialized = true;
  }

  ngOnChanges() {
    this.applyLoadingOptions();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.applyLoadingOptions();
    this.init();
  }

  onBrowserPageDone(event: any | null = null) {
    console.log('dani: onPageDone', event);
  }

  onBrowserPageLoaded(event: any | null = null) {
    console.log('dani: onPageLoaded', event);
  }

  onBrowserPageRendered(event: any | null = null) {
    console.log('dani: onPageRendered', event);
  }

  onEditorInputChanged(value: string, key: string = 'input', index: number = 0) {

  }

  onTableFieldsChanged(fields: any[] = []) {
    //this.type.fields = (fields || []);
    this.view.fieldsChanged = true;
  }

  onTableItemsChanged(items: any[] = []) {
    this.view.selectedItems = (items || []);
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
    this.view.itemsChanged = true;
  }

  onTableItemsUpdated(items: any[] = []) {
    this.view.itemsChanged = true;
  }

  public play() {
    return this.splineViewer.play();
  }

  public async record() {

    if (!!this.tools.isWeb()) {

      try {
        this.webSpeechSynthesis.cancel();
      } catch (e) {
      }

      // start listening
      this.webRecognition.start();
      this.blListening = true;

      // on error
      this.webRecognition.onerror = (event) => {
        console.error('speech recognition error', event);
      };

      // handle no match
      this.webRecognition.onnomatch = (event) => {
        console.log('no match', event);
      };

      // on input update, set response to chat message textarea
      this.webRecognition.onresult = (event) => {
        if (!!event && !!event.results && event.results[0] && event.results[0][0] && event.results[0][0].transcript) {
          this.inputPrompt = event.results[0][0].transcript;
        }
      };

      // on speech end, stop listening and send input
      this.webRecognition.onspeechend = () => {
        this.webRecognition.stop();
        this.blListening = false;

        setTimeout(() => {

          if (!!this.inputPrompt) {
            this.runAiPrompt()
              .catch((error: any) => {
                this.events.publish('error', error);
              });
          }

        }, 100);
      };

      return false;
    }

    let check: any = false;

    try {
      check = await this.checkPermissions();
    } catch (e) {
      check = false;
    }

    if (!check) {

      try {
        SpeechRecognition.requestPermissions();
      } catch (e) {
        this.events.publish('error', e);
      }

      return false;
    }

    try {

      const langCode: string = await this.getLangCode();
      //console.log('speech langCode', langCode);

      SpeechRecognition.start({
        language: langCode,
        maxResults: 2,
        prompt: "Say something",
        partialResults: true,
        popup: true,
      });

      SpeechRecognition.removeAllListeners();

      SpeechRecognition.addListener("partialResults", (data: any) => {
        console.log("partialResults was fired", data.matches);
        this.inputPrompt = (!!data.matches && !!data.matches[0] ? data.matches[0] : '');
      });

      this.blListening = true;

    } catch (e) {
      this.events.publish('error', e);
      this.blListening = false;
    }
  }

  public resend(message: chatHistoryItem) {
    this.inputPrompt = `${message.input || ''}`;

    this.runAiPrompt()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  public runAiPrompt(options: any = {}, message: any = {}) {
    return new Promise((resolve, reject) => {

      if (!!this.blBlockRunAiPrompt) {
        return false;
      }

      this.zone.run(async () => {
        this.blBlockRunAiPrompt = true;

        setTimeout(() => {
          this.blBlockRunAiPrompt = false;
        }, 500);

        this.chat = (this.chat || {} as chat);
        this.iExecutionTry = (!!options && !!options.iTry ? options.iTry : 0);
        this.blLoading = true;

        this.setSplineUrl(this.urlsByState.WORKING);

        setTimeout(() => {
          this.scrollDown();
        }, 100);

        let history: chatHistoryItem[] = [];

        // calculate history
        if (!!this.chat && !!this.chat.messages && !!this.chat.messages.length) {
          this.chat.messages.forEach((historyItem: aiExecutionHistoryItem) => {
            history.push({
              input: `${historyItem.post_content || historyItem.input}`,
              role: `${historyItem.role || 'user'}` as any,
              type: `${historyItem.type || 'text'}` as any,
              mode: `${historyItem.mode || 'message'}` as any,
            });
          });
        }

        message = Object.assign({
          input: `${this.inputPrompt || ''}`,
          role: 'user',
          mode: 'message',
        }, (message || {}) as any) as chatHistoryItem;

        // add input to chat
        if (!!message.input) {
          this.addChatMessage(message);
        }

        this.calcViewVars();

        const config: any = this.config || {};

        const chatSendRequestOptions: chatSendRequestOptions = {
          history: history,
          post_content: message.input,
        };

        let newMessageIndex: number | null = null;

        this.dani.sendChat(
          chatSendRequestOptions,
          false,
          config,
          {
            language: config.language,

            // stream partial result (currently only supported in local mode)
            stream: await this.aiConfig.useStreaming(),

            onPartialResult: (partialResult: string, partIndex: number) => {
              console.log('dani: onPartialResult: partialResult', partialResult);

              if (!partIndex) {

                const add: any = this.addChatMessage({
                  mode: 'message',
                  input: `${this.tools.nl2br(partialResult)}`,
                  role: 'assistant',
                });

                // store the new message index for next partial updates
                if (add.hasOwnProperty('index')) {
                  newMessageIndex = add.index;
                }

              } else
                if ((newMessageIndex !== null) && !!this.chat[newMessageIndex]) {
                  this.chat[newMessageIndex].input = partialResult;
                }
            },

            useFunctions: true,
          }
        )
          .then((response: any) => {
            this.blLoading = false;

            const zoom: number = (window.outerWidth > 768 ? 0.55 : 0.25);

            this.setZoom(zoom);
            this.setSplineUrl(this.urlsByState.IDLE);

            const checkFunctionCall = this.aiTools.hasFunctionCallResponse(response);
            console.log('checkFunctionCall', checkFunctionCall);

            const blHasFunctionCall: boolean = !!(!!checkFunctionCall && !!checkFunctionCall.match);

            // generic method to handle function calls inline
            const handleInline: any = (checkFunctionCall: any) => {

              if (!checkFunctionCall.details.arguments || !checkFunctionCall.details.arguments.input) {
                return false;
              }

              let message: any = {
                arguments: checkFunctionCall.details.arguments,
                functionName: checkFunctionCall.details.name,
                mode: 'function',
                input: checkFunctionCall.details.arguments.input,
                role: 'assistant',
              };

              if (!!checkFunctionCall.details.arguments && !!checkFunctionCall.details.arguments.output) {
                message.output = checkFunctionCall.details.arguments.output;
              }

              switch (checkFunctionCall.details.name) {
                case 'create_article':
                  message.mode = 'editor';
                  break;
                case 'table_view':

                  // parse list data
                  if (message.arguments.hasOwnProperty('data')) {
                    message.arguments.data = JSON.parse(message.arguments.data);
                    console.log('dani: tableView: message.arguments.data', message.arguments.data);
                  }

                  message.mode = 'table';
                  break;
              }

              this.addChatMessage(message);

              setTimeout(async () => {
                this.inputPrompt = '';
                this.scrollDown();
              }, 100);
            };

            if (blHasFunctionCall && !!checkFunctionCall.details) {

              switch (checkFunctionCall.details.name) {

                case 'ai_browser':
                  handleInline(checkFunctionCall);
                  break;

                case 'create_article':
                  handleInline(checkFunctionCall);
                  break;

                case 'create_code':
                  handleInline(checkFunctionCall);
                  break;

                case 'create_ticket':
                  this.createTicket();
                  break;

                case 'image_to_video':
                  handleInline(checkFunctionCall);
                  break;

                case 'table_view':
                  handleInline(checkFunctionCall);
                  break;

                case 'text_to_audio':
                  handleInline(checkFunctionCall);
                  break;

                case 'text_to_image':
                  handleInline(checkFunctionCall);
                  break;

                case 'text_to_speech':
                  handleInline(checkFunctionCall);
                  break;

                default:
                  this.aiTools.executeFunctionCall(response)
                    .then((functionCallResponse: aiFunctionCallResponse) => {
                      console.log('dani: fallback functionCallResponse', functionCallResponse);
                      this.inputPrompt = '';
                      resolve(true);
                    })
                    .catch((error: any) => {
                      console.warn('executing fallback function call failed', error);
                    });
                  break;
              }
            } else
              if (!!response && !!response.output) {
                const split: string[] = this.tools.splitTripleBackticksWithCodeBlocks(`${response.output}`);
                console.log('split', split);

                this.chat.messages = [...history, message];
                this.setChat(this.chat);

                split.forEach((part: string, splitIndex: number) => {
                  setTimeout(() => {
                    if (!!part) {
                      this.zone.run(async () => {
                        await this.speakText(part);

                        const input: string = `${this.tools.nl2br(part)}`;
                        console.log('input', input);

                        this.addChatMessage({
                          mode: 'message',
                          input: input,
                          role: 'assistant',
                        });

                        setTimeout(async () => {
                          this.scrollDown();
                        }, 100);
                      });
                    }
                  }, (splitIndex) * 300);
                });

                this.inputPrompt = '';
                this.calcViewVars();
                resolve(true);
              }

            setTimeout(() => {
              this.afterAiPromptExecution();
              this.scrollDown();
            }, 200);

          })
          .catch((error: any) => {
            console.warn('send response error', error);

            this.blLoading = false;
            this.setSplineUrl(this.urlsByState.IDLE);

            if (!!error && (error === 'Error_pipeline_ai_resource_busy') && (3 > this.iExecutionTry)) {
              setTimeout(() => {
                this.runAiPrompt({
                  iTry: ((this.iExecutionTry || 0) + 1),
                }).then(resolve).catch(reject);
              }, 500);
            } else {
              reject(error);
            }
          });
      });
    });
  }

  async runItemSelectionOption(item: any, option: any) {
    try {

      if (!option || !option.uid) {
        return false;
      }

      const exec: any = await this[option.uid](item);

      this.isSettingsPopoverOpen = false;
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  public scrollDown() {
    try {
      if (!!this.historyWrapper) {
        this.historyWrapper.nativeElement.scrollTop = this.historyWrapper.nativeElement.scrollHeight;
      }
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  public send(blSubmit: boolean = false, event: any | null = null) {

    try {
      if (!!event) {
        event.preventDefault();
      }
    } catch (e) {
      console.warn('e', e);
    }

    try {
      if (!!this.blListening) {
        this.stopListening();
      }
    } catch (e) {
      console.warn('stopping to listen failed', e);
    }

    this.runAiPrompt()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  public setChat(chat: chat) {
    this.zone.run(() => {
      this.chat = (chat || {}) as chat;

      this.detectChanges();

      this.chatChanged.emit(this.chat);

      setTimeout(() => {
        this.calcViewVars();
        this.scrollDown();
      });
    });
  }

  public setLanguage(language: string) {
    console.log('dani-component: setting language to: ', language);
    this.config.language = language;
    return this;
  }

  public async setSplineUrl(url: string) {
    try {

      this.splineOptions = {
        ...this.splineOptions,
        path: url,
      };

      if (!this.splineViewer) {
        return false;
      }

      await this.splineViewer.updateAnimation(this.splineOptions);

    } catch (e) {
      console.warn('setting url failed', e);
    }
  }

  public setSize(width: number, height: number) {
    return this.splineViewer.setSize(width, height);
  }

  public setVoice(voice: string) {
    console.log('dani-component: setting voice to: ', voice);
    this.config.voice = voice;
    return this;
  }

  public setZoom(zoom: number) {

    this.splineOptions = {
      ...this.splineOptions,
      zoom: zoom,
    };

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.setZoom(zoom);
  }

  async speakText(text: string) {

    if (!text || (!!this.config && !this.config.allowSpeak)) {
      return false;
    }

    await this.initTextToSpeechPipeline();

    try {

      const tts: any = await this.aiTools.textToSpeech(text, {
        onError: (event: any = null) => {
          console.log('tts: onError', event);
        },
        onPlay: (event: any = null) => {
          console.log('tts: onPlay', event);
        },
      });

      console.log('tts: done', tts);

    } catch (e) {
      console.warn('tts using pipe failed: ', e);

      if (!!this.webSpeechSynthesis) {
        //this.webSpeechSynthesis.speak(text);

        const utterThis = new SpeechSynthesisUtterance(text);

        if (!!this.voices && !!this.voices[0]) {
          utterThis.voice = this.voices[0];
        }

        this.webSpeechSynthesis.speak(utterThis);
      }
    }
  }

  public stop() {

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.stop();
  }

  public stopListening() {
    this.blListening = false;

    try {
      SpeechRecognition.stop();
    } catch (e) {
      console.warn('stopping failed', e);
    }

    this.runAiPrompt()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  toChatPage() {
    this.navCtrl.navigateForward('/dani/chat');
  }

  toggleSpeak() {
    this.view.shouldSpeak = !this.view.shouldSpeak;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  upload(params: any = {}) {
    this.mediaService.applyFromWeb(null, params)
      .then((response: any) => {
        this.handleUploadResponse(response);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}