import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { SettingsService } from 'src/app/services/core/settings.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';

import { RegionSetupPage } from 'src/app/pages/core/regions/region-setup/region-setup.page';

@Component({
  selector: 'app-account',
  standalone: false,
  templateUrl: './account.page.html',
  styleUrls: ['./account.page.scss'],
})
export class AccountPage implements OnInit {

  config: pipelineAppConfig;

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  languageOptions: languagesSelectOptions = {
    all: true,
    cached: false,
    mode: 'user',
  };

  state: state = {};

  user: user;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    showBackButton: true,
    route: 'account',
    title: 'account',
  };

  constructor(
    private cache: CacheService,
    private configService: ConfigService,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private settings: SettingsService,
    public userService: UserService,
    private viewService: ViewService,
  ) {
    this.config = this.configService.getConfig();
    this.view.blockUpdates = true;

    this.user = this.userService.getUser() || {};
    this.view.region = this.user.city;
  }

  abonnement() {
    this.navCtrl.navigateForward('/abonnements');
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  clearCaches() {
    this.cache.clear()
      .then(() => {
        this.navCtrl.navigateForward('/login');
      })
      .catch((error: any) => {
        console.warn('error', error);
      });
  }

  connections() {
    this.navCtrl.navigateForward('/connect');
  }

  creatorSetup() {
    this.navCtrl.navigateForward('/create-contents-banner');
  }

  dismiss(data: any | null = null) {

    if (this.view.mode === 'edit') {
      this.view.mode = 'view';
      return false;
    }

    this.modalService.dismiss(data);
  }

  edit() {
    this.view.mode = 'edit';
  }

  ionViewWillEnter() {
    this.view.blockUpdates = true;
    this.user = this.userService.getUser() || {};

    if (!this.user || !this.user.uid || (this.user.uid === -1)) {
      this.navCtrl.navigateRoot('/login');
    } else {
      setTimeout(() => {
        this.view.blockUpdates = false;
      }, 300);
    }

  }

  ionViewDidEnter() {
  }

  ionViewDidLeave() {
    this.view.blockUpdates = true;
  }

  ionViewWillLeave() {
    this.view.blockUpdates = true;
  }

  ngOnInit() {
    this.calcViewVars();
  }

  notifications() {
    this.navCtrl.navigateForward('/notifications/settings');
  }

  async regionSetup() {

    const modal: any = await this.modalService.create({
      component: RegionSetupPage,
      animated: true,
      presentingElement: document.getElementById('ion-router-outlet-content'),
      cssClass: 'defaultModal',
    });

    this.modalService.present(modal);
  }

  security() {
    this.navCtrl.navigateForward('/security');
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackAvatarImg;
  }

  transparency() {
    this.navCtrl.navigateForward('/transparency-settings');
  }

  updateUser() {
    if (!this.view.blockUpdates) {
      this.userService.setUser(this.user, true)
        .then(() => {
          this.settings.showUpdatedToast();
          this.view.mode = 'view';
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    }).then((response: any) => {
      let imageUrl: string | null = (typeof response === 'string' ? response : null);

      if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }

      if (!!imageUrl) {
        this.user.photo = imageUrl;
        this.updateUser();
      }
    })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}