<!-- Wallets popover -->
<ion-popover #walletsPopover [isOpen]="isWalletsPopoverOpen" (didDismiss)="isWalletsPopoverOpen = false">
  <ng-template>

    <ion-radio-group [(ngModel)]="wallet" *ngIf="!!wallets">

      <ion-item button *ngFor="let wallet of wallets; trackBy:trackItems"
        [disabled]="!!view.loading || !!view.loadingWallet">
        <ion-radio justify="start" labelPlacement="end" [value]="wallet.uid" class="ion-text-wrap">
          <ion-label class="ion-text-wrap">
            <h3 [innerHTML]="wallet.name|translate"></h3>
            <p [innerHTML]="wallet.balance"></p>
          </ion-label>
        </ion-radio>
      </ion-item>

    </ion-radio-group>

  </ng-template>
</ion-popover>

<ion-item *ngIf="!!view" lines="none" class="ion-text-wrap"
  [class.danger]="!!wallet && !!wallet.uid && (1 > wallet.balance)">

  <ion-icon [name]="!!wallet && (wallet.balance > 0) ? 'wallet-outline' : 'warning-outline'" slot="start"
    [hidden]="!!view.loadingWallet" (click)="openWallet()"></ion-icon>

  <!-- wallet balance -->
  <ion-label class="ion-text-wrap" *ngIf="!!wallet && !!wallet.uid" [hidden]="!view.isDesktop || !!view.loadingWallet"
    (click)="openWallet()">
    <h3 [innerHTML]="'balance'|translate" class="ion-text-wrap"></h3>
    <p *ngIf="!!wallet && (wallet.balance > 0)" [innerHTML]="wallet.balance" class="ion-text-wrap"></p>
    <p *ngIf="!wallet || (1 > wallet.balance)" [innerHTML]="'getgenius_wallet_restock_warning'|translate"></p>
  </ion-label>

  <!-- wallet not set-up -->
  <ion-label class="ion-text-wrap" *ngIf="!wallet || !wallet.uid" [hidden]="!view.isDesktop || !!view.loadingWallet"
    (click)="openWallet()">
    <h3 [innerHTML]="'wallet'|translate" class="ion-text-wrap"></h3>
    <p [innerHTML]="'setup_wallet'|translate" class="ion-text-wrap"></p>
  </ion-label>

  <!-- Mobile toggle button -->
  <ion-button slot="end" (click)="pick($event)" *ngIf="!!wallets && !!wallets.length" [hidden]="!!view.loadingWallet">
    <ion-icon name="chevron-down" slot="end"></ion-icon>
  </ion-button>

  <!-- Loading wallet spinner -->
  <ion-spinner slot="end" [hidden]="!view.loadingWallet"></ion-spinner>

</ion-item>