import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { UserService } from "src/app/services/core/user.service";
import { PostsService } from 'src/app/services/posts/posts.service';

@Component({
  selector: 'app-loading',
  standalone: false,
  templateUrl: './loading.page.html',
  styleUrls: ['./loading.page.scss'],
})
export class LoadingPage implements OnInit {

  allowWithoutLogin: boolean = true;

  config: loadingOptions = {};

  loadingService: any;

  user: user;

  view: any = {
    footer: {},
    header: {},
    post: {},
  };

  constructor(
    private events: EventsService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private posts: PostsService,
    private userService: UserService,
  ) {
    this.user = this.userService.getUser() || {};
    this.view.slug = this.route.snapshot.paramMap.get('slug');

    if (this.view.slug) {
      this.loadData();
    }
  }

  dismiss() {
    this.loadingService.dismiss();
  }

  initEvents() {

    this.events.subscribe('loading:component:update', (config: any) => {
      this.config = Object.assign(this.config || {}, config || {});
    });

  }

  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
  }

  loadData() {
    const method: string = this.view.slug == parseInt(this.view.slug) ? 'getPostByUid' : 'getPostBySlug';

    if (!this.posts || !this.posts[method]) {
      console.warn('missing posts method');
      this.events.publish('view:post', this.view.slug);
      return false;
    }

    this.posts[method](this.view.slug)
      .then((post: post) => {
        this.events.publish('view:post', post);
        setTimeout(() => {
          this.navCtrl.navigateRoot('/dashboard');
        });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.navCtrl.navigateForward('/login');
      });
  }

  ngOnInit() {
    this.initEvents();
  }

}