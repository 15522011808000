import { AfterContentInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { TemplatesService } from 'src/app/services/media/templates.service';

@Component({
  selector: 'pipeline-video-template-preview',
  standalone: false,
  templateUrl: './video-template-preview.component.html',
  styleUrls: ['./video-template-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoTemplatePreviewComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() options: videoTemplatePreviewOptions;
  @Input() template: mediaTemplate;

  @ViewChild('videoElement', { read: ElementRef }) videoElement: ElementRef;

  fallbackAvatarImg: string = 'https://getgenius.ai/assets/img/avatars/1.webp';
  fallbackImg: string = 'https://getgenius.ai/assets/img/fallback.webp';

  view: any = {
    avatar_src: 'https://getgenius.ai/assets/img/avatars/1.webp',
    bg_src: 'https://getgenius.ai/assets/img/fallback.webp',
    logo_src: '',
    phase: 2,
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private templates: TemplatesService,
  ) {
    this.options = this.options || {};
  }
  
  applyMetaData() {
    this.template = this.template || {};
    this.template.config = this.template.config || {};
    this.template.config._meta = this.template.config._meta || {};

    if(!!this.template && !!this.template.config && !!this.template.config._meta) {
      this.view.avatar_src = this.template.config._meta.avatar_src || this.view.avatar_src;
      this.view.bg_src = this.template.config._meta.bg_src || this.view.bg_src;
      this.view.logo_src = this.template.config._meta.logo_src || this.view.logo_src;
      
      this.template.config._meta.avatar_src = this.view.avatar_src;
      this.template.config._meta.bg_src = this.view.bg_src;
      this.template.config._meta.logo_src = this.view.logo_src;
    }
  }

  changeAvatar() {
    return this.changeImage('avatar_src');
  }

  changeBackground() {
    return this.changeImage('bg_src');
  }

  changeImage(key: string = 'bg_src') {
    this.media.applyFromWeb(null, {
    })
    .then((response: any) => {
      let imageUrl: string|null = (typeof response === 'string' ? response : null);
      
      if(!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
        imageUrl = response.items[0].thumbnail;
      }
      
      this.view[key] = imageUrl;

      this.template.config._meta = this.template.config._meta || {};
      this.template.config._meta[key] = imageUrl;
    })
    .catch((error: any) => {
      if(!!error) {
        this.events.publish('error', error);
      }
    });
  }

  changeLogo() {
    return this.changeImage('logo_src');
  }

  configReady() {

  }

  dataReady() {

  }

  destroy(event: any|null = null) {
    //console.warn('destroy', event);
  }

  domLoaded() {

  }

  error(event: any|null = null) {
    console.warn('error', event);
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.mediaTemplatePreviewRun = this.events.subscribe('media:template:preview:run', (data: any) => {
      let template = (!!data && !!data.template ? data.template : null);
      
      if(this.templates.isCurrent(template, this.template)) {
        this.playTemplate();
      }
    });

    this.view.events.videoTemplatePreviewUpdated = this.events.subscribe('video:template:preview:updated', (template: mediaTemplate) => {
      if(template.uid === this.template.uid) {
        template.config = template.config || {};
        template.config._meta = template.config._meta || {};
  
        this.template = template;

        this.applyMetaData();
      }
    });
  }

  ionViewWillEnter() {
    this.applyMetaData();
  }

  ngAfterContentInit() {
    if(!!this.options && !!this.options.autoplay) {
      this.playTemplate();
    }
  }

  ngOnDestroy() {
    if(!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }
  
  ngOnInit() {
    this.options = this.options || {};

    this.initEvents();
    this.applyMetaData();
  }

  playTemplate() {
    try {
      this.videoElement.nativeElement.play();
    } catch(e) {
      console.warn('playing video failed', e);
    }
  }

  thumbnailLoadingfailed() {
    this.template.photo = this.fallbackImg;
  }

  updateAnimation(): void {
    this.options = {
      ...this.options,
      path: this.options.render_path,
    };
  }

}
