<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="object && !!object.uid" shape="round" color="primary" fill="solid"
        icon-only>
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!object || !object.uid" shape="round" color="primary" fill="solid" icon-only>
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!!introCard" [hidden]="introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <!-- Iterate fields -->
    <div [hidden]="!!view.loading" *ngIf="!!type && !!type.fields && !!type.fields.length">

      <ion-card *ngFor="let field of type.fields">
        <ion-list>

          <!-- Single field -->
          <ion-item lines="none">

            <!--
            <ion-thumbnail slot="start" (click)="uploadPhoto()">
              <ion-img [src]="object.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(object)"></ion-img>
            </ion-thumbnail>
            -->

            <!-- Type: Checkbox -->
            <ion-checkbox *ngIf="field.type === 'boolean' || field.type === 'checkbox'" [(ngModel)]="field.value"
              [disabled]="field.disabled" [required]="field.required" [innerHTML]="field.name || field.uid"
              justify="start" labelPlacement="end"></ion-checkbox>

            <!-- Type: Date -->
            <ion-datetime *ngIf="field.type === 'date' || field.type === 'datetime' || field.type === 'timestamp'"
              [(ngModel)]="field.value"></ion-datetime>

            <!-- Type: Select -->
            <ion-select *ngIf="field.type === 'select' && !!field.options">
              <ion-select-option *ngFor="let option of field.options" [innerHTML]="option"
                [value]="option"></ion-select-option>
            </ion-select>

            <!-- Type: Text -->
            <ion-input
              *ngIf="field.type === 'email' || field.type === 'number' || field.type === 'text' || field.type === 'url'"
              [(ngModel)]="field.value" type="text" [disabled]="field.disabled" [required]="field.required"
              [label]="field.name || field.uid" labelPlacement="stacked"
              [placeholder]="'field_value_placeholder'|translate"></ion-input>

          </ion-item>

        </ion-list>
      </ion-card>

    </div>

  </div>

</ion-content>