import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class RulesService {

    operators: operator[] = [
        {
            indent: 'equals',
            name: 'operator_equals',
        },
        {
            indent: 'not_equals',
            name: 'operator_not_equals',
        },
        {
            indent: 'empty',
            name: 'operator_empty',
        },
        {
            indent: 'not_empty',
            name: 'operator_not_empty',
        },
        {
            indent: 'like',
            name: 'operator_like',
        },
        {
            indent: 'not_like',
            name: 'operator_not_like',
        },
        {
            indent: 'smaller_or_equal',
            name: 'operator_smaller_or_equal',
        },
        {
            indent: 'greater_or_equal',
            name: 'operator_greater_or_equal',
        },
    ];

    constructor(
        private AppCMS: AppcmsService,
    ) {

    }

    public delete(ruleId: number) {
        return this.AppCMS.loadPluginData('pipeline', {
            rule_uid: ruleId,
        }, ['rules', 'delete']);
    }

    public getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['rules'], null, blForceRefresh);
    }

    getOperators() {
        return this.operators;
    }

}