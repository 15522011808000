import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';

import { AiCoderService } from "src/app/services/ai/ai-coder.service";

import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from "src/app/services/core/events.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { WindowManagerService } from 'src/app/services/core/window-manager.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { proxyUrl } from 'src/config/variables';

import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

@Component({
  selector: 'app-ai-codes',
  standalone: false,
  templateUrl: './ai-codes.page.html',
  styleUrls: ['./ai-codes.page.scss'],
})
export class AiCodesPage implements OnDestroy, OnInit {
  
  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;
  @ViewChild('headerPopover') headerPopover;

  isHeaderPopoverOpen: boolean = false;

  @ViewChild('importPopover') importPopover: any;

  introCard: introCardConfig = {
    uid: 'ai_codes_top_card',
    text: 'ai_codes_top_card_text',
    title: 'ai_codes_top_card_title',
  };

  isImportPopoverOpen = false;

  appConfig: pipelineAppConfig;

  cards: any = {
    folders: { open: true },
  };

  cta: any = {
    handler: () => {
      this.add();
    },
    icon: 'add-outline',
    label: 'create',
  };

  paginationConfig: paginationConfig = {
    itemsKey: 'coder_projects',
    limit: 500,
  };

  proxyUrl: string;

  search: searchOptions = {
    itemsKey: 'coder_projects',
    keys: ['name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    {
      color: 'warning',
      icon: 'play-outline',
      label: 'execute',
      uid: 'execute',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];


  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    itemsKey: 'coder_projects',
    multiple: true,
    output: '',
    route: 'ai/codes',
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_codes',
    viewType: 'grid',
  };

  constructor(
    public aiCoder: AiCoderService,

    private alertService: AlertService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
    private windowManager: WindowManagerService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.proxyUrl = proxyUrl;
  }

  add(event: any | null = null) {
    this.windowManager
      .open({
        route: '/ai/coder',
        uid: 'ai_coder',
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
      item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? 4 : 6) : 12),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };

    //this.view.showViewModeSelect = !!this.view.isDesktop;
  }

  delete(project: aiCoderProject, blAsk: boolean = true) {
    if (!!blAsk) {
      this.alertService.requestConfirm()
        .then((bl: boolean) => {
          if (!!bl) {
            return this.submitDelete(project);
          }
        })
        .catch((e: any) => {
          console.warn('not deleting: ', e);
        });
    } else {
      return this.submitDelete(project);
    }
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

  }

  doRefresh(event: any | null = null) {
    this.loadCoderProjects(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {

        if (!!event) {
          event.target.complete();
        }

        if (!!error) {
          this.events.publish('error', error);
        }

      });
  }

  duplicate(item: any | null = null) {
    console.log('duplicate single: item', item);
    console.log('duplicate single: view', this.view);

    this.aiCoder.duplicateProject(item.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'duplicateProject',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.aiCoder,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  edit(project: aiCoderProject) {

    if (!project || !project.uid) {
      return false;
    }

    this.navCtrl.navigateForward(`/ai/coder/${project.uid}`);
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    this.exportService.exportUsingUI({
      data: (this.view.posts || []),
      service: this.aiCoder,
      source_tool: 'ai_codes',
      source_type: 'tool',
    })
      .then((response: any) => {
        console.log('export response', response);

        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
        item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
        right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadCoderProjects()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
    this.calcViewVars();
  }

  loadCoderProjects(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.coder_projects = [];
        resolve(this.view.coder_projects);
      } else {
        this.view.loading = true;

        this.aiCoder.getProjects(blForceRefresh)
          .then((projects: aiCoderProject[]) => {
            this.view.loading = false;

            if (projects && projects.length) {
              projects.forEach((project: aiCoderProject) => {
                let blCodeFound: boolean = false;

                if (!!project.files && !!project.files.length) {
                  project.files.forEach((file: any) => {
                    if (!!file.code && (!blCodeFound || file.label === 'index.html')) {
                      project.code = file.code;
                      blCodeFound = true;
                    }
                  });
                }

                if (!!project.code) {
                  project.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${project.code}`);
                }
              });
            }

            this.view.coder_projects = projects;

            setTimeout(() => {
              this.calcViewVars();
            });

            resolve(projects);
          })
          .catch((error: any) => {
            this.view.loading = false;
            reject(error);
          });
      }
    });
  }

  loadCoderProjectsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  moveFolder(item: any) {
    this.folders.moveFolder([item], 'code_projects')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'code_projects')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProjects() {

  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadCards();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);
    this.doRefresh();
  }

  onFolderLoaded(items: any[] | null = null) {
    console.log('onFolderLoaded', items);
  }

  onItemChecked(plan: any) {

    this.view.selectedItems = this.view.coder_projects.filter((_plan: any) => {
      return _plan.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProjects();
        break;
    }
  }

  presentPopover(e: Event, item: any) {
    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  refreshView(project: aiCoderProject) {
    project.code = `${project.code || ''}`;
    project.code_preview = this.domSanitizer.bypassSecurityTrustHtml(`${project.code}`);

    this.calcViewVars();
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  submitDelete(task: aiCoderProject) {
    return new Promise((resolve, reject) => {
      this.aiCoder.deleteProject(task.uid)
        .then((response: any) => {
          this.loadCoderProjects(true);
          resolve(response);
        })
        .catch(reject);
    });
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}