import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-media-create-form-realestate',
  standalone: false,
  templateUrl: './media-create-form-realestate.component.html',
  styleUrls: ['./media-create-form-realestate.component.scss'],
})
export class MediaCreateFormRealestateComponent implements OnInit {
  @Input() view: any;

  fallbackImg: string = './assets/img/fallback.webp';

  constructor() { }
  
  ngOnInit() {
    this.view.variables = [
      {
        uid: 'address',
        label: 'address',
        type: 'string',
      },
      {
        uid: 'square_meter',
        label: 'square_meter',
        type: 'number',
      },
      {
        uid: 'number_of_rooms',
        label: 'number_of_rooms',
        type: 'number',
      },
      {
        uid: 'price',
        label: 'price',
        type: 'string',
      }
    ];
  }

}
