import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { EventsService } from 'src/app/services/core/events.service';
import { NetworkService } from 'src/app/services/core/network.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private alertCtrl: AlertController,
    private events: EventsService,
    private network: NetworkService,
    private translate: TranslateService,
  ) {
  }

  async handleError(error: any) {
    const blConnected: boolean = await this.network.isConnected();

    if (!blConnected) {
      this.network.showOfflineMessage();
      return false;
    }

    if (error && typeof error === 'object') {
      error = (error.message || (error.error || error.errorMessage));
    }

    const alertParams = {
      animated: true,
      header: "Oh oh!",
      subHeader: 'Das hat nicht geklappt',
      message: error,
      buttons: [
        {
          text: "Okay",
        },
      ],
    };

    let keys: string[] = ['error', 'error_subHeader', error];

    if (!!error && (typeof error === 'string') && keys.indexOf(error) === -1) {
      keys.push(error);
    }

    this.translate.get(keys)
      .subscribe(async (translations: any) => {
        alertParams.header = translations['error'];
        alertParams.subHeader = translations['error_subHeader'];

        if (!!error) {
          alertParams.message = translations[error as string] || error;
        }

        const alert: any = await this.alertCtrl.create(alertParams);
        alert.present();

      }, async (error: any) => {
        console.warn('main error handler -> translations -> error', error);

        const alert: any = await this.alertCtrl.create(alertParams);
        alert.present();
      });
  }

  init() {
    this.initEvents();
  }

  initEvents() {
    this.initOnErrorEvent();
  }

  initOnErrorEvent() {
    this.events.subscribe("error", async (error: any) => {
      this.handleError(error);
    });
  }

}