import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { AiShortcodePage } from 'src/app/pages/core/ai/ai-shortcode/ai-shortcode.page';

@Injectable({
    providedIn: 'root'
})
export class AiShortcodesService {

    shortcodes: aiShortcode[] = [
        {
            icon: 'speedometer-outline',
            input: 'Optimize the content and return the improved content only.',
            name: 'ai_tools_enhance',
            key: 'enhance',
            token: 50,
            type: "custom",
        },
        {
            icon: 'expand-outline',
            input: 'Extend the content and return the extended content only.',
            name: 'ai_tools_extend',
            key: 'extend',
            token: 100,
            type: "custom",
        },
        {
            icon: 'shield-checkmark-outline',
            input: 'Fact-check the content and return the fact-checked content with additional information and facts about the input added to each important part.',
            name: 'ai_tools_factchecking',
            key: 'factchecking',
            token: 25,
            type: "custom",
        },
        {
            icon: 'dice-outline',
            input: 'Create a quiz based on the input and return the quiz only.',
            name: 'ai_tools_quiz',
            key: 'quiz',
            token: 50,
            type: "custom",
        },
        {
            icon: 'create-outline',
            input: 'Rewrite the content and improve your own version only.',
            name: 'ai_tools_rewrite',
            key: 'rewrite',
            token: 100,
            type: "custom",
        },
        {
            icon: 'crop-outline',
            input: 'Summarize the input and return the summarization only.',
            name: 'ai_tools_shorten',
            key: 'shorten',
            token: 50,
            type: "custom",
        },
        {
            icon: 'bulb-outline',
            input: 'Simplify the input and return the simplified version only.',
            name: 'ai_tools_simplify',
            key: 'simplify',
            token: 50,
            type: "custom",
        },
        {
            icon: 'text-outline',
            input: 'Rewrite the text in a gender-appropriate way and return the improved version only.',
            name: 'ai_tools_gender',
            key: 'gender',
            token: 75,
            type: "custom",
        },
        {
            icon: 'text-outline',
            input: 'Remove all gender-inclusive language and return the improved version only.',
            name: 'ai_tools_ungender',
            key: 'ungender',
            token: 75,
            type: "custom",
        }
    ];

    constructor(
        private AppCMS: AppcmsService,
        private modalService: ModalService,
    ) {

    }

    create(data: any | null = null) {
        data = data || {};

        return new Promise(async (resolve, reject) => {

            const modal: any = await this.modalService.create({
                component: AiShortcodePage,
                componentProps: {
                    aiShortcodes: this,
                },
                animated: true,
                presentingElement: await this.modalService.getTop(),
                cssClass: "defaultModal",
            });

            modal.onWillDismiss().then((response: any) => {
                resolve(response);
            });

            await this.modalService.present(modal);
        });
    }

    delete(shortcode: aiShortcode) {
        return this.AppCMS.loadPluginData('pipeline', {
            shortcode_uid: shortcode.uid,
        }, ['ai', 'shortcodes', 'delete']);
    }

    duplicate(shortcode: aiShortcode) {
        return this.AppCMS.loadPluginData('pipeline', {
            shortcode_uid: shortcode.uid,
        }, ['ai', 'shortcodes', 'duplicate']);
    }

    edit(shortcode: aiShortcode) {
        return new Promise(async (resolve, reject) => {

            const modal: any = await this.modalService.create({
                component: AiShortcodePage,
                componentProps: {
                    aiShortcodes: this,
                    shortcode: shortcode,
                },
                animated: true,
                presentingElement: await this.modalService.getTop(),
                cssClass: "defaultModal",
            });

            modal.onWillDismiss().then((response: any) => {
                resolve(response);
            });

            await this.modalService.present(modal);
        });
    }

    getCustomShortcodes(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'shortcodes'], {}, blForceRefresh);
    }

    getDefaultShortcodes() {
        return this.shortcodes;
    }

    saveShortcode(shortcode: aiShortcode) {
        return this.AppCMS.loadPluginData('pipeline', {
            shortcode: shortcode,
        }, ['ai', 'shortcodes', 'create']);
    }

    updateShortcode(shortcode: aiShortcode) {
        return this.AppCMS.loadPluginData('pipeline', {
            shortcode: shortcode,
        }, ['ai', 'shortcodes', 'update']);
    }

}