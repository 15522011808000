import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';

//import weclapp from '@weclapp/connect';

@Injectable({
  providedIn: 'root'
})
export class WeclappService {

  apiConfig: apiExplorerConfig = {
    baseUrl: '',
    routes: [
    ],
  };

  cacheKey: string = 'weclappConfigs';

  current: weclappConfig;

  paymentMethodIcons: any = {
    'Abschlagzahlung': 'cash-outline',
    'Auf Rechnung': 'cash-outline',
    'Bank': 'extension-puzzle-outline',
    'Barzahlung': 'cash-outline',
    'EC-Karte': 'card-outline',
    'Kreditkarte': 'card-outline',
    'Lastschrift': 'extension-puzzle-outline',
    'Online Zahlungsservice': 'extension-puzzle-outline',
    'PayPal': 'logo-paypal',
    'SumUp': 'extension-puzzle-outline',
    'Vorkasse': 'cash-outline',
  };

  paymentOptions: any;

  user: any;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
  ) {

  }

  async addConnection(config: weclappConfig) {
    let connections: weclappConfig[] = await this.getConnections();
    connections.push(config);

    this.setConnections(connections);
  }

  auth(connection: weclappConfig = null) {
    return new Promise(async (resolve, reject) => {
      connection = (connection || await this.getCurrentConnection());

      if (!this.user) {
        try {

          /*
          let weclappAuthConfig: any = {
            //username: '<string>',
            apikey: connection.api_token,
            tenant: connection.tenant,
            //domain: `${connection.tenant}.weclapp.com`
          };

          this.user = weclapp(weclappAuthConfig);
          resolve(this.user);
          */

        } catch (e) {
          reject(e);
        }
      }
    });
  }

  calculateArticlePrice(article: any) {
    let articlePrices: weclappArticlePrice[] = (article && !!article.articlePrices ? article.articlePrices : []);

    article.amount = article.amount || 0;

    if (!article.price && articlePrices && articlePrices.length) {
      let price: number = 0;

      articlePrices.forEach((articlePrice: weclappArticlePrice) => {
        if (articlePrice &&
          (articlePrice.currencyName === 'EUR') &&
          (articlePrice.priceScaleType === 'SCALE_FROM') &&
          (parseInt(articlePrice.priceScaleValue) <= article.amount)) {
          price = parseFloat(articlePrice.price);
        }
      });

      article.price = price;
    }

    return article.price;
  }

  connect(config: weclappConfig) {
    return new Promise((resolve, reject) => {
      this.validateConnection(config)
        .then(() => {
          this.addConnection(config);
          this.setCurrentConnection(config);

          resolve({
            success: true,
            config: config,
          });
        })
        .catch(reject);
    });
  }

  createArticle(article: loomaArticle) {
    return this.execute('createArticle', 'POST', { article: article });
  }

  createArticleCategory(category: loomaArticleCategory) {
    return this.execute('createArticleCategory', 'POST', { category: category });
  }

  createParty(party: loomaUser) {
    return this.execute('createParty', 'POST', { party: party });
  }

  deleteArticle(articleId: number | string) {
    return this.execute('deleteArticle', 'DELETE', { articleId: articleId });
  }

  deleteArticleCategory(categoryId: number | string) {
    return this.execute('deleteArticleCategory', 'DELETE', { categoryId: categoryId });
  }

  deleteParty(partyId: number | string) {
    return this.execute('deleteParty', 'DELETE', { partyId: partyId });
  }

  deleteConnection(config: weclappConfig) {
    return new Promise(async (resolve, reject) => {
      let connections: weclappConfig[] = await this.getConnections();

      connections = connections.filter((connection: weclappConfig) => {
        return connection.api_token !== config.api_token;
      });

      this.setConnections(connections);
      resolve(connections);
    });
  }

  deleteOffer(offerId: number | string) {
    return this.execute('deleteOffer', 'DELETE', { offerId: offerId });
  }

  downloadArticleImage(articleId: number, options: any = {}, blForceRefresh: boolean = false) {
    return this.execute(['article', (articleId as any), 'downloadArticleImage'], options, blForceRefresh);
  }

  execute(route: string | string[], method: string = 'GET', data: any = {}, blForceRefresh: boolean = false) {

    // @debug, because CORS
    return this.executeUsingProxy(route, method, data, blForceRefresh);

    /*
    return new Promise((resolve, reject) => {
      
      this.auth()
      .then((authResponse: any) => {
        let methodName = '';

        switch(method) {
          case 'GET':
            methodName = `get${this.tools.capitalize(route as string)}s`;
            break;
        }

        authResponse.fetch(route).then(resolve).catch(reject);
      })
      .catch(reject);
    });
    */
  }

  executeUsingProxy(route: string | string[], method: string = 'GET', data: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      let connection: weclappConfig = await this.getCurrentConnection();

      if (!connection) {
        reject('error_weclapp_not_connected');
        return false;
      }

      if (!connection.api_token) {
        reject('error_weclapp_api_token_missing');
      }

      if (!connection.tenant) {
        reject('error_weclapp_tenant_missing');
      }

      let params: any = Object.assign(data, {
        apiKey: connection.api_token,
        apiUrl: connection.tenant,
      });

      this.AppCMS.loadPluginData('weclapp', params, [route])
        .then((response: any) => {
          if (response && response.length) {
            resolve(response);
          } else
            if (response && response.result) {
              resolve(response.result);
            } else {
              reject(response);
            }
        })
        .catch(reject);
    });
  }

  getArticles(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('article', 'GET', options, blForceRefresh);
  }

  getArticleCategories(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('articleCategory', 'GET', options, blForceRefresh);
  }

  getArticlePrices(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('articlePrice', 'GET', options, blForceRefresh);
  }

  getArticleRatings(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('articleRating', 'GET', options, blForceRefresh);
  }

  getBlanketPurchaseOrders(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('blanketPurchaseOrder', 'GET', options, blForceRefresh);
  }

  async getCacheKey() {
    let tenant: string = await this.getTenant(),
      seperator: string = (!!tenant ? '_' : ''),
      suffix: string = (!!tenant ? tenant : ''),
      cacheKey: string = `${this.cacheKey}${seperator}${suffix}`;
    return cacheKey;
  }

  async getConnections(blForceRefresh: boolean = false) {
    let connections: weclappConfig[] = [],
      fromCache: cacheItem = await this.cache.get(this.cacheKey, (!!blForceRefresh ? 0 : -1));
    return (fromCache && fromCache.data ? fromCache.data : connections);
  }

  async getCurrentConnection(blIncludeDefaultConnection: boolean = true) {

    if (!blIncludeDefaultConnection) {
      return this.current;
    }

    let connections: weclappConfig[] = (await this.getConnections()),
      defaultConnection: weclappConfig = (connections && connections[0] ? connections[0] : null);

    return this.current || defaultConnection;
  }

  getCalendar(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('calendar', 'GET', options, blForceRefresh);
  }

  getCampaigns(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('campaign', 'GET', options, blForceRefresh);
  }

  getComments(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('comments', 'GET', options, blForceRefresh);
  }

  getContacts(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('contact', 'GET', options, blForceRefresh);
  }

  getCurrencies(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('currency', 'GET', options, blForceRefresh);
  }

  getCustomers(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('party', 'GET', options, blForceRefresh);
  }

  getDocuments(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('document', 'GET', options, blForceRefresh);
  }

  getLeads(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('lead', 'GET', options, blForceRefresh);
  }

  getNotifications(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('notification', 'GET', options, blForceRefresh);
  }

  getOffers(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('offers', 'GET', options, blForceRefresh);
  }

  getOrders(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('orders', 'GET', options, blForceRefresh);
  }

  getPaymentMethodIcon(paymentMethod: weclappPaymentMethod) {
    return this.paymentMethodIcons[paymentMethod.name];
  }

  getPaymentMethods(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('paymentMethod', 'GET', options, blForceRefresh);
  }

  getPaymentOptions() {
    return this.paymentOptions;
  }

  getProductionOrders(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('productionOrder', 'GET', options, blForceRefresh);
  }

  getPurchaseOrders(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('purchaseOrder', 'GET', options, blForceRefresh);
  }

  getPurchaseOrderRequests(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('purchaseOrderRequest', 'GET', options, blForceRefresh);
  }

  getSalesChannel(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('salesChannel', 'GET', options, blForceRefresh);
  }

  getSalesOrders(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('salesOrder', 'GET', options, blForceRefresh);
  }

  getSuppliers(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('supplier', 'GET', options, blForceRefresh);
  }

  getTax(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('tax', 'GET', options, blForceRefresh);
  }

  async getTenant() {
    let connection: weclappConfig = await this.getCurrentConnection(false);
    return (connection && connection.tenant ? connection.tenant : null);
  }

  getUnits(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('unit', 'GET', options, blForceRefresh);
  }

  getUsers(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('user', 'GET', options, blForceRefresh);
  }

  getVariantArticles(options: any = {}, blForceRefresh: boolean = false) {
    return this.execute('variantArticle', 'GET', options, blForceRefresh);
  }

  parsePaymentMethods(paymentMethods: weclappPaymentMethod[]) {
    if (paymentMethods && paymentMethods.length) {
      paymentMethods.forEach((paymentMethod: weclappPaymentMethod) => {
        paymentMethod.uid = parseInt(paymentMethod.id);
        paymentMethod.icon = paymentMethod.icon || this.getPaymentMethodIcon(paymentMethod);
      });
    }
    return paymentMethods;
  }

  pay() {
    return new Promise(async (resolve, reject) => {
      let paymentOptions = this.getPaymentOptions();
      resolve(paymentOptions);
    });
  }

  async setConnections(connections: weclappConfig[]) {
    this.cache.set(this.cacheKey, connections);
  }

  setCurrentConnection(connection: weclappConfig) {
    this.current = connection;
  }

  setPaymentOptions(paymentOptions: any) {
    this.paymentOptions = paymentOptions;
  }

  updateArticle(article: loomaArticle) {
    return this.execute('updateArticle', 'PUT', { article: article });
  }

  updateArticleCategory(category: loomaArticleCategory) {
    return this.execute('updateArticleCategory', 'PUT', { category: category });
  }

  updateConnection(connection: weclappConfig) {
    return new Promise((resolve, reject) => {
      this.validateConnection(connection)
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);
    })
  }

  updateParty(party: loomaUser) {
    return this.execute('updateParty', 'PUT', { party: party });
  }

  validateConnection(connection: weclappConfig) {
    return new Promise((resolve, reject) => {
      resolve(connection);
    });
  }

}
