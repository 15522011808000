import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { WindowManagerService } from 'src/app/services/core/window-manager.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

import { IntegrationSetupPage } from 'src/app/pages/integrations/integration/integration-setup/integration-setup.page';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  apiConfig: apiExplorerConfig = {
    baseUrl: '',
    routes: [
    ],
  };

  _detailItem: integration | null;

  integrationsByType: any = {
    compute: ['aws', 'google_cloud', 'google_colab', 'hyperstack', 'lambdalabs', 'microsoft_azure'],
    messenger: ['discord', 'facebook', 'instagram', 'linkedin', 'onesignal', 'telegram', 'whatsapp'],
    newsletter: ['constantcontact', 'mailchimp', 'mailerlite'],
    social: ['discord', 'facebook', 'instagram', 'linkedin', 'meta', 'pinterest', 'reddit', 'snapchat', 'threads', 'tiktok', 'twitter', 'twitch', 'xing', 'youtube'],
    storage: ['aws', 'dropbox', 'ftp', 'google_drive', 'hyperstack', 'lambdalabs', 'microsoft_azure', 'nextcloud'],
  };

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private events: EventsService,
    private modalService: ModalService,
    private windowManager: WindowManagerService,
  ) {

  }

  connect(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!!options && (!!options.error || !!options.message)) {
        reject(options.message || options.error);
      } else {

        const chooseConfig: chooseConfig = Object.assign((options || {}), {
          data: (options.data || []),
          labelKey: 'name',
          multiple: true,
          service: this,
          valueKey: 'uid',
        });

        this.chooser.choose(chooseConfig)
          .then((chooseResponse: chooseResponse) => {
            console.log('chooseResponse', chooseResponse);
            resolve(chooseResponse);
          })
          .catch(reject);
      }

    });
  }

  disable(integrationId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integrationId,
    }, ['integrations', 'disable']);
  }

  edit(integration: integration) {
    return new Promise(async (resolve, reject) => {
      try {
        this.detailItem(integration);

        const integrationKey: string = `${integration.name || ''}`.replace('integration_', '').toLowerCase();
        const route: string = `${integration.url || '/integration/settings/' + integrationKey}`;

        this.windowManager
          .open({
            route: route,
            uid: integrationKey,
          })
          .catch((error: any) => {
            this.events.publish('error', error);
          });

        //this.navCtrl.navigateForward(route);
      } catch (e) {
        reject(e);
      }
    });
  }

  enable(integrationId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integrationId,
    }, ['integrations', 'enable']);
  }

  detailItem(item: integration | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = true) {
    return this.getLog(options, blForceRefresh);
  }

  getAvailable(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: (typeof options === 'object' ? options : {}),
    }, ['integrations', 'list'], {}, blForceRefresh);
  }

  getByIndent(indent: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      indent: indent,
    }, ['integrations', 'single'], {}, blForceRefresh);
  }

  getByType(type: string | null = null) {
    if (!!type) {
      return this, this.integrationsByType[type];
    }
    return this.integrationsByType;
  }

  getEnabled(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const available: any = await this.getAvailable(options, blForceRefresh);

        if (!!available && !!available.length) {
          resolve(available.filter((integration: integration) => {
            return !!integration.enabled;
          }));
        }

        resolve([]);
      } catch (e) {
        reject(e);
      }
    });
  }

  getLog(options: any = {}, blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('pipeline', options, ['integrations', 'log'], {}, blForceRefresh);
  }

  update(integration: integration) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integration,
    }, ['integrations', 'update']);
  }

  validateIntegrationByUid(integrationId: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const integration: integration = await this.getByIndent(integrationId);
        let blValid: boolean = !!integration && !!integration.enabled;

        if (!!blValid && !!integration.settings && !!integration.settings.length) {
          integration.settings.forEach((setting: any) => {
            if (!setting || !setting.value) {
              blValid = false;
            }
          });
        }

        if (!blValid) {
          this.detailItem(integration);

          const modal: any = await this.modalService.create({
            component: IntegrationSetupPage,
            componentProps: {
              integration: integration,
              integrationId: integrationId,
              integrationName: integrationId,
            },
            animated: true,
            presentingElement: await this.modalService.getTop(),
            cssClass: 'mediumModal',
          });

          modal.onWillDismiss().then((data: any) => {
            console.log('data', data);
            resolve(data);
          });

          this.modalService.present(modal);
        } else {
          resolve(true);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

}