import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { SettingsService } from "src/app/services/core/settings.service";
import { UserService } from 'src/app/services/core/user.service';
import { BlogService } from 'src/app/services/pipeline/blog.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private AppCMS: AppcmsService,
    private blog: BlogService,
    private chooser: ChooserService,
    private events: EventsService,
    private settings: SettingsService,
    private userService: UserService,
  ) {
  }

  create(category: category) {
    category.active = true;

    return this.AppCMS.loadPluginData('pipeline', {
      category: category,
    }, ['categories', 'create']);
  }

  delete(categoryId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {},
      ['categories', 'delete', categoryId]
    );
  }

  getBySourceUid(sourceId: number, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['categories', 'source', sourceId],
      null,
      blForceRefresh,
    )
  }

  getByUserSources(userId: number | null = null, blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {
      userId = userId || this.userService.getUid();

      if (!userId) {
        reject('error_missing_user_uid');
      }

      this.AppCMS.loadPluginData(
        'pipeline',
        Object.assign(options, {

        }),
        ['categories', 'user', userId],
        null,
        blForceRefresh,
      )
        .then(resolve).catch(reject);
    });
  }

  getCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise(async (resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", {}, ["categories"], {}, blForceRefresh)
        .then((categories: any) => {
          this.settings
            .getSetting("categories", blForceRefresh)
            .then((categoriesSettings: any) => {

              if (categories && categories.length) {

                categories.forEach((category: category, index: number) => {
                  let id = parseInt(`${(category.id || category.uid) || (category.remote_uid || category.term_id)}`);
                  if (category) {
                    category.id = category.uid || id;
                    category.uid = category.uid || id;
                    categories[index] = this.getFullCategory(category);
                  }
                });

                let filteredCategories: category[];

                if (blFilterResults) {
                  filteredCategories = categories.filter((_category: category) => {
                    let blReturn: boolean = false;
                    if (
                      categoriesSettings &&
                      categoriesSettings[_category.remote_uid]
                    ) {
                      blReturn = categoriesSettings[_category.remote_uid].rank > 1;
                    }
                    blReturn = _category.name !== 'Allgemein';
                    return blReturn;
                  });
                }

                categories = filteredCategories && filteredCategories.length ? filteredCategories : categories;
              }

              this.events.publish("categories:loaded", categories);
              resolve(categories);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  getFullCategory(category: category) {
    category.name = category.name.replace("&amp;", "&");
    //category.icon = this.tools.getCategoryIcon(category.name);

    return category;
  }

  getMainCategories(blForceRefresh: boolean = false, blFilterResults: boolean = true) {
    return new Promise((resolve, reject) => {
      this.getCategories(blForceRefresh, blFilterResults)
        .then((categories: category[]) => {

          let mainCategories = categories.filter((category: category) => {
            if (category.hasOwnProperty('categories') && !category.hasOwnProperty('parent')) {
              return category.categories && category.categories.length;
            }
            return !category.parent;
          });

          this.events.publish("categories:main:loaded", mainCategories);
          resolve(mainCategories);
        })
        .catch(reject);
    });
  }

  getSelectedCategories() {
    return new Promise(async (resolve, reject) => {
      let selectedCategories: any = (await this.settings.getSetting('categories', false)) || {};
      let selectedCategoriesIds = !!selectedCategories ? Object.keys(selectedCategories) : [];
      let categories: category[] = [];

      if (selectedCategoriesIds && selectedCategoriesIds.length) {
        this.blog.getCategories()
          .then(() => {
            selectedCategoriesIds.forEach((selectedCategoriesId: string, index: number) => {
              let selectedCategory: category = selectedCategories[selectedCategoriesId];

              new Promise(async (resolve, reject) => {
                if (selectedCategory && (selectedCategory.rank > 1)) {
                  try {
                    let fullSelectedCategory: category = await this.blog.getCategory(parseInt(selectedCategoriesId));
                    let childrenKeys = Object.keys(selectedCategory.children);

                    if (fullSelectedCategory && fullSelectedCategory.name) {
                      categories.push(fullSelectedCategory);
                    }

                    if (childrenKeys && childrenKeys.length) {
                      childrenKeys.forEach(async (childId: string, index: number) => {
                        const bl: boolean = selectedCategory.children[childId],
                          fullSelectedSubCategory: category = await this.blog.getCategory(parseInt(childId));

                        if (bl && (fullSelectedSubCategory && fullSelectedSubCategory.name)) {
                          categories.push(fullSelectedSubCategory);
                        }

                        if (index === (childrenKeys.length - 1)) {
                          resolve({});
                        }

                      });
                    } else {
                      resolve({});
                    }

                  } catch (e) {
                    console.warn('> e', e);
                  }
                }
              })
                .then(() => {
                  if (index === (selectedCategoriesIds.length - 1)) {
                    resolve(categories);
                  }
                })
                .catch(reject);
            });
          })
          .catch(reject);
      } else {
        resolve({});
      }
    });
  }

  async getSelectedCategoriesNames() {
    let selectedCategories: any = await this.getSelectedCategories();
    let selectedCategoriesNames = [];

    if (selectedCategories && selectedCategories.length) {
      selectedCategories.forEach((selectedCategory: category) => {
        selectedCategoriesNames.push(selectedCategory.name);
      });
    }

    return selectedCategoriesNames;
  }

  pick(options: any | null = null, event: any | null = null) {
    return new Promise(async (resolve, reject) => {

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.getMainCategories(),
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
        .then((chooseResponse: chooseResponse) => {
          resolve(chooseResponse);
        })
        .catch(reject);
    });
  }

  update(category: category) {
    return this.AppCMS.loadPluginData('pipeline', {
      category: category,
    }, ['categories', 'update']);
  }

}
