import { Component, Input, OnInit } from '@angular/core';


import { EventsService } from "src/app/services/core/events.service";
import { RatingsService } from 'src/app/services/pipeline/ratings.service';
import { AiIdeasService } from '../../../services/ai/ai-ideas.service';

@Component({
  selector: 'pipeline-rate-item-buttons',
  standalone: false,
  templateUrl: './rate-item-buttons.component.html',
  styleUrls: ['./rate-item-buttons.component.scss']
})
export class RateItemButtonsComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() item: any;
  @Input() loading: boolean = false;
  @Input() slot: string;
  @Input() type: string;

  constructor(
    private aiIdeas: AiIdeasService,
    private events: EventsService,
    private ratings: RatingsService,
  ) {

  }

  favorite() {
    this.aiIdeas.create(this.item);

    return this.thumb(5);
  }

  ngOnInit() {

  }

  thumb(i: number) {

    if (!this.item) {
      this.events.publish('error', 'error_missing_item');
      return false;
    }

    const options: aiRatingOptions = {
      item: this.item,
      type: this.type || (this.item.type || 'idea'),
      value: i,
    };

    this.loading = true;

    this.ratings.submitAiRating(this.item, i, options.type)
      .then(() => {
        this.item.favorite = (i === 5);
        this.item.rating = i;
        this.loading = false;
      })
      .catch((error: any) => {
        this.loading = false;
        this.events.publish('error', error);
      });
  }

}