import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorsService {

  constructor(

  ) {

  }

  calcSimilarity(hexA: string, hexB: string) {

    if (!hexA && !hexB) return 0;

    const _hexA = `${hexA}`.charAt(0) == "#" ? `${hexA}`.substring(1, 7) : hexA;
    const _hexB = `${hexB}`.charAt(0) == "#" ? `${hexB}`.substring(1, 7) : hexB;

    const _r = parseInt(_hexA.substring(0, 2), 16);
    const _g = parseInt(_hexA.substring(2, 4), 16);
    const _b = parseInt(_hexA.substring(4, 6), 16);

    const __r = parseInt(_hexB.substring(0, 2), 16);
    const __g = parseInt(_hexB.substring(2, 4), 16);
    const __b = parseInt(_hexB.substring(4, 6), 16);

    const dr = _r - __r;
    const dg = _g - __g;
    const db = _b - __b;

    const distance = Math.sqrt(dr * dr + dg * dg + db * db);
    const maxDistance = Math.sqrt(3 * 255 * 255);

    return Math.round(100 - (distance / maxDistance) * 100);
  }

  isColor(hexColor: string | null) {

    if (!hexColor || !hexColor.length || (hexColor.toLowerCase() === 'white') || (hexColor.toLowerCase() === 'black')) {
      return false;
    }

    return !this.isGraytone(hexColor);
  }

  isGraytone(hexColor: string) {
    hexColor = hexColor.replace("#", "").substr(0, 6);

    const r = parseInt(hexColor.substr(0, 2), 16),
      g = parseInt(hexColor.substr(2, 2), 16),
      b = parseInt(hexColor.substr(4, 2), 16);

    return (r === g && g === b);
  }

}