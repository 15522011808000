import { Component, OnDestroy, OnInit } from '@angular/core';

import { DropService } from 'src/app/services/core/drop.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ProjectsPickerService } from '../../../services/core/projects-picker.service';

import { ProjectPage } from 'src/app/pages/core/projects/project/project.page';

@Component({
  selector: 'pipeline-project-select',
  standalone: false,
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
})
export class ProjectSelectComponent implements OnDestroy, OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  view: any = {

  };

  constructor(
    private dropService: DropService,
    private events: EventsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private projectsPicker: ProjectsPickerService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async createProject() {

    const modal: any = await this.modalService.create({
      component: ProjectPage,
    });

    this.modalService.present(modal);

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

  }

  doRefresh() {
    this.loadProjects(true);
  }

  async editProject() {

    if (!!this.view.loadingProjects) {
      return false;
    }

    const modal: any = await this.modalService.create({
      component: ProjectPage,
      componentProps: {
        project: this.view.project,
      },
    });

    this.modalService.present(modal);

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

  }

  handleItemDropped(event: any | null = null) {
    console.log('projects-select: handleItemDropped: event', event);

    this.dropService.handleItemDrop(event.dragItem, event.dropItem, 'project')
      .then((response: any) => {
        console.log('drop response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {

    this.events.subscribe('team:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  loadProjects(blForceRefresh: boolean = false) {
    this.view.loadingProjects = true;

    this.projects.getActive({

    }, blForceRefresh)
      .then(async (projects: project[]) => {
        const currentProject: project = await this.projects.getCurrent();

        this.view.projects = projects.sort((a: project, b: project) => {
          if (!!a.title && !!b.title) {
            if (a.title < b.title) return -1;
            if (a.title > b.title) return 1;
          }
          return 0;
        });

        // load current project if set and has uid
        if (!!currentProject && !!currentProject.uid) {
          this.view.project = currentProject;
          this.view.projectId = currentProject.uid;
        }

        this.view.loadingProjects = false;
      })
      .catch((error: any) => {
        this.view.loadingProjects = false;
        console.warn('> creators dashboard: projects failed', error);
      });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();

    this.initEvents();
    this.loadProjects();
  }

  onProjectChanged() {

    if (!this.view.projectId) {
      this.view.project = null;
      this.projects.setCurrent(null);
      return false;
    }

    const matching: project[] = this.view.projects.filter((_project: project) => {
      return _project.uid === this.view.projectId;
    });

    if (!!matching && !!matching[0]) {
      this.view.project = matching[0];
      this.projects.setCurrent(this.view.project);
    } else {
      this.projects.setCurrent(null);
    }

  }

  pick() {

    if (!!this.view.loadingProjects) {
      return false;
    }

    this.projectsPicker.pick()
      .then((response: any) => {
        if (!!response && (response.role === 'done')) {
          const project: project | null = (!!response && !!response.data && !!response.data.item ? response.data.item : null);

          this.view.project = project;
          this.view.projectId = (!!project && !!project.uid ? project.uid : null);

          this.projects.setCurrent(this.view.project);
        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  thumbnailLoadingFailed() {
    if (!!this.view.project) {
      this.view.project.photo = this.fallbackImg;
    }
  }

}