import { Injectable } from '@angular/core';

import { StatisticsService } from 'src/app/services/analytics/statistics.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { PushnotificationsService } from 'src/app/services/core/pushnotifications.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private cache: CacheService,
    private notifications: PushnotificationsService,
    private statistics: StatisticsService,
    private translations: TranslationService,
    private userService: UserService,
  ) {
  }

  async checkDailyAdminCustomersNotification() {
    let dailyNotificationKey = 'daily_admin_customers_notification_' + (Math.floor(1 + Math.random() * 3) - 1);

    this.translations.get([
      dailyNotificationKey,
    ])
      .subscribe(async (translations: any) => {
        let customersByTimestamp: any = await this.statistics.getByPlugin('customers', 'customersByTimestamp');
        let pipelineStatistics: any = await this.statistics.getPipelineStatistics();
        let currentUserAmount = pipelineStatistics.counts.customers;
        let nextGoal = Math.ceil(currentUserAmount / 50) * 50;
        let today: string = new Date().toISOString().split('T')[0], todayIndex: number;
        let data: any = {
          nextGoal: nextGoal,
        };

        if (customersByTimestamp && customersByTimestamp.data && customersByTimestamp.data.labels) {
          customersByTimestamp.data.labels.forEach((label: any, index: number) => {
            if (label === today) {
              todayIndex = index;
              if (customersByTimestamp.data.datasets && customersByTimestamp.data.datasets[0].data && customersByTimestamp.data.datasets[0].data[index]) {
                data.count = customersByTimestamp.data.datasets[0].data[index];
              }
            }
          });
        }

        data.count = data.count || 0;
        data.left = data.nextGoal - currentUserAmount;

        let text = translations[dailyNotificationKey] || 'daily_admin_customers_notification_0';
        text = text.replace('$count', data.count);
        text = text.replace('$left', data.left);
        text = text.replace('$nextGoal', data.nextGoal);

        try {

          this.notifications.sendLocal({
            id: 2,
            text: text,
            //sound: isAndroid? 'file://sound.mp3': 'file://beep.caf',
            data: {},
          });

          this.cache.set('adminInfo_stats', {});

        } catch (e) {
          console.warn('local send e', e);
        }

      });
  }

  async checkDailyBlogAdminNotification() {
    let dailyNotificationKey = 'daily_blog_admin_notification_' + (Math.floor(1 + Math.random() * 5) - 1);

    this.translations.get([
      dailyNotificationKey,
    ])
      .subscribe((translations: any) => {
        let text = translations[dailyNotificationKey] || 'daily_blog_admin_notification_0';
        text = text.replace('$firstname', this.userService.getFirstname());

        try {

          this.notifications.sendLocal({
            id: 1,
            text: text,
            //sound: isAndroid? 'file://sound.mp3': 'file://beep.caf',
            data: {},
            trigger: { at: new Date(new Date().getTime() + 3600) },
          });

          this.cache.set('adminInfo_stats', {});

        } catch (e) {
          console.warn('local send e', e);
        }

      });
  }

  async checkNotifications() {
    let adminInfo: cacheItem = await this.cache.get('adminInfo_stats', (60 * 60 * 24));

    if (adminInfo && adminInfo.data) {
      console.log('push already send');
      return false;
    }

    //setTimeout(() => { this.checkDailyBlogAdminNotification() }, (10 * 1000),
    //setTimeout(() => { this.checkDailyAdminCustomersNotification() }, (10 * 60) * 1000));
  }

  init() {

    if (!this.userService.isType('Admin')) {
      console.warn('cannot init admin: not admin');
      return false;
    }

    this.checkNotifications();
  }

}
