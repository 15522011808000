<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="export()" color="dark" fill="clear" [hidden]="view.mode === 'pick'"
        [disabled]="!!view.loading">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'export'|translate"></ion-label>
      </ion-button>

      <ion-button (click)="add()" shape="round" color="primary" fill="solid">
        <ion-icon name="add" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar *ngIf="(view.ads && view.ads.length) || (search.query && search.query.length)"
    [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <pipeline-select-project-card
    *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension"></pipeline-select-project-card>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!view.isDesktop" [hidden]="introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
    </div>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="view.colSize.left" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card [(cards)]="cards" [service]="ads" [(view)]="view" location="ads"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadAdsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Connections -->
          <pipeline-connections-filter-card [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <ion-toolbar *ngIf="view.adTypes" class="ion-no-border second-toolbar container">
            <ion-segment scrollable [(ngModel)]="view.adType" (ionChange)="adTypeSegmentChanged()">
              <ion-segment-button *ngFor="let adType of view.adTypes" [value]="adType.type" icon-only>
                <ion-icon [name]="adType.icon"></ion-icon>
                <ion-label [innerHTML]="adType.title|translate"></ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-toolbar>

          <!-- Intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
            [(view)]="introCard"></pipeline-intro-card>

          <!-- No entries -->
          <pipeline-no-entries-card
            [cta]="cta" [text]="'ads_admin_cta_text'|translate"
            *ngIf="view.ads && !view.ads.length">
          </pipeline-no-entries-card>

          <ion-grid *ngIf="view.ads && !!view.ads.length">

            <ion-row>

              <ion-col *ngFor="let ad of view.ads; trackBy:trackItems"
                [size]="view.viewType === 'grid' ? (view.isDesktop ? (view.expertMode ? 4 : 3) : 6) : 12"
                [hidden]="ad.hidden">

                <ion-card>
                  <ion-list lines="none">
                    <ion-item-sliding>
                      <ion-item class="ion-text-wrap" lines="none">

                        <ion-thumbnail (click)="viewAd(ad)" slot="start">
                          <ion-img [src]="ad.thumbnail || fallbackImg"
                            (ionError)="thumbnailLoadingFailed(ad)"></ion-img>
                          <ion-skeleton-text class="image" *ngIf="!ad.thumbnail"
                            [animated]="!ad.uid"></ion-skeleton-text>
                        </ion-thumbnail>

                        <ion-label class="ion-text-wrap">

                          <span class="active-span" (click)="toggleActive(ad)">
                            <ion-icon [name]="ad.active ? 'radio-button-on-outline' : 'radio-button-off-outline'"
                              [color]="ad.active ? 'success' : 'danger'" slot="start"></ion-icon>
                            <span [hidden]="ad.active" [innerHTML]="'not_active'|translate"></span>
                            <span [hidden]="!ad.active" [innerHTML]="'active'|translate"></span>
                          </span>

                          <h2 (click)="viewAd(ad)" *ngIf="!!ad.uid" class="ion-text-wrap"
                            [innerHTML]="ad.label || ('no_name'|translate)"></h2>
                          <ion-skeleton-text *ngIf="!ad.label" style="float:left;width:200px;" [animated]="!ad.uid">
                          </ion-skeleton-text>

                          <h4 (click)="viewAd(ad)" class="ion-text-wrap">
                            <ion-icon name="calendar-outline"></ion-icon>
                            <span *ngIf="!!ad.date_start_formatted && !!ad.date_end_formatted"
                              [innerHTML]="ad.date_start_formatted + ' - ' + ad.date_end_formatted"></span>
                            <ion-skeleton-text *ngIf="!ad.date_start_formatted"
                              style="float:left;width:100px;margin-right:10px;"
                              [animated]="!ad.uid"></ion-skeleton-text>
                            <ion-skeleton-text *ngIf="!ad.date_end_formatted" style="float:left;width:100px;"
                              [animated]="!ad.uid"></ion-skeleton-text>
                          </h4>
                          <p (click)="viewAd(ad)" class="ion-text-wrap">
                            <ion-icon name="eye-outline"></ion-icon>
                            <span *ngIf="!!ad.type"
                              [innerHTML]="(ad.type|translate) + ', ' + ((view.locationTranslations[ad.location] || ad.location)|translate)"></span>
                            <ion-skeleton-text *ngIf="!ad.type" style="float:left;width:200px;" [animated]="!ad.uid">
                            </ion-skeleton-text>
                          </p>
                        </ion-label>

                        <ion-button [hidden]="!view.isDesktop || !ad.uid" slot="end" fill="clear" (click)="pay(ad)">
                          <ion-icon name="bag-handle-outline" slot="start"></ion-icon>
                          <ion-label>
                            <h3 [innerHTML]="'pay'|translate"></h3>
                          </ion-label>
                        </ion-button>

                        <ion-button [hidden]="!view.isDesktop || !ad.uid" slot="end" fill="clear" color="danger"
                          (click)="delete(ad)">
                          <ion-icon name="trash-outline" slot="start"></ion-icon>
                          <ion-label>
                            <h3 [innerHTML]="'delete'|translate"></h3>
                          </ion-label>
                        </ion-button>

                      </ion-item>
                      <ion-item-options *ngIf="!view.isDesktop && !!ad.uid" slot="end" [hidden]="!ad.uid">
                        <ion-item-option (click)="pay(ad)">
                          <ion-icon name="bag-handle-outline" slot="start"></ion-icon>
                          <ion-label>
                            <h3 [innerHTML]="'pay'|translate"></h3>
                          </ion-label>
                        </ion-item-option>
                        <ion-item-option color="danger" (click)="delete(ad)">
                          <ion-icon name="trash-outline" slot="start"></ion-icon>
                          <ion-label>
                            <h3 [innerHTML]="'delete'|translate"></h3>
                          </ion-label>
                        </ion-item-option>
                      </ion-item-options>
                    </ion-item-sliding>
                  </ion-list>
                </ion-card>

              </ion-col>

            </ion-row>

          </ion-grid>

          <!--<pipeline-ads-contact-expert-card></pipeline-ads-contact-expert-card>-->

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>