import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { OauthService } from 'src/app/services/core/oauth.service';

import * as msal from "@azure/msal-browser";

@Injectable({
    providedIn: 'root'
})
export class MicrosoftService {

    apiConfig: apiExplorerConfig = {
        baseUrl: '',
        routes: [
        ],
    };

    appId: string = '1d0303fc-f633-4fd1-ba00-decfec57456b';

    tenantId: string = 'edebfeac-84c8-47ca-9ef0-0af23b3b2c11';

    constructor(
        private AppCMS: AppcmsService,
        private oauth: OauthService,
    ) {

    }

    authorize() {
        const accessTokenRoute: string = this.AppCMS.getRequestUrl('microsoft', ['authorized']); // `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/token`
        const resourceUrl: string = this.AppCMS.getRequestUrl('microsoft', ['profile']);

        const pca = msal.createStandardPublicClientApplication({
            auth: {
                clientId: this.appId,
                authority: "https://login.microsoftonline.com/" + this.tenantId,
            },
        });

        console.log('pca', pca);

        return this.oauth.authorize({
            appId: this.appId,
            authorizationBaseUrl: `https://login.microsoftonline.com/${this.tenantId}/oauth2/v2.0/authorize`,
            scope: 'https://graph.microsoft.com/Contacts.Read https://outlook.office365.com/IMAP.AccessAsUser.All https://graph.microsoft.com/Mail.Read https://graph.microsoft.com/User.Read offline_access openid',
            accessTokenEndpoint: accessTokenRoute,
            //resourceUrl: resourceUrl,
            pkceEnabled: true,
            web: {
                clientId: this.appId,
                redirectUrl: `${window.location.origin}/integrations/integration/microsoft/connections`,
                responseType: 'code',
                windowOptions: 'height=600,left=0,top=0',
            },
            android: {
                clientId: this.appId,
                redirectUrl: 'msauth://{package-name}/{url-encoded-signature-hash}',
                responseType: 'code',
            },
            ios: {
                clientId: this.appId,
                pkceEnabled: true,
                redirectUrl: 'msauth.{package-name}://auth',
                responseType: 'code',
            },
        });
    }

    connect(options: any = {}) {
        return new Promise((resolve, reject) => {

            // first, authorize application
            this.authorize().then((authResponse: any) => {
                resolve(authResponse);
            }).catch(reject);
        });
    }

}