import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { InfoPopoverComponent } from 'src/app/components/generic/info-popover/info-popover.component';

@Component({
  selector: 'pipeline-media-input-information-card',
  standalone: false,
  templateUrl: './media-input-information-card.component.html',
  styleUrls: ['./media-input-information-card.component.scss']
})
export class MediaInputInformationCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() disabled: boolean;

  @ViewChild(InfoPopoverComponent) infoPopover: any;

  infoPopoverContent: string;

  @Input() input: string;

  @Input() items: mediaItem[];

  @Input() item: any;

  @Input() options: any = {};
  @Input() view: any;

  @Output() onInputChanged = new EventEmitter();

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {

  }

  calcViewVars() {
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.calcViewVars();
  }

  _onInputChanged(event: any | null = null) {
    this.onInputChanged.emit(this.input);
  }

  presentInfoPopover(e: Event, message: string) {
    this.infoPopoverContent = message;

    this.infoPopover.show({
      content: message,
      event: e,
    });
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleFineTune() {
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}