import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { UserService } from 'src/app/services/core/user.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private userService: UserService,
  ) {

  }

  create(company: company, options: any = {}) {
    return this.AppCMS.loadPluginData('companies', Object.assign(options, {
      company: company,
    }), ['create']);
  }

  delete(companyId: number) {
    return this.AppCMS.loadPluginData('companies', {}, [companyId, 'delete']);
  }

  get(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return new Promise((resolve, reject) => {
      let userId: number = this.userService.getUid();

      if(!this.userService.isType('Admin')) {
        if(!userId) {
          reject('error_missing_user_uid');

          return false;
        } else {
          options.user = userId;
        }
      }

      this.AppCMS.loadPluginData('companies', {
        filter: (typeof options === 'object' ? options : {}),
      }, [], params, blForceRefresh).then(resolve).catch(reject);
    });
  }

  getByUid(companyId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('companies', {}, [companyId], null, blForceRefresh);
  }
  
  pick(options: any|null = null, event: any|null = null) {
    return new Promise(async (resolve, reject) => {
      
      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: await this.get(),
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig)
      .then((chooseResponse: chooseResponse) => {
        if(chooseResponse && chooseResponse.data && chooseResponse.data.item) {
          resolve(chooseResponse.data.item);
        } else
        if(chooseResponse && chooseResponse.data && chooseResponse.data.items) {
          resolve(chooseResponse.data.items);
        } else {
          resolve(chooseResponse);
        }
      })
      .catch(reject);
    });
  }

  search(query: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('companies', {
      query: query
    }, ['search'], null, blForceRefresh);
  }

  update(company: company, options: any = {}) {
    company.active = !!company.active;
    company.user = this.userService.getUid();

    return this.AppCMS.loadPluginData('companies', Object.assign(options, {
      company: company
    }), [company.uid, 'update']);
  }

}