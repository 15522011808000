<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-button (click)="back()" fill="clear" color="dark" *ngIf="!view.isModal || !!view.isSaveMode">
        <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'back'|translate"></ion-label>
      </ion-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view"
        *ngIf="!!view.isModal && !view.isSaveMode"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

      <!-- Save -->
      <ion-button shape="round" [fill]="view.isSaveMode ? 'solid' : 'clear'" *ngIf="!!view.startManually"
        [color]="view.isSaveMode ? 'primary' : 'dark'" (click)="save()" [disabled]="!view.canSave"
        [disabled]="!!view.isSaveMode && !videoProject.name">
        <ion-icon [name]="view.isSaveMode ? 'checkmark-outline' : 'save-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate" [hidden]="!view.isSaveMode && !view.isDesktop"></ion-label>
      </ion-button>

    </ion-buttons>

    <ion-buttons slot="end" [hidden]="!!view.isSaveMode">

      <!-- Small generate button (if buttonAction = use)-->
      <ion-button icon-only
        *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        (click)="aiCreate()" color="dark" fill="clear" [disabled]="!!view.loading || (view.phase === 'loading')">
        <ion-icon name="sync-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'rebuild_all'|translate" [hidden]="!view.isDesktop"></ion-label>
      </ion-button>

      <!-- Download button -->
      <!--
      <ion-button *ngIf="!!view.finalResultUrl" [href]="view.finalResultUrl" download
        [color]="view.downloaded ? 'success' : 'primary'" fill="clear">
        <ion-icon [name]="view.downloaded ? 'download' : 'download-outline'" slot="start"></ion-icon>
        <ion-label [innerHTML]="(view.downloaded ? 'downloaded' : 'download')|translate"></ion-label>
      </ion-button>
      -->

      <!-- Use button -->
      <ion-button *ngIf="view.phase !== 'presenting' && view.buttonAction === 'use'"
        [hidden]="view.phase === 'loading' || view.phase === 'presenting'"
        [disabled]="!!view.loading || !view.selectedItems || !view.selectedItems.length" (click)="generate()"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

      <!-- Complete button -->
      <ion-button *ngIf="view.phase === 'presenting'" (click)="compileParts()" [disabled]="!!view.loading" shape="round"
        color="primary" fill="solid">
        <ion-icon name="cut-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'complete'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="!!view.startManually && (view.phase !== 'presenting' && view.buttonAction === 'generate')"
        [hidden]="view.phase === 'loading' || view.phase === 'presenting'" (click)="generate()"
        [disabled]="!!view.loading || !view.canGenerate" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Stored tasks toolbar -->
  <ion-toolbar class="ion-no-border container second-toolbar" *ngIf="!!view.isSaveMode || !!view.progress">

    <!-- Video project name -->
    <ion-item lines="none" *ngIf="!!view.isSaveMode">
      <ion-icon name="eye-outline" *ngIf="!!view.isDesktop" slot="start"></ion-icon>
      <ion-input [(ngModel)]="videoProject.name" [label]="'project_name'|translate" labelPlacement="floating"
        [placeholder]="'project_name'|translate"></ion-input>
    </ion-item>

    <!-- Progress bar -->
    <pipeline-progress-bar *ngIf="!!view.progress" [progress]="view.progress" [view]="view"></pipeline-progress-bar>

  </ion-toolbar>

</ion-header>

<ion-content>

  <!-- Single media item information popover -->
  <pipeline-media-item-info-popover></pipeline-media-item-info-popover>

  <!-- Create media intro mode -->
  <pipeline-intro-overlay [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [(cards)]="cards"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" [loading]="view.loading"
    [mediaPickerOptions]="introOverlayMediaPickerOptions" (onCreate)="aiCreate()"
    (onInputChanged)="onOverlayInputChanged($event)" (onSkip)="startManually()" [(view)]="view">
  </pipeline-intro-overlay>

  <!-- Select project picker -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    class="slider-container container-fluid" [hidden]="!!view.isSaveMode">

    <!-- Mobile Intro card -->
    <pipeline-intro-card *ngIf="!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
      [(view)]="introCard"></pipeline-intro-card>

    <div class="main-view">

      <ion-grid class="main-grid">

        <ion-row>

          <!-- Sidebar -->
          <ion-col class="sidebar" [size]="view.sidebarSize || 3">

            <!-- Types (if empty project) -->
            <div class="col-inner"
              [hidden]="!!videoProject && !!videoProject.sections && !!videoProject.sections.length">

              <!-- Types (Create & edit mode) -->
              <ion-card class="sidebar-accordion-card" *ngIf="!!types && !!types.length"
                [hidden]="view.uiMode === 'review'">

                <ion-card-header>

                  <ion-card-title>
                    <span [innerHTML]="'type'|translate"></span>
                  </ion-card-title>

                  <ion-button *ngIf="!!cards && !!cards.types" class="card-toggle" (click)="toggleCard('types')"
                    icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.types && !!cards.types.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <ion-card-content [hidden]="!!cards && !!cards.types && !cards.types.open">
                  <p [innerHTML]="'ai_video_creator_ai_helper_type_description'|translate"></p>
                </ion-card-content>

                <ion-grid class="types-grid" [hidden]="!!cards && !!cards.types && !cards.types.open">
                  <ion-row>

                    <ion-col size="6" *ngFor="let type of types; let iType = index; trackBy:trackItems">

                      <ion-button class="types-btn" [class.active]="type.checked" size="block"
                        [fill]="type.checked ? 'solid' : 'outline'" color="primary" (click)="toggleType(type, iType)">
                        <ion-icon [name]="type.icon"></ion-icon>
                        <ion-label [innerHTML]="type.name|translate"></ion-label>
                      </ion-button>

                    </ion-col>

                  </ion-row>
                </ion-grid>

              </ion-card>

            </div>

            <!-- Current item: Actions -->
            <div class="col-inner"
              *ngIf="!!videoProject.sections && !!videoProject.sections[sectionIndex] && (videoProject.sections[sectionIndex].type === 'image' || videoProject.sections[sectionIndex].type === 'video')">

              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('actions')">
                    <span [innerHTML]="'actions'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('actions')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.actions && !!cards.actions.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <!-- Single item actions -->
                <ion-grid class="types-grid" [hidden]="!!cards && !!cards.actions && !cards.actions.open">
                  <ion-row>

                    <!-- Image To Video -->
                    <ion-col *ngIf="videoProject.sections[sectionIndex].type === 'image'">
                      <ion-button class="types-btn" size="block" fill="outline" color="primary"
                        [disabled]="!!videoProject.sections[sectionIndex].disabled"
                        (click)="accept(videoProject.sections[sectionIndex], sectionIndex, true)">
                        <ion-icon name="film-outline"></ion-icon>
                        <ion-label [innerHTML]="'image_to_video'|translate"></ion-label>
                      </ion-button>
                    </ion-col>

                    <!-- Video: Upscale -->
                    <ion-col
                      *ngIf="videoProject.sections[sectionIndex].type === 'video' && !!videoProject.sections[sectionIndex].url">
                      <ion-button class="types-btn" size="block" fill="outline" color="primary"
                        [disabled]="!!videoProject.sections[sectionIndex].disabled"
                        (click)="upscaleVideo(videoProject.sections[sectionIndex], sectionIndex)">
                        <ion-icon name="expand-outline"></ion-icon>
                        <ion-label [innerHTML]="'upscale'|translate"></ion-label>
                      </ion-button>
                    </ion-col>

                    <!-- Video: Interpolate -->
                    <ion-col
                      *ngIf="videoProject.sections[sectionIndex].type === 'video' && !!videoProject.sections[sectionIndex].url">
                      <ion-button class="types-btn" size="block" fill="outline" color="primary"
                        [disabled]="!!videoProject.sections[sectionIndex].disabled"
                        (click)="interpolateVideo(videoProject.sections[sectionIndex], sectionIndex)">
                        <ion-icon name="layers-outline"></ion-icon>
                        <ion-label [innerHTML]="'interpolate'|translate"></ion-label>
                      </ion-button>
                    </ion-col>

                    <!-- Recreate -->
                    <ion-col
                      *ngIf="(videoProject.sections[sectionIndex].type === 'image' || videoProject.sections[sectionIndex].type === 'video') && !!videoProject.sections[sectionIndex].prompt">
                      <ion-button class="types-btn" size="block" fill="outline" color="primary"
                        [disabled]="!!videoProject.sections[sectionIndex].disabled"
                        (click)="rebuildSection(videoProject.sections[sectionIndex], sectionIndex)">
                        <ion-icon name="sync-outline"></ion-icon>
                        <ion-label [innerHTML]="'rebuild'|translate"></ion-label>
                      </ion-button>
                    </ion-col>

                  </ion-row>
                </ion-grid>

              </ion-card>
            </div>

            <!-- Current item: Information -->
            <div class="col-inner" *ngIf="!!videoProject.sections && !!videoProject.sections[sectionIndex]"
              [class.success]="!!videoProject.sections && !!videoProject.sections[sectionIndex] && !!videoProject.sections[sectionIndex].prompt">

              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('information')">
                    <span [innerHTML]="'information'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('information')" icon-only color="dark"
                    fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.information && !!cards.information.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <ion-list [hidden]="!!cards && !!cards.information && !cards.information.open">

                  <!-- Positive prompt -->
                  <ion-item lines="none">

                    <ion-icon name="eye-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!videoProject.sections[sectionIndex].analyzing || !!videoProject.sections[sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="videoProject.sections[sectionIndex].prompt"
                      [placeholder]="'ai_prompt_section_modification'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Negative prompt -->
                  <ion-item lines="none">

                    <ion-icon name="eye-off-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!videoProject.sections[sectionIndex].analyzing || !!videoProject.sections[sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="videoProject.sections[sectionIndex].negative_prompt"
                      [placeholder]="'negative_prompt'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Camera -->
                  <ion-item lines="none">

                    <ion-icon name="videocam-outline" slot="start"></ion-icon>

                    <ion-textarea
                      [disabled]="!!videoProject.sections[sectionIndex].analyzing || !!videoProject.sections[sectionIndex].loading"
                      [rows]="view.isDesktop ? 3 : 6" [(ngModel)]="videoProject.sections[sectionIndex].camera_behaviour"
                      [label]="'camera'|translate" labelPlacement="stacked"
                      [placeholder]="'camera_behaviour'|translate"></ion-textarea>

                  </ion-item>

                  <!-- Optimize input -->
                  <ion-item lines="none">

                    <ion-checkbox [(ngModel)]="aiSettings.creative" justify="start" labelPlacement="end"
                      [innerHTML]="'media_creator_fine_tune_input'|translate" class="ion-text-wrap"></ion-checkbox>

                    <ion-button (click)="presentInfoPopover($event, 'media_creator_fine_tune_input_infotext')" icon-only
                      fill="clear" color="dark" slot="end">
                      <ion-icon name="information-circle-outline"></ion-icon>
                    </ion-button>

                  </ion-item>

                </ion-list>

              </ion-card>
            </div>

            <!-- Output settings -->
            <div [class.success]="view.aspect_ratio" class="col-inner"
              [hidden]="!!videoProject.sections[sectionIndex] && (videoProject.sections[sectionIndex].type === 'video')">

              <ion-card class="sidebar-accordion-card">

                <ion-card-header>

                  <ion-card-title (click)="toggleCard('output')">
                    <span [innerHTML]="'output'|translate"></span>
                  </ion-card-title>

                  <ion-button class="card-toggle" (click)="toggleCard('output')" icon-only color="dark" fill="clear">
                    <ion-icon
                      [name]="!!cards && !!cards.output && cards.output.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
                  </ion-button>

                </ion-card-header>

                <div [hidden]="!!cards && !!cards.output && !cards.output.open">

                  <!--
                  <ion-card-content>
                    <p [innerHTML]="'media_creator_output_text'|translate"></p>
                  </ion-card-content>
                  -->

                  <!-- Aspect ratio -->
                  <ion-grid class="types-grid">
                    <ion-row>

                      <ion-col size="3" *ngFor="let aspect_ratio of aspect_ratios; let iAspectRatio = index;">

                        <ion-button class="types-btn" [class.active]="aspect_ratio.checked" size="block"
                          [fill]="aspect_ratio.checked ? 'solid' : 'outline'" color="primary"
                          (click)="toggleAspectRatio(aspect_ratio, iAspectRatio)">
                          <ion-icon [name]="aspect_ratio.icon"></ion-icon>
                          <ion-label [innerHTML]="aspect_ratio.name|translate"></ion-label>
                        </ion-button>

                      </ion-col>

                    </ion-row>
                  </ion-grid>

                  <ion-list>

                    <ion-item lines="none" [hidden]="!view.expertMode">
                      <ion-icon [hidden]="!view.isDesktop" name="recording-outline" slot="start"></ion-icon>
                      <ion-input [label]="'fps'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="1"
                        [max]="60" [(ngModel)]="aiSettings.fps"></ion-input>
                    </ion-item>

                  </ion-list>

                </div>

              </ion-card>

            </div>

            <!-- Styles -->
            <div class="col-inner"
              [hidden]="!!videoProject.sections[sectionIndex] && (videoProject.sections[sectionIndex].type === 'video')">
              <pipeline-ai-style-picker [(cards)]="cards" [(view)]="view"></pipeline-ai-style-picker>
            </div>

            <!-- Voiceover -->
            <div class="col-inner">
              <pipeline-voiceover-card [(cards)]="cards" [(enabled)]="view.use_tts" [(view)]="view"
                [input]="!!videoProject.sections && !!videoProject.sections[sectionIndex] && !!videoProject.sections[sectionIndex].speaker_text ? videoProject.sections[sectionIndex].speaker_text : ''"
                (settingsChanged)="onVoiceoverSettingsChanged($event)"></pipeline-voiceover-card>
            </div>

            <!-- Music -->
            <div class="col-inner">
              <pipeline-music-card [(cards)]="cards" [(view)]="view"
                [input]="!!videoProject.sections && !!videoProject.sections[sectionIndex] && !!videoProject.sections[sectionIndex].background_sound ? videoProject.sections[sectionIndex].background_sound : ''"
                (settingsChanged)="onMusicSettingsChanged($event)"></pipeline-music-card>
            </div>

            <!-- AI Settings -->
            <div class="col-inner">
              <pipeline-ai-settings-card [(cards)]="cards" [(config)]="aiSettings" [options]="aiSettingsOptions"
                [(view)]="view"></pipeline-ai-settings-card>
            </div>

          </ion-col>

          <!-- Content -->
          <ion-col class="content" [size]="!!view.sidebarSize ? (12 - view.sidebarSize) : 9">

            <!-- Desktop Intro card -->
            <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="introCard.hidden" class="introCard"
              [(view)]="introCard"></pipeline-intro-card>

            <!-- Video editor -->
            <pipeline-video-editor [project]="videoProject" [loading]="view.generating || view.loading"
              [options]="videoEditorOptions" [(view)]="view" (onClipUpdated)="onEditorClipUpdated($event)"
              (onClipStyleUpdated)="onEditorClipStyleUpdated($event)" (onLayerUpdated)="onEditorLayerUpdated($event)"
              (onLibraryUpdated)="onEditorLibraryUpdated($event)" (onTimeUpdated)="onEditorTimeUpdated($event)">
            </pipeline-video-editor>

          </ion-col>

        </ion-row>
      </ion-grid>

    </div>

  </div>

</ion-content>

<ion-footer class="ion-no-border" [hidden]="!!view.isSaveMode" *ngIf="!(!!media && !media.uid && !view.startManually)">
  <ion-toolbar class="container ion-no-border">

    <!-- Prompt input card -->
    <pipeline-ai-prompt-card type="code" [disabled]="!!view.loading" [(input)]="view.aiPrompt"
      (onSubmit)="runAiPrompt($event)"></pipeline-ai-prompt-card>

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>