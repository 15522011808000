import { Injectable } from "@angular/core";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';

@Injectable({
    providedIn: "root",
})
export class SetupService {

    constructor(
        private AppCMS: AppcmsService,
        private media: MediaextendService,
    ) {

    }

    async setupAiTasks(options: any = {}) {
        console.log('setupAiTasks', options);
        return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'ai_tasks'], {}, true);
    }

    async setupAiTools(options: any = {}) {
        console.log('setupAiTools', options);
        return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'ai_tools'], {}, true);
    }

    async setupCampaigns(options: any = {}) {
        console.log('setupCampaigns', options);
        return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'campaigns'], {}, true);
    }

    async setupEcommerce(options: any = {}) {
        console.log('setupEcommerce', options);
        return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'ecommerce'], {}, true);
    }

    async setupMediaLibrary(options: any = {}) {
        console.log('setupMediaLibrary', options);

        return this.media.importFromWebsite(options.project.link);
        //return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'media_library'], {}, true);
    }

    async setupSocialMedia(options: any = {}) {
        console.log('setupSocialMedia', options);
        return this.AppCMS.loadPluginData('pipeline', options, ['setup', 'social_media'], {}, true);
    }

}