<div *ngIf="!!view && !!view.sections">

  <ion-card class="section-card" *ngFor="let section of view.sections; let iSection = index;">

    <ion-card-header [hidden]="view.sections.length < 2">
      <ion-card-title>
        <span class="step">
          <span [innerHTML]="'section'|translate"></span>&nbsp;
          <span [innerHTML]="iSection+1"></span>:&nbsp;
        </span>
      </ion-card-title>

      <ion-button color="danger" (click)="removeSection(iSection)" fill="clear" icon-only>
        <ion-icon name="trash-outline" slot="start"></ion-icon>
      </ion-button>

    </ion-card-header>

    <ion-grid *ngIf="!!view.variables">
      <ion-row>

        <ion-col *ngFor="let variable of view.variables" [size]="variable.size || 12">
          <ion-item lines="none">
            <ion-icon *ngIf="!!variable.icon" [name]="variable.icon" slot="start"></ion-icon>
            <ion-input [label]="variable.label|translate" labelPlacement="floating" [type]="variable.type" [(ngModel)]="variable.value"></ion-input>
          </ion-item>
        </ion-col>

      </ion-row>
    </ion-grid>

    <ion-list lines="none">

      <ion-item>
        <ion-icon name="eye-outline" slot="start"></ion-icon>
        <ion-input [label]="'media_headline'|translate" labelPlacement="floating" type="text" [(ngModel)]="section.title" (ionInput)="onInputChanged(iSection)"></ion-input>
      </ion-item>

      <ion-item lines="none">
        <ion-icon name="chatbox-ellipses-outline" slot="start"></ion-icon>
        <ion-textarea [label]="'media_subtitle'|translate" labelPlacement="floating" type="text" [(ngModel)]="section.subtext" rows="3"
          (ionInput)="onInputChanged(iSection)"></ion-textarea>
      </ion-item>

    </ion-list>

  </ion-card>

</div>