<!-- Create code intro mode -->
<pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
  [aiSettings]="aiSettings" [aiSettingsOptions]="aiSettingsOptions" [config]="introOverlayConfig"
  [hidden]="!!view.startManually" [loading]="view.loading" (onCreate)="aiCreate()" (onSkip)="startManually()"
  [(view)]="view"></pipeline-intro-overlay>

<ion-card class="sidebar-accordion-card">

  <!-- Header -->
  <ion-card-header>

    <ion-card-title [innerHTML]="'ai_coder'|translate"></ion-card-title>

    <!-- Open as code project -->
    <ion-button *ngIf="!!code" [disabled]="!!rebuild || !!coding" (click)="makeProject()" fill="clear" color="primary"
      size="small" fill="clear" icon-only>
      <ion-icon name="open-outline"></ion-icon>
    </ion-button>

  </ion-card-header>

  <!-- Segment toolbar -->
  <ion-segment [(ngModel)]="segment" (ionChange)="segmentChanged()">

    <!-- Code -->
    <ion-segment-button value="code">
      <ion-icon name="code-slash-outline"></ion-icon>
      <ion-label [innerHTML]="'code'|translate"></ion-label>
    </ion-segment-button>

    <!-- Preview -->
    <ion-segment-button value="preview">
      <ion-icon name="browsers-outline"></ion-icon>
      <ion-label [innerHTML]="'preview'|translate"></ion-label>
    </ion-segment-button>

  </ion-segment>

  <!-- Loading spinner -->
  <ion-spinner [hidden]="!rebuild && !coding && !(segment === 'preview' && !!generatingSimulation)"></ion-spinner>

  <!-- Code editor -->
  <pipeline-editor *ngIf="!rebuild && !coding" [hidden]="segment !== 'code'" [input]="code"
    (inputChange)="onEditorInputChanged($event)" [language]="codeLanguage || 'typescript'" [mode]="editorMode">
  </pipeline-editor>

  <!-- Code execution / preview / simulation -->
  <iframe class="code-preview" [hidden]="segment !== 'preview' || !!generatingSimulation"
    [srcdoc]="code_preview"></iframe>

  <ion-item lines="none">

    <!-- Copy output to clipboard -->
    <ion-button slot="end" fill="clear" color="dark" (click)="copyText()">
      <ion-icon name="clipboard-outline" slot="start"></ion-icon>
      <ion-label [innerHTML]="'copy_to_clipboard'|translate" [hidden]="!view.isDesktop"></ion-label>
    </ion-button>

    <!-- Reload -->
    <ion-button slot="end" fill="clear" color="dark" icon-only (click)="refreshView()">
      <ion-icon name="sync-outline"></ion-icon>
    </ion-button>

  </ion-item>

</ion-card>