<!-- mobile intro card -->
<!--<pipeline-intro-card
  *ngIf="!!blInitialized && !!view && !!introCard && (!view.isDesktop && (!config || !config.uiMode) && (config.uiMode !== 'widget'))"
  [(view)]="introCard" [hidden]="introCard.hidden"></pipeline-intro-card>-->

<!-- Add actions popover -->
<ion-popover *ngIf="!!blInitialized && !!config && !!view" #daniChatSettingsPopover [isOpen]="isSettingsPopoverOpen"
  (didDismiss)="isSettingsPopoverOpen = false">
  <ng-template>
    <ion-content>
      <ion-list *ngIf="!!addActions">

        <ion-item button class="btn-item" *ngFor="let option of addActions; trackBy:trackItems"
          (click)="runItemSelectionOption(view.item, option)"
          [class.danger]="option.color == 'danger' || option.uid === 'delete'"
          [class.success]="option.color == 'success'" [class.warning]="option.color == 'warning'">
          <ion-icon [name]="option.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="option.label|translate"></ion-label>
        </ion-item>

      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ion-grid *ngIf="!!blInitialized && !!config && !!view" [class.widget]="config.uiMode === 'widget'"
  [class.mini]="!!config.mini" [class.hasChat]="!!config.hasChat" [class.userCanWrite]="!!config.userCanWrite">

  <ion-row>

    <!-- Left / top column -->
    <ion-col *ngIf="!!splineOptions"
      [size]="!config || !config.uiMode || (config.uiMode !== 'widget') ? (view.isDesktop ? 6 : 12) : 12"
      class="spline-col" [class.full-width]="!!config && (!!config.uiMode || (config.uiMode === 'widget'))"
      [class.has-messages]="state.hasMessages" [class.inConversation]="state.inConversation">

      <!-- Dani Preview -->
      <pipeline-spline-viewer *ngIf="!!blInitialized" [class]="animationClass" [(options)]="splineOptions"
        (click)="toChatPage()"></pipeline-spline-viewer>

    </ion-col>

    <!-- Right / bottom column -->
    <ion-col *ngIf="(!!config && (!!config.userCanWrite || !!config.hasChat)) || (!!introCard && !introCard.hidden)"
      class="chat-col" [class.has-messages]="state.hasMessages" [class.inConversation]="state.inConversation"
      [size]="!config || (!!config && (!config.uiMode || config.uiMode !== 'widget')) ? (view.isDesktop ? 6 : 12) : 12"
      [class.full-width]="!!config && (!!config.uiMode || (config.uiMode === 'widget'))"
      [hidden]="!!introCard && !!introCard.hidden && (!chat || !config.hasChat)">

      <!-- Chat -->
      <div id="daniChat" [class.widget]="!!config && !!config.uiMode && (config.uiMode === 'widget')" #daniChat
        *ngIf="!!chat && !!config.hasChat || (!!view.isDesktop && (!config || !config.uiMode || (config.uiMode !== 'widget')))">

        <!-- Desktop intro card -->
        <!--<pipeline-intro-card *ngIf="!!view.isDesktop && !!introCard && (!config || !config.uiMode) && (config.uiMode !== 'widget')"
          [(view)]="introCard" [hidden]="!!introCard && !!introCard.hidden"></pipeline-intro-card>-->

        <!-- Chat history -->
        <div class="history-wrapper" #historyWrapper *ngIf="!!chat && !!chat.messages">

          <div *ngFor="let message of chat.messages; let iChatMessage = index">

            <!-- Chat item -->
            <pipeline-chat-message
              *ngIf="message.mode === 'input' || message.mode === 'message' || message.mode === 'view'"
              [index]="iChatMessage" [(message)]="chat.messages[iChatMessage]" [hidden]="message.role === 'system'"
              (onResend)="resend(message)">
            </pipeline-chat-message>

            <!-- Editor -->
            <pipeline-editor *ngIf="message.mode === 'editor' && !view.rebuild" [id]="'postEditor' + (iChatMessage+1)"
              [input]="message.output || message.input" [disabled]="!!view.loading"
              (inputChange)="onEditorInputChanged($event, message, iChatMessage)"></pipeline-editor>

            <!-- Function call -->
            <div *ngIf="message.mode === 'function'">

              <!-- AI: Crawl URL content -->
              <pipeline-ai-browser [autostart]="true" [config]="browserConfig" (onPageDone)="onBrowserPageDone($event)"
                (onPageLoaded)="onBrowserPageLoaded($event)" (onPageRendered)="onBrowserPageRendered($event)"
                [input]="message.input" *ngIf="message.functionName === 'ai_browser'">
              </pipeline-ai-browser>

              <!-- AI: Coder -->
              <pipeline-ai-coder-card [autostart]="true" [colSize]="view.isDesktop ? 4 : 6" [input]="message.input"
                [output]="message.output" *ngIf="message.functionName === 'create_code'">
              </pipeline-ai-coder-card>

              <!-- AI: Image to video -->
              <pipeline-ai-image-to-video-card [autostart]="true" [colSize]="view.isDesktop ? 4 : 6"
                [input]="message.input" *ngIf="message.functionName === 'image_to_video'">
              </pipeline-ai-image-to-video-card>

              <!-- AI: Text to audio -->
              <pipeline-ai-text-to-audio-card [autostart]="true" [input]="message.input"
                *ngIf="message.functionName === 'text_to_audio'">
              </pipeline-ai-text-to-audio-card>

              <!-- AI: Text to image -->
              <pipeline-ai-text-to-image-card [autostart]="true" [colSize]="view.isDesktop ? 4 : 6"
                [input]="message.input" *ngIf="message.functionName === 'text_to_image'">
              </pipeline-ai-text-to-image-card>

              <!-- AI: Text to speech -->
              <pipeline-ai-text-to-speech-card [autostart]="true" [input]="message.input"
                *ngIf="message.functionName === 'text_to_speech'">
              </pipeline-ai-text-to-speech-card>

              <!-- AI: Upscaler -->
              <pipeline-ai-upscaler-card [autostart]="true" [input]="message.input"
                *ngIf="message.functionName === 'upscaler'">
              </pipeline-ai-upscaler-card>

            </div>

            <!-- Table view -->
            <pipeline-table-view *ngIf="message.mode === 'table' && !!message.arguments"
              (itemsChanged)="onTableItemsChanged($event)" (itemsUpdated)="onTableItemsUpdated($event)"
              (fieldsChanged)="onTableFieldsChanged($event)" [buttons]="tableViewButtons"
              [fields]="message.arguments.fields || []" [items]="message.arguments.data || []"
              [method]="message.arguments.method" [mode]="message.arguments.mode" [options]="tableViewOptions"
              [service]="dani" [(view)]="view">
            </pipeline-table-view>

          </div>

        </div>

        <!-- Write input form -->
        <form (ngSubmit)="send(true, $event)">

          <ion-item lines="none" class="write-bar" [hidden]="!config.userCanWrite">

            <!-- Add file -->
            <ion-button slot="start" icon-only color="primary" fill="clear" shape="round" (click)="addFile($event)"
              *ngIf="!!view.isLoggedIn">
              <ion-icon name="add-outline"></ion-icon>
            </ion-button>

            <!-- Stop listening -->
            <ion-button slot="start" class="record-btn stop-record-btn" icon-only color="danger"
              (click)="stopListening()" fill="clear" [hidden]="!blListening">
              <ion-icon name="stop-circle-outline" color="danger"></ion-icon>
            </ion-button>

            <!-- Record mic -->
            <ion-button slot="start" class="record-btn" icon-only (click)="record()" fill="clear" color="dark"
              [hidden]="!!blListening || !blSpeechRecognitionAvailable">
              <ion-icon name="mic-outline"></ion-icon>
            </ion-button>

            <!-- Input text -->
            <ion-input name="aiPrompt" [autocorrect]="'on'" [disabled]="state.speaking" [(ngModel)]="inputPrompt"
              type="text"
              [placeholder]="(!!assistant && !!assistant.uid ? 'write_something' : 'ask_dani')|translate"></ion-input>

            <ion-spinner slot="end" [hidden]="!blLoading"></ion-spinner>

            <!-- Submit chat message -->
            <ion-button slot="end" icon-only color="primary" (click)="send(false, $event)" shape="round"
              [disabled]="!!blBlockRunAiPrompt || !inputPrompt || !!blLoading">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-button>

          </ion-item>

        </form>

      </div>

    </ion-col>

  </ion-row>
</ion-grid>