import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  get(board: string, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getPipelineStatistics(blForceRefresh)
        .then((statistics: statistics) => {
          resolve(statistics.boards[board] || null);
        })
        .catch(reject);
    });
  }

  getByPlugin(pluginName: string, board: string | null = null) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('statistics', {}, [pluginName, 'boards'])
        .then((response: any) => {
          if (board) {
            resolve(response && response[board] ? response[board] : null);
          } else {
            resolve(response);
          }
        })
        .catch(reject);
    });
  }

  getCreatorStatistics(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['statistics', 'creator'], {}, blForceRefresh);
  }

  getPipelineStatistics(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['statistics'], {}, blForceRefresh);
  }

  getSocialMediaStatistics(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['statistics', 'social_media'], {}, blForceRefresh);
  }

  async getUsersByCategories() {
    return this.get('usersByCategories');
  }

  async getUsersByCategory(categoryId: any) {
    let all = await this.get('usersByCategories');
  }

}
