import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ExternalService } from 'src/app/services/extensions/external.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { BrowserService } from "src/app/services/utils/browser.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { EditSourcePage } from 'src/app/pages/core/sources/edit-source/edit-source.page';
import { ConnectBlogPage } from 'src/app/pages/extensions/blog-admin/connect-blog/connect-blog.page';

@Component({
  selector: 'app-creator-blogs',
  standalone: false,
  templateUrl: './creator-blogs.page.html',
  styleUrls: ['./creator-blogs.page.scss'],
})
export class CreatorBlogsPage implements OnInit {

  cta: any = {
    handler: () => {
      this.addBlog();
    },
    icon: 'link-outline',
    label: 'connect',
  };

  fallbackImg: string = './assets/img/fallback.webp';

  search: searchOptions = {
    itemsKey: 'blogs',
    keys: ['indent', 'language', 'name', 'post_content', 'host', 'title', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  state: state = {};

  view: any = {
    filters: {

    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      text: 'creators_blog_admin_text',
      title: 'creators_blog_admin_headline',
      uid: 'creators_blog_admin',
    },
    isModal: false,
    itemSize: 72,
    multiple: true,
    options: {
      limit: 100,
    },
    regions: [],
    key: ['name', 'url', 'uid'],
    showMenuButton: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'creators_blog_admin',
    viewType: 'list',
  };

  constructor(
    private browser: BrowserService,
    private creators: CreatorsService,
    private events: EventsService,
    private external: ExternalService,
    private modalService: ModalService,
    private sources: SourcesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.view.isModal = this.modalService.isModal();
  }

  async addBlog() {

    const blogModal: any = await this.modalService.create({
      component: ConnectBlogPage,
      animated: true,
      cssClass: 'defaultModal',
    });

    blogModal.onWillDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(blogModal);
  }

  calcRegions() {
    let regions: city[] = [], regionIds: number[] = [];

    this.view.blogs.forEach((blog: source) => {
      if ((!!blog.region_uid && regionIds.indexOf(blog.region_uid) === -1)) {
        regionIds.push(blog.region_uid);

        regions.push({
          title: blog.region,
          uid: blog.region_uid,
        });
      }

    });

    this.view.regions = regions.sort((a: any, b: any) => {
      if (a.title < b.title) return -1;
      if (a.title > b.title) return 1;
      return 0;
    });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  delete(blog: source, event: any) {
    this.external.deleteSource(blog.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteSource',
      items: this.view.selectedItems,
      service: this.external,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any | null = null) {
    this.loadBlogs(true)
      .then(() => {
        if (event) {
          event.target.complete();
        }

        delete this.view.blogs_backup;
        this.runSearch();
      })
      .catch((error: any) => {
        if (event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected() {

  }

  async edit(blog: source, event: any | null = null) {

    const modal: any = await this.modalService.create({
      component: EditSourcePage,
      componentProps: {
        source: blog,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then((response: any) => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  index(blog: source, event: any) {
    this.sources.index(blog)
      .then((response: any) => {
        this.loadBlogs(true)
          .catch((error: any) => {
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

  }

  loadBlogs(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      if (!this.view.project || !this.view.project.uid) {
        this.view.blogs = [];
        resolve(this.view);
      } else {
        this.creators.getBlogs(blForceRefresh, this.view.blogs)
          .then((blogs: source[]) => {
            this.view.blogs = blogs;

            this.calcRegions();
            resolve(blogs);
          })
          .catch(reject);
      }
    });
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });

    this.loadBlogs()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  onItemChecked(shop) {

    this.view.selectedItems = this.view.blogs.filter((_item: any) => {
      return _item.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

  open(url: string) {
    this.browser.create(url);
  }

  runSearch(event: any | null = null) {
    console.log('tell search toolbar to update', event);
  }

  thumbnailLoadingFailed(blog: source) {
    blog.photo = this.fallbackImg;
  }

  toggleActive(blog: source) {

    if (!this.userService.isType(['Admin', 'Moderator'])) {
      return false;
    }

    blog.active = !blog.active;

    this.sources.updateSource(blog)
      .catch((error: any) => {
        this.events.publish('error', error);
        blog.active = !blog.active;
      });

  }

}