import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { PagesService } from 'src/app/services/extensions/pages.service';

@Component({
  selector: 'app-dynamic',
  standalone: false,
  templateUrl: './dynamic.page.html',
  styleUrls: ['./dynamic.page.scss'],
})
export class DynamicPage implements OnInit {

  isModal: boolean;

  page: page;

  pageId: string;

  view: any = {
    expertMode: false,
  };
  
  constructor(
    private events: EventsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private pages: PagesService,
  ) {
    this.isModal = this.pages.isModal();

    this.pageId = this.route.snapshot.paramMap.get('pageId');
    this.page = this.pages.getCurrent();
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }
  
  doRefresh(event: any) {
    this.loadPage()
    .then(() => {
      event.target.complete();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    if(!this.page && this.pageId) {
      this.loadPage()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    }
  }

  ngOnInit() {
  }

  loadPage(pageId = undefined) {
    return new Promise((resolve, reject) => {
      this.pages
      .getByUid(pageId || this.pageId)
      .then(page => {
        this.page = page;
        resolve(this.page);
      })
      .catch(reject);
    });
  }
  
}