<ion-popover #headerAddButtonPopover [isOpen]="isHeaderAppButtonPopoverOpen"
  (didDismiss)="isHeaderAppButtonPopoverOpen = false">
  <ng-template>
    <ion-content>

      <ion-list>

        <ion-item *ngFor="let item of items; trackBy:trackItems" (click)="select(item, $event)">
          <ion-icon *ngIf="!!item.icon" [name]="item.icon" slot="start"></ion-icon>
          <ion-label [innerHTML]="item.name|translate" class="ion-text-wrap"></ion-label>
        </ion-item>

      </ion-list>

    </ion-content>
  </ng-template>
</ion-popover>

<ion-button (click)="presentPopover($event)" [fill]="view.isDesktop ? 'solid' : 'clear'" shape="round" size="small"
  color="primary">
  <ion-label [innerHTML]="'create'|translate" [hidden]="!view.isDesktop"></ion-label>
  <ion-icon name="chevron-down-outline" slot="end"></ion-icon>
</ion-button>