import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pipeline-media-create-form-dynamic',
  standalone: false,
  templateUrl: './media-create-form-dynamic.component.html',
  styleUrls: ['./media-create-form-dynamic.component.scss'],
})
export class MediaCreateFormDynamicComponent implements OnInit {
  @Input() view: any;

  @Output() onViewChanged = new EventEmitter();

  fallbackImg: string = './assets/img/fallback.webp';

  constructor(
  ) {

  }
  
  ngOnInit() {
    
  }

  onInputChanged(iSection: number = 0) {
    this.onViewChanged.emit(this.view);
  }

  removeSection(iSection: number) {
    this.view.sections = this.view.sections.filter((_section: any, _index: number) => {
      return _index !== iSection;
    });
  }
  
}
