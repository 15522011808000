import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ProjectsService } from "src/app/services/core/projects.service";
import { ViewService } from 'src/app/services/core/view.service';
import { IntegrationsService } from "src/app/services/integrations/integrations.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'app-integrations',
  standalone: false,
  templateUrl: './integrations.page.html',
  styleUrls: ['./integrations.page.scss'],
})
export class IntegrationsPage implements OnInit {
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  appConfig: pipelineAppConfig;

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() inSetupEmbedded: boolean = false;

  introCard: introCardConfig = {
    uid: 'integrations_top_card',
    text: 'integrations_top_card_text',
    title: 'integrations_top_card_title',
  };

  paginationConfig: paginationConfig = {
    itemsKey: 'integrations',
    limit: 500,
  };

  search: searchOptions = {
    itemsKey: 'integrations',
    keys: ['name', 'post_content', 'host', 'title', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'checkmark-outline',
      label: 'enable',
      uid: 'enable',
    },
    {
      icon: 'close-outline',
      label: 'disable',
      uid: 'disable',
    }
  ];

  state: state = {};

  view: any = {
    categories: [
      {
        icon: 'sparkles-outline',
        name: 'ai',
        uid: 'ai',
      },
      {
        icon: 'hardware-chip-outline',
        name: 'compute',
        uid: 'compute',
      },
      {
        icon: 'server-outline',
        name: 'data',
        uid: 'tools',
      },
      {
        icon: 'storefront-outline',
        name: 'ecommerce',
        uid: 'ecommerce',
      },
      {
        icon: 'megaphone-outline',
        name: 'marketing',
        uid: 'marketing',
      },
      {
        icon: 'share-social-outline',
        name: 'social_media',
        uid: 'social',
      },
      {
        icon: 'folder-open-outline',
        name: 'blogs',
        uid: 'blogs',
      },
    ],
    category: 'all',
    colSize: (window.innerWidth >= 768 ? 3 : 12),
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    hideSelectAll: false,
    route: 'integrations',
    key: ['description', 'name', 'url', 'uid'],
    multiple: true,
    showMenuButton: true,
    showProjectsSelect: true,
    title: 'integrations',
    verified: false,
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private projects: ProjectsService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
  }

  async categoryChanged() {
    if (!!this.view.integrations && !!this.view.integrations.length) {
      this.view.integrations.forEach((integration: any) => {
        integration.hidden = (this.view.category === 'all' ? false : (integration.category !== this.view.category));
      });
    }
  }

  doRefresh(event: any | null = null) {
    this.loadIntegrations(true)
      .then(() => {

        if (!!event) {
          event.target.complete();
        }

        this.runSearch();
        this.categoryChanged();
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }
      });
  }

  disableIntegration(integration: integration) {
    console.log('disableIntegration', integration);

    this.integrations.disable(integration.uid)
      .then((response: any) => {
        integration.enabled = !response.success;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  disableSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'disable',
      items: this.view.selectedItems,
      service: this.integrations,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  editIntegration(integration: integration) {
    this.integrations.edit(integration)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  enableIntegration(integration: integration) {
    console.log('enableIntegration', integration);

    this.integrations.enable(integration.uid)
      .then((response: any) => {
        integration.enabled = !!response.success;

        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  enableSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'enable',
      items: this.view.selectedItems,
      service: this.integrations,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.loadIntegrations();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
    });
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!this.view.project || !this.view.project.uid) {
      return false;
    }

    this.view.loading = true;

    try {
      this.view.integrations = await this.integrations.getAvailable({}, blForceRefresh);
      this.view.integrations_backup = (this.view.integrations || []);

      this.view.loading = false;
    } catch (e) {
      this.view.loading = false;
      console.warn('> loading integrations failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
    this.calcViewVars();
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    this.loadIntegrations();
  }

  onIntegrationCheckboxClicked(integration: integration) {

    this.view.selectedItems = this.view.integrations.filter((_integration: integration) => {
      return _integration.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'disable':
        this.disableSelected();
        break;
      case 'enable':
        this.enableSelected();
        break;
    }

  }

  async runSearch() {
    try {
      const search: any = await this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  thumbnailLoadingFailed(integration: integration) {
    integration.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}