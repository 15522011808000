import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { MediaCreateFormAutomotiveComponent } from './media-create-form-automotive.component';
import { MediaCreateFormDynamicComponentModule } from '../media-create-form-dynamic/media-create-form-dynamic.component.module';

@NgModule({
  declarations: [
    MediaCreateFormAutomotiveComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MediaCreateFormDynamicComponentModule,
  ],
  exports: [
    MediaCreateFormAutomotiveComponent,
  ]
})
export class MediaCreateFormAutomotiveComponentModule { }