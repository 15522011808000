import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { TeamsPickerService } from './teams-picker.service';
import { UserPickerService } from './user-picker.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  cacheKey: string = 'current_project';

  current: project;

  _detailItem: project | null;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private configService: ConfigService,
    private events: EventsService,
    private loadingCtrl: LoadingController,
    private teamsPicker: TeamsPickerService,
    private tools: ToolsService,
    private userService: UserService,
    private userPickerService: UserPickerService,
  ) {

  }

  addToTeam(projectId: number, teamId: number) {
    return this.AppCMS.loadPluginData('projects', {
      relation: {
        project_uid: projectId,
        team_uid: teamId,
      }
    }, ['addToTeam']);
  }

  addTeamToProject(projectId: number, teamId: number = null) {
    return new Promise(async (resolve, reject) => {
      if (!teamId) {
        this.teamsPicker.pick()
          .then((response: chooseResponse) => {
            const team: team = (response && response.data && response.data.item ? response.data.item : null);

            if (!!team && !!team.uid) {
              this.performAddTeamToProject(projectId, team.uid).then(resolve).catch(reject);
            } else {
              reject();
            }
          })
          .catch(reject);
      } else {
        this.performAddTeamToProject(projectId, teamId).then(resolve).catch(reject);
      }
    });
  }

  addUserToProject(projectId: number, userId: number = null) {
    return new Promise(async (resolve, reject) => {
      if (!userId) {
        this.userPickerService.pick()
          .then((response: chooseResponse) => {
            const user: user = (response && response.data && response.data.item ? response.data.item : null);

            if (!!user && !!user.uid) {
              this.performAddUserToProject(projectId, user.uid).then(resolve).catch(reject);
            } else {
              reject();
            }
          })
          .catch(reject);
      } else {
        this.performAddUserToProject(projectId, userId).then(resolve).catch(reject);
      }
    });
  }

  calculateAssetsConfig(project: project) {
    project.config = project.config || {};
    project.config.assets = project.config.assets || {};

    if (!!project.config.assets.negative_prompt && (typeof project.config.assets.negative_prompt === 'object')) {
      project.config.assets.negative_prompt = this.tools.trim(project.config.assets.negative_prompt.map((attribute: any) => {
        return attribute.title;
      }).join(', '));
    }

    if (!!project.config.assets.prompt_suffix && (typeof project.config.assets.prompt_suffix === 'object')) {
      project.config.assets.prompt_suffix = this.tools.trim(project.config.assets.prompt_suffix.map((attribute: any) => {
        return attribute.title;
      }).join(', '));
    }

    const negativePrompt: string = `${project.config.assets.negative_prompt || ''}`,
      positivePrompt: string = `${project.config.assets.prompt_suffix || ''}`;

    return {
      cfg_scale: `${project.config.assets.cfg_scale || ''}`,
      denoising_strength: `${project.config.assets.denoising_strength || ''}`,
      instances: (project.config.assets.instances || []),
      models: (project.config.assets.models || []),
      negative_prompt: negativePrompt,
      prompt_suffix: positivePrompt,
      steps: `${project.config.assets.steps || ''}`,
    };
  }


  copyItemToProject(itemId: number, type: string, sourceProject: number, targetProject: number) {

  }

  delete(projectId: number) {
    return this.AppCMS.loadPluginData('projects', {
      uid: parseInt(`${projectId}`),
    }, ['delete']);
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  duplicate(projectId: number, projectData: project = null) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loadingCtrl.create({
        spinner: 'circular',
      });

      loading.present();

      this.AppCMS.loadPluginData(
        'projects',
        {
          uid: projectId,
          data: projectData,
        },
        ['duplicate', projectId],
      )
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error: any) => {
          loading.dismiss();
          reject(error);
        });

    });
  }

  getActive(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {

    if (typeof options !== 'object') {
      options = {};
    }

    if (typeof params !== 'object') {
      params = {};
    }

    params.project_uid = 0;

    return this.getAll(options, blForceRefresh, params);
  }

  getAfterSetupRedirectUri() {
    const locationParams = new URLSearchParams(location.search),
      redirectUriFromParams = locationParams.get('redirect_uri');

    if (!!redirectUriFromParams) {
      return redirectUriFromParams;
    }

    const redirectUriFromConfig: string = this.configService.getConfigParam('projectsSetupRedirectUri');

    if (!!redirectUriFromConfig) {
      return redirectUriFromConfig;
    }

    return this.configService.getRoute('home');
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {

    if (typeof options !== 'object') {
      options = {};
    }

    if (typeof params !== 'object') {
      params = {};
    }

    params.project_uid = 0;

    return this.AppCMS.loadPluginData('projects', Object.assign(params, {
      filter: (typeof options === 'object' ? options : {}),
    }), [], params, blForceRefresh);
  }

  getByTeamUid(teamId: number, blForceRefresh: boolean = false, params: any = {}) {
    return this.AppCMS.loadPluginData('projects', params, ['team', teamId], params, blForceRefresh);
  }

  getByUserUid(userId: number, blForceRefresh: boolean = false, params: any = {}) {

    // @debug
    params.userId = userId;
    return this.getActive({}, blForceRefresh, params);

    //return this.AppCMS.loadPluginData('projects', params, ['user', userId], params, blForceRefresh);
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('projects', {}, [uid], {}, blForceRefresh);
  }

  async getCurrent() {

    if (!!this.current) {
      return this.current;
    }

    const fromCache: cacheItem = await this.cache.get(this.cacheKey, -1);

    if (!!fromCache && !!fromCache.data) {
      this.setCurrent(fromCache.data);
      return (fromCache.data as project);
    }

    return null;
  }

  async getCurrentUid() {
    const current: project | null = await this.getCurrent();
    return (!!current && !!current.uid ? current.uid : null);
  }

  getProjectSetupStates(projectId: number, blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('projects', {}, [projectId, 'setup_states'], {}, blForceRefresh);
  }

  moveItemToProject(itemId: number, type: string, sourceProject: number, targetProject: number) {

  }

  performAddTeamToProject(projectId: number, teamId: number) {
    return this.AppCMS.loadPluginData('projects', {
      relation: {
        project_uid: projectId,
        team_uid: teamId,
      }
    }, ['addTeamToProject']);
  }

  performAddUserToProject(projectId: number, userId: number) {
    return this.AppCMS.loadPluginData('projects', {
      relation: {
        project_uid: projectId,
        user_uid: userId,
      }
    }, ['addUserToProject']);
  }

  removeFromTeam(projectId: number, teamId: number) {
    return this.AppCMS.loadPluginData('projects', {
      relation: {
        project_uid: projectId,
        team_uid: teamId,
      }
    }, ['removeFromTeam']);
  }

  save(project: project) {
    project.icon = project.icon || 'folder-outline';
    project.user_uid = project.user_uid || this.userService.getUid();

    return this.AppCMS.loadPluginData('projects', {
      project: project,
    }, ['create']);
  }

  setCurrent(project: project) {
    this.current = project;
    this.cache.set(this.cacheKey, this.current);

    this.AppCMS.setRequestParam('project_uid', (!!this.current && !!this.current.uid ? this.current.uid : null));
    this.events.publish('project:current:updated', this.current);

    return this;
  }

  update(project: project) {
    project = JSON.parse(JSON.stringify(project));

    if (!!project.config) {
      project.value = project.config;
      delete project.config;
    }

    delete project.checked;

    return this.AppCMS.loadPluginData('projects', {
      project: project,
    }, ['update']);
  }

}