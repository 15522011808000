import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';
import { EventsService } from "src/app/services/core/events.service";
import { ImagesService } from "src/app/services/media/images.service";

import { apiUrl, proxyUrl } from 'src/config/variables';

/* Libraries: */
import { RawImage, Tensor } from '@huggingface/transformers';
import 'img-comparison-slider';
import { createDemoApp } from 'polotno/polotno-app';
import Viewer from 'viewerjs';

@Component({
  selector: 'pipeline-image-editor',
  standalone: false,
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements AfterViewInit, OnInit {

  @Input() aiSettings: aiSettings = {

  };

  @Input() aiSettingsOptions: aiSettingsOptions = {
    operations: ['image_to_image'],
  };

  @Input() action: string;

  apiUrl: string;

  // Constants
  BASE_URL: string = 'https://huggingface.co/datasets/Xenova/transformers.js-docs/resolve/main/';

  blockDetectOnChange: boolean = true;

  clearButton: any = document.getElementById('clear-points');

  @Input() config: any = {};

  cross: any;

  cutButton: any = document.getElementById('cut-mask');

  @Input() disabled: boolean;

  drawingConfig: any = {
    mode: 'squares',
  };

  @Input() gallery: mediaItem[] = [];

  example: any = document.getElementById('example');

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  @Input() id: string | null;

  imageDataURI: any = null;
  imageEmbeddings: any;
  imageInputs: any;

  @Input() index: number = 0;

  @ViewChild('inpaintCanvas', { read: ElementRef, }) inpaintCanvas: ElementRef;

  @Input() input: string;

  @Output() inputChange = new EventEmitter();

  isEncoded: any = false;
  isDecoding: any = false;
  isMultiMaskMode: any = false;

  @Input() mini: boolean = false;

  @Input() mode: string;

  model: any;

  @ViewChild("polotnoElement", { read: ElementRef }) private polotnoElement: ElementRef;

  processor: any;

  proxyUrl: string;

  rebuild: boolean = false;

  saveProcess: any;

  resetButton: any = document.getElementById('reset-image');

  lastPoints: any = null;

  @Input() loading: boolean = false;

  @Input() media: mediaItem = {};

  @ViewChild('mediaItemsList', { read: ElementRef, }) mediaItemsList: ElementRef;

  @Input() mediaList: mediaItem[] = [];

  modelReady: any = false;

  @Output() onChanged = new EventEmitter();

  @Output() onInpaintChanged = new EventEmitter();

  @Output() onUpdated = new EventEmitter();

  @ViewChild('overlayCanvas', { read: ElementRef, }) overlayCanvas: ElementRef;

  @ViewChild('previewImage', { read: ElementRef, }) previewImage: ElementRef;

  star: any;

  store: any;

  @Input() url: string;

  @Output() urlChanged = new EventEmitter();

  @Input() view: any = {};

  viewer: any;

  constructor(
    private aiWorker: AiWorkerService,
    private el: ElementRef,
    private events: EventsService,
    private images: ImagesService,
  ) {
    this.apiUrl = apiUrl;
    this.proxyUrl = proxyUrl;
  }

  async _onInpaintChanged(event: any | null = null, media: any | null = null, index: number = 0) {
    this.onInpaintChanged.emit({
      event: event,
      index: index,
      media: media,
    });
  }

  public addIcon({ point, label }) {
    const icon = (label === 1 ? this.star : this.cross).cloneNode();
    icon.style.left = `${point[0] * 100}%`;
    icon.style.top = `${point[1] * 100}%`;

    this.previewImage.nativeElement.appendChild(icon);
  }

  public calculateGallery() {
    const gallery: mediaItem[] = JSON.parse(JSON.stringify(!!this.gallery && !!this.gallery.length ? this.gallery : (!!this.mediaList && !!this.mediaList.length ? this.mediaList : [])));
    gallery.shift();
    this.gallery = gallery;
  }

  // Clamp a value inside a range [min, max]
  public clamp(x: number, min: number = 0, max: number = 1) {
    return Math.max(Math.min(x, max), min)
  }

  public clearPointsAndMask() {
    this.isMultiMaskMode = false;
    this.lastPoints = null;

    // Remove points from previous mask (if any)
    document.querySelectorAll('.icon').forEach(e => e.remove());

    // Disable cut button
    //this.cutButton.disabled = true;

    // Reset mask canvas
    this.inpaintCanvas.nativeElement.getContext('2d').clearRect(0, 0, this.inpaintCanvas.nativeElement.width, this.inpaintCanvas.nativeElement.height);
  }

  public decode() {
    this.isDecoding = true;
    this.aiWorker.postMessage({ type: 'decode', data: this.lastPoints });
  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
    */
  }

  public async exportCanvas(index: number = 0, event: any | null = null) {

    if (!!this.media && (this.media.mode === 'polotno')) {
      this.url = await this.getStore().toDataURL();
    } else
      if (!!this.inpaintCanvas && !!this.inpaintCanvas.nativeElement) {
        this.url = this.inpaintCanvas.nativeElement.toDataURL();
      }

    if (!!this.url) {
      this.urlChanged.emit(this.url);
      return this.url;
    }
  }

  public exportCanvasMask(index: number = 0, event: any | null = null, backgroundColor: string = '#ffffff') {
    const canvas: any = this.overlayCanvas.nativeElement;

    if (!canvas) {
      return false;
    }

    const combinedCanvas = document.createElement("canvas");
    combinedCanvas.width = canvas.width;
    combinedCanvas.height = canvas.height;

    const combinedCtx: any = combinedCanvas.getContext('2d');
    combinedCtx.fillStyle = backgroundColor;

    if (!this.view.outpaint) {
      combinedCtx.filter = 'invert(1)';
    }

    combinedCtx.fillRect(0, 0, canvas.width, canvas.height);
    combinedCtx.drawImage(canvas, 0, 0);

    const maskURL: string = combinedCanvas.toDataURL('image/png');

    return maskURL;
  }

  public getInpaintCanvas() {
    return this.inpaintCanvas;
  }

  public getOverlayCanvas() {
    return this.overlayCanvas;
  }

  public getPoint(e: any) {
    // Get bounding box
    const bb = this.previewImage.nativeElement.getBoundingClientRect();

    // Get the mouse coordinates relative to the container
    const mouseX = this.clamp((e.clientX - bb.left) / bb.width);
    const mouseY = this.clamp((e.clientY - bb.top) / bb.height);

    return {
      point: [mouseX, mouseY],
      label: e.button === 2 // right click
        ? 0  // negative prompt
        : 1, // positive prompt
    }
  }

  public getStore() {
    return this.store;
  }

  public async initInpainting() {
    try {

      // Create a web worker so that the main (UI) thread is not blocked during inference.
      /*
      this.aiWorker = new Worker(
        new URL('./worker.js', import.meta.url),
        { type: 'module' }
      );
      */

      //this.clearButton.addEventListener('click', this.clearPointsAndMask);

      if (!this.media || !this.media.guid) {
        console.log('image-editor: missing media guid', this.media);
        return false;
      }

      if (!!this.previewImage && this.previewImage.nativeElement) {
        this.view.drawCanvasHeight = parseInt(`${this.previewImage.nativeElement.clientHeight || 0}`);
        this.view.drawCanvasWidth = parseInt(`${this.previewImage.nativeElement.clientWidth || 0}`);
      }

      try {
        // try inpainting using segmentation first

        this.loading = true;

        const [model, processor] = await this.aiWorker.getSegmentAnythingInstance();
        this.model = model;
        this.processor = processor;

        this.loading = false;

        this.cross = new Image();
        this.cross.src = this.BASE_URL + 'cross-icon.png';
        this.cross.className = 'icon';

        // Preload star and cross images to avoid lag on first click
        this.star = new Image();
        this.star.src = this.BASE_URL + 'star-icon.png';
        this.star.className = 'icon';

        const response = await fetch(this.proxyUrl + this.media.guid);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.initInpaintingEvents();
          this.segment(e.target.result);
        };

        reader.onerror = (e: any) => {
          console.error('onerror: e', e);
          this.events.publish('error', (e.message || e) || 'unknown_error');
        };

        reader.readAsDataURL(blob);

        // Handle file selection
        /*
        this.fileUpload.addEventListener('change', function (e) {
          const file = e.target.files[0];
    
          if (!file) {
            return;
          }
    
          const reader = new FileReader();
    
          // Set up a callback when the file is loaded
          reader.onload = (e2: any) => this.segment(e2.target.result);
          reader.readAsDataURL(file);
        });
        */

        // Do not show context menu on right click
        this.previewImage.nativeElement.addEventListener('contextmenu', (e: any) => {
          e.preventDefault();
        });

        // Handle cut button click
        /*
        this.cutButton.addEventListener('click', () => {
          const [w, h] = [this.inpaintCanvas.nativeElement.width, this.inpaintCanvas.nativeElement.height];
    
          // Get the mask pixel data
          const maskContext = this.inpaintCanvas.nativeElement.getContext('2d');
          const maskPixelData = maskContext.getImageData(0, 0, w, h);
    
          // Load the image
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = async () => {
    
            // Create a new canvas to hold the image
            const imageCanvas: any = new OffscreenCanvas(w, h);
            const imageContext: any = imageCanvas.getContext('2d');
            imageContext.drawImage(image, 0, 0, w, h);
    
            const imagePixelData: any = imageContext.getImageData(0, 0, w, h);
    
            // Create a new canvas to hold the cut-out
            const cutCanvas: any = new OffscreenCanvas(w, h);
            const cutContext: any = cutCanvas.getContext('2d');
            const cutPixelData: any = cutContext.getImageData(0, 0, w, h);
    
            // Copy the image pixel data to the cut canvas
            for (let i = 3; i < maskPixelData.data.length; i += 4) {
              if (maskPixelData.data[i] > 0) {
                for (let j = 0; j < 4; ++j) {
                  const offset = i - j;
                  cutPixelData.data[offset] = imagePixelData.data[offset];
                }
              }
            }
    
            cutContext.putImageData(cutPixelData, 0, 0);
    
            // Download image 
            const link = document.createElement('a');
            link.download = 'image.png';
            link.href = URL.createObjectURL(await cutCanvas.convertToBlob());
            link.click();
            link.remove();
          }
          image.src = this.imageDataURI;
        });
    
        this.resetButton.addEventListener('click', () => {
    
          // Update state
          this.isEncoded = false;
          this.imageDataURI = null;
    
          // Indicate to worker that we have reset the state
          this.aiWorker.postMessage({ type: 'reset' });
    
          // Clear points and mask (if present)
          this.clearPointsAndMask();
    
          // Update UI
          this.cutButton.disabled = true;
          this.previewImage.nativeElement.style.backgroundImage = 'none';
          this.statusLabel.textContent = 'Ready';
        });
        */

        // Set up message handler
        this.aiWorker.addEventListener('message', async (e: any) => {
          switch (e.type) {

            case 'decode':

              // Prepare inputs for decoding
              const reshaped = this.imageInputs.reshaped_input_sizes[0];
              const points = e.data.map(x => [x.point[0] * reshaped[1], x.point[1] * reshaped[0]])
              const labels = e.data.map(x => BigInt(x.label));

              const input_points = new Tensor(
                'float32',
                points.flat(Infinity),
                [1, 1, points.length, 2],
              );

              console.log('input_points', input_points);

              const input_labels = new Tensor(
                'int64',
                labels.flat(Infinity),
                [1, 1, labels.length],
              );

              console.log('input_labels', input_labels);

              // Generate the mask
              const { pred_masks, iou_scores } = await this.model({
                ...this.imageEmbeddings,
                input_points,
                input_labels,
              })

              // Post-process the mask
              const masks = await this.processor.post_process_masks(
                pred_masks,
                this.imageInputs.original_sizes,
                this.imageInputs.reshaped_input_sizes,
              );

              console.log('masks', masks);

              // Send the result back to the main thread
              this.aiWorker.postMessage({
                type: 'decode_result',
                data: {
                  mask: RawImage.fromTensor(masks[0][0]),
                  scores: iou_scores.data,
                },
              });

              break;

            case 'decode_result':
              this.isDecoding = false;

              if (!this.isEncoded) {
                return; // We are not ready to decode yet
              }

              if (!this.isMultiMaskMode && this.lastPoints) {
                // Perform decoding with the last point
                this.decode();
                this.lastPoints = null;
              }

              const { mask, scores } = e.data;
              console.log('image-editor: message: mask', mask);
              console.log('image-editor: message: scores', scores);

              // Update canvas dimensions (if different)
              if (this.inpaintCanvas.nativeElement.width !== mask.width || this.inpaintCanvas.nativeElement.height !== mask.height) {
                //this.inpaintCanvas.nativeElement.width = mask.width;
                //this.inpaintCanvas.nativeElement.height = mask.height;
              }

              // Create context and allocate buffer for pixel data
              const context = this.inpaintCanvas.nativeElement.getContext('2d');
              const imageData = context.createImageData(this.inpaintCanvas.nativeElement.width, this.inpaintCanvas.nativeElement.height);

              // Select best mask
              const numMasks = scores.length; // 3
              let bestIndex = 0;

              for (let i = 1; i < numMasks; ++i) {
                if (scores[i] > scores[bestIndex]) {
                  bestIndex = i;
                }
              }

              //this.statusLabel.textContent = `Segment score: ${scores[bestIndex].toFixed(2)}`;

              // Fill mask with colour
              const pixelData = imageData.data;
              for (let i = 0; i < pixelData.length; ++i) {
                if (mask.data[numMasks * i + bestIndex] === 1) {
                  const offset = 4 * i;
                  pixelData[offset] = 0;       // red
                  pixelData[offset + 1] = 114; // green
                  pixelData[offset + 2] = 189; // blue
                  pixelData[offset + 3] = 255; // alpha
                }
              }

              // Draw image data to context
              context.putImageData(imageData, 0, 0);
              break;

            case 'ready':
              this.modelReady = true;
              //this.statusLabel.textContent = 'Ready';
              break;

            case 'reset':

              break;

            case 'segment':

              // Indicate that we are starting to segment the image
              this.aiWorker.postMessage({
                type: 'segment_result',
                data: 'start',
              });

              // Read the image and recompute image embeddings
              const image = await RawImage.read(e.data);
              console.log('segment: image', image);

              this.imageInputs = await this.processor(image);
              console.log('segment: imageInputs', this.imageInputs);

              this.imageEmbeddings = await this.model.get_image_embeddings(this.imageInputs);
              console.log('segment: imageEmbeddings', this.imageEmbeddings);

              // Indicate that we have computed the image embeddings, and we are ready to accept decoding requests
              this.aiWorker.postMessage({
                type: 'segment_result',
                data: 'done',
              });

              break;

            case 'segment_result':
              if (e.data === 'start') {
                //this.statusLabel.textContent = 'Extracting image embedding...';
              } else {
                //this.statusLabel.textContent = 'Embedding extracted!';
                this.isEncoded = true;
              }
              break;

            default:
              break;
          }

        });
      } catch (e: any) {
        // else, init inpainting using boxes (fallback)
        console.warn('inpainting using segmentation failed: ', e);
      }
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  public initInpaintingEvents() {

    // Attach hover event to image container
    this.previewImage.nativeElement.addEventListener('mousedown', (e: any) => {

      if (e.button !== 0 && e.button !== 2) {
        return;
      } else
        if (!this.isEncoded) {
          console.warn('not encoded yet');
          return;
        }

      if (!this.isMultiMaskMode) {
        this.lastPoints = [];
        this.isMultiMaskMode = true;
        //this.cutButton.disabled = false;
      }

      const point = this.getPoint(e);
      console.log('> point', point);

      this.lastPoints.push(point);
      console.log('> lastPoints', this.lastPoints);

      // add icon
      this.addIcon(point);
      this.decode();
    });

    // Attach hover event to image container
    this.previewImage.nativeElement.addEventListener('mousemove', (e: any) => {

      if (!this.isEncoded || this.isMultiMaskMode) {
        // Ignore mousemove events if the image is not encoded yet,
        // or we are in multi-mask mode
        return;
      }

      this.lastPoints = [this.getPoint(e)];
      console.log('> lastPoints', this.lastPoints);

      if (!this.isDecoding) {
        this.decode(); // Only decode if we are not already decoding
      }
    });

  }

  public initPolotnoCanvasEditor() {
    try {

      if (!this.polotnoElement || !this.polotnoElement.nativeElement) {
        return false;
      }

      const { store } = createDemoApp({
        container: this.polotnoElement.nativeElement,
        key: 'QdxAQBfP8gF-3fbZEQpH',
        showCredit: false,
        //sections: [],
      } as any);

      this.store = store;

      store.on('change', (event: any) => {
        this.onUpdated.emit({
          event: event,
          provider: 'polotno',
          store: this.store,
        });
      });

      /*
      const page = store.addPage();
      console.log('page', page);

      page.addElement({
        x: 50,
        y: 50,
        type: 'text',
        fill: 'black',
        text: 'hello',
      });
      */

      // load back polotno data if provided
      if (!!this.config) {
        this.loadFromJson(this.config);

        if (this.action === 'export') {
          this.exportCanvas();
        }
      }

    } catch (e) {
      console.warn('canvas init failed', e);
    }
  }

  public initViewer() {
    try {

      if (!this.mediaItemsList || !this.mediaItemsList.nativeElement) {
        return false;
      }

      const minHeight: number = this.el.nativeElement.offsetHeight || 640;
      const minWidth: number = this.el.nativeElement.offsetWidth || 480;

      const viewerOptions: any = {
        filter(image: mediaItem) {
          return !!image; // && (image.post_mime_type === 'image');
        },
        fullscreen: false,
        initialViewIndex: this.index,
        inline: true,
        loading: this.loading,
        minHeight: minHeight,
        minWidth: minWidth,
        toolbar: {
          zoomIn: 4,
          zoomOut: 4,
          oneToOne: 0,
          reset: 0,
          prev: 4,
          play: {
            show: 0,
            size: 'large',
          },
          next: 4,
          rotateLeft: 4,
          rotateRight: 4,
          flipHorizontal: 0,
          flipVertical: 0,
        },
        ready(event: any | null = null) {
          // 2 methods are available here: "show" and "destroy".
          //console.log('ready: event', event);
        },
        show(event: any | null = null) {
          console.log('show: event', event);
        },
        shown(event: any | null = null) {
          // 9 methods are available here: "hide", "view", "prev", "next", "play", "stop", "full", "exit" and "destroy".
          console.log('shown: event', event);
        },
        view: (event: any | null = null) => {
          const index: number | null = event?.detail?.index;

          // update media item based on index
          if ((index !== null) && (index !== this.index) && !!this.mediaList && !!this.mediaList[index]) {
            this.setMedia(this.mediaList[index], index);
          }
        },
        viewed(event: any | null = null) {
          //const index: number | null = event?.detail?.index;
          //console.log('viewed: event', event);
          //this.viewer.zoomTo(0.9);
        },
      };
      
      this.viewer = new Viewer(this.mediaItemsList.nativeElement, viewerOptions);
    } catch (e) {
      console.warn('> init image editor failed: ', e);
    }
  }

  async loadFromJson(json: any) {
    console.log('image-editor: loadFromJson: json (a)', json);

    if (!!json && typeof json === 'object' && !!json[0]) {
      delete json[0].id;
      await this.getStore().activePage.set(json[0]);
    } else {

      if (typeof json === 'string') {
        json = JSON.parse(json);
      }

      console.log('image-editor: loadFromJson: json (b)', json);

      if (!!json) {
        await this.getStore().loadJSON(json);
      }
    }

    return this.getStore();
  }

  async loadImageMetaData() {
    try {
      console.log('loadImageMetaData: media', this.media);

      if (!this.media || !this.media.post_mime_type || (this.media.post_mime_type !== 'image')) {
        return false;
      }

      if (!this.previewImage || !this.previewImage.nativeElement) {
        return false;
      }

      /*
      if (!!this.previewImage.nativeElement.src && (this.previewImage.nativeElement.src.indexOf(this.apiUrl) === -1)) {
        this.previewImage.nativeElement.src = this.proxyUrl + this.previewImage.nativeElement.src;
      }
      */

      const exifData: any = await this.images.getExif(this.previewImage.nativeElement as HTMLImageElement);
      console.log('image-editor: exifData', exifData);
    } catch (e) {
      console.warn('loading image exif failed', e);
    }
  }

  ngAfterViewInit() {
    this.calculateGallery();

    switch (this.media.mode) {
      case 'inpaint':
        break;
      case 'outpaint':
        break;
      case 'polotno':
        setTimeout(() => {
          this.initPolotnoCanvasEditor();
        }, 250);
        break;
      default:
        setTimeout(() => {
          this.initViewer();
        }, 1000);
        break;
    }
  }

  ngOnInit() {

    if (!!this.mode) {
      this.media = (this.media || {}) as any;
      this.media.mode = this.mode as any;
    }

    // can be segmentation or inpaint
    this.view.targetSelectMode = this.view.targetSelectMode || 'segmentation';
  }

  onLoadImage() {
    this.loadImageMetaData();
  }

  public segment(data: any) {
    this.isEncoded = false;

    if (!this.modelReady) {
      this.events.publish('toast', {
        icon: 'hourglass-outline',
        message: 'ai_loader_init_step_0',
      });
    }

    this.media.live_preview = data;
    //this.cutButton.disabled = true;

    // Instruct worker to segment the image
    this.aiWorker.postMessage({ type: 'segment', data });
  }

  public setMedia(mediaItem: mediaItem, index: number | null = null) {
    this.media = mediaItem;

    if (index !== null) {
      this.index = index;
    }

    this.onChanged.emit({
      index: index,
      media: this.media,
    });
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}