import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';


import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { StablediffusionService } from 'src/app/services/media/stablediffusion.service';

@Component({
  selector: 'pipeline-ai-upscaler-card',
  standalone: false,
  templateUrl: './ai-upscaler-card.component.html',
  styleUrls: ['./ai-upscaler-card.component.scss']
})
export class AiUpscalerCardComponent implements AfterViewInit, OnDestroy, OnInit {

  aiSettings: aiSettings = {};

  @Input() autostart: boolean = false;
  @Input() input: string = '';
  @Input() options: aiUpscalerOptions = {};

  disabled: boolean = false;

  items: mediaItem[] = [];

  fallbackImg: string = './assets/img/fallback.webp';

  view: any = {
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private sd: StablediffusionService,
    private viewService: ViewService,
  ) {

  }

  public add() {
    return this.uploadAttachment('photo');
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  chooseMediaFromList(media: mediaItem) {

  }

  generate() {
    this.view.upscaling = true;

    if (!this.items || !this.items.length) {
      return false;
    }

    const iAll: number = this.items.length;
    let iDone: number = 0;

    this.items.forEach(async (item: any, index: number) => {

      if (!this.items[iDone]) {
        this.items[iDone] = { state: 'loading' };
      }

      this.items[iDone].state = 'loading';
      delete this.items[iDone].url;

      try {
        const response: any = await this.sd.upscale(item.guid || item.thumbnail);
        console.log('upscale response', index, response);

        if (!!response && !!response.images && !!response.images[0]) {
          this.items[iDone] = {
            disabled: false,
            guid: response.images[0],
            photo: response.images[0],
            state: 'done',
            thumbnail: response.images[0],
            type: 'image',
            url: response.images[0],
          };
        }

        iDone++;
        this.view.generatingImageToVideo = (iAll > iDone);

      } catch (e) {
        this.events.publish('error', e);
        iDone++;
        this.view.generatingImageToVideo = (iAll > iDone);
      }
    });
  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
      this.generate();
    }
  }

  removeMediaFromList(media: mediaItem, index: number | null = null) {

    this.view.mediaList = this.view.mediaList.filter((_mediaItem: mediaItem, _index: number) => {

      if (index !== null) {
        return index !== _index;
      }

      return (_mediaItem.ID !== media.ID) && (_mediaItem.thumbnail !== media.thumbnail);
    });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  uploadAttachment(key: string) {
    const blIsBackground: boolean = (key === 'bg_src' || key === 'photo');

    let params: any = {};
    params.mediaType = 'asset';
    params.multiple = true;
    params.services = ['media_library', 'database', 'upload'];

    this.media.applyFromWeb(null, params)
      .then((response: any) => {

        if (!!response && (typeof response === 'object') && !!response.items) {
          if (!!blIsBackground) {
            this.items = (this.items || []).concat(response.items || []).map((item: mediaItem) => {
              item.guid = item.guid || item.url;
              delete item.url;
              return item;
            });
          }
        }

        this.generate();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}
