import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';
import { PostsService } from 'src/app/services/posts/posts.service';

@Component({
  selector: 'app-order-by-modal',
  standalone: false,
  templateUrl: './order-by-modal.page.html',
  styleUrls: ['./order-by-modal.page.scss'],
})
export class OrderByModalPage implements OnInit {

  state: state = {};
  
  view: any = {
    orderBy: 'auto',
    options: [
      {
        indent: 'auto',
        disabled: false,
      },
      {
        indent: 'interests',
        disabled: false,
      },
      {
        indent: 'timestamp',
        disabled: false,
      },
      {
        indent: 'trending',
        disabled: true,
      }
    ],
    title: 'order_by',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private posts: PostsService,
    private viewService: ViewService,
  ) {

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
    
    this.view.orderBy = this.posts.orderBy();
  }

  onChanged() {
    this.posts.orderBy(this.view.orderBy);
    this.dismiss(this.view);
  }

}
