<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add -->
      <ion-button (click)="add()" color="primary" shape="round" fill="solid">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header-->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar *ngIf="!view.rebuild && !!view.crawlers && !!view.crawlers.length"
    [sizeFactor]="view.isDesktop ? 4 : 1" [(items)]="view.crawlers" [(view)]="view" [options]="selectionOptions"
    [(paginationConfig)]="paginationConfig"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>


  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importCrawlers()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.crawlers && !!view.crawlers.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>

      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!!view.project && !!view.project.uid">

    <ion-grid class="mail-crawlers-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Filters -->
          <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
            (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Intro card -->
          <div class="ion-padding" [hidden]="view.introCard.hidden">
            <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
          </div>

          <!-- No entries -->
          <pipeline-no-entries-card [cta]="cta" [hidden]="!!view.loading" [text]="'mail_admin_cta_text'|translate"
            *ngIf="view.crawlers && !view.crawlers.length">
          </pipeline-no-entries-card>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loadingCrawlers"></ion-spinner>

          <!-- Dynamic view -->
          <pipeline-dynamic-view *ngIf="!!view.crawlers && !!view.crawlers.length" [(items)]="view.crawlers"
            [buttons]="buttons" [(view)]="view" [method]="'getCrawlers'" [options]="options"
            [selectionOptions]="selectionOptions" [service]="mail"></pipeline-dynamic-view>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>