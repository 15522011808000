<ion-card [class]="'introCard ' + (view.cssClass || '')" [hidden]="view.hidden === true">

  <!-- Close intro card button -->
  <ion-button *ngIf="view.dismissable !== false" (click)="dismiss()" color="dark" icon-only class="close-btn"
    fill="clear" size="small">
    <ion-icon name="close-outline" slot="start"></ion-icon>
  </ion-button>

  <!-- Multiple intro cards -->
  <div class="swiper-stage" *ngIf="!!config && !!config.groups && !!config.groups.length" [hidden]="!!view.loading">

    <!-- Swiper container -->
    <swiper-container #introCardSwiper id="introCardSwiper" [options]="sliderOptions" [pagination]="true"
      class="step-{{ view.step }}" (slidechangetransitionend)="slidechangetransitionend($event)"
      (slidechangetransitionstart)="slidechangetransitionstart($event)">

      <!-- Single group -->
      <swiper-slide *ngFor="let group of config.groups; let iGroup = index;">

        <!-- Single group header -->
        <ion-card-header *ngIf="!!group.headline || !!group.description">
          <ion-card-title *ngIf="!!group.headline" [innerHTML]="group.headline|translate"></ion-card-title>
          <p *ngIf="!!group.description" [innerHTML]="group.description|translate"></p>
        </ion-card-header>

        <!-- Full loading (if ai generate is disabled) -->
        <ion-spinner [hidden]="!group.loading || (activeIndex !== iGroup)"></ion-spinner>

        <!-- Buttons -->
        <div class="buttons-wrapper" [hidden]="!!group.loading || (activeIndex !== iGroup)"
          *ngIf="!!group && !!group.buttons && !!group.buttons.length">

          <ion-grid class="types-grid">
            <ion-row>

              <ion-col size="6" *ngFor="let button of group.buttons; let iButton = index; trackBy:trackItems">

                <ion-button class="types-btn" [class.active]="button.checked" size="block"
                  [disabled]="!!group.loading || !!button.loading || !!view.loading || !!view.generating"
                  [fill]="button.checked ? 'solid' : 'outline'" color="primary"
                  (click)="toggleButton(button, iButton, iGroup)">
                  <ion-icon [name]="button.icon"></ion-icon>
                  <ion-label [innerHTML]="button.name|translate"></ion-label>
                </ion-button>

              </ion-col>

            </ion-row>
          </ion-grid>

        </div>

        <!-- Components -->
        <div class="components-wrapper" [hidden]="!!group.loading || (activeIndex !== iGroup)"
          *ngIf="!!group && !!group.components && !!group.components.length">

          <!-- Iterate components -->
          <div *ngFor="let componentName of group.components; let iComponent = index;">

            <!-- Media Picker -->
            <pipeline-media-picker-card *ngIf="componentName === 'MediaPicker'" #mediaPickerComponent [(cards)]="cards"
              [(item)]="media" [items]="mediaList" [options]="mediaPickerOptions" [view]="view"
              (onMediaChanged)="_onMediaChanged($event)"></pipeline-media-picker-card>

          </div>

        </div>

        <!-- Inputs -->
        <form class="inputs-wrapper" [hidden]="!!group.loading"
          *ngIf="!!group && !!group.inputs && !!group.inputs.length && (activeIndex === iGroup)">

          <!-- Single input -->
          <ion-card *ngFor="let input of group.inputs; let iInput = index; trackBy:trackItems">
            <ion-item lines="none">

              <!-- Input icon -->
              <ion-icon *ngIf="!!input.icon" [name]="input.icon" slot="start"></ion-icon>

              <!-- Input Type: Checkbox -->
              <ion-checkbox *ngIf="input.type === 'checkbox'" [(ngModel)]="input.value"
                [disabled]="input.loading || !!input.disabled" [name]="'checkbox' + (input.uid || iInput)"
                [placeholder]="input.placeholder|translate"></ion-checkbox>

              <!-- Input Type: Date -->
              <ion-label *ngIf="input.type === 'date'" [innerHTML]="(input.label || input.name)|translate"></ion-label>

              <ion-datetime-button *ngIf="input.type === 'date'" datetime="datePicker" [disabled]="input.disabled"
                slot="end"></ion-datetime-button>

              <!-- Input Type: Email, Number, Password, Text, URL -->
              <ion-input [name]="'input' + (input.uid || iInput)"
                *ngIf="input.type === 'email' || input.type === 'number' || input.type === 'password' || input.type === 'text' || input.type === 'url'"
                [type]="input.type" [(ngModel)]="input.value" [disabled]="input.loading || !!input.disabled"
                [label]="(input.label || input.name)|translate" labelPlacement="stacked"
                [placeholder]="(input.placeholder || input.label)|translate">
              </ion-input>

              <!-- Input Type: Select -->
              <ion-select *ngIf="input.type === 'select' && !!input.values" [(ngModel)]="input.value"
                justify="space-between" alignment="end" labelPlacement="start" [okText]="'okay'|translate"
                [cancelText]="'cancel'|translate" interface="popover" [multiple]="!!input.multiple"
                [name]="'select' + (input.uid || iInput)"
                [disabled]="input.loading || !!input.disabled || (!input.values || !input.values.length)"
                [label]="input.label|translate" [placeholder]="(input.placeholder || 'select')|translate">
                <ion-select-option *ngFor="let option of input.values" [innerHTML]="option.name|translate"
                  [value]="option.uid"></ion-select-option>
              </ion-select>

              <!-- Input Type: Textarea -->
              <ion-textarea *ngIf="input.type === 'textarea'" [name]="'textarea' + (input.uid || iInput)"
                [rows]="input.rows || 5" [(ngModel)]="input.value" [disabled]="input.loading || !!input.disabled"
                [placeholder]="input.placeholder|translate"></ion-textarea>

            </ion-item>
          </ion-card>

        </form>

      </swiper-slide>

    </swiper-container>

    <!-- Swiper buttons -->
    <ion-grid [hidden]="!!view.loading || !config.showSliderButtons">
      <ion-row>

        <ion-col size="6">

          <!-- Slide back -->
          <ion-button [disabled]="!view.canSlideBack" color="dark" expand="block" fill="clear" (click)="slideBack()">
            <ion-icon name="arrow-back-outline" slot="start"></ion-icon>
            <ion-label [innerHTML]="'back'|translate"></ion-label>
          </ion-button>

        </ion-col>

        <ion-col size="6">

          <!-- Slide next -->
          <ion-button [hidden]="!!view.canSlideDone" shape="round" [disabled]="!view.canSlideNext" color="primary"
            expand="block" (click)="slideNext()">
            <ion-label [innerHTML]="'next'|translate"></ion-label>
            <ion-icon name="arrow-forward" slot="end"></ion-icon>
          </ion-button>

          <!-- Done -->
          <!--
              <ion-button [hidden]="!view.canSlideDone" shape="round" color="primary" expand="block"
                (click)="slideDone()">
                <ion-label [innerHTML]="'next'|translate"></ion-label>
                <ion-icon name="arrow-forward" slot="end"></ion-icon>
              </ion-button>
              -->

        </ion-col>

      </ion-row>
    </ion-grid>
  </div>

  <!-- Single (main) intro card header -->
  <ion-grid *ngIf="!config || !config.groups || !config.groups.length">
    <ion-row>
      <ion-col>

        <ion-card-header *ngIf="!!view.title">
          <ion-card-title [innerHTML]="view.title|translate|fillVars"></ion-card-title>
          <!--<ion-card-subtitle *ngIf="!!view.subtitle" [innerHTML]="view.subtitle|translate|fillVars"></ion-card-subtitle>-->
        </ion-card-header>

        <!-- [class.has-lottie]="!!lottieOptions.path" -->
        <ion-card-content [hidden]="!view.isDesktop" *ngIf="!!view.text">
          <p [innerHTML]="view.text|translate|fillVars">
          </p>
        </ion-card-content>

      </ion-col>

      <!-- !!lottieOptions && !!lottieOptions.path -->
      <ion-col *ngIf="!!splineOptions && !!splineOptions.path" class="lottieCol animationCol visualCol">

        <pipeline-spline-viewer *ngIf="!!view.splineSrc && (!!appConfig.showGetGeniusDaniInSidebar || !view.isDesktop)"
          [class]="view.animationClass" [options]="splineOptions"></pipeline-spline-viewer>

        <!--
        <ng-lottie
          *ngIf="!!view.lottieSrc"
          width="100%"
          height="100%"
          containerClass="intro-card-lottie-wrapper"
          [options]="lottieOptions"
          (animationCreated)="animationCreated($event)"
          (configReady)="configReady()"
          (dataReady)="dataReady()"
          (domLoaded)="domLoaded()"
          (destroy)="destroy($event)"
          (error)="error($event)">
        </ng-lottie>
        -->

      </ion-col>

    </ion-row>
  </ion-grid>

  <!-- Single (main intro card text )-->
  <ion-card-content [hidden]="!!view.isDesktop"
    *ngIf="(!config || !config.groups || !config.groups.length) && !!(view.text|translate|fillVars)">
    <p *ngIf="!!view.text" [innerHTML]="view.text|translate|fillVars"></p>
  </ion-card-content>

</ion-card>