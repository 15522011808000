<ion-popover #itemInfoPopover [isOpen]="open" (didDismiss)="open = false">
    <ng-template>
        <ion-content>

            <ion-list *ngIf="!!item && !!item.info">

                <!-- Item search query -->
                <ion-item [hidden]="!item.search_query">
                    <ion-icon name="text-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'input'|translate"></h3>
                        <p [innerHTML]="item.search_query"></p>
                    </ion-label>

                </ion-item>

                <!-- Item negative_prompt -->
                <ion-item [hidden]="!item.info.negative_prompt">
                    <ion-icon name="text-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'negative_prompt'|translate"></h3>
                        <p [innerHTML]="item.info.negative_prompt"></p>
                    </ion-label>

                </ion-item>

                <!-- Model name -->
                <ion-item [hidden]="!item.info.sd_model_name">
                    <ion-icon name="extension-puzzle-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'sd_model_name'|translate"></h3>
                        <p [innerHTML]="item.info.sd_model_name"></p>
                    </ion-label>

                </ion-item>

                <!-- Seed -->
                <ion-item [hidden]="!item.info.seed">
                    <ion-icon name="finger-print-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'seed'|translate"></h3>
                        <p [innerHTML]="item.info.seed"></p>
                    </ion-label>

                </ion-item>

                <!-- Sampler -->
                <ion-item [hidden]="!item.info.sampler_name">
                    <ion-icon name="layers-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'sampler_name'|translate"></h3>
                        <p [innerHTML]="item.info.sampler_name"></p>
                    </ion-label>

                </ion-item>

                <!-- CFG Scale -->
                <ion-item [hidden]="!item.info.cfg_scale">
                    <ion-icon name="flask-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'cfg_scale'|translate"></h3>
                        <p [innerHTML]="item.info.cfg_scale"></p>
                    </ion-label>

                </ion-item>

                <!-- Denoising -->
                <ion-item [hidden]="!item.info.denoising_strength">
                    <ion-icon name="flask-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'denoising_strength'|translate"></h3>
                        <p [innerHTML]="item.info.denoising_strength"></p>
                    </ion-label>

                </ion-item>

                <!-- Steps -->
                <ion-item [hidden]="!item.info.steps">
                    <ion-icon name="sync-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'steps'|translate"></h3>
                        <p [innerHTML]="item.info.steps"></p>
                    </ion-label>

                </ion-item>

                <!-- Instance -->
                <ion-item [hidden]="!item.instance && !item.info.instance">
                    <ion-icon name="server-outline" slot="start"></ion-icon>

                    <ion-label class="ion-text-wrap">
                        <h3 [innerHTML]="'instance'|translate"></h3>
                        <p [innerHTML]="item.instance || item.info.instance"></p>
                    </ion-label>

                </ion-item>

            </ion-list>

        </ion-content>
    </ng-template>
</ion-popover>