import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HashService {

  constructor(

  ) {

  }

  hashCode(_string: string | object) {

    if (typeof _string === 'object') {
      _string = JSON.stringify(_string);
    }

    var hash: number = 0, i: number, chr: number;
    if (_string.length === 0) return hash;

    // no data in string, return 0
    if (_string.length < 3) {
      return 0;
    }

    for (i = 0; i < _string.length; i++) {
      chr = _string.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }

    return hash;
  };

}
