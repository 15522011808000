import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { UserService } from 'src/app/services/core/user.service';
import { TemplateEditorService } from 'src/app/services/media/template-editor.service';
import { TemplatesService } from 'src/app/services/media/templates.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

@Component({
  selector: 'pipeline-media-items',
  standalone: false,
  templateUrl: './media-items.component.html',
  styleUrls: ['./media-items.component.scss'],
})
export class MediaItemsComponent implements OnDestroy, OnInit {
  @Input() view: any;

  @Output() selectedItemsChanged = new EventEmitter();

  @ViewChild('itemSettingsPopover') itemSettingsPopover;
  isItemSettingsPopoverOpen = false;

  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  fallbackImg: string = 'https://getgenius.ai/assets/img/fallback.webp';

  @Input() selectionOptions: selectionOption[];

  splineOptions: splineOptions = {};

  constructor(
    private editor: TemplateEditorService,
    private events: EventsService,
    private templates: TemplatesService,
    private tools: ToolsService,
    private userService: UserService,
    private zone: NgZone,
  ) {
    this.view = this.view || {};
  }

  calcSelectedItems(idea: aiIdea | null = null) {

    if (this.view.ideas && this.view.ideas.length) {
      this.view.selectedItems = this.view.ideas.filter((_idea: aiIdea) => {
        return !!_idea.checked || (!!idea && (_idea.uid === idea.uid && !!idea.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.selectedItemsChanged.emit(this.view.selectedItems || []);
  }

  calcViewVars() {
    this.view.isAdmin = this.userService.isType(['Admin', 'Moderator']);
    this.view.isDesktop = this.tools.isDesktop();
    this.view.mediaItemColSize = (this.view.isDesktop ? 4 : 12);
  }

  async editCustomTemplate(template: any) {

    if (!template.custom) {
      return false;
    }

    this.editor.editCustomTemplate(template);
  }

  disableTemplate(idea: any) {

    if (!idea.template || !idea.template.uid) {
      return false;
    }

    idea.template.public = false;

    this.templates.update(idea.template)
      .then(() => {
        idea.template.public = true;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  initEvents() {
    this.view.events = {};

    this.view.events.mediaTemplatesItemsUpdated = this.events.subscribe('media:templates:items:updated', (items: any[]) => {
      this.zone.run(() => {
        this.view.ideas = items;
        this.calcSelectedItems();
      });
    });

    this.events.subscribe('window:resized', () => {
      this.view.isDesktop = this.tools.isDesktop();
      this.view.mediaItemColSize = (this.view.isDesktop ? 4 : 12);
    });
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.initEvents();

    this.calcSelectedItems();
    this.calcViewVars();
  }

  onItemCheckboxClicked(idea: aiIdea) {
    this.zone.run(() => {
      this.calcSelectedItems(idea);
    });
  }

  onItemClicked(idea: aiIdea) {
    idea.checked = !idea.checked;

    this.zone.run(() => {
      this.calcSelectedItems(idea);
    });
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  onSelectionActionChanged(event: any | null = null) {
    console.log('media-items: onSelectionActionChanged', event);
  }

  onSelectedItemsChanged(event: any | null = null) {
    this.calcSelectedItems();
    this.calcViewVars();
  }

  playIdea(idea: any) {
    this.events.publish('media:template:preview:run', {
      template: idea,
      view: this.view,
    });
  }

  presentPopover(e: Event, idea: any) {
    this.view.idea = idea;
    this.itemSettingsPopover.event = e;
    this.isItemSettingsPopoverOpen = true;
  }

  public runSearch() {
    try {

      if (!this.searchToolbar) {
        return false;
      }

      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  stopIdea(idea: any) {
    this.events.publish('media:template:preview:stop', {
      template: idea,
      view: this.view,
    });
  }

  thumbnailLoadingFailed(idea: any) {
    idea.url = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}