import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { TableViewComponent } from './table-view.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { TranslateModule } from '@ngx-translate/core';

import { NoEntriesCardComponentModule } from 'src/app/components/generic/no-entries-card/no-entries-card.component.module';
import { PaginationComponentModule } from 'src/app/components/generic/pagination/pagination.component.module';
import { SelectionOptionsPickerComponentModule } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component.module';

@NgModule({
  declarations: [
    TableViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ScrollingModule,
    TranslateModule,
    NoEntriesCardComponentModule,
    PaginationComponentModule,
    SelectionOptionsPickerComponentModule,
  ],
  exports: [
    TableViewComponent,
  ]
})
export class TableViewComponentModule { }