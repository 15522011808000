import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { ConfigService } from "src/app/services/core/config.service";
import { DataService } from 'src/app/services/core/data.service';
import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { ParamsService } from 'src/app/services/core/params.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ShopService } from 'src/app/services/ecommerce/shop.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { PaginationService } from 'src/app/services/utils/pagination.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { SelectionOptionsPickerComponent } from 'src/app/components/generic/selection/selection-options-picker/selection-options-picker.component';

import { CreateMediaPage } from 'src/app/pages/core/media/media/create-media/create-media.page';
import { ConnectShopPage } from 'src/app/pages/extensions/shop/connect-shop/connect-shop.page';
import { ShopProductPage } from '../shop-product/shop-product.page';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-shop-products',
  standalone: false,
  templateUrl: './shop-products.page.html',
  styleUrls: ['./shop-products.page.scss'],
})
export class ShopProductsPage implements OnInit {
  @ViewChild('headerPopover') headerPopover;
  isHeaderPopoverOpen: boolean = false;

  @ViewChild('optimizeProductsDescriptionModal') optimizeProductsDescriptionModal: any;

  @ViewChild('shareProductPopover') shareProductPopover: any;

  aiSettings: aiSettings = {
    context: 'text_generation',
    use_proxy: true,
  };

  appConfig: pipelineAppConfig;

  cards: any = {};

  cta: any = {
    handler: () => {
      this.connect();
    },
    icon: 'link-outline',
    label: 'connect',
  };

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];

  isOptimizeProductsDescriptionModalOpen: boolean = false;

  isShareProductPopoverOpen: boolean = false;

  paginationConfig: paginationConfig = {
    itemsKey: 'products',
    limit: 1000,
  };

  search: searchOptions = {
    itemsKey: 'products',
    keys: ['title', 'description', 'image', 'sku', 'url'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      icon: 'images-outline',
      label: 'send_to_media_creator',
      uid: 'send_to_media_creator',
    },
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
    {
      icon: 'folder-outline',
      label: 'move_folder',
      uid: 'move_folder',
    },
    {
      icon: 'briefcase-outline',
      label: 'move_project',
      uid: 'move_project',
    },
    /*
    {
      icon: 'hardware-chip-outline',
      label: 'add_to_training',
      uid: 'add_to_training',
    },
    */
    {
      icon: 'sparkles-outline',
      label: 'optimize',
      uid: 'optimize',
    },
    {
      color: 'warning',
      icon: 'warning-outline',
      label: 'remove_optimizations',
      uid: 'remove_optimizations',
    },
    {
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  @ViewChild(SelectionOptionsPickerComponent) selectionOptionsPicker: any;

  state: state = {};

  subs = new Subscription();

  view: any = {
    autoAddOnEmpty: true,
    canDelete: true,
    category: {},
    filters: [
      {
        icon: 'eye-outline',
        name: 'active',
        type: 'select',
        uid: 'active',
        value: [0, 1],
        values: [0, 1]
      },
      {
        icon: 'arrow-back-outline',
        name: 'start_date',
        type: 'date',
        uid: 'start_date',
      },
      {
        icon: 'arrow-forward-outline',
        name: 'end_date',
        type: 'date',
        uid: 'end_date',
      },
      {
        dualKnobs: true,
        icon: 'cash-outline',
        max: 9999, // adjust this by highest price
        min: 0,
        name: 'price',
        type: 'range',
        value: {
          lower: 0,
          upper: 9999, // adjust this by highest price
        },
        uid: 'price',
      }
    ],
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    introCard: {
      main: {
        uid: 'shop_products_top_card',
        subtitle: 'shop_products_top_card_subtitle',
        text: 'shop_products_top_card_text',
        title: 'shop_products_top_card_title',
      },
      optimize: {
        uid: 'shop_products_optimize_top_card',
        subtitle: 'shop_products_optimize_top_card_subtitle',
        text: 'shop_products_optimize_top_card_text',
        title: 'shop_products_optimize_top_card_title',
      }
    },
    itemSize: 74,
    limit: -1, // 1000, // disabled for pagination
    list: {
      buttons: [

      ],
      options: {
        labelKey: 'name',
        photoKey: 'image',
        showPhoto: true,
        subLabelKey: 'sku',
      },
    },
    multiple: true,
    offset: 0,
    options: {},
    progress: 0,
    showMenuButton: true,
    showLanguagesSelect: false,
    showPagination: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'shop_products',
    viewType: 'list',
  };

  constructor(
    private configService: ConfigService,
    private creators: CreatorsService,
    private dataService: DataService,
    private events: EventsService,
    private exportService: ExportService,
    private folders: FoldersService,
    private importService: ImporterService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private pagination: PaginationService,
    private paramsService: ParamsService,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    public shop: ShopService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    this.view.category.uid = this.route.snapshot.paramMap.get('categoryId');
  }

  async add() {

    const modal: any = await this.modalService.create({
      component: ShopProductPage,
      componentProps: {
        product: {},
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  addSelectedToTraining() {
    console.log('addSelectedToTraining: ', this.view.selectedItems);

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcSelectedItems(product: any | null = null) {

    if (this.view.products && this.view.products.length) {
      this.view.selectedItems = this.view.products.filter((_product: any) => {
        return !!_product.checked || (!!product && (_product.uid === product.uid && !!product.checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.colSize = {
      left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
      item: (!!this.view.isDesktop ? (!!this.view.expertMode ? 4 : 3) : 6),
      right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
    };
  }

  async connect() {

    const shopModal: any = await this.modalService.create({
      component: ConnectShopPage,
      animated: true,
      cssClass: 'defaultModal',
    });

    shopModal.onWillDismiss().then(() => {
      this.view.autoAddOnEmpty = false;

      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(shopModal);
  }

  delete(product: product) {
    this.shop.deleteProduct(product.uid)
      .then(() => {

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.tools.bulk({
      action: 'deleteProduct',
      identifier: 'uid',
      items: this.view.selectedItems,
      service: this.shop,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh(event: any | null = null) {
    this.loadShops()
      .then(() => {

        this.load(true)
          .then(() => {
            if (!!event) {
              event.target.complete();
            }
          })
          .catch((error: any) => {
            this.view.loading = false;
            this.events.publish('error', error);

            if (!!event) {
              event.target.complete();
            }

          });
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  async edit(product: product, index: number = 0) {

    const modal: any = await this.modalService.create({
      component: ShopProductPage,
      componentProps: {
        index: index,
        product: product,
        products: (!!this.view.products ? this.view.products : []),
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      //this.doRefresh();
    });

    this.modalService.present(modal);
  }

  editSelected(event: any | null = null) {
    this.dataService.editUsingUI({
      data: this.view.selectedItems,
      service: this.shop,
      source: 'pipeline',
      type: 'product',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  async export(event: any | null = null, options: any = {}) {
    this.isHeaderPopoverOpen = false;

    const selectedItems: product[] = (
      !!this.paginationConfig && !!this.paginationConfig.backup ? this.paginationConfig.backup : (this.view.products || [])
    );

    this.exportService.exportUsingUI({
      data: (selectedItems || []),
      service: this.shop,
      source: 'shop',
      source_tool: 'shop_products',
      source_type: 'tool',
      type: 'product',
    })
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });
  }

  exportSelected() {

  }

  importProducts(event: any | null = null) {
    this.isHeaderPopoverOpen = false;

    const params: dataImportOptions = {
      service: this.shop,
      target_tool: 'shop_products',
      target_type: 'tool',
    };

    this.importService.importUsingUI(params)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        if (!!event) {
          event.target.complete();
        }
      });

  }

  importSelected() {

  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);

      this.view.colSize = {
        left: this.view.sidebarSize || (!!this.view.isDesktop ? 3 : 12),
        item: (this.view.viewType === 'grid' ? (this.view.isDesktop ? (this.view.expertMode && !this.view.isUltraWide ? 4 : this.view.isUltraWide ? 2 : 3) : 6) : 12),
        right: (!!this.view.isUltraWide ? 10 : (window.innerWidth > 768 ? 9 : 12)),
      };
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  load(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        reject('error_missing_project_uid');
      } else
        if (!this.view.category || !this.view.category.uid) {
          this.view.loading = true;

          const filters: any = (!!this.view.options && !!this.view.options.filters ? this.view.options.filters : {});

          this.shop.getProducts(
            filters,
            blForceRefresh,
            {
              limit: (this.view.limit || 100),
              project_uid: this.view.project.uid,
            }
          )
            .then(async (response: appCmsResponse) => {
              let products: product[] = [];

              if (!!response && !!response.data) {
                products = (response.data || []) as product[];
              }

              this.view.products_backup = (products || []);

              this.paginationConfig = this.paginationConfig || {};
              this.paginationConfig.backup = this.view.products_backup;

              const calc: any = await this.pagination.calculateConfig(
                Object.assign(this.view, {
                  products: this.view.products_backup,
                }),
                this.paginationConfig
              );

              if (!!calc && !!calc.view) {
                this.view = Object.assign(this.view, calc.view);
              }

              setTimeout(() => {
                this.calcViewVars();

                //this.loadCategories();
                this.loadFolders(blForceRefresh);

                this.view.loading = false;
              });

              resolve(this.view);
            })
            .catch((error: any) => {
              this.view.loading = false;
              reject(error);
            });
        } else {
          this.view.loading = true;

          this.shop.getCategoryByUid(this.view.category.uid)
            .then((category: category) => {
              this.view.category = category;
              this.view.title = category.name;

              this.shop.getProductsByCategory(this.view.category.uid, blForceRefresh, {
                limit: this.view.limit,
              })
                .then((products: product[]) => {
                  this.view.loading = false;

                  if (this.view && !!this.view.categories) {
                    this.view.parentCategories = this.view.categories;
                    delete this.view.categories;
                  }

                  this.view.products = this.shop.getFullProducts(products);
                  this.calcViewVars();

                  resolve(this.view);
                })
                .catch(reject);
            })
            .catch(reject);
        }
    });
  }

  loadAttributes(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!this.view.project || !this.view.project.uid) {
        return false;
      } else {
        /*
        this.shop.getAttributes(this.view.options, blForceRefresh, { limit: 1000 })
          .then((attributes: attribute[]) => {

            this.view.attributes = (attributes || [])
              .map((attribute: attribute) => {
                attribute.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === attribute.uid));
                return attribute;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;

                return 0;
              });

            resolve(this.view.attributes);
          })
          .catch(reject);
          */
      }
    });
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadCategories(blForceRefresh: boolean = false) {
    try {

      const categories: category[] = (await this.shop.getCategories(blForceRefresh, {
        project_uid: this.view.project.uid,
      }) as category[]);

      console.log('categories', categories);
      this.view.categories = this.shop.parseCategories(categories || []);

      this.calcViewVars();
    } catch (e) {
      console.warn('loading categories failed', e);
    }
  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        this.shop.getFolders(this.view.options, blForceRefresh, { limit: 1000 })
          .then((folders: folder[]) => {
            this.view.loadingFolders = false;

            this.view.folders = (folders || [])
              .map((folder: folder) => {
                folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                return folder;
              })
              .sort((a: any, b: any) => {

                const _a: string = `${a.title}`.toLowerCase(),
                  _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;

                return 0;
              });

            resolve(folders);
          })
          .catch((error: any) => {
            this.view.loadingFolders = false;
            reject(error);
          });
      }

    });
  }

  loadNext(event: any | null = null) {
    return new Promise((resolve, reject) => {
      resolve({});
    });
  }

  loadProductsByFolder(event: any | null = null) {
    if (!event) {
      this.doRefresh();
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  loadShops(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.sources = [];
        resolve(this.view);
      } else {
        this.view.loadingConnections = true;

        this.creators.getShops(blForceRefresh)
          .then((shops: shopSource[]) => {

            this.view.connections = (shops || []).map((connection: integrationConnection) => {
              connection.checked = true;
              return connection;
            });

            this.view.sources = JSON.parse(JSON.stringify(this.view.connections));
            this.view.loadingConnections = false;

            // if no connections exist, show connect screen
            if (!this.view.sources || !this.view.sources.length && !!this.view.autoAddOnEmpty) {
              this.view.autoAddOnEmpty = false;
              this.connect();
            }

            resolve(shops);
          })
          .catch((error: any) => {
            this.view.loadingConnections = false;
            this.events.publish('error', error);
          });
      }
    });
  }

  moveFolder(item: any) {
    this.folders.moveFolder([item], 'product')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedFolders() {
    this.folders.moveFolder(this.view.selectedItems, 'product')
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  moveSelectedProject() {
    //this.projects.moveItemToProject()
  }

  ngOnInit() {
    this.view.input_prompt = this.shop.getDefaultProductOptimizationPrompt();
    this.view.loading = true;
    this.view.title = 'shop_products';

    this.calcViewVars();
    this.loadCards();

    this.loadShops()
      .then(() => {
        this.load()
          .then(() => {
            this.view.loading = false;
          })
          .catch((error: any) => {
            this.view.loading = false;
            this.events.publish('error', error);
          });
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  onAttributeFiltersChanged(event: any | null = null) {
    console.log('onAttributeFiltersChanged: event', event);
    console.log('onAttributeFiltersChanged: this.view.filters', JSON.parse(JSON.stringify(this.view.filters)));

    this.doRefresh();
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);

    this.doRefresh();
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
  }

  onFolderLoaded(items: any[] | null = null) {
    this.view.rebuild = true;
    this.view.loading = true;

    this.view.products = JSON.parse(JSON.stringify((items || [])));

    this.paginationConfig = this.paginationConfig || {};
    this.paginationConfig.backup = this.view.products;

    setTimeout(() => {
      //this.runSearch();
      this.view.rebuild = false;
      this.view.loading = false;
    }, 100);
  }

  onItemCheckboxClicked(product: any) {
    this.calcSelectedItems(event);
  }

  onMouseMove(e: any) {
    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);
  }

  onSearchChanged(searchOptions: any | null = null) {
    //console.log('onSearchChanged: searchOptions', searchOptions);
  }

  async onSelectedTagChanged(event: any | null = null) {
    this.view.tag = event;
    //await this.runSearch();
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'add_to_training':
        this.addSelectedToTraining();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'edit':
        this.editSelected();
        break;
      case 'export':
        this.exportSelected();
        break;
      case 'import':
        this.importSelected();
        break;
      case 'move_folder':
        this.moveSelectedFolders();
        break;
      case 'move_project':
        this.moveSelectedProject();
        break;
      case 'optimize':
        this.optimizeSelected();
        break;
      case 'send_to_media_creator':
        this.sendSelectedToMediaCreator();
        break;
      default:
        console.warn('not implemented', event);
        break;
    }

  }

  async optimize(product: product, key: string, options: any) {

    if (!product || !product.uid) {
      return false;
    }

    const exec: any = await this.shop.optimizeProductDescription(product.uid, key, options);

    return exec;
  }

  optimizeSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.optimizeField = 'description';
    this.view.optimizeFields = ['description', 'name', 'price', 'url']; // Object.keys(this.view.selectedItems[0]);

    this.isOptimizeProductsDescriptionModalOpen = true;
  }

  presentPopover(e: Event, item: any) {
    this.selectionOptionsPicker.show({
      event: e,
      item: item,
    });
  }

  async runItemSelectionOption(event: any) {
    try {

      if (!event || !event.option || !event.option.uid) {
        return false;
      }

      if (typeof this[event.option.uid] === 'function') {
        const exec: any = await this[event.option.uid](event.item);
      }

      this.selectionOptionsPicker.close();
    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  runOptimizeSelectedItems(key: string = 'description') {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    this.view.title = 'optimizing';

    const input_prompt: string = this.tools.nl2br(`${this.view.input_prompt || ''}`);

    this.view.iDone = 0;
    this.view.loading = true;

    this.view.selectedItems.forEach(async (product: any, index: number) => {
      setTimeout(async () => {
        this.view.selectedItems[index].disabled = true;
        this.view.selectedItems[index].loading = true;

        try {

          await this.optimize(product, key, {
            force_rebuild_images: !!this.view.force_rebuild_images,
            force_rebuild_texts: !!this.view.force_rebuild_texts,
            input_prompt: input_prompt,
          });

          this.view.iDone++;
        } catch (e) {
          console.warn('optimize product failed', e);
        }

        this.view.selectedItems[index].disabled = false;
        this.view.selectedItems[index].loading = false;

        this.view.progress = parseInt(`${(100 / this.view.selectedItems.length) * this.view.iDone}`);
        this.view.loading = 100 > this.view.progress;
      }, (index * 50));
    });
  }

  sendSelectedToMediaCreator() {

    if (!this.view.selectedItems || !this.view.selectedItems[0]) {
      return false;
    }

    // todo: create media for each item
    this.view.selectedItems.forEach((item: product) => {
      this.send_to_media_creator(item, [{
        guid: item.image,
        thumbnail: item.image,
        type: 'image',
      }]);
    });

    // sends all to one at the moment
    //this.send_to_media_creator(this.view.selectedItems[0], this.view.selectedItems);
  }

  async send_to_media_creator(product: product, mediaList: mediaItem[] | null = null) {

    if (!product.uid) {
      return false;
    }

    const view: any = {
      media: {},
      mediaList: (mediaList || [product]).map((mediaItem: any) => {
        mediaItem.photo = (mediaItem.thumbnail || mediaItem.photo) || mediaItem.image;
        mediaItem.thumbnail = (mediaItem.thumbnail || mediaItem.photo) || mediaItem.image;
        mediaItem.guid = (mediaItem.thumbnail || mediaItem.photo) || mediaItem.image;

        return mediaItem;
      }),
      sections: [],
      types: [
        {
          checked: true,
          icon: 'image-outline',
          uid: 'image',
          name: 'image',
        },
        {
          checked: true,
          icon: 'film-outline',
          uid: 'video',
          name: 'video',
        }
      ],
    };

    if (!!product && !!product.name) {

      // add section
      view.sections.push({
        title: product.name,
      });

      view.media.title = product.name;
    }

    if (!!product && !!product.description) {

      // update section description if set
      if (!!view.sections[0]) {
        view.sections[0].subtext = product.description;
      }

      view.media.subtext = product.description;
    }

    // apply UI view data based on request
    this.paramsService.set('viewData_createMedia', {
      fire: false,
      search: {},
      view: view,
    });

    const modal: any = await this.modalService.create({
      component: CreateMediaPage,
      componentProps: {},
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal',
    });

    modal.onWillDismiss().then((data: any) => {
      console.warn('create_media from dani: dismissed', data);
    });

    this.modalService.present(modal);
  }

  share(product: product, event: any | null = null) {
    this.view.shareProduct = product;

    this.shareProductPopover.event = event;
    this.isShareProductPopoverOpen = true;
  }

  showHeaderPopover(event: any | null = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  thumbnailLoadingFailed(item: any) {
    item.image = this.fallbackImg;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  up() {
    this.navCtrl.navigateBack('/shop');
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

  viewProduct(product: product) {

  }

}