<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Import selected -->
      <ion-button (click)="downloadSelected()" [disabled]="!view.selectedItems || !view.selectedItems.length"
        fill="solid" shape="round" color="primary">
        <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'import'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!-- Selection toolbar -->
  <pipeline-selection-toolbar [(items)]="view.templates" [(view)]="view" [options]="selectionOptions"
    (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
    </div>

    <ion-grid class="templates-store-grid">
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="!!view.colSize && !!view.colSize.left ? view.colSize.left : 12" class="sidebar"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Filters -->
          <pipeline-items-filters-card [(cards)]="cards" [(view)]="view"
            (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode && !!view.colSize ? (view.colSize.right || 12) : 12" class="content"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- No templates -->
          <pipeline-no-entries-card [hidden]="!!view.loading"
            *ngIf="!view.loading && (!view.templates || !view.templates.length)"></pipeline-no-entries-card>

          <!-- Templates grid -->
          <ion-grid [hidden]="!!view.loading" *ngIf="!!view.templates && !!view.templates.length">
            <ion-row>

              <!-- Iterate templates -->
              <ion-col *ngFor="let template of view.templates; let i = index; trackBy:trackItems"
                [size]="view.isDesktop ? 3 : 12">
                <ion-card>

                  <!-- Template top item -->
                  <ion-item lines="none">

                    <ion-checkbox justify="start" labelPlacement="end" class="ion-text-wrap"
                      [(ngModel)]="template.checked" (ionChange)="onItemCheckboxClicked(template)"
                      [disabled]="template.disabled">
                      <ion-label [hidden]="!template.uid" class="excerpt-label ion-text-wrap">
                        <h3 [innerHTML]="template.name" class="ion-text-wrap"></h3>
                      </ion-label>
                    </ion-checkbox>

                  </ion-item>

                  <!-- Loading template -->
                  <ion-spinner [hidden]="!!template.uid"></ion-spinner>

                  <!-- Template Type: Image -->
                  <pipeline-media-template-preview *ngIf="!!template.custom && !!template.uid"
                    [(template)]="view.templates[i]" [(view)]="template.view"></pipeline-media-template-preview>

                  <!-- Template Type: Video -->
                  <pipeline-video-template-preview [options]="view.videoTemplateOptions"
                    *ngIf="!template.custom && !!template.uid"
                    [(template)]="view.templates[i]"></pipeline-video-template-preview>

                  <!-- Template bottom item -->
                  <ion-item lines="none">

                    <ion-skeleton-text [hidden]="!!template.uid"
                      style="float:left;width:60%;margin-right: 10px;"></ion-skeleton-text>
                    <ion-skeleton-text [hidden]="!!template.uid" style="float:left;width:30%;"></ion-skeleton-text>

                    <ion-label [hidden]="!template.uid" class="excerpt-label ion-text-wrap" (click)="use(template)">
                      <p
                        [innerHTML]="(!!template.coins ? (template.coins + ' ' + ('token'|translate)) : ('free'|translate))">
                      </p>
                    </ion-label>

                    <ion-button [disabled]="!template.uid" [hidden]="!!template.coins" slot="end" shape="round"
                      expand="block" color="primary" (click)="use(template)">
                      <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'load'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                    <ion-button [disabled]="!template.uid" [hidden]="!template.coins" slot="end" shape="round"
                      expand="block" color="primary" (click)="buy(template)">
                      <ion-icon name="card-outline" slot="start"></ion-icon>
                      <ion-label [innerHTML]="'buy'|translate" class="ion-text-wrap"></ion-label>
                    </ion-button>

                  </ion-item>

                </ion-card>
              </ion-col>

            </ion-row>
          </ion-grid>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>