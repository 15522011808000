import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'pipeline-no-entries-card',
  standalone: false,
  templateUrl: './no-entries-card.component.html',
  styleUrls: ['./no-entries-card.component.scss'],
})
export class NoEntriesCardComponent implements OnInit {

  @Input() cta: any = {};

  @Input() icon: string;

  @Input() subtitle: string;

  @Input() text: string;

  @Input() title: string;

  constructor(
    private navCtrl: NavController,
  ) {
  }

  ctaClick() {

    if (!this.cta || (!this.cta.handler && !this.cta.url)) {
      return false;
    }

    if (this.cta.hasOwnProperty('handler')) {
      this.cta.handler();
    } else {

      this.navCtrl.navigateForward(this.cta.url);

      /*
      
      if (!tool || !tool.url) {
        return false;
      }

      this.windowManager
      .open({
        route: tool.url,
        uid: tool.key,
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
      */
    }
  }

  ngOnInit() {
  }

}