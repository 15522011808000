<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="container ion-no-border">
    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>
  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar
    [placeholder]="'search_accounts'|translate" [(search)]="search" [(view)]="view" (onChange)="runSearch($event)"></pipeline-header-search-toolbar>

</ion-header>

<ion-content>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Loading spinner -->
  <ion-spinner name="circular" *ngIf="!view.accounts"></ion-spinner>

  <div class="container">

    <!-- Accounts grid -->
    <ion-grid *ngIf="!!view.accounts && !!view.accounts.length">

      <ion-row>

        <!-- Single account -->
        <ion-col *ngFor="let account of view.accounts; trackBy:trackItems" [hidden]="!!account.hidden">

          <ion-card>
            <ion-list lines="none">

              <!-- main account -->
              <ion-item-sliding>

                <ion-item button (click)="switchTo(account)" [hidden]="!account.uid">

                  <ion-avatar slot="start">
                    <ion-img [src]="account.photo || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(account, fallbackImg)"></ion-img>
                  </ion-avatar>

                  <ion-checkbox alignment="end" justify="space-between" labelPlacement="start"
                    [(ngModel)]="account.checked">
                    <p
                      [innerHTML]="account.displayName || account.nickname || (account.firstname + ' ' + account.lastname)">
                    </p>
                    <small [innerHTML]="account.email"></small>
                  </ion-checkbox>

                  <ion-button slot="end" *ngIf="view.isDesktop" color="danger" fill="clear" (click)="logout(account)">
                    <ion-icon name="log-out-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'log_out'|translate"></ion-label>
                  </ion-button>

                </ion-item>

                <ion-item-options *ngIf="!view.isDesktop" slot="end">
                  <ion-item-option color="danger" (click)="logout(account)">
                    <ion-icon name="log-out-outline" slot="start"></ion-icon>
                    <ion-label [innerHTML]="'log_out'|translate"></ion-label>
                  </ion-item-option>
                </ion-item-options>

              </ion-item-sliding>

              <!-- Spinner -->
              <ion-spinner *ngIf="!account.teams"></ion-spinner>

              <!-- Teams -->
              <ion-radio-group *ngIf="!!account.teams && !!account.teams.length" [(ngModel)]="account.team">
                <ion-item *ngFor="let team of account.teams" class="team" (click)="switchToTeam(team, account)"
                  [hidden]="!team.uid || !!team.hidden">

                  <ion-avatar slot="start">
                    <ion-img [src]="team.photo || fallbackImg"
                      (ionError)="thumbnailLoadingFailed(team, fallbackImg)"></ion-img>
                  </ion-avatar>

                  <ion-radio alignment="end" justify="space-between" labelPlacement="start" [value]="team">
                    <p [innerHTML]="team.name"></p>
                  </ion-radio>

                </ion-item>
              </ion-radio-group>

            </ion-list>
          </ion-card>
        </ion-col>
      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-grid>
      <ion-row>
        <ion-col>

          <!-- Add account -->
          <ion-button expand="block" color="primary" (click)="addAccount()">
            <ion-label [innerHTML]="'add_account'|translate"></ion-label>
          </ion-button>

        </ion-col>
        <ion-col>

          <!-- Logout all -->
          <ion-button expand="block" color="danger" fill="clear" (click)="logoutAll()">
            <ion-label [innerHTML]="'logout_all'|translate" class="ion-text-wrap"></ion-label>
          </ion-button>

        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>