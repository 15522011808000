import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {

  iosShortcutList: any = [];

  constructor(
    private platform: Platform,
  ) {

  }

  init() {
    this.platform.ready().then(() => {
      setTimeout(() => {
        this.initShortcutLists()
          .catch((error: any) => {
            console.warn('shortcuts error', error);
          });
      }, 2000);
    });
  }

  async initIOSShortcutList() {
    return new Promise((resolve, reject) => {
    });
  }

  initShortcutLists() {
    return Promise.all([
      this.initIOSShortcutList(),
    ]);
  }

}
