<ion-header [translucent]="config.translucentHeaders" class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!!view.showBackButton || !view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <!-- Menu button -->
      <ion-menu-button *ngIf="!view.isModal && !view.showBackButton"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end">

      <ion-grid>
        <ion-row>

          <!-- Environments -->
          <ion-col *ngIf="!!view.admin && !!view.environments && !!view.environments.length">

            <ion-item lines="none">
              <ion-select interface="popover" [placeholder]="'environment'|translate" [okText]="'okay'|translate"
                [cancelText]="'cancel'|translate" [(ngModel)]="view.environment" (ionChange)="updatedEnvironment()">
                <ion-select-option *ngFor="let env of view.environments" [value]="env.url"
                  [innerHTML]="env.label|translate"></ion-select-option>
              </ion-select>
            </ion-item>

          </ion-col>

          <!-- User group -->
          <ion-col *ngIf="view.admin && view.user_groups && view.user_groups.length">

            <ion-item lines="none">
              <ion-select interface="popover" [placeholder]="'view_as_user_group'|translate" [okText]="'okay'|translate"
                [cancelText]="'cancel'|translate" [(ngModel)]="user.classifications.type"
                (ionChange)="updatedUserType()">
                <ion-select-option *ngFor="let userGroup of view.user_groups" [value]="userGroup.uid"
                  [innerHTML]="userGroup.title"></ion-select-option>
              </ion-select>
            </ion-item>

          </ion-col>

        </ion-row>
      </ion-grid>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <ion-grid>

      <ion-row>

        <!-- Sidebar (in desktop mode, else main page) -->
        <ion-col class="sidebar" [size]="view.isDesktop ? view.colSizes.left || 12 : 12">

          <!-- Account -->
          <ion-card *ngIf="!!config.allowUserLogin" [class.active]="view.contentPage === 'account'">
            <ion-list lines="none">

              <ion-item button (click)="account()" [disabled]="!user || !user.uid || (user.uid === -1)">

                <ion-icon name="person-outline" slot="start"
                  [color]="view.isDesktop && view.contentPage === 'account' ? 'white' : 'primary'"></ion-icon>

                <ion-label>
                  <h2 [innerHTML]="'account'|translate"></h2>
                  <p [innerHTML]="'account_description'|translate"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>

              </ion-item>

            </ion-list>
          </ion-card>

          <!-- Appearance -->
          <ion-card *ngIf="!!config.useAppearance && !!config.allowUserLogin"
            [class.active]="view.contentPage === 'appearance'">

            <ion-list lines="none">

              <ion-item button (click)="appearance()" [disabled]="!user || !user.uid || (user.uid === -1)">

                <ion-icon name="color-wand-outline" slot="start"
                  [color]="view.isDesktop && view.contentPage === 'appearance' ? 'white' : 'primary'"></ion-icon>

                <ion-label>
                  <h2 [innerHTML]="'appearance'|translate"></h2>
                  <p class="ion-text-wrap" [innerHTML]="'appearance_description'|translate|fillVars"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>
                
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Devices -->
          <ion-card *ngIf="!!config.allowUserLogin && !!config.useDevicesExtension"
            [class.active]="view.contentPage === 'devices'">

            <ion-list lines="none">

              <ion-item button (click)="devices()" [disabled]="!user || !user.uid || (user.uid === -1)">
                <ion-icon name="phone-portrait-outline" slot="start"
                  [color]="view.isDesktop && view.contentPage === 'devices' ? 'white' : 'primary'"></ion-icon>

                <ion-label>
                  <h2 [innerHTML]="'devices'|translate"></h2>
                  <p [innerHTML]="'devices_description'|translate"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>

              </ion-item>

            </ion-list>
          </ion-card>

          <!-- Wallet -->
          <ion-card *ngIf="!!config.useWallet" [class.active]="view.contentPage === 'wallet'">
            <ion-list lines="none">
              <ion-item button (click)="wallet()" [disabled]="!user || !user.uid || (user.uid === -1)">
                <ion-icon name="wallet-outline" slot="start"
                  [color]="view.isDesktop && view.contentPage === 'wallet' ? 'white' : 'primary'"></ion-icon>
                <ion-label>
                  <h2 [innerHTML]="'wallet'|translate"></h2>
                  <p [innerHTML]="'wallet_description'|translate"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>

          <!-- Filters -->
          <ion-card *ngIf="!!config.useFiltersExtension">
            <ion-list lines="none">
              <ion-item button (click)="filters()" [disabled]="view.aboVersion == 'free'">
                <ion-icon name="filter-outline" slot="start" color="primary"></ion-icon>
                <ion-label>
                  <h2 [innerHTML]="'filters'|translate"></h2>
                  <p [innerHTML]="'filters_description'|translate"></p>
                  <p *ngIf="view.aboVersion == 'free'" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_abo_premium_short'|translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>

          <!-- Notifications -->
          <ion-card *ngIf="!!config.usePushNotifications && !!config.allowUserLogin">
            <ion-list lines="none">
              <ion-item button (click)="notifications()" [disabled]="!user || !user.uid || (user.uid === -1)">
                <ion-icon name="chatbox-outline" slot="start" color="primary"></ion-icon>
                <ion-label>
                  <h2 [innerHTML]="'push_notifications'|translate"></h2>
                  <p [innerHTML]="'push_notifications_description'|translate"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>

          <!-- Experiments -->
          <ion-card *ngIf="!!config.useExperiments">
            <ion-list lines="none">
              <ion-item button (click)="experiments()" [disabled]="!user || !user.uid || (user.uid === -1)">
                <ion-icon name="rocket-outline" slot="start" color="primary"></ion-icon>
                <ion-label>
                  <h2 [innerHTML]="'experiments'|translate"></h2>
                  <p [innerHTML]="'experiments_description'|translate"></p>
                  <p *ngIf="!user || !user.uid || (user.uid === -1)" color="danger" class="ion-text-wrap"
                    [innerHTML]="'feature_requires_account_short'|translate"></p>
                </ion-label>
              </ion-item>
            </ion-list>
          </ion-card>

          <!-- Links -->
          <ion-card
            *ngIf="config.showSettingsLinks && !!config.links && !!config.links.settings && !!config.links.settings.length">

            <ion-list lines="none">

              <ion-item [disabled]="!!link.disabled || !link.url" button *ngFor="let link of config.links.settings"
                (click)="onLinkClick(link)">

                <ion-icon slot="start" [name]="link.icon || 'link-outline'" color="primary"></ion-icon>

                <ion-label class="ion-text-wrap">
                  <h3 [innerHTML]="link.label|translate"></h3>
                  <p *ngIf="!!link.description" [innerHTML]="link.description|translate"></p>
                </ion-label>

              </ion-item>

            </ion-list>

          </ion-card>

        </ion-col>

        <!-- Content area (displays pages on desktop mode ) -->
        <ion-col class="content" *ngIf="!!view.isDesktop" [size]="view.isDesktop ? view.colSizes.right || 12 : 12">

          <!-- Account -->
          <app-account *ngIf="view.contentPage === 'account'"></app-account>

          <!-- Appearance -->
          <app-appearance *ngIf="view.contentPage === 'appearance'"></app-appearance>

          <!-- Devices -->
          <app-devices *ngIf="view.contentPage === 'devices'"></app-devices>

          <!-- Wallet -->
          <app-getgenius-wallet *ngIf="view.contentPage === 'wallet'"></app-getgenius-wallet>

        </ion-col>

      </ion-row>

    </ion-grid>

    <!-- Information -->
    <span class="version">

      <b *ngIf="view.appVersion">
        <span [innerHTML]="'version'|translate"></span>&nbsp;<span [innerHTML]="view.appVersion"></span>
      </b>

      <br>

      <span *ngIf="!config.isWhitelabel && !!config.showSettingsFooterLove"
        [innerHTML]="(config.footerLoveText || 'footer_love')|translate"></span>

    </span>

  </div>

</ion-content>