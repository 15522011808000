import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { NewslettersService } from 'src/app/services/newsletters/newsletters.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { EditorComponent } from 'src/app/components/editors/editor/editor.component';

@Component({
  selector: 'app-newsletter',
  standalone: false,
  templateUrl: './newsletter.page.html',
  styleUrls: ['./newsletter.page.scss'],
})
export class NewsletterPage implements OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    max_tokens: (32 * 1024),
    operations: [
      'text_generation',
      'text_to_image',
    ],
    temperature: 1,
  };

  cards: any = {};

  @ViewChild(EditorComponent) editor: any;

  fallbackImg: string = './assets/img/fallback.webp';

  index: number = 0;

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    headline: 'create_newsletter_ai_helper_text',
    input_placeholder: 'create_newsletter_ai_helper_input_placeholder',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
    showIntegrations: false,
  };

  mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    showGenerateOptions: false,
  };

  mode: string = 'create';

  newsletter: newsletter = {
    language: 'en',
    title: '',
  };

  newsletters: newsletter[];

  state: state = {};

  user: user;

  view: any = {
    ai: {
      models: [],
      providers: [],
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    iMaxFails: 3,
    placeholders: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    title: 'create_newsletter',
    media: {},
    mediaList: [],
    newsletter: {
      settings: {
        branding: {
          colors: {
            primary: '',
            secondary: '',
          },
        },
      },
      sources: {},
    },
    newsletterType: 'blog',
    newsletterTypes: [
      {
        icon: 'document-text-outline',
        name: 'newsletter_type_blog',
        uid: 'blog',
      },
      {
        icon: 'storefront-outline',
        name: 'newsletter_type_ecommerce',
        uid: 'ecommerce',
      },
      {
        icon: 'desktop-outline',
        name: 'newsletter_type_landingpage',
        uid: 'landingpage',
      },
      {
        icon: 'id-card-outline',
        name: 'newsletter_type_recruiting',
        uid: 'recruiting',
      },
    ],
  };

  constructor(
    private aiTools: AiToolsService,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private newslettersService: NewslettersService,
    private sidebar: SidebarService,
    private sources: SourcesService,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.user = this.userService.getUser() || {};

    this.mode = this.mode || 'create';
  }

  addSources() {
    this.sources.pick({
      multiple: true,
    })
      .then((response: any) => {
        if (!!response && !!response.data && !!response.data.items && !!response.data.items.length) {

          let sources: source[] = this.view.sources || [], existingSourcesUids: number[] = sources.map((source: source) => {
            return source.uid;
          });

          this.view.newsletter.sources = this.newsletter.sources || {};

          this.view.sources = (this.view.sources || []).concat(response.data.items.filter((source: source) => {
            this.view.newsletter.sources[source.uid] = this.view.newsletter.sources[source.uid] || source;
            this.view.newsletter.sources[source.uid].checked = true;

            return existingSourcesUids.indexOf(source.uid) === -1;
          }));

        }
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      })
  }

  async aiCreate() {

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    const validate: any = this.tools.validateUrl(this.view.aiCreateInput);

    this.view.isValidWebsiteUrl = validate.success;

    if (!!this.view.isValidWebsiteUrl) {
      return this.aiCreateFromUrl(this.view.aiCreateInput);
    }

    this.view.generating = true;
    this.view.loading = true;
    this.view.cssBackup = await this.editor.getCss();

    const html: string = (this.editor.getHtml() || '');
    const extract: any = await this.editor.extractCSS(html);
    const bodySelect: any = /<body.*?>([\s\S]*)<\/body>/.exec(`${extract.html || html}`);
    const bodyHtml: any = (!!bodySelect && !!bodySelect[1] ? bodySelect[1] : null);

    if (!bodyHtml || !bodyHtml.length) {
      return false;
    }

    this.newslettersService.generate({
      query: this.view.aiCreateInput,
      post_content: `${bodyHtml}`,
    }, this.aiSettings)
      .then((response: any) => {
        this.view.generating = false;
        this.view.loading = false;

        /*
        if (!!response && !!response.output) {
          let replacement: string = `${extract.html || html}`.replace(bodyHtml, response.output);
          console.log('replacement (1)', replacement);
        }
        */

        this.applyAiResponse(response);
      })
      .catch((error: any) => {
        console.warn('> error', error);

        this.view.iLastFails = this.view.iLastFails || 0;
        this.view.iLastFails++;
        this.view.generating = false;
        this.view.loading = false;

        if (!!error && (typeof error === 'string') && (error.indexOf('resource_busy') !== -1) && (this.view.iLastFails < this.view.iMaxFails)) {
          console.warn('resources busy, retrying aiCreate', this.view.iLastFails, this.view.iMaxFails, error);
          return this.aiCreate();
        } else {
          this.events.publish('error', error);
        }
      });
  }

  aiCreateFromUrl(url: string) {

    if (!url || !url.length) {
      return false;
    }

    this.view.loading = true;

    this.aiTools.inspectURL(url, true, {
      index: false,
    })
      .then((response: any) => {
        console.log('inspect url response', response);

        this.view.loading = false;

        // set cta input url if empty
        if (!this.view.ctaInput || !this.view.ctaInput.length) {
          this.view.ctaInput = url;
        }

        let inputPrompt: string = (!!response && !!response.ci && !!response.ci.title ? `${response.ci.title}. ` : '');
        let inputPromptParts: string[] = [];

        if (!!response && !!response.ci) {
          response.ci.logo = (response.ci.logo || (!!response.ci.links && !!response.ci.links['apple-touch-icon'] ? response.ci.links['apple-touch-icon'] : (!!response.ci.links && !!response.ci.links.icon ? response.ci.links.icon : response.ci.icon)) || '');

          this.view.ci = response.ci;

          // apply newsletter title from ci title if set
          if (!!response.ci.title) {
            this.newsletter.title = response.ci.title;
          }

        }

        if (!!response && !!response.ideas) {

          this.view.ideas = (response.ideas || []).map((idea: any) => {

            idea.label = idea.label || idea.text;
            idea.photo = idea.photo || this.fallbackImg;
            idea.name = idea.name || idea.text;
            idea.title = idea.title || idea.text;
            idea.thumbnail = idea.thumbnail || ((idea.url || idea.photo) || null);
            idea.type = idea.type || 'image';
            idea.url = (idea.url || idea.photo) || null;

            return idea;
          });

          // calculate input prompt
          response.ideas.forEach((idea: any) => {

            if (!!idea.headline && (inputPromptParts.indexOf(idea.headline) === -1)) {
              inputPrompt += idea.headline + '. ';
              inputPromptParts.push(idea.headline);
            }

            if (!!idea.text && (inputPromptParts.indexOf(idea.text) === -1)) {
              inputPrompt += idea.text + '. ';
              inputPromptParts.push(idea.text);
            }

          });
        }

        this.startManually();
        this.extractMediaListFromIdeas();

        // re-run ai generation
        this.view.aiPrompt = this.tools.trim(inputPrompt);
        this.runAiPrompt();
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  async applyAiResponse(response: any) {

    if (!response || !response.output) {
      return false;
    }

    const split: string[] = `${response.output || ''}`.split('```html'),
      output: string = `${split[split.length - 1] || ''}`.replace('```', ''),
      css: string = (await this.editor.getCss() || '');

    let combined: string;

    if ((output.toLowerCase().indexOf('<!doctype') !== -1) || (output.toLowerCase().indexOf('<html>') !== -1)) {
      combined = output;
    } else {
      combined = `<html><head><style>${css}</style></head><body>${output}</body></html>`;
    }

    this.newsletter.html = combined;
    this.newsletter.title = this.newsletter.title || (this.view.aiCreateInput || '');

    this.view.executed = true;

    this.setHtml(this.newsletter.html);

    if (!!response && !!response.newsletter) {
      this.newsletter = Object.assign(this.newsletter, response.post || {});
    }

    this.loadMissingImages();

    if (!this.newsletter || !this.newsletter.uid) {
      this.create(false);
    }
  }

  attachmentPreviewFailed(key: string) {
    console.warn('attachmentPreviewFailed', key);
  }

  back() {
    this.view.executed = false;
  }

  bindKeyEvents() {
    window.addEventListener('keydown', (event) => {
      event.stopImmediatePropagation();

      this.onKeyDown(event);
    }, false);
  }

  calcNewsletterVars(newsletter: newsletter | null = null) {
    this.newsletter = (newsletter || this.newsletter);

    this.newsletter.settings = this.newsletter.settings || {};
    this.newsletter.settings.branding = this.newsletter.settings.branding || {};

    this.newsletter.settings.branding.colors = this.newsletter.settings.branding.colors || {
      primary: '',
      secondary: '',
    };
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.newsletter && !!this.newsletter.uid ? 'edit_newsletter' : 'create_newsletter');

    this.view.canNavigateBack = (!!this.newsletters && (this.index !== 0));
    this.view.canNavigateNext = (!!this.newsletters && (this.index !== this.newsletters.length));
  }

  colorChanged(event: any | null = null, _target: any | null = null) {
    _target = event;
  }

  async create(blDismiss: boolean = true) {

    if (!!this.newsletter && !!this.newsletter.uid) {
      return this.update();
    }

    let html: string = (this.editor.getHtml() || ''),
      css: string = (await this.editor.getCss() || '');

    this.newsletter.html = `<html><style>${css}</style>${html}</html>`;

    let sources: any[] = JSON.parse(JSON.stringify(Object.values(this.view.newsletter.sources))).filter((source: any) => {
      return !!source.checked;
    });

    this.newsletter.sources = sources;

    if (this.newsletter.sources && this.newsletter.sources.length) {
      this.newsletter.sources.forEach((source: any) => {
        if (source.systems) {
          let _systems: string[] = [];
          Object.keys(source.systems).forEach((system: string) => {
            if (_systems.indexOf(system) === -1) {
              _systems.push(system);
            }
          });
          source.systems = _systems;
        }
        delete source.checked;
      });
    }

    this.newslettersService.submit(this.newsletter)
      .then((response: any) => {

        if (!!response && !!response.uid) {
          this.newsletter.uid = response.uid;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        } else {
          this.startManually();
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  extractMediaListFromIdeas() {

    if (!this.view.ideas || !this.view.ideas.length) {
      return false;
    }

    let mediaList: any[] = [], mediaListUrls: string[] = [];

    this.view.ideas.forEach((idea: any) => {
      if (!!idea.photo && !!idea.photo.length && (mediaListUrls.indexOf(idea.photo) === -1) && (idea.photo.indexOf('fallback') === -1)) {
        mediaList.push(idea);
        mediaListUrls.push(idea.photo);
      }
    });

    // apply media list from ideas
    this.view.mediaList = (mediaList || []);

    // set newsletter photo if not set
    try {
      if ((!this.newsletter.photo || !this.newsletter.photo.length) && !!this.view.mediaList && !!this.view.mediaList[0] && !!this.view.mediaList[0].photo) {
        this.newsletter.photo = this.view.mediaList[0].photo;
      }
    } catch (e) {

    }
  }

  initEditor() {
    setTimeout(() => {
      this.view.loading = false;
    });
  }

  ionViewWillEnter() {
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadMissingImages() {
    try {

      if (!this.newsletter || !this.newsletter.html) {
        return false;
      }

      const parser = new DOMParser();
      const doc = parser.parseFromString(`${this.newsletter.html || ''}`, 'text/html');
      const images = doc.querySelectorAll('img');

      if (!!images && !!images.length) {
        images.forEach(async (img: any, imgIndex: number) => {
          const altText: string | null = img.getAttribute('alt');
          const imgSearchQuery: string = (!!altText ? altText : (!!this.view.ci && !!this.view.ci.title ? this.view.ci.title : this.newsletter.title));

          const blAltRequiresLogo: boolean = !!altText && (altText.toLowerCase() === 'logo');

          if (!!blAltRequiresLogo && !!this.view.ci && !!this.view.ci.logo) {
            // if logo is requested in alt tag and logo provided, set img src to logo

            img.setAttribute('src', this.view.ci.logo);

            const modifiedHtmlString = doc.documentElement.innerHTML;

            if (!!modifiedHtmlString) {
              this.newsletter.html = modifiedHtmlString;
              this.setHtml(this.newsletter.html);
            }

          } else
            if (!!this.view.mediaList && !!this.view.mediaList && !!this.view.mediaList[imgIndex] && !!this.view.mediaList[imgIndex].photo) {
              // if photo from media list for index is set, use it
              img.setAttribute('src', this.view.mediaList[imgIndex].photo);

              const modifiedHtmlString = doc.documentElement.innerHTML;

              if (!!modifiedHtmlString) {
                this.newsletter.html = modifiedHtmlString;
                this.setHtml(this.newsletter.html);
              }

            } else {
              // else, create new photo

              img.removeAttribute('height');

              // https://via.placeholder.com/750?text=
              img.setAttribute('src', `https://fakeimg.pl/1024x576/f5f5f5/a56b3d?text=${imgSearchQuery}&font_size=25`);
              img.setAttribute('width', '100%');

              if (!!imgSearchQuery) {
                this.aiTools.search({
                  blFineTuneInput: true,
                  creative: true,
                  limit: 1,
                  query: `${imgSearchQuery}`,
                  request: 'images',
                  showStep: (data: any) => {
                    console.log('newsletter-page: showStep: data', data);
                  },
                  height: 576,
                  width: 1024,
                })
                  .then((response: any) => {

                    if (!!response && !!response.images && !!response.images[0]) {
                      img.setAttribute('src', `${response.images[0]}`);

                      const modifiedHtmlString = doc.documentElement.innerHTML;

                      if (!!modifiedHtmlString) {
                        this.newsletter.html = modifiedHtmlString;
                        this.setHtml(this.newsletter.html);
                      }

                      // set newsletter photo if not set
                      if (!this.newsletter.photo || !this.newsletter.photo.length) {
                        this.newsletter.photo = response.images[0];
                      }

                    }
                  })
                  .catch((error: any) => {
                    console.warn('creating image failed', error);
                  });
              }
            }
        });
      }
    } catch (e) {
      console.warn('loading missing images failed', e);
    }
  }

  loadTemplate(template: any) {
    this.view.template = template;
    this.newsletter.html = `${this.view.template.value || ''}`;

    this.setHtml(this.newsletter.html, true);

    // re-run ai creation
    this.view.aiCreateInput = `${(this.view.aiCreateInput || this.newsletter.title) || ''}`;
    this.aiCreate();
  }

  loadTemplates() {
    return new Promise((resolve, reject) => {
      this.newslettersService.getTemplates()
        .then((templates: any[]) => {

          if (!!templates && !!templates.length) {
            templates = this.tools.shuffle(templates);
            /*
            templates = templates
              .sort((a: any, b: any) => {
                
                const _a: string = `${a.title}`.toLowerCase(),
                      _b: string = `${b.title}`.toLowerCase();

                if (_a < _b) return -1;
                if (_b > _a) return 1;
                
                return 0;
              });
              */
          }

          this.view.templates = templates.map((template: any) => {
            template.preview = this.domSanitizer.bypassSecurityTrustHtml(`${template.value || ''}`);
            return template;
          });

          if ((!this.newsletter.html || !this.newsletter.html.length) && !!templates && !!templates[0] && !!templates[0].value) {
            this.view.template = templates[0];
            this.loadTemplate(templates[0]);
          }

          this.initEditor();

          resolve(this.view.templates);
        })
        .catch(reject);
    });
  }

  loadUI() {
    this.loadTemplates();

    if (!this.newsletter.html || !this.newsletter.html.length) {
      return false;
    }

    this.view.executed = (!!this.newsletter && !!this.newsletter.uid);

    if (!!this.view.executed) {
      this.startManually();
    }

    this.initEditor();
  }

  navBack() {
    this.index--;
    this.onNavIndexChanged();
  }

  navNext() {
    this.index++;
    this.onNavIndexChanged();
  }

  ngAfterContentInit() {
  }

  ngOnDestroy() {
    this.unbindKeyEvents();
  }

  ngOnInit() {
    const detailItem: any | null = this.newslettersService.detailItem();

    // apply newsletter from details
    this.newsletter = detailItem || (this.newsletter || {});

    this.calcViewVars();
    this.loadCards();
    this.bindKeyEvents();

    this.newsletter = this.newsletter || {};
    this.newsletter.language = this.userService.getLanguage() || 'en';
    this.calcNewsletterVars(this.newsletter);

    this.view.loading = true;

    setTimeout(() => {
      this.loadUI();
    }, 500);
  }

  onAttachmentUrlPasted(key: string) {
    console.log('onAttachmentUrlPasted', key);
  }

  onKeyDown(event: any) {
    switch (event.key) {
      case "ArrowLeft":
        if (!this.view.canNavigateBack) { return false; }
        this.navBack();
        break;
      case "ArrowRight":
        if (!this.view.canNavigateNext) { return false; }
        this.navNext();
        break;
    }
  }

  onMediaChanged(event: any | null = null) {
    console.log('onMediaChanged', event);
  }

  onNavIndexChanged() {
    this.newsletter = this.newsletters[this.index];
    this.view.history = [this.newsletter];

    this.calcViewVars();
    this.loadUI();
  }

  onOverlayInputChanged(event: any = null) {

    if (!event || !event.input || (!!event.input.name && (event.input.name !== 'input')) || !event.input.hasOwnProperty('value')) {
      return false;
    }

    this.view.aiCreateInput = event.input.value;
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  onSourceToggled(sourceId: number) {
    if (this.view.newsletter.sources && this.view.newsletter.sources[sourceId] && this.view.newsletter.sources[sourceId].systems) {
      Object.keys(this.view.newsletter.sources[sourceId].systems).forEach((system: string) => {
        this.view.newsletter.sources[sourceId].systems[system] = this.view.newsletter.sources[sourceId].checked;
      });
    }
  }

  onTemplateCheckboxClicked(template: mediaTemplate, i: number) {
    this.view.template = template;
    this.templateChanged();
  }

  preview() {
    this.view.executed = true;
  }

  async runAiPrompt(event: any = null) {
    this.view.loading = true;

    const html: string = `${this.newsletter.html || ''}`;

    if (!this.editor) {
      return false;
    }

    const extract: any = await this.editor.extractCSS(html);
    const bodySelect: any = /<body.*?>([\s\S]*)<\/body>/.exec(`${extract.html || html}`);
    const bodyHtml: any = (!!bodySelect && !!bodySelect[1] ? bodySelect[1] : null);

    if (!bodyHtml || !bodyHtml.length) {
      return false;
    }

    this.newslettersService.generate({
      query: `${this.view.aiPrompt || ''}`,
      post_content: `${bodyHtml}`,
    }, this.aiSettings)
      .then((response: any) => {
        this.view.aiPrompt = '';
        this.view.loading = false;

        /*
        if (!!response && !!response.output) {
          let replacement: string = `${extract.html || html}`.replace(bodyHtml, response.output);
          console.log('replacement (2)', replacement);
        }
        */

        this.applyAiResponse(response);
      })
      .catch((error: any) => {
        console.warn('> error (2)', error);

        this.view.iLastFails = this.view.iLastFails || 0;
        this.view.iLastFails++;
        this.view.loading = false;

        if (!!error && (typeof error === 'string') && (error.indexOf('resource_busy') !== -1) && (this.view.iLastFails < this.view.iMaxFails)) {
          console.warn('resources busy, retrying runAiPrompt', this.view.iLastFails, this.view.iMaxFails, error);
          return this.runAiPrompt();
        } else {
          this.events.publish('error', error);
        }
      });
  }

  searchTemplates() {

  }

  async setHtml(html: string, blOverwriteCss: boolean = false) {
    html = (html || '').replace('```', '');

    try {
      return this.editor.setHtml(html, blOverwriteCss);
    } catch (e) {
      console.warn('updating editor values failed', e);
    }
  }

  startManually() {
    this.view.startManually = true;

    this.cards.general.open = false;
    this.cards.look_and_feel.open = false;
    //this.cards.templates.open = false;
  }

  templateChanged() {
    if (!!this.view.template && !!this.view.template.value) {
      this.setHtml(this.view.template.value, true);
    }
  }

  thumbnailLoadingFailed(item: any | null = null) {
    if (!!item) {
      item.photo = this.fallbackImg;
    } else {
      this.view.media.logo_src = this.fallbackImg;
    }
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  unbindKeyEvents() {
    window.removeEventListener('keydown', this.onKeyDown, false);
  }

  async update() {

    if (!this.newsletter.uid) {
      return this.create(false);
    }

    let html: string = (this.editor.getHtml() || ''),
      css: string = (await this.editor.getCss() || '');

    this.newsletter.html = `<html><style>${css}</style>${html}</html>`;

    let sources: any[] = JSON.parse(JSON.stringify(Object.values(this.view.newsletter.sources))).filter((source: any) => {
      return !!source.checked;
    });

    this.newsletter.sources = sources;

    if (this.newsletter.sources && this.newsletter.sources.length) {
      this.newsletter.sources.forEach((source: any) => {
        if (source.systems) {
          let _systems: string[] = [];
          Object.keys(source.systems).forEach((system: string) => {
            if (_systems.indexOf(system) === -1) {
              _systems.push(system);
            }
          });
          source.systems = _systems;
        }
        delete source.checked;
      });
    }

    this.newslettersService.update(this.newsletter)
      .then((response: any) => {
        this.dismiss(response, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  uploadAttachment(key: string) {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.newsletter.settings.branding[key] = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useNewsletterType(type: any) {
    this.view.newsletterType = type.uid;
  }

  validateCTAInput() {
    const validate: any = this.tools.validateUrl(this.view.ctaInput);

    this.view.isValidWebsiteUrl = validate.success;

    if (!!this.view.isValidWebsiteUrl) {
      return this.aiCreateFromUrl(this.view.ctaInput);
    }

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}