import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AbonnementService } from 'src/app/services/core/abonnement.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { UserService } from 'src/app/services/core/user.service';
import { SourcesService } from 'src/app/services/pipeline/sources.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  _detailItem: post | null;

  _orderBy: string = 'auto';

  constructor(
    private abo: AbonnementService,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private sources: SourcesService,
    private tools: ToolsService,
    private userService: UserService,
    private _sanitizer: DomSanitizer,
  ) {

  }

  cachePost(post: post, options: any = {}) {
    const postId = post.uid || post.ID,
      key = "pipeline_fullPost_" + postId + "_" + JSON.stringify(options);

    post = JSON.parse(JSON.stringify(post));

    delete post.checked;
    delete post.parsed;

    this.cache.set(key, post);
  }

  deletePost(postId: number) {
    return this.AppCMS.loadPluginData("pipeline", {}, [
      "posts",
      postId,
      "delete",
    ]);
  }

  detailItem(item: post | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getFullPost(post: any) {

    post.style = {
      backgroundUrl: post.thumbnail
        ? this._sanitizer.bypassSecurityTrustStyle(
          `url('${post.thumbnail}')`
        )
        : "",
    };

    if (!!post.parsed) {
      return post;
    }

    post.excerpt = this.tools.stripHtml(
      (this.tools.cleanContent(post, 'excerpt') || this.tools.cleanContent(post, 'post_excerpt'))
    );

    if (post.host && post.host === 'pipeline') {
      post.avatar = './assets/img/icon.webp';
    }

    post.thumbnail = (post.thumbnail || post.image);
    post.vip = this.abo.calcVip(post);

    post.date_gmt = (post.date_gmt || post.post_date_gmt);
    post.modified_gmt = (post.modified_gmt || post.post_modified_gmt);

    post = {
      active: post.active,
      allowed: !!(post.allowed !== null ? post.allowed : !post.vip),
      avatar: post.avatar,
      category: (post.category || 'discover'),
      collection: post.collection,
      date_gmt: post.date_gmt,
      //date_gmt_formatted: post.date_gmt_formatted || (post.date_gmt ? moment(post.date_gmt).format('DD.MM.YYYY HH:mm') : null),
      excerpt: post.excerpt,
      external: !!(post.external || false),
      genre: post.genre,
      host: post.host,
      host_uid: post.host_uid,
      hostLabel: post.hostLabel,
      local: !!(post.local || false),
      modified_gmt: post.modified_gmt,
      //modified_gmt_formatted: post.modified_gmt_formatted || (post.modified_gmt ? moment(post.modified_gmt).format('DD.MM.YYYY HH:mm') : null),
      name: this.tools.decodeEntities(post.name || post.post_title),
      parsed: true,
      post_content: post.post_content,
      rating: post.rating,
      reactions: post.reactions,
      region: post.region,
      relatedArticles: post.relatedArticles,
      sponsored: !!post.sponsored,
      style: post.style,
      timestamp: post.timestamp,
      //timestamp_formatted: (post.timestamp_formatted || moment(post.modified_gmt || post.timestamp, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')),
      thumbnail: post.thumbnail,
      type: post.type || 'post',
      uid: (post.uid || post.ID),
      url: (post.url || post.permalink),
      verified: !!(post.verified || true),
      vip: !!post.vip,
    };

    return post;
  }

  getFullPosts(posts: any) {

    if (posts && posts.length) {
      posts.forEach((post: any, index: number) => {
        posts[index] = this.getFullPost(post);
      });

      posts = posts.filter((post: post) => {
        return post && post.thumbnail && post.thumbnail.length;
      });
    }

    return posts;
  }

  getFullRelatedArticles(relatedArticles: number[] | post[]) {
    return new Promise((resolve, reject) => {
      let iDone = 0;

      if (relatedArticles.length > 10 && (typeof relatedArticles[0] === 'number')) {
        relatedArticles = relatedArticles.slice(0, 10);
      }

      relatedArticles.forEach(
        (relatedArticle: number | post, index: number) => {
          if (typeof relatedArticle === "number") {
            setTimeout(() => {
              this.getPostByUid(relatedArticle)
                .then((post: post) => {
                  iDone++;
                  relatedArticles[index] = post;
                  if (iDone === relatedArticles.length) {
                    resolve(relatedArticles);
                  }
                })
                .catch((error) => {
                  iDone++;
                  console.error("error", error);
                  if (iDone === relatedArticles.length) {
                    resolve(relatedArticles);
                  }
                });
            }, index * 1000);
          } else {
            iDone++;
            relatedArticles[index] = this.getFullPost(relatedArticle);
            if (iDone === relatedArticles.length) {
              resolve(relatedArticles);
            }
          }
        }
      );
    });
  }

  getPostByUid(postId: number, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!postId) {
        reject('error_missing_post_uid');
      } else {
        this.AppCMS.loadPluginData("pipeline", options, ["posts", postId], {}, blForceRefresh)
          .then((post: post) => {
            post = this.getFullPost(post);
            resolve(post);
          })
          .catch(reject);
      }
    });
  }

  getPostBySlug(slug: string) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        "pipeline",
        {
          filter: {
            url: slug,
          },
          like: true,
        },
        ["posts"]
      )
        .then((posts: post[]) => {
          resolve(posts ? posts[0] || null : null);
        })
        .catch(reject);
    });
  }

  getPosts(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      const language: string = (this.userService.getLanguage() || "en");

      options.language = options.language || language;

      this.AppCMS.loadPluginData("pipeline", options, ["posts"], null, blForceRefresh)
        .then((posts: post[]) => {
          if (posts && posts.length) {
            posts.forEach((post: post) => {
              post = this.getFullPost(post);
            });
          }
          resolve(posts);
        })
        .catch(reject);
    });
  }

  getPostsByCategory(categoryId: string | number, options: any = {}) {
    return new Promise(async (resolve, reject) => {
      const key: string =
        "pipeline_postsByCategory_" +
        categoryId +
        "_" +
        JSON.stringify(options),
        fromCache: cacheItem = await this.cache.get(key, 30 * 60),
        user = this.userService.getUser() || {},
        language = (this.userService.getLanguage() || "en");

      options.language = options.language || language;
      options.limit = options.limit || 25;

      if (fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else
        if (typeof categoryId === "string") {
          this.getPosts(options).then(resolve).catch(reject);
        } else {
          this.AppCMS.loadPluginData("pipeline", options, [
            "posts",
            "category",
            categoryId,
          ])
            .then((posts: any) => {
              if (posts) {
                posts.forEach((post: any, index: number) => {
                  posts[index] = this.getFullPost(post);
                });
                this.cache.set(key, posts);
                resolve(posts);
              } else {
                reject();
              }
            })
            .catch(reject);
        }
    });
  }

  getPostsByHost(url: string, blForceRefresh: boolean = false) {
    return this.getPosts({
      filter: {
        host: url,
      }
    }, blForceRefresh);
  }

  getPostsBySourceUid(sourceId: number, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.sources.getSourceByUid(sourceId)
        .then((source: source) => {
          this.getPostsByHost(source.url, blForceRefresh).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  getPostsSuggestions() {
    return this.getPosts({
      limit: 10,
    });
  }

  hidePostByUid(postId: number, blHide: boolean) {
    console.log('> hide post by uid', postId, blHide);
  }

  orderBy(orderBy: string | null = null) {
    if (orderBy !== null) {
      this._orderBy = orderBy;
      return this;
    }
    return this._orderBy;
  }

  rejectPost(postId: number) {
    this.cache.remove("blog_public");
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        uid: postId,
      },
      ["posts", "reject"]
    );
  }

  updatePost(post: post) {
    post.active = !!post.active;

    if (!post.language) {
      post.language = this.userService.getLanguage();
    }

    if (!!post.name) {
      post.title = post.name;
    }

    return this.AppCMS.loadPluginData('pipeline', {
      post: post,
      user: this.userService.getUid()
    }, ['posts', post.uid, 'update']);
  }

}