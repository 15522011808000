import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';

import { AiModelsPage } from 'src/app/pages/core/ai/ai-models/ai-models.page';

import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
    providedIn: 'root'
})
export class AiModelsService {

    constructor(
        private aiBridge: AiBridgeService,

        private AppCMS: AppcmsService,
        private chooser: ChooserService,
    ) {

    }

    choose(options: any = {}, blForceRefresh: boolean = false) {
        return new Promise(async (resolve, reject) => {
            try {

                const chooseConfig: chooseConfig = Object.assign(options, {
                    component: AiModelsPage,
                    labelKey: 'name',
                    multiple: !!options.multiple,
                    subLabelKey: 'provider',
                    title: 'choose_ai_model',
                    valueKey: 'uid',
                });

                const choose: chooseResponse = await this.chooser.choose(chooseConfig);

                if (choose && choose.data && choose.data.items && choose.data.items.length && (choose.data.items.length > 1)) {
                    resolve(choose.data);
                } else
                    if (choose && choose.data && choose.data.item) {
                        resolve(choose.data.item);
                    } else {
                        resolve(false);
                    }
            } catch (e) {
                reject(e);
            }
        });
    }

    delete(model: aiModel) {
        return this.AppCMS.loadPluginData('pipeline', {
            model_uid: model.uid,
        }, ['ai', 'models', 'delete']);
    }

    getAvailableImageModels(options: any = {}, blForceRefresh: boolean = false) {
        options.filter = options.filter || {};
        options.filter.type = 'image';

        return this.getAvailable(options, blForceRefresh);
    }

    getAvailable(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'models'], {}, blForceRefresh);
    }

    async getPreferred(context: string = 'general') {
        return this.aiBridge.getPreferredModels(context);
    }

    async setPreferred(models: aiModel[]) {
        return this.aiBridge.setPreferredModels(models);
    }

}