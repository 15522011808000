import { Injectable } from '@angular/core';

import { UserService } from 'src/app/services/core/user.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
    providedIn: 'root'
})
export class UserPickerService {

    constructor(
        private chooser: ChooserService,
        private userService: UserService,
    ) {

    }

    pick(options: chooseConfig = null) {
        return new Promise(async (resolve, reject) => {

            const chooseConfig: chooseConfig = Object.assign((options || {}), {
                labelKey: 'email',
                service: this.userService,
                subLabelKey: 'nickname',
                valueKey: 'uid',
            });

            this.chooser.choose(chooseConfig).then(resolve).catch(reject);
        });
    }

}