import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  _detailItem: any | null;

  blDebug: boolean = false;

  debounceBlocks: any = {};

  debounceTimeout: number = 10;

  eventsStorage: any = {};

  constructor(
  ) {
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  publish(key: string, data: any | null = null): boolean {

    const isCircular = (obj: any, seen = new WeakSet()): boolean => {
      if (typeof obj !== 'object' || obj === null) return false;
      if (seen.has(obj)) return true;
      seen.add(obj);
      return Object.values(obj).some(val => isCircular(val, seen));
    }

    try {

      if (this.blDebug) {
        console.log(`[ EVENTS ] Publish event: key: ${key}`);
        console.log(`[ EVENTS ] Publish event: data:`, data);
      }

      if (!this.debounceBlocks[key]) {
        this.debounceBlocks[key] = data;

        Promise.resolve().then(() => {
          this.debounceBlocks[key] = false;
        });

        this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
        this.eventsStorage[key].next(data);

        return true;
      }

      if (typeof data === 'object' && data !== null && isCircular(data)) {
        if (this.blDebug) {
          console.log('[ EVENTS ] Publish event: blocked: circular reference detected');
        }
        return false;
      }

      if (typeof data === 'object' && typeof this.debounceBlocks[key] === 'object') {
        const areEqual = Object.entries(data || {}).toString() ===
          Object.entries(this.debounceBlocks[key] || {}).toString();

        if (areEqual) {
          if (this.blDebug) {
            console.log('[ EVENTS ] Publish event: blocked: object', true);
          }
          return false;
        }
      }

      if (this.debounceBlocks[key] === data) {
        if (this.blDebug) {
          console.log(`[ EVENTS ] Publish event: blocked: ${typeof data}`, true);
        }
        return false;
      }

      this.debounceBlocks[key] = data;

      Promise.resolve().then(() => {
        this.debounceBlocks[key] = false;
      });

      this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
      this.eventsStorage[key].next(data);

      return true;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Unknown error';

      console.error('[ FATAL ERROR] Event handling failed:', errorMessage);
      console.error(error);

      alert(errorMessage);

      return false;
    }
  }

  stop(eventsList: any[] | null = null) {

    if (!eventsList) {
      return false;
    }

    let _event: any, keys: string[] = Object.keys(eventsList);

    keys.forEach((eventKey: string) => {
      try {
        _event = eventsList[eventKey];
        _event.unsubscribe();
      } catch (e) {
        console.warn('> unsubscribing event failed', _event, e);
      }
    });
  }

  subscribe(key: string, callback: any | null = null): Subject<any> {
    this.eventsStorage[key] = this.eventsStorage[key] || new Subject<any>();
    return this.eventsStorage[key].subscribe((data: any) => {
      if (callback) {
        callback(data);
      }
    });
  }

}