import { Component, NgZone, OnInit } from '@angular/core';

import { UserService } from 'src/app/services/core/user.service';
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';
import { PostsService } from 'src/app/services/posts/posts.service';

@Component({
  selector: 'app-post-information',
  standalone: false,
  templateUrl: './post-information.page.html',
  styleUrls: ['./post-information.page.scss'],
})
export class PostInformationPage implements OnInit {

  post: post;

  constructor(
    private posts: PostsService,
    private postsAdmin: PostsAdminService,
    private zone: NgZone,
    public userService: UserService,
  ) {
    this.post = (this.postsAdmin.detailItem() || {} as any);
  }

  ionViewWillEnter() {
    this.zone.run(() => {
      this.post = this.posts.getFullPost(this.post);
    });
  }

  ngOnInit() {
  }

}
