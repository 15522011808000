<ion-spinner color="white" class="top-spinner" [hidden]="!loading"></ion-spinner>

<!-- Mode: View -->
<div class="image-view-wrapper"
    *ngIf="(!media.mode || (media.mode === 'view')) && (!media.post_mime_type || (media.post_mime_type === 'image'))"
    [hidden]="!!media.live_preview && (media.mode === 'inpaint' || media.mode === 'outpaint') && (view.mode === 'edit') && (view.targetSelectMode === 'inpaint')">

    <!-- Proxied Image -->
    <!--
    <img class="previewExifImage" hidden (load)="onExifImageLoaded()" #exifImage
    *ngIf="!media.post_mime_type || (media.post_mime_type === 'image')"
    [src]="proxyUrl + ((media.photo || media.guid) || media.thumbnail)" />
    -->

    <div class="preview-image-stage viewer-container">
        <ul #mediaItemsList id="mediaItemsList">

            <!-- Main media item -->
            <li>
                <img class="image previewImage" [alt]="media.title" (load)="onLoadImage()" #previewImage
                    id="previewImage" [src]="media.live_preview || ((media.photo || media.guid) || media.thumbnail)" />
            </li>

            <!-- Media list (if provided) -->
            <li *ngFor="let _media of gallery; let i = index; trackBy:trackItems">
                <img *ngIf="_media.post_mime_type === 'image'" [alt]="_media.title"
                    [src]="_media.live_preview || ((_media.photo || _media.guid) || _media.thumbnail)" />
            </li>

        </ul>
    </div>

</div>

<!-- Mode: Inpainting / outpainting -->
<div class="image-editor-wrapper" [class.mini]="mini"
    *ngIf="!!media.mode && ((media.mode === 'inpaint') || (media.mode === 'outpaint'))">

    <!-- Overlay canvas -->
    <canvas
        *ngIf="(view.targetSelectMode === 'inpaint') && !!inpaintCanvas && (media.mode === 'inpaint' && !!media.overlay_initialized)"
        #overlayCanvas drawing [(canvas)]="inpaintCanvas" [config]="drawingConfig" [(overlay)]="overlayCanvas"
        (onStop)="_onInpaintChanged($event, media, index)" [height]="view.drawCanvasHeight"
        [width]="view.drawCanvasWidth" class="drawing-canvas overlay"></canvas>

    <!-- Inpaint canvas -->
    <canvas class="mode-{{view.targetSelectMode}}" *ngIf="media.mode === 'inpaint' && !!media.overlay_initialized"
        #inpaintCanvas [height]="view.drawCanvasHeight" [width]="view.drawCanvasWidth" class="drawing-canvas"></canvas>

    <!-- Image (Edit / inpaint mode )-->
    <img-comparison-slider width="100%" #slider
        *ngIf="!!media.live_preview && (!media.post_mime_type || (media.post_mime_type === 'image')) && (((media.mode === 'inpaint') || (media.mode === 'outpaint')) || view.mode === 'edit')">

        <!-- Before -->
        <img [alt]="media.title" slot="first" [src]="(media.photo || media.guid) || media.thumbnail" />

        <!-- After -->
        <img [alt]="media.title" slot="second"
            [src]="(media.live_preview || ((media.photo || media.guid) || media.thumbnail)) || fallbackImg" />

    </img-comparison-slider>

    <!-- Inpaint options -->
    <ion-item lines="none" *ngIf="media.mode === 'inpaint'">

        <ion-checkbox [(ngModel)]="view.inpainting_send_mask" justify="start" labelPlacement="end">
            <p [innerHTML]="'send_mask'|translate"></p>
        </ion-checkbox>

        <ion-checkbox [(ngModel)]="view.inpainting_outpaint" justify="start" labelPlacement="end"
            [disabled]="!view.inpainting_send_mask">
            <p [innerHTML]="'invert_selection'|translate"></p>
        </ion-checkbox>

    </ion-item>

    <!-- Outpaint options -->
    <ion-grid *ngIf="media.mode === 'outpaint'">
        <ion-row>

            <ion-col>
                <ion-item lines="none">
                    <ion-icon [hidden]="!view.isDesktop" name="swap-vertical-outline" slot="start"></ion-icon>
                    <ion-input [label]="'height'|translate" labelPlacement="stacked" type="number" [step]="1"
                        [min]="128" [max]="2048" [(ngModel)]="aiSettings.height"></ion-input>
                </ion-item>
            </ion-col>

            <ion-col>
                <ion-item lines="none">
                    <ion-icon [hidden]="!view.isDesktop" name="swap-horizontal-outline" slot="start"></ion-icon>
                    <ion-input [label]="'width'|translate" labelPlacement="stacked" type="number" [step]="1" [min]="128"
                        [max]="2048" [(ngModel)]="aiSettings.width"></ion-input>
                </ion-item>
            </ion-col>

        </ion-row>
    </ion-grid>

</div>

<!-- Mode: Polotno -->
<div class="polotno-wrapper" [class.loading]="!!loading" [class.mini]="mini"
    *ngIf="!!media.mode && (media.mode === 'polotno')">
    <div id="polotno" #polotnoElement></div>
</div>