import { Injectable } from '@angular/core';

import { AppcmsService } from "src/app/services/core/appcms.service";
import { CacheService } from 'src/app/services/core/cache.service';

import { proxyUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class CrawlerService {

    // LOOP VARS
    _nestingLevel: number = 0;

    _nestingDepth: number = 10;

    _url: string;

    _useProxy: boolean = true;

    // END LOOP VARS

    crawledLinks: string[] = [];

    foundLinks: string[] = [];

    proxyUrl: string;

    storeResults: any[] = [];

    constructor(
        private AppCMS: AppcmsService,
        private cache: CacheService,
    ) {
        this.proxyUrl = proxyUrl;
    }


    createCrawler(crawler: any, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            crawler: crawler,
        }), ['crawler', 'create']);
    }

    deleteCrawler(crawlerId: number, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            crawler: crawlerId,
        }), ['crawler', 'delete']);
    }

    exportLinks(rawResponse: string, options: any = {}) {
        let snippet = document.createElement("div");
        snippet.innerHTML = rawResponse;

        const elements: any = snippet.getElementsByTagName("a");
        let links: string[] = [];

        links = [...elements]
            .map((element: any) => {
                let link: string = `${element.href || ''}`.replace(window.location.origin, '');

                if (link[0] === '/' && !!options.root) {
                    link = `${options.root}${link}`;
                }

                return link;
            })
            .filter((link: string) => {
                return !!link && (
                    (link[0] !== '#') &&
                    (link.indexOf('.jpg') === -1) &&
                    (link.indexOf('.jpeg') === -1) &&
                    (link.indexOf('.mp3') === -1) &&
                    (link.indexOf('.mp4') === -1) &&
                    (link.indexOf('.png') === -1) &&
                    (link.indexOf('.web') === -1)
                );
            });

        links = [... new Set(links)];

        return {
            elements: elements,
            links: links,
        }
    }

    getCacheKey(url: string, options: any = {}) {
        options = options || {};
        options.url = url;

        return `crawling_cache_result_${JSON.stringify(options)}`;
    }

    getCrawlers(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['crawler', 'list'], {}, blForceRefresh);
    }

    async getLocalFetchResponse(url: string, params: any = {}) {
        const cacheKey: string = this.getCacheKey(url, params);
        const fromCache: cacheItem | null = await this.cache.get(cacheKey, -1);

        return (!!fromCache && !!fromCache.data ? fromCache.data : null);
    }

    getNestingDepth() {
        return this._nestingDepth;
    }

    getNestingLevel() {
        return this._nestingLevel;
    }

    getUrl() {
        return this._url;
    }

    fetch(url: string, options: any = {}, blForceRefresh: boolean = false) {
        return this.fetchOnly(url, options);
    }

    fetchOnly(url: string, options: any = {}, blForceRefresh: boolean = false) {
        return new Promise(async (resolve, reject) => {

            if (!!options.proxy || this.use_proxy()) {
                url = this.proxyUrl + url;
            }

            try {
                let params: any = {};

                if (!!options.headers) {
                    params.headers = options.headers;
                }

                if (!!options.method) {
                    params.method = options.method;
                }

                const cachedResult: any = (!!blForceRefresh ? null : await this.getLocalFetchResponse(url, options));

                if (!!cachedResult && !blForceRefresh) {
                    resolve(cachedResult);
                } else {

                    // fetch response
                    const rawResponse: any = await fetch(url, params).then((response) => response.text());

                    // store result
                    try {
                        await this.store(url, rawResponse, options);
                    } catch (e) {
                        console.warn('crawler: storing fetch result failed', e);
                    }

                    // return result
                    resolve(rawResponse);
                }
            } catch (e) {
                reject(e);
            }
        });
    }

    /*
    fetchAndRender(url: string, options: any = {}) {
        return new Promise(async (resolve, reject) => {
            console.log('fetchAndRender: url', url);

            if (!!options.proxy || this.use_proxy()) {
                url = this.proxyUrl + url;
            }

            const iframe: any = document.createElement('iframe');
            iframe.style.display = 'none';

            iframe.setAttribute('sandbox', 'allow-same-origin allow-scripts allow-forms');
            iframe.setAttribute('crossorigin', 'anonymous');

            iframe.onload = () => {
                try {
                    console.log('fetchAndRender: iframe: onload', iframe);

                    if (!iframe.contentDocument) {
                        throw new Error('error_cors_same_origin_policy_violation');
                    }

                    if (iframe.contentDocument.readyState !== 'complete') {
                        await new Promise((resolve) => {
                            console.log('registering second load event: event', event);

                            iframe.contentWindow?.addEventListener('load', (event: any) => {
                                console.log('second load event fired: event', event);
                                resolve(event);
                            });
                        });
                    }

                    const renderedHtml = iframe.contentDocument.documentElement.outerHTML;

                    if (!renderedHtml || renderedHtml.trim() === '') {
                        throw new Error('error_empty_content');
                    }

                    document.body.removeChild(iframe);

                    resolve(renderedHtml);
                } catch (err) {
                    document.body.removeChild(iframe);
                    reject(new Error(`Failed to access iframe content: ${err.message}`));
                }
            };

            iframe.onerror = (err: Event) => {
                document.body.removeChild(iframe);
                reject(new Error('error_failed_loading_url_content'));
            };

            try {
                document.body.appendChild(iframe);
                iframe.src = url;
            } catch (err) {
                if (iframe.parentNode) {
                    document.body.removeChild(iframe);
                }
                reject(err);
            }
        });
    }
    */

    index() {

    }

    run(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            options = options || {};

            const url: string | null = this.getUrl();

            if (!url || !url.length) {
                reject('error_missing_crawling_url');
            }

            this.crawledLinks = [];
            this.foundLinks = [];
            this.storeResults = [];

            try {

                // fetch current url
                const rawResponse: any = await this.fetch(url, options);

                // fire on page loaded callback
                if (!!options.onPageLoaded) {
                    options.onPageLoaded({
                        options: options,
                        response: rawResponse,
                        url: url,
                    });
                }

                // export links
                const exportLinks: any = this.exportLinks(rawResponse, { root: url });
                let aResponse: any = {};

                // crawling all found links on page
                if (!!exportLinks && !!exportLinks.links && !!exportLinks.links.length) {

                    exportLinks.links.forEach(async (link: string) => {

                        if (this.foundLinks.indexOf(link) === -1) {
                            this.foundLinks.push(link);

                            if (!!options.onFoundLinksChanged) {
                                options.onFoundLinksChanged({
                                    links: this.crawledLinks,
                                });
                            }
                        }

                        if (!!options.onCurrentLinkChanged) {
                            options.onCurrentLinkChanged({
                                link: link,
                            });
                        }

                        await this.fetch(link, options);

                        if (this.crawledLinks.indexOf(link) === -1) {
                            this.crawledLinks.push(link);

                            if (!!options.onCrawledLinksChanged) {
                                options.onCrawledLinksChanged({
                                    links: this.crawledLinks,
                                });
                            }
                        }
                    });

                    aResponse.links = exportLinks.links;
                }

                aResponse.results = this.storeResults;

                resolve(aResponse);
            } catch (e) {
                reject(e);
            }
        });
    }

    setNestingDepth(depth: number) {
        this._nestingDepth = depth;
        return this;
    }

    setUrl(url: string) {
        this._url = url;
        return this;
    }

    async store(url: string, response: any, options: any = {}) {

        try {
            await this.storeLocal(url, response, options);
        } catch (e) {
            console.warn('crawler: storing local failed', e);
        }

        return this.storeServerSide(url, response, options);
    }

    storeLocal(url: string, response: any, options: any = {}) {
        const cacheKey: string = this.getCacheKey(url, options);
        return this.cache.set(cacheKey, response);
    }

    storeServerSide(url: string, response: any, options: any = {}) {
        options = options || {};
        options.response = response;
        options.url = url;

        return new Promise((resolve, reject) => {
            this.AppCMS.loadPluginData('pipeline', options, ['crawler', 'store'])
                .then((response: any) => {
                    this.storeResults = this.storeResults || [];
                    this.storeResults.push(response);

                    resolve(response);
                })
                .catch(reject);
        })
    }

    updateCrawler(crawler: any, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            crawler: crawler,
        }), ['crawler', 'update']);
    }

    use_proxy(bl: boolean | null = null) {
        if (bl !== null) {
            this._useProxy = bl;
            return this;
        }
        return this._useProxy;
    }

}