import { Injectable } from '@angular/core';
import { LoadingController } from "@ionic/angular";

import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ProjectsService } from './projects.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectsPickerService {

    constructor(
        private chooser: ChooserService,
        private loadingCtrl: LoadingController,
        private projects: ProjectsService,
    ) {

    }

    pick(options: any | null = null, event: any | null = null) {
        return new Promise(async (resolve, reject) => {
            const loading: any = await this.loadingCtrl.create();
            loading.present();

            try {
                const data: project[] = (await this.projects.getAll() || []) as project[];
                loading.dismiss();

                const chooseConfig: chooseConfig = Object.assign((options || {}), {
                    data: (data || []),
                    labelKey: 'title',
                    service: this,
                    valueKey: 'uid',
                });

                this.chooser.choose(chooseConfig)
                    .then((chooseResponse: chooseResponse) => {
                        resolve(chooseResponse);
                    })
                    .catch(reject);
            } catch (e) {
                loading.dismiss();
                reject(e);
            }
        });
    }

}