<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="container">

    <ion-buttons slot="start">

      <!-- Back button -->
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!view.startManually">

      <!-- Save -->
      <ion-button (click)="create()" color="primary" shape="round" fill="solid" *ngIf="!crawler.uid">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

      <!-- Update -->
      <ion-button (click)="update()" color="primary" shape="round" fill="solid" *ngIf="!!crawler.uid">
        <ion-icon name="save-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <!-- Connect mail crawler intro mode -->
  <pipeline-intro-overlay *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)"
    [config]="introOverlayConfig" [hidden]="!!view.startManually" [loading]="view.loading" (onCreate)="aiCreate()"
    (onSkip)="startManually()" (onSlideNext)="onIntroOverlaySlideNext($event)" [(view)]="view"></pipeline-intro-overlay>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" [hidden]="view.introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <ion-grid>

      <ion-row>

        <!-- General + Credentials -->
        <ion-col size="12">

          <!-- General -->
          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'mail_crawler_general'|translate"></ion-card-title>
            </ion-card-header>

            <ion-list>

              <ion-item [hidden]="!crawler.type">
                <ion-icon name="eye-outline" slot="start"></ion-icon>
                <ion-input labelPlacement="stacked" [label]="'name'|translate" [label]="'mail_crawler_name'|translate"
                  [(ngModel)]="crawler.name"></ion-input>
              </ion-item>

              <!-- Connection Type -->
              <ion-item lines="none">

                <ion-icon [name]="view.crawlerTypeIcons[crawler.type] || 'mail-outline'" slot="start"></ion-icon>

                <ion-select interface="popover" [label]="'mail_crawler_type'|translate" [(ngModel)]="crawler.type"
                  [okText]="'okay' | translate" [cancelText]="'cancel' | translate"
                  (ionChange)="onCrawlerTypeChanged($event)">
                  <ion-select-option *ngFor="let type of crawlerTypes" [value]="type"
                    [innerHTML]="('mail_crawler_type_' + type)|translate"></ion-select-option>
                </ion-select>
              </ion-item>

            </ion-list>

          </ion-card>

          <!-- Credentials -->
          <ion-card [hidden]="!crawler.type" *ngIf="!!view.startManually">

            <ion-card-header>
              <ion-card-title [innerHTML]="'authentification'|translate"></ion-card-title>
            </ion-card-header>

            <form>

              <ion-list>

                <!-- Username / Email -->
                <ion-item>
                  <ion-icon name="mail-outline" slot="start"></ion-icon>
                  <ion-input name="mailMailCrawlerEmail" labelPlacement="stacked"
                    [label]="'mail_crawler_email_address'|translate" placeholder="info@example.com"
                    [(ngModel)]="crawler.email"></ion-input>
                </ion-item>

                <!-- 2FA -->
                <ion-item
                  [hidden]="crawler.type !== 'exchange' && crawler.type !== 'google' && crawler.type !== 'icloud'"
                  [disabled]="crawler.type === 'exchange'">
                  <ion-icon name="shield-checkmark-outline" slot="start"></ion-icon>
                  <ion-checkbox name="mailMailCrawlerUseTwoFactor" justify="start" labelPlacement="end"
                    [(ngModel)]="crawler.use_two_factor_auth">
                    <ion-label [innerHTML]="'use_two_factor_auth'|translate"></ion-label>
                  </ion-checkbox>
                </ion-item>

                <!-- App / Account Password -->
                <ion-item lines="none">
                  <ion-icon name="lock-closed-outline" slot="start"></ion-icon>
                  <ion-input name="mailMailCrawlerPassword" type="password" labelPlacement="stacked"
                    [label]="(crawler.use_two_factor_auth ? 'app_password' : 'mail_crawler_password')|translate"
                    [(ngModel)]="crawler.password"></ion-input>
                </ion-item>

                <!-- App Password information text -->
                <ion-item lines="none" [hidden]="!crawler.use_two_factor_auth" color="warning">
                  <ion-icon name="warning-outline" slot="start"></ion-icon>
                  <ion-label [innerHTML]="'app_password_required_text'|translate"></ion-label>
                </ion-item>

              </ion-list>

            </form>

          </ion-card>

        </ion-col>

        <!-- Incoming -->
        <ion-col [size]="view.isDesktop ? 6 : 12" [hidden]="!crawler.type">

          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'mail_crawler_server_in'|translate"></ion-card-title>
            </ion-card-header>

            <ion-list>

              <!-- Incoming server: Type -->
              <ion-item lines="none">

                <ion-icon name="cloud-download-outline" slot="start"></ion-icon>

                <ion-select interface="popover" [label]="'mail_crawler_server_in_type'|translate"
                  [(ngModel)]="crawler.server_in_type" [okText]="'okay' | translate"
                  [cancelText]="'cancel' | translate">
                  <ion-select-option value="imap"
                    [innerHTML]="'mail_crawler_server_in_type_imap'|translate"></ion-select-option>
                  <ion-select-option value="pop3"
                    [innerHTML]="'mail_crawler_server_in_type_pop3'|translate"></ion-select-option>
                </ion-select>
              </ion-item>

              <!-- Incoming server: Host -->
              <ion-item [hidden]="!crawler.server_in_type">
                <ion-input labelPlacement="stacked" [label]="'mail_crawler_server_in_host'|translate"
                  [placeholder]="crawler.server_in_type + '.example.com'"
                  [(ngModel)]="crawler.server_in_host"></ion-input>
              </ion-item>

              <!-- Incoming Server: Port -->
              <ion-item [hidden]="!crawler.server_in_type" lines="none" [hidden]="!view.expertMode">
                <ion-input type="number" labelPlacement="stacked" [label]="'mail_crawler_server_in_port'|translate"
                  [placeholder]="crawler.server_in_type === 'pop3' ? 995 : 993"
                  [(ngModel)]="crawler.server_in_port"></ion-input>
              </ion-item>

            </ion-list>

          </ion-card>

        </ion-col>

        <!-- Outgoing -->
        <ion-col [size]="view.isDesktop ? 6 : 12" [hidden]="!crawler.type">

          <ion-card>

            <ion-card-header>
              <ion-card-title [innerHTML]="'mail_crawler_server_out'|translate"></ion-card-title>
            </ion-card-header>

            <ion-list>

              <!-- Outgoing server host -->
              <ion-item>

                <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>

                <ion-input labelPlacement="stacked" [label]="'mail_crawler_server_out_host'|translate"
                  placeholder="smtp.example.com" [(ngModel)]="crawler.server_out_host"></ion-input>

              </ion-item>

              <!-- Outgoing server port -->
              <ion-item lines="none" [hidden]="!view.expertMode">
                <ion-input type="number" labelPlacement="stacked" [label]="'mail_crawler_server_out_port'|translate"
                  placeholder="465" [(ngModel)]="crawler.server_out_port"></ion-input>
              </ion-item>

            </ion-list>

          </ion-card>
        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border" *ngIf="!!view.startManually">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>