import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-media-create-form-automotive',
  standalone: false,
  templateUrl: './media-create-form-automotive.component.html',
  styleUrls: ['./media-create-form-automotive.component.scss'],
})
export class MediaCreateFormAutomotiveComponent implements OnInit {
  @Input() view: any;

  fallbackImg: string = './assets/img/fallback.webp';

  constructor() { }
  
  ngOnInit() {
    this.view.variables = [
      {
        uid: 'manufacturer',
        label: 'manufacturer',
        type: 'string',
      },
      {
        uid: 'model',
        label: 'model',
        type: 'string',
      },
      {
        uid: 'year_of_construction',
        label: 'year_of_construction',
        type: 'number',
      },
      {
        uid: 'horse_power',
        label: 'horse_power',
        type: 'number',
      },
      {
        uid: 'number_doors',
        label: 'number_doors',
        type: 'number',
      },
      {
        uid: 'price',
        label: 'price',
        type: 'string',
      },
      {
        uid: 'legal_notes',
        label: 'legal_notes',
        type: 'string',
      },
    ];
  }

}
