<ion-header class="ion-no-border">

  <!-- Top header bar -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Options button -->
      <ion-button (click)="showHeaderPopover($event)" fill="clear" icon-only color="dark" [disabled]="!!view.loading">
        <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
      </ion-button>

      <!-- Add button -->
      <ion-button (click)="add($event)" shape="round" fill="solid" color="primary"
        [disabled]="!!view.loading || ((view.mode === 'pick') && (!view.selectedItems || !view.selectedItems.length))">
        <ion-icon slot="start" name="add"></ion-icon>
        <ion-label [innerHTML]="'add'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header bar -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!-- Search toolbar -->
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view"
    (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>

  <!--
  Mobile segment:
  <ion-toolbar class="ion-no-border container second-toolbar" *ngIf="!view.isDesktop">
    <ion-segment scrollable [(ngModel)]="view.type" (ionChange)="typeChanged()">
      <ion-segment-button *ngFor="let type of view.types" [value]="type.uid">
        <ion-icon *ngIf="!!type.icon" [name]="type.icon"></ion-icon>
        <ion-label [innerHTML]="type.name|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
  -->

  <!-- Mobile selection bar -->
  <pipeline-selection-toolbar *ngIf="!view.rebuild && !view.isDesktop" [filters]="view.selectionFilters"
    [(items)]="view.posts" [languageOptions]="languageOptions" [options]="view.currentSelectionOptions"
    [(paginationConfig)]="paginationConfig" [(view)]="view" (onLanguageChanged)="onLanguageChanged($event)"
    (onSelectionActionChanged)="onSelectionActionChanged($event)" (onViewTypeChanged)="onViewTypeChanged($event)">
  </pipeline-selection-toolbar>

</ion-header>

<ion-content fullscreen="true">

  <!-- Header actions popover -->
  <ion-popover #headerPopover [isOpen]="isHeaderPopoverOpen" (didDismiss)="isHeaderPopoverOpen = false">
    <ng-template>

      <ion-list>

        <!-- Import -->
        <ion-item button (click)="importPosts()" [disabled]="!!view.loading">
          <ion-icon name="cloud-upload-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'import'|translate"></ion-label>
        </ion-item>

        <!-- Export -->
        <ion-item lines="none" button (click)="export()" [disabled]="!!view.loading"
          *ngIf="!!view.posts && !!view.posts.length">
          <ion-icon name="cloud-download-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'export'|translate"></ion-label>
        </ion-item>
      </ion-list>

    </ng-template>
  </ion-popover>

  <!-- Refresher -->
  <ion-refresher *ngIf="!view.dragging" slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!view.isDesktop" [hidden]="view.introCard.hidden || !view.isDesktop">
      <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
    </div>

    <!-- Main grid -->
    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col [size]="view.colSize.left" class="left"
          [hidden]="!view.expertMode || (!view.isDesktop && !view.showFilters)">

          <!-- Folders -->
          <pipeline-folders-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [service]="postsAdmin" [(view)]="view"
            (onItemsChanged)="onFolderLoaded($event)"
            (onChanged)="loadPostsByFolder($event)"></pipeline-folders-filter-card>

          <!-- Filters -->
          <pipeline-items-filters-card *ngIf="!!view.expertMode" [(cards)]="cards" [(view)]="view"
            (filtersChanged)="onFiltersChanged($event)"></pipeline-items-filters-card>

          <!-- Categories -->
          <!--
          <pipeline-categories-filter-card [(cards)]="cards" [(view)]="view" (onChanged)="onCategoriesFilterChanged($event)"></pipeline-categories-filter-card>
          -->

          <!-- Connections -->
          <pipeline-connections-filter-card *ngIf="!!view.expertMode" [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col [size]="view.expertMode ? (view.colSize.right || 12) : 12" class="right"
          [hidden]="!view.isDesktop && !!view.showFilters">

          <!-- Desktop post type segment bar -->
          <!--
          <ion-toolbar class="ion-no-border container" *ngIf="!!view.isDesktop">

            <ion-segment scrollable [(ngModel)]="view.type" (ionChange)="typeChanged()">
              <ion-segment-button *ngFor="let type of view.types" [value]="type.uid">
                <ion-icon *ngIf="!!type.icon" [name]="type.icon"></ion-icon>
                <ion-label [innerHTML]="type.name|translate"></ion-label>
              </ion-segment-button>
            </ion-segment>

          </ion-toolbar>
          -->

          <!-- Desktop selection toolbar -->
          <pipeline-selection-toolbar *ngIf="!view.rebuild && !!view.isDesktop" [filters]="view.selectionFilters"
            [(items)]="view.posts" [languageOptions]="languageOptions" [options]="view.currentSelectionOptions"
            [(paginationConfig)]="paginationConfig" [(view)]="view" (onLanguageChanged)="onLanguageChanged($event)"
            (onSelectionActionChanged)="onSelectionActionChanged($event)"
            (onViewTypeChanged)="onViewTypeChanged($event)">
          </pipeline-selection-toolbar>

          <!-- Desktop intro card -->
          <pipeline-intro-card *ngIf="!!view.isDesktop" [hidden]="view.introCard.hidden || !view.isDesktop"
            class="introCard" [(view)]="view.introCard"></pipeline-intro-card>

          <!-- No entries card -->
          <pipeline-no-entries-card [cta]="cta" [hidden]="!!view.loading" [text]="'posts_admin_cta_text'|translate"
            *ngIf="!!view.posts && !view.posts.length"></pipeline-no-entries-card>

          <!-- Loading spinner -->
          <ion-spinner name="circular"
            *ngIf="!view.posts || (!!view.loading && (!view.posts || !view.posts.length))"></ion-spinner>

          <!-- View: Calendar -->
          <pipeline-calendar *ngIf="view.posts && view.posts.length && (view.viewType === 'calendar')"
            [(events)]="eventsList" [options]="calendarOptions" [selectionOptions]="selectionOptions">
          </pipeline-calendar>

          <!-- View: Chart -->
          <pipeline-chart
            *ngIf="!!view.expertMode && !!view.statistics && !!view.statistics.length && !!view.chart && (view.viewType === 'chart')"
            [chart]="view.chart"></pipeline-chart>

          <!-- View: Grid & list -->
          <div class="posts-grid-wrapper" *ngIf="!view.rebuild && !!view.posts && !!view.posts.length"
            [hidden]="view.viewType !== 'grid' && view.viewType !== 'list'">

            <!-- Post grid -->
            <ion-grid class="posts-grid">
              <ion-row>

                <ion-col *ngFor="let post of view.posts; let i = index" [hidden]="post.hidden"
                  [draggable]="view.isDesktop" [dragItem]="post" [attr.drag-location]="'POSTS_ADMIN'"
                  [attr.data-model]="view.posts" [size]="view.colSize.item" [attr.data-location]="'posts'"
                  [attr.data-type]="'post'" [attr.data-uid]="post.uid">

                  <!-- Post card -->
                  <pipeline-post-card [index]="i" [(post)]="view.posts[i]" [(posts)]="view.posts"
                    [selectionOptions]="selectionOptions" [(view)]="view"
                    (onPostItemChecked)="onPostItemChecked($event)" (onShouldRefresh)="doRefresh()"
                    (onShouldRunSearch)="runSearch()">
                  </pipeline-post-card>

                </ion-col>

              </ion-row>
            </ion-grid>

          </div>

          <!-- View: Table -->
          <div *ngIf="view.viewType === 'table'" [hidden]="!!view.loading">

            <pipeline-table-view (itemsChanged)="onItemsChanged($event)" (itemsUpdated)="onItemsUpdated($event)"
              (fieldsChanged)="onFieldsChanged($event)" [buttons]="buttons" [(fields)]="view.tableFields"
              [(items)]="view.posts" [method]="'getAll'" mode="view" [options]="tableViewOptions" [service]="postsAdmin"
              [(view)]="view">
            </pipeline-table-view>

          </div>

        </ion-col>

      </ion-row>
    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode toggle -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>