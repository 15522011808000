import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CreatorsService } from 'src/app/services/pipeline/creators.service';

@Injectable({
  providedIn: 'root'
})
export class ShopAdminService {

  _detailItem: source;

  systems: string[] = ['csv', 'custom', 'json', 'rss', 'shopify', 'shopware', 'web', 'woocommerce', 'xml'];

  constructor(
    private AppCMS: AppcmsService,
    private creators: CreatorsService,
  ) {

  }

  acceptProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['shop', 'products', 'accept']);
  }

  createProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['products', 'create']);
  }

  deleteSource(source: shopSource) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {},
      ['shop', "sources", source.uid, "delete"]
    );
  }

  detailItem(item: source | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  exportSourceData(sourceId: any) {
    console.log('exportSourceData', sourceId);

    return this.AppCMS.loadPluginData('pipeline', {
      source_uid: sourceId,
    }, ['shop', 'export']);
  }

  get(options: any = {}) {
    return this.creators.getShops(options);
  }

  getAll(options: any = {}) {
    return this.get(options);
  }

  getSystems() {
    return this.systems;
  }

  importProducts(products: product[], options: any = {}) {
    return new Promise(async (resolve, reject) => {

      let response: any = {
        counts: { all: products.length, done: 0, error: 0, success: 0 },
        sync: [],
      };

      products.forEach((product: product, index: number) => {
        setTimeout(async () => {
          try {
            const importProduct: any = await this.createProduct(product);
            console.log('> import product', importProduct);

            response.counts.done++;
            response.counts.success++;

            response.sync.push({
              success: true,
              data: importProduct
            });

            if (!!options.hasOwnProperty('onPartialResult')) {
              options.onPartialResult(response);
            }

            if (index === (products.length - 1)) {
              resolve(response);
            }
          } catch (e) {
            console.warn('> error', e);

            response.counts.done++;
            response.counts.error++;

            response.sync.push({
              success: false,
              error: e,
            });

            if (!!options.hasOwnProperty('onPartialResult')) {
              options.onPartialResult(response);
            }

            if (index === (products.length - 1)) {
              resolve(response);
            }
          }
        }, (index * 200));
      });
    });
  }

  importSourceData(sourceId: any) {
    console.log('importSourceData', sourceId);

    return this.AppCMS.loadPluginData('pipeline', {
      source_uid: sourceId,
    }, ['shop', 'import']);
  }

  updateProduct(product: product) {
    return this.AppCMS.loadPluginData('pipeline', {
      product: product,
    }, ['products', 'update']);
  }

  updateSource(source: shopSource) {
    source = JSON.parse(JSON.stringify(source));
    delete source.configured;

    return this.AppCMS.loadPluginData('pipeline', {
      source: source,
    }, ['shop', 'sources', source.uid, 'update']);
  }

}