<ion-header class="ion-no-border">

  <!-- Top header -->
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Menu button (if not modal) -->
      <ion-menu-button *ngIf="view.isModal === false"></ion-menu-button>

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

    </ion-buttons>

    <ion-buttons slot="end">

      <!-- Update -->
      <ion-button (click)="update()" *ngIf="type && !!type.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!type.name || !type.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

      <!-- Save -->
      <ion-button (click)="save()" *ngIf="!type || !type.uid" shape="round" color="primary" fill="solid" icon-only
        [disabled]="!type.name || !type.name.length">
        <ion-icon name="checkmark" slot="start"></ion-icon>
        <ion-label [innerHTML]="'save'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <!-- Main header -->
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content>

  <div class="container">

    <!-- Intro card -->
    <div class="ion-padding" *ngIf="!!introCard" [hidden]="introCard.hidden">
      <pipeline-intro-card class="introCard" [(view)]="introCard"></pipeline-intro-card>
    </div>

    <!-- Loading spinner -->
    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <div [hidden]="!!view.loading">

      <!-- Information -->
      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'general'|translate"></ion-card-title>
        </ion-card-header>

        <ion-list>

          <!-- Photo + name -->
          <ion-item>

            <ion-thumbnail slot="start" (click)="uploadPhoto()">
              <ion-img [src]="type.photo || fallbackImg" (ionError)="thumbnailLoadingFailed(type)"></ion-img>
            </ion-thumbnail>

            <ion-input type="text" [label]="'name'|translate" [autofocus]="true" labelPlacement="stacked"
              [(ngModel)]="type.name" [placeholder]="'object_type_name'|translate"></ion-input>

          </ion-item>

          <!-- Icon -->
          <pipeline-icon-picker (valueEvent)="updateIcon($event)"></pipeline-icon-picker>

          <!-- Description -->
          <ion-item>

            <ion-textarea rows="3" [label]="'description'|translate" labelPlacement="stacked"
              [(ngModel)]="type.description" [placeholder]="'object_type_description'|translate"></ion-textarea>

          </ion-item>

          <!-- Template -->
          <ion-item *ngIf="!!view.templates && !!view.templates.length">
            <ion-select [(ngModel)]="view.template" [label]="'template'|translate" labelPlacement="floating"
              interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
              <ion-select-option *ngFor="let template of view.templates" [value]="template.uid"
                [innerHTML]="template.name|translate"></ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item lines="none" [hidden]="!type || !type.uid">
            <ion-checkbox [(ngModel)]="type.active" alignment="end" justify="space-between" labelPlacement="start">
              <p [innerHTML]="'state_active'|translate" class="ion-text-wrap"></p>
            </ion-checkbox>
          </ion-item>

        </ion-list>
      </ion-card>

      <!-- Fields -->
      <ion-card>

        <ion-card-header>
          <ion-card-title [innerHTML]="'fields'|translate"></ion-card-title>
        </ion-card-header>

        <ion-grid *ngIf="!!type.fields && !!type.fields.length">
          <ion-row *ngFor="let field of type.fields">

            <!-- Identifier -->
            <ion-col [size]="view.isDesktop ? 4 : 12">
              <ion-item lines="none">
                <ion-input [(ngModel)]="field.uid" [label]="'key'|translate" labelPlacement="stacked"
                  [placeholder]="'object_type_field_identifier_placeholder'|translate"></ion-input>
              </ion-item>
            </ion-col>

            <!-- Name -->
            <ion-col [size]="view.isDesktop ? 4 : 12">
              <ion-item lines="none">
                <ion-input [(ngModel)]="field.name" [label]="'description'|translate" labelPlacement="stacked"
                  [placeholder]="'object_type_field_name_placeholder'|translate"></ion-input>
              </ion-item>
            </ion-col>

            <!-- Type -->
            <ion-col [size]="view.isDesktop ? 4 : 12" *ngIf="!!fieldTypes && !!fieldTypes.length">
              <ion-item lines="none">
                <ion-select [(ngModel)]="field.type" labelPlacement="stacked" interface="popover"
                  [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [label]="'type'|translate">
                  <ion-select-option *ngFor="let type of fieldTypes" [innerHTML]="type.name|translate"
                    [value]="type.uid"></ion-select-option>
                </ion-select>
              </ion-item>
            </ion-col>

          </ion-row>
        </ion-grid>

        <ion-button (click)="addField($event)" fill="clear" color="dark">
          <ion-icon name="add-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'add'|translate"></ion-label>
        </ion-button>

      </ion-card>

    </div>

  </div>

</ion-content>