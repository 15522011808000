import { Injectable } from '@angular/core';

import { AppcmsService } from './appcms.service';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  create(userGroup: any) {
    return this.AppCMS.loadPluginData('customers', { group: userGroup }, ['user_groups', 'create']);
  }

  getAvailable(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('customers', {}, ['user_groups'], null, blForceRefresh);
  }

  update(userGroup: any) {
    return this.AppCMS.loadPluginData('customers', { group: userGroup }, ['user_groups', 'update']);
  }

}