<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">

      <!-- Window buttons -->
      <pipeline-header-window-buttons [(view)]="view" *ngIf="!!view.isModal"></pipeline-header-window-buttons>

      <ion-menu-button *ngIf="!view.isModal"></ion-menu-button>

    </ion-buttons>

    <ion-buttons slot="end" *ngIf="!!appConfig.useMediaCreator" [hidden]="!view.startManually">

      <!-- Small generate button (if buttonAction = use)-->
      <ion-button icon-only *ngIf="view.buttonAction === 'use'"
        [hidden]="(view.phase === 'loading') || (view.phase === 'presenting')" (click)="generate()"
        [disabled]="!!view.loading || !view.hasSelectedTypes || ((!view.mediaList || !view.mediaList.length) && (!search || !search.query || !search.query.length))"
        color="dark" fill="clear">
        <ion-icon name="sync-outline"></ion-icon>
      </ion-button>

      <!-- Use button -->
      <ion-button *ngIf="view.buttonAction === 'use'"
        [hidden]="(view.phase === 'loading') || (view.phase === 'presenting')"
        [disabled]="!view.selectedItems || !view.selectedItems.length" (click)="generate()" shape="round"
        color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'use'|translate"></ion-label>
      </ion-button>

      <!-- Main generate button -->
      <ion-button *ngIf="view.buttonAction === 'generate'"
        [hidden]="(view.phase === 'loading') || (view.phase === 'presenting')" (click)="generate()"
        [disabled]="!view.hasSelectedTypes"
        shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'generate'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <!--
  <pipeline-selection-toolbar [(items)]="view.images" [(view)]="view" [options]="selectionOptions" (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>
  -->

</ion-header>

<ion-content [fullscreen]="true">

  <div class="style-helper-element a"></div>
  <div class="style-helper-element b"></div>
  <div class="style-helper-element c"></div>

  <div class="container" *ngIf="!appConfig.useMediaCreator">
    <ion-card>
      <ion-card-header>
        <ion-card-title [innerHTML]="'tool_removed_card_title'|translate"></ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <p [innerHTML]="'tool_removed_card_description'|translate"></p>
      </ion-card-content>
    </ion-card>
  </div>

  <div class="container"
    *ngIf="!!appConfig.useMediaCreator && (!view.project || !view.project.uid && !!appConfig.useProjectsExtension)">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container creator-container"
    *ngIf="!!appConfig.useMediaCreator && (!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid))">
    <pipeline-media-creator [(cards)]="cards" [(search)]="search" [(view)]="view"
      (onSelectionChanged)="onSelectionChanged($event)"
      (onViewChanged)="onViewChanged($event)"></pipeline-media-creator>
  </div>

</ion-content>

<ion-footer class="ion-no-border" [hidden]="!view.isDesktop && (view.phase !== 'input')"
  *ngIf="!!appConfig.useMediaCreator && !(!!view.media && !view.media.uid && !view.startManually)">
  <ion-toolbar class="container ion-no-border">
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view"></pipeline-view-mode-picker>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>