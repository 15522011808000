import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from "src/app/services/core/view.service";
import { IntroService } from 'src/app/services/utils/intro.service';

@Component({
  selector: 'pipeline-intro-card',
  standalone: false,
  templateUrl: './intro-card.component.html',
  styleUrls: ['./intro-card.component.scss'],
})
export class IntroCardComponent implements OnInit {
  @Input() view: any;

  @Input() activeIndex: number = 0;

  appConfig: pipelineAppConfig;

  @Input() cards: any = {};

  @Input() config: introCardConfig = {};

  @Input() lottieOptions: lottieOptions = {};

  @Input() media: any = {};
  @Input() mediaList: mediaItem[] = [];

  @Input() mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    hideDescription: true,
    hideHeader: true,
    showGenerateOptions: true,
  };

  @Output() onDismiss = new EventEmitter();
  @Output() onMediaChanged = new EventEmitter();

  @Input() sliderOptions: any = {
    autoHeight: true,
    parallax: false,
    navigation: false,
    speed: 1000,
    slidesPerView: 1,
    slidesPerGroup: 1,
  };

  @Input() splineOptions: splineOptions = {};

  @ViewChild('introCardSwiper') swiper: ElementRef | undefined;

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private introService: IntroService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  _onMediaChanged(event: any = null) {
    this.view.mediaList = (event || []);
    this.onMediaChanged.emit(this.view.mediaList);
  }

  async calcHidden() {
    this.view.hidden = await this.introService.isIntroCardHidden(this.view.uid);
  }

  async calcSliderVars() {

    try {
      if (!!this.swiper && !!this.swiper.nativeElement) {
        this.activeIndex = await this.swiper.nativeElement.swiper.activeIndex;
      }
    } catch (e) {
      console.warn('calcSliderVars: get swiper activeIndex failed', e);
    }

    // calculate slider button states
    this.view.canSlideBack = !!this.config.groups && !!this.config.groups.length && !!this.activeIndex;
    this.view.canSlideDone = !!this.config.groups && !!this.config.groups.length && (this.config.groups.length === (this.activeIndex + 1));
    this.view.canSlideNext = !this.view.canSlideDone && (!!this.config.groups && !!this.config.groups.length && (this.config.groups.length > this.activeIndex));

    if (!!this.config.groups && !!this.config.groups.length) {
      console.log('active group:', this.config.groups[this.activeIndex]);
    }
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcSliderVars();
  }

  configReady() {

  }

  dataReady() {

  }

  destroy(event: any | null = null) {
    console.warn('destroy', event);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.introService.isIntroCardHidden(this.view.uid, true);
    this.view.hidden = true;

    this.onDismiss.emit({
      data: data,
      role: role,
      view: this.view,
    });
  }

  domLoaded() {

  }

  error(event: any | null = null) {
    console.warn('error', event);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  loadLottie() {

    if (!this.view.lottieSrc || !this.view.lottieSrc.length) {
      return false;
    }

    this.lottieOptions.render_path = this.view.lottieSrc;

    if (!!this.lottieOptions.render_path) {
      this.updateLottieAnimation();
    }
  }

  loadSpline() {

    if (!this.view.splineSrc || !this.view.splineSrc.length) {
      return false;
    }

    this.lottieOptions.render_path = this.view.splineSrc;

    if (!!this.lottieOptions.render_path) {
      this.updateSplineAnimation();
    }

  }

  ngOnChanges() {
    this.calcHidden();
  }

  ngOnInit() {
    this.calcHidden();
    this.calcViewVars();

    this.loadLottie();
    this.loadSpline();
  }

  async slideBack() {
    await this.swiper.nativeElement.swiper.slidePrev();
    this.calcSliderVars();
  }

  slidechangetransitionend(event: any) {
    this.calcSliderVars();
  }

  slidechangetransitionstart(event: any) {
    this.calcSliderVars();
  }

  slideNext() {
    this.update()
      .then(async () => {
        try {
          await this.swiper.nativeElement.swiper.slideNext();
          this.calcSliderVars();
        } catch (e) {
          console.warn('> slide next failed', e);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  slideDone() {

  }

  toggleButton(button: button, iButton: number, iGroup: number) {
    console.log('toggleButton: button', button);

    if (!button) {
      return false;
    }

    button.checked = !button.checked;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  updateLottieAnimation(): void {
    this.lottieOptions = {
      ...this.lottieOptions,
      path: this.lottieOptions.render_path,
    };
  }

  updateSplineAnimation() {
    this.splineOptions = {
      ...this.splineOptions,
      path: this.splineOptions.render_path,
    };
  }

  async update() {
    return true;
  }

}