import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';

import { AlertService } from "src/app/services/core/alert.service";

@Injectable({
  providedIn: "root",
})
export class ToolsService {

  categoryIcons: any = {
    'Accessoires': 'diamond-outline',
    'Aktien': 'bar-chart-outline',
    'Autos': 'car-outline',
    'Basketball': 'basketball-outline',
    'Business & Finanzen': 'briefcase-outline',
    'Comedy': 'happy-outline',
    'Computer': 'laptop-outline',
    'Cryptowährungen': 'logo-bitcoin',
    'eBikes': 'bicycle-outline',
    'Fashion & Beauty': 'shirt-outline',
    'Fernseher': 'tv-outline',
    'Filme': 'film-outline',
    'Football': 'american-football-outline',
    'Fotografie': 'camera-outline',
    'Fussball': 'football-outline',
    'Gaming': 'game-controller-outline',
    'Garten': 'leaf-outline',
    'Gesundheit': 'body-outline',
    'Golf': 'golf-outline',
    'Hardware': 'hardware-chip-outline',
    'Immobilien': 'business-outline',
    'Körper & Geist': 'leaf-outline',
    'Küche': 'restaurant-outline',
    'Laptops': 'laptop-outline',
    'Luftverkehr': 'airplane-outline',
    'Leichtathletik': 'body-outline',
    'Marketing': 'megaphone-outline',
    'Meldungen': 'alert-circle-outline',
    'Mobilität': 'bicycle-outline',
    'Musik': 'musical-notes-outline',
    'Programmierung': 'code-outline',
    'Regionales': 'location-outline',
    'Schlafzimmer': 'bed-outline',
    'Server': 'server-outline',
    'Smartphones': 'phone-portrait-outline',
    'Sprachen': 'language-outline',
    'Sport': 'football-outline',
    'Startup': 'rocket-outline',
    'Steuern': 'cash-outline',
    'Tablets': 'tablet-portrait-outline',
    'Technik': 'save-outline',
    'Tennis': 'tennisball-outline',
    'Tipps': 'bulb-outline',
    'Uhren': 'time-outline',
    'Unser Planet': 'globe-outline',
    'Unterhaltung': 'wine-outline',
    'Unternehmensführung': 'business-outline',
    'Weltraum': 'rocket-outline',
    'Wirtschaft': 'trending-up-outline',
    'Wissen': 'book-outline',
    'Wohnen': 'home-outline',
  };

  videoPosterUrl: string = './assets/img/pipeline_loading.webp';

  /* Used to define which device UI should be loaded */
  viewports: any = {
    car: 1024,
    desktop: 1024,
    mobile: 768,
    tablet: 769,
    ultrawide: 2800,
    watch: 360,
  };

  constructor(
    private alertService: AlertService,
    private platform: Platform,
  ) {

  }

  bulk(config: bulkConfig) {
    return new Promise((resolve, reject) => {
      config.identifier = config.identifier || 'uid';

      if (!config.action || !config.action.length) {
        reject('error_missing_bulk_config_action');
      } else
        if (!config.hasOwnProperty('params') && (!config.items || !config.items.length)) {
          reject('error_missing_bulk_config_items');
        } else
          if (!config.hasOwnProperty('service')) {
            reject('error_missing_bulk_config_service');
          } else
            if (config.action.indexOf('delete') !== -1) {
              try {
                this.alertService.requestConfirm()
                  .then((bl: boolean) => {
                    if (!!bl) {
                      this.runBulk(config).then(resolve).catch(reject);
                    }
                  })
                  .catch((e: any) => {
                    console.warn('executing button action failed', e);
                    reject(e);
                  });
              } catch (e) {
                console.warn('executing button action failed', e);
                reject(e);
              }
            } else {
              this.runBulk(config).then(resolve).catch(reject);
            }
    });
  }

  camelCase(string: string, seperator: string = '_', capitalizeFirstCharacter: boolean = false) {
    let str: string = string.split(seperator).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');

    if (!capitalizeFirstCharacter) {
      str = str.charAt(0).toLowerCase() + str.slice(1);
    }

    return str;
  }

  capitalize(string: string) {
    return `${string || ''}`.charAt(0).toUpperCase() + `${string || ''}`.slice(1);
  }

  cleanContent(post, key = 'post_content') {
    let content = post[key];

    if (!content) {
      return '';
    }

    if (content && (typeof content === 'object') && content.hasOwnProperty('rendered')) {
      content = content.rendered;
    }

    content = this.decodeEntities(content);

    let contentElement: HTMLElement = document.createElement('div');
    contentElement.innerHTML = content;

    Array.from(contentElement.getElementsByTagName('img')).forEach((element) => {
      element.setAttribute('loading', 'lazy');

      if (element.getAttribute('src') && element.getAttribute('srcset')) {
        element.removeAttribute('srcset');
      }
    });

    Array.from(contentElement.querySelectorAll('style,form,input,button')).forEach((element: any) => {
      element.parentNode?.removeChild(element);
    });

    Array.from(contentElement.getElementsByTagName('*')).forEach((element) => {
      element.removeAttribute('class');
      element.removeAttribute('style');

      Array.from(element.attributes).forEach((attr) => {
        if (attr.name.startsWith('data-')) {
          element.removeAttribute(attr.name);
        }
      });
    });

    content = contentElement.outerHTML;

    if (post.thumbnail && post.thumbnail.length) {
      const thumbnailSplit = post.thumbnail.split("/wp-content/");
      let thumbnailLookup = thumbnailSplit[thumbnailSplit.length - 1];

      thumbnailLookup = thumbnailLookup.replace(".jpg", "").replace(".png", "").replace(".jpeg", "");

      const thumbnailLookupSplit = thumbnailLookup.split('-');

      if (isNaN(thumbnailLookupSplit[thumbnailLookupSplit.length - 1])) {
        thumbnailLookup = thumbnailLookup.replace('-' + thumbnailLookupSplit[thumbnailLookupSplit.length - 1], '');
      }

      const selector = "img[src*='" + thumbnailLookup + "']";
      let thumbnailInContent = contentElement.querySelector(selector);

      if (thumbnailInContent) {
        thumbnailInContent.parentNode?.removeChild(thumbnailInContent);
      }
    }

    content = contentElement.outerHTML;
    content = (content || '').replace(/\[.*?\]/g, "");

    Array.from(contentElement.getElementsByTagName('video')).forEach((videoElement) => {
      videoElement.setAttribute('webkit-playsinline', '');
      videoElement.setAttribute('playsinline', '');
      videoElement.setAttribute("preload", 'auto');
      videoElement.setAttribute('controlsList', 'nodownload');
      videoElement.setAttribute('poster', this.getVideoPosterUrl());
      videoElement.classList.add('pipeline-video');
    });

    content = contentElement.outerHTML;

    return content;
  }

  cleanLink(link: string) {
    return (link || '').replace('https://', '').replace('http://', '').replace('www.', '');
  }

  decodeEntities(encodedString: string) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;

    return (textArea.value || '').replace('=“', '="').replace('″]', '"]');
  }

  extractJson(string: string) {
    string = `${string || ''}`.replace(/[\n\r\t]/gm, "");

    const startChars = ['{', '['];
    let jsonStart = -1;

    // Find first occurrence of either { or [
    for (const char of startChars) {
      const index = string.indexOf(char);
      if (index !== -1 && (jsonStart === -1 || index < jsonStart)) {
        jsonStart = index;
      }
    }

    if (jsonStart === -1) return null;

    let bracketCount = 0;
    let inString = false;
    let escapeNext = false;

    for (let i = jsonStart; i < string.length; i++) {
      const char = string[i];

      if (escapeNext) {
        escapeNext = false;
        continue;
      }

      if (char === '\\') {
        escapeNext = true;
        continue;
      }

      if (char === '"' && !escapeNext) {
        inString = !inString;
        continue;
      }

      if (inString) continue;

      if (char === '{' || char === '[') {
        bracketCount++;
      } else if (char === '}' || char === ']') {
        bracketCount--;
        if (bracketCount === 0) {
          try {
            const json = string.substring(jsonStart, i + 1);
            return JSON.parse(json);
          } catch (e) {
            return null;
          }
        }
      }
    }

    return null;
  }

  generateRandomUid() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  getCalcRandomSize(
    lastSize: number,
  ) {
    if (lastSize) {
      return 12 - lastSize;
    }
    const sizes = window.innerWidth >= 768 ? [6, 12, 4, 8] : [6, 12];
    return sizes[Math.floor(Math.random() * sizes.length)];
  }

  getCategoryIcon(categoryName: string) {
    return this.categoryIcons[this.decodeEntities(categoryName)];
  }

  getOS() {
    return this.platform.is('ios') ? 'ios' : (this.platform.is('android') ? 'android' : 'web');
  }

  getVideoFromContent(content: string) {
    let div: HTMLElement = document.createElement('div');
    div.innerHTML = content;

    const video: HTMLElement | null = div.querySelector('video');

    return (video ? video.outerHTML : null);
  }

  getVideoPosterUrl() {
    return this.videoPosterUrl;
  }

  groupBy(data: any[], key: string) {
    return data.reduce(function (r, a) {
      r[a[key]] = r[a[key]] || [];
      r[a[key]].push(a);
      return r;
    }, Object.create(null));
  }

  isDesktop() {
    return (window.innerWidth >= this.viewports.desktop) && !this.isTablet() && this.isLandscape();
  }

  isLandscape() {
    return window.innerWidth >= window.innerHeight;
  }

  isPortrait() {
    return !this.isLandscape();
  }

  isPropValuesEqual(subject: object, target: object, propNames: string[]) {
    return propNames.every(propName => subject[propName] === target[propName]);
  }

  isTablet() {
    return (window.innerWidth >= this.viewports.tablet) && this.isPortrait();
  }

  isUltraWide() {
    return window.innerWidth >= this.viewports.ultrawide;
  }

  isValidURL(string: string, blAppendHttpProtocolIfMissing: boolean = true) {

    if (!!blAppendHttpProtocolIfMissing && (string.indexOf('http') !== 0) && (string.indexOf('.') !== -1)) {
      string = `http://${string}`;
    }

    var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

    return !!urlPattern.test(string);
  }

  isVideoModeContent(content: string) {
    return (content.substr(0, 30).indexOf('<video') !== -1)
  }

  isWeb() {
    return this.platform.is('mobileweb') || this.platform.is('desktop') || (document.URL || '').indexOf('de.app.tie') >= 0 || (document.URL || '').indexOf('web.pipeline.page') >= 0;
  }

  nl2br(str: string, is_xhtml: boolean = false) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  runBulk(config: bulkConfig) {
    return new Promise((resolve, reject) => {
      let i: number = 0;

      if (!config.hasOwnProperty('items') && !config.hasOwnProperty('params')) {
        reject('error_missing_bulk_config_and_params');
      } else
        if (!config.hasOwnProperty('items') && config.hasOwnProperty('params') && typeof config.service[config.action] === 'function') {
          config.service[config.action](config.params).then(resolve).catch(reject);
        } else
          if (typeof config.service[config.action] === 'function') {
            config.items.forEach((item: any) => {
              if (!!item[config.identifier]) {
                config.service[config.action](
                  (config.params || (!!config.useObjectAsIdentifier ? item : item[config.identifier]))
                )
                  .then((response: any) => {
                    i++;

                    if (config.hasOwnProperty('onItemResponse')) {
                      config.onItemResponse(response, item);
                    }

                    if (i === (config.items.length)) {
                      resolve(true);
                    }
                  })
                  .catch(() => {
                    i++;
                    if (i === (config.items.length)) {
                      resolve(true);
                    }
                  });
              } else {
                i++;
                if (i === (config.items.length)) {
                  resolve(true);
                }
              }
            });
          } else {
            reject('error_method_not_implemented');
          }
    })
  }

  splitTripleBackticksWithCodeBlocks(text: string) {
    const parts: string[] = `${text || ''}`.split(/([^`]+)/g);
    console.log('splitTripleBackticksWithCodeBlocks: parts', parts);

    let result: string[] = [];

    for (const part of parts) {
      if (/<pre>|<code>/i.test(part)) {
        const codeParts = part.split(/(<pre>.*?&lt;\/pre&gt;|<code>.*?&lt;\/code&gt;)/gis);
        console.log('splitTripleBackticksWithCodeBlocks: codeParts', codeParts);

        for (const codePart of codeParts) {
          if (codePart.trim()) {
            result.push(codePart);
          }
        }
      } else {
        // Split on double line breaks
        const breakParts = part.split(/(\r?\n\r?\n|\r\r|<br\s*\ ?="">\s*<br\s*\ ?="">)/gi);
        console.log('splitTripleBackticksWithCodeBlocks: breakParts', breakParts);

        for (const breakPart of breakParts) {
          if (breakPart.trim()) {
            result.push(breakPart);
          }
        }
      }
    }

    console.log('splitTripleBackticksWithCodeBlocks: result', result);
    return result;
  }

  shuffle(a: any) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  stripHtml(html: string) {
    var tmp: HTMLElement = document.createElement("DIV");
    tmp.innerHTML = (html || '').replace(/<[^>]*>?/gm, '');

    html = (tmp.textContent || tmp.innerText || "");
    return html;
  }

  textToList(text: string, blTrim: boolean = true, blTrimNumbers: boolean = true) {
    return `${text || ''}`.split("\n")
      .map((line: string) => {

        if (!!blTrim) {
          line = this.trim(this.stripHtml(`${line || ''}`).replace('• ', '').replace('- ', '').trim(), '"');
        }

        if (!!blTrimNumbers) {
          if (line[1] === '.') {
            line = line.slice(3);
          } else
            if (line[2] === '.') {
              line = line.slice(4);
            }
        }

        return line;
      }).filter((line: string) => {
        return !!line && !!line.length;
      });
  }

  titleCase(string: string) {
    var splitStr: string[] = string.toLowerCase().split(' ');

    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = `${splitStr[i]}`.charAt(0).toUpperCase() + `${splitStr[i]}`.substring(1);
    }

    return splitStr.join(' ');
  }

  trim(s: string, c: string = ' ') {
    if (c === "]") c = "\\]";
    if (c === "^") c = "\\^";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp(
      "^[" + c + "]+|[" + c + "]+$", "g"
    ), "");
  }

  truncateBySentences(text: string, sentCount: number = 5, moreText: string = "") {
    var sentences = text.match(/[^\.!\?]+[\.!\?]+/g);
    if (sentences) {
      if (sentences.length >= sentCount && sentences.length > sentCount) {
        return sentences.slice(0, sentCount).join('') + moreText;
      }
    }
    return text;
  }

  unique(items: any[], propNames: string | string[]) {
    const propNamesArray = Array.from(propNames);
    return items.filter((item, index, array) => {
      return index === array.findIndex(foundItem => {
        return this.isPropValuesEqual(foundItem, item, propNamesArray);
      });
    });
  };

  validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateUrl(url: string, blAppendHttpProtocolIfMissing: boolean = true) {
    
    if(!url || !url.length) {
      return {
        success: false,
        url: url,
      };
    }

    if (!!blAppendHttpProtocolIfMissing && (url.indexOf('http') !== 0) && (url.indexOf('.') !== -1)) {
      url = `http://${url}`;
    }

    return {
      success: this.isValidURL(url),
      url: url,
    };
  }

  wrapURL(text: string, target: string = '_blank') {

    if (!!text && (text.indexOf('<a href') !== -1)) {
      return text;
    }

    const urlPattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\z`!()\[\]{};:'".,<>?«»“”‘’]))/ig;

    const result = text.replace(urlPattern, function (url) {
      return `<a href="${url.trim()}" ${!!target ? `target="${target}"` : target} class="wrapped-a-tag">${url.trim()}</a>`;
    });

    return result;
  }

}