import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';

import { EditorService } from 'src/app/services/utils/editor.service';

// text editor 
import editorJsHTML from "editorjs-html/.build/edjsHTML.node";

// Newsletter / WYSIWYG editor
import grapesjs from 'grapesjs';
import plugin from 'grapesjs-blocks-basic';
import customCodePlugin from 'grapesjs-custom-code';
import gjsForms from 'grapesjs-plugin-forms';
import pluginTooltip from 'grapesjs-tooltip';
import grapesjsTouch from 'grapesjs-touch';

import 'grapesjs-component-countdown';
import 'grapesjs-parser-postcss';
import 'grapesjs-plugin-ckeditor';
import 'grapesjs-plugin-export';
import 'grapesjs-preset-newsletter';
import 'grapesjs-style-bg';
import 'grapesjs-tabs';
import 'grapesjs-tui-image-editor';
import 'grapesjs-typed';

@Component({
  selector: 'pipeline-editor',
  standalone: false,
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements AfterViewInit {

  blockDetectOnChange: boolean = true;

  codeTheme: string = 'vs-dark';

  codeModel: any = {
    language: 'typescript',
    uri: 'index.js',
    value: "\n",
  };

  codeOptions: any = {
    contextmenu: true,
    minimap: {
      enabled: true
    }
  };

  cssBackup: string;

  @Input() disabled: boolean;

  @Input() editor: any;
  //editor: any;

  @ViewChild('editorJs', { read: ElementRef }) private editorElement: ElementRef;

  @Input() id: string | null;

  @Input() input: string;

  @Output() inputChange = new EventEmitter();

  @Input() language: string;

  @Input() loading: boolean = false;

  @Input() mode: string = 'text'; // text or code

  rebuild: boolean = false;

  saveProcess: any;

  constructor(
    private editorService: EditorService,
    private zone: NgZone,
  ) {

  }

  detectChanges() {
    /*
    this.zone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
    */
  }

  public async extractCSS(html: string) {

    // Remove comments and style tags from HTML
    let cleanedHtml = html.replace(/|]*>|]*>|/gi, '');
    cleanedHtml = cleanedHtml.replace(/<\!--.*?-->/g, "");
    cleanedHtml = cleanedHtml.replace(/<!--([^-]|-[^-]|--+[^->])*--+>/g, '');
    cleanedHtml = cleanedHtml.replace(/style type="text\/css"\n/gi, 'style type="text/css"></style>\n');
    cleanedHtml = cleanedHtml.replace(/link type="text\/css"\n/gi, 'link type="text/css" />\n');

    // Extract CSS from style tags and remove style tags
    let styleTagsRegex = /]*>([^<]+)<\/style>/gm;
    let cssMatches = cleanedHtml.match(styleTagsRegex);
    let css = cssMatches ? cssMatches.map((styleTag) => styleTag.replace(/]*>|<\/style>/g, '')).join('\n') : '';

    // Remove style tags from cleaned HTML
    //cleanedHtml = cleanedHtml.replace(styleTagsRegex, '');
    cleanedHtml = cleanedHtml.replace(/<!--([^-]|-[^-]|--+[^->])*--+>/g, '');
    cleanedHtml = cleanedHtml.replace(/style type="text\/css"\n/gi, 'style type="text/css"></style>\n');
    cleanedHtml = cleanedHtml.replace(/link type="text\/css"\n/gi, 'link type="text/css" />\n');

    // Remove comments from CSS
    css = css.replace(/\/\*[\s\S]*?\*\/|([^:]|^)\/\/.*$/gm, '');

    return {
      html: cleanedHtml, // html, //cleanedHtml,
      css: css
    };
  }

  public getCss() {

    if (!this.editor) {
      return false;
    }

    return this.editor.getCss();
  }

  async initCodeEditor() {

    if (this.mode !== 'code') {
      return false;
    }

    if (!!this.language) {
      this.codeModel.language = this.language;
    }

    this.setInput(this.input || "\n");
  }

  async initNewsletterEditor() {
    var host = 'https://grapesjs.com/';

    // Set up GrapesJS editor with the Newsletter plugin
    this.editor = grapesjs.init({
      selectorManager: { componentFirst: true },
      //clearOnRender: true,
      height: '100%',
      storageManager: {
        type: 'local', // Type of the storage, available: 'local' | 'remote'
        autosave: false, // Store data automatically
        autoload: false, // Autoload stored data on init
        stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        options: {
          local: { // Options for the `local` type
            key: 'gjsProject', // The key for the local storage
          },
        },
      },
      assetManager: {
        assets: [
          host + 'img/grapesjs-logo.png',
          host + 'img/tmp-blocks.jpg',
          host + 'img/tmp-tgl-images.jpg',
          host + 'img/tmp-send-test.jpg',
          host + 'img/tmp-devices.jpg',
        ],
        upload: false,
        //uploadText: 'Uploading is not available in this demo',
      },
      container: '#gjs',
      fromElement: true,
      showOffsets: false,
      plugins: [
        'grapesjs-preset-newsletter',
        //'grapesjs-preset-webpage',

        customCodePlugin,
        grapesjsTouch,
        plugin,
        pluginTooltip,
        gjsForms,

        'grapesjs-plugin-ckeditor',
        'grapesjs-component-countdown',
        'grapesjs-plugin-export',
        'grapesjs-tabs',
        'grapesjs-parser-postcss',
        'grapesjs-tui-image-editor',
        'grapesjs-typed',
        'grapesjs-style-bg',
      ],
      pluginsOpts: {
        'grapesjs-preset-newsletter': {
          modalLabelImport: 'Paste all your code here below and click import',
          modalLabelExport: 'Copy the code and use it wherever you want',
          codeViewerTheme: 'material',
          importPlaceholder: '<table class="table"><tr><td class="cell">Hello world!</td></tr></table>',
          cellStyle: {
            'font-size': '12px',
            'font-weight': 300,
            'vertical-align': 'top',
            color: 'rgb(111, 119, 125)',
            margin: 0,
            padding: 0,
          }
        },
        'grapesjs-plugin-ckeditor': {
          onToolbar: el => {
            el.style.minWidth = '350px';
          },
          options: {
            startupFocus: true,
            extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
            allowedContent: true, // Disable auto-formatting, class removing, etc.
            enterMode: 2, // CKEDITOR.ENTER_BR,
            extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
            toolbar: [
              { name: 'styles', items: ['Font', 'FontSize'] },
              ['Bold', 'Italic', 'Underline', 'Strike'],
              { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'colors', items: ['TextColor', 'BGColor'] },
            ],
          }
        },

        plugin: { flexGrid: true },
        'grapesjs-tui-image-editor': {
          script: [
            // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
            'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
          ],
          style: [
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
          ],
        },
        'grapesjs-tabs': {
          tabsBlock: { category: 'Extra' }
        },
        'grapesjs-typed': {
          block: {
            category: 'Extra',
            content: {
              type: 'typed',
              'type-speed': 40,
              strings: [
                'Text row one',
                'Text row two',
                'Text row three',
              ],
            }
          }
        },
      }
    } as any);

    this.setHtml(this.input, true);

    // Let's add in this demo the possibility to test our newsletters
    var pnm = this.editor.Panels;
    var cmdm = this.editor.Commands;
    var md = this.editor.Modal;

    // Add info command
    var infoContainer = document.getElementById("info-panel");

    if (!!cmdm) {
      cmdm.add('open-info', {
        run: (editor: any, sender: any) => {
          var mdlClass = 'gjs-mdl-dialog-sm';
          sender.set('active', 0);
          var mdlDialog = document.querySelector('.gjs-mdl-dialog');
          mdlDialog.className += ' ' + mdlClass;
          infoContainer.style.display = 'block';
          md.open({
            title: 'About this demo',
            content: infoContainer,
          });
          md.getModel().once('change:open', () => {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, '');
          })
        }
      });
    }

    if (!!pnm) {
      // Add info button
      const iconStyle = 'style="display: block; max-width: 22px"';

      pnm.addButton('options', [{
        id: 'view-info',
        label: `<svg ${iconStyle} viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
        </svg>`,
        command: 'open-info',
        attributes: {
          'title': 'About',
          'data-tooltip-pos': 'bottom',
        },
      }]);

      // Beautify tooltips
      [
        ['sw-visibility', 'Show Borders'],
        ['preview', 'Preview'],
        ['fullscreen', 'Fullscreen'],
        ['export-template', 'Export'],
        ['undo', 'Undo'],
        ['redo', 'Redo'],
        ['gjs-open-import-template', 'Import'],
        ['gjs-toggle-images', 'Toggle images'],
        ['canvas-clear', 'Clear canvas']
      ].forEach((item) => {
        try {
          const button: any = pnm.getButton('options', item[0]);

          if (!!button) {
            button.set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom' });
          }
        } catch (e) {
          console.warn('updating editor button failed', e);
        }
      });

    }

    // [
    //   ['open-sm', 'Style Manager'],
    //   ['open-layers', 'Layers'],
    //   ['open-blocks', 'Blocks']
    // ].forEach(function(item) {
    //   pnm.getButton('views', item[0]).set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom', title2: item[1] });
    //   console.log('views', item[0], pnm.getButton('views', item[0]).get('attributes'))
    // });

    var titles = document.querySelectorAll('*[title]');

    for (var i = 0; i < titles.length; i++) {
      var el = titles[i];
      var title = el.getAttribute('title');
      title = title ? title.trim() : '';
      if (!title)
        break;
      el.setAttribute('data-tooltip', title);
      el.setAttribute('title', '');
    }

    // Update canvas-clear command
    cmdm.add('canvas-clear', () => {
      if (confirm('Are you sure to clean the canvas?')) {
        this.editor.runCommand('core:canvas-clear');

        setTimeout(() => {
          localStorage.clear();
        }, 0);
      }
    });

    //this.editor.setDevice('mobilePortrait');

    this.editor.on('load', () => {
      try {
        //const deviceManager = this.editor.Devices;
        //const devices = deviceManager.getDevices();
        const $ = grapesjs.$;

        // Hide borders by default
        pnm.getButton('options', 'sw-visibility').set('active', 0);

        // Load and show settings and style manager
        const openTmBtn = pnm.getButton('views', 'open-tm');
        openTmBtn && openTmBtn.set('active', 1);
        const openSm = pnm.getButton('views', 'open-sm');
        openSm && openSm.set('active', 1);

        // Remove trait view
        //pnm.removeButton('views', 'open-tm');

        // Add Settings Sector
        const traitsSector = $(`
        <div class="gjs-sm-sector no-select">
          <div class="gjs-sm-sector-title"><span class="icon-settings fa fa-cog"></span> <span class="gjs-sm-sector-label">Settings</span></div>
          <div class="gjs-sm-properties" style="display: none;"></div>
        </div>`);

        if (!traitsSector) {
          return false;
        }

        const traitsProps = traitsSector.find('.gjs-sm-properties');

        if (!traitsProps) {
          return false;
        }

        traitsProps.append($('.gjs-trt-traits'));

        $('.gjs-sm-sectors').before(traitsSector);

        traitsSector.find('.gjs-sm-sector-title').on('click', function () {
          var traitStyle = traitsProps.get(0).style;
          const hidden = traitStyle.display == 'none';
          if (hidden) {
            traitStyle.display = 'block';
          } else {
            traitStyle.display = 'none';
          }
        });

        // Open block manager
        const openBlocksBtn = this.editor.Panels.getButton('views', 'open-blocks');
        openBlocksBtn && openBlocksBtn.set('active', 1);

      } catch (e) {
        console.warn('init newsletter editor failed:', e);
      }
    });

    this.loading = false;
  }

  async initTextEditor() {

    if (!this.editorElement || !this.editorElement.nativeElement) {
      return false;
    }

    const edjsParser = editorJsHTML();

    this.blockDetectOnChange = true;

    this.editor = await this.editorService.create(this.editorElement.nativeElement, {
      onChange: async (api: any) => {

        if (!!this.blockDetectOnChange) {
          return false;
        }

        const content = await api.saver.save();
        const blockStrings: any = edjsParser.parse(content);
        let html: string;

        if (typeof blockStrings === 'string') {
          html = blockStrings;
        } else {
          html = (blockStrings || [] as string[]).join("\n");
        }

        this.zone.run(() => {
          this.input = `${html || ''}`;
          this.codeModel.value = this.input;
          this.inputChange.emit(this.input);
        });
      },
    });

    setTimeout(() => {

      try {
        if (!!this.editor) {
          console.log('this.editor', this.editor);

          /*
          this.editor.addEventListener('focusin', (event: any | null = null) => {
            console.log('editor: focusin', event);
          });

          this.editor.addEventListener('focusout', (event: any | null = null) => {
            console.log('editor: focusout', event);
          });
          */
        }
      } catch (e) {
        console.warn('adding editor focus events failed', e);
      }

      this.blockDetectOnChange = false;
      this.renderInput();
    }, 50);
  }

  async initWYSIWYGEditor() {
    var host = 'https://grapesjs.com/';

    // Set up GrapesJS editor with the Newsletter plugin
    this.editor = grapesjs.init({
      selectorManager: { componentFirst: true },
      //clearOnRender: true,
      height: '100%',
      storageManager: {
        type: 'local', // Type of the storage, available: 'local' | 'remote'
        autosave: false, // Store data automatically
        autoload: false, // Autoload stored data on init
        stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        options: {
          local: { // Options for the `local` type
            key: 'gjsProject', // The key for the local storage
          },
        },
      },
      assetManager: {
        assets: [
          host + 'img/grapesjs-logo.png',
          host + 'img/tmp-blocks.jpg',
          host + 'img/tmp-tgl-images.jpg',
          host + 'img/tmp-send-test.jpg',
          host + 'img/tmp-devices.jpg',
        ],
        upload: false,
        //uploadText: 'Uploading is not available in this demo',
      },
      container: '#gjs',
      fromElement: true,
      showOffsets: false,
      plugins: [
        //'grapesjs-preset-newsletter',
        'grapesjs-preset-webpage',

        customCodePlugin,
        grapesjsTouch,
        plugin,
        pluginTooltip,
        gjsForms,

        'grapesjs-plugin-ckeditor',
        'grapesjs-component-countdown',
        'grapesjs-plugin-export',
        'grapesjs-tabs',
        'grapesjs-parser-postcss',
        'grapesjs-tui-image-editor',
        'grapesjs-typed',
        'grapesjs-style-bg',
      ],
      pluginsOpts: {
        'grapesjs-plugin-ckeditor': {
          onToolbar: el => {
            el.style.minWidth = '350px';
          },
          options: {
            startupFocus: true,
            extraAllowedContent: '*(*);*{*}', // Allows any class and any inline style
            allowedContent: true, // Disable auto-formatting, class removing, etc.
            enterMode: 2, // CKEDITOR.ENTER_BR,
            extraPlugins: 'sharedspace,justify,colorbutton,panelbutton,font',
            toolbar: [
              { name: 'styles', items: ['Font', 'FontSize'] },
              ['Bold', 'Italic', 'Underline', 'Strike'],
              { name: 'paragraph', items: ['NumberedList', 'BulletedList'] },
              { name: 'links', items: ['Link', 'Unlink'] },
              { name: 'colors', items: ['TextColor', 'BGColor'] },
            ],
          }
        },

        plugin: { flexGrid: true },
        'grapesjs-tui-image-editor': {
          script: [
            // 'https://cdnjs.cloudflare.com/ajax/libs/fabric.js/1.6.7/fabric.min.js',
            'https://uicdn.toast.com/tui.code-snippet/v1.5.2/tui-code-snippet.min.js',
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.js',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.js'
          ],
          style: [
            'https://uicdn.toast.com/tui-color-picker/v2.2.7/tui-color-picker.min.css',
            'https://uicdn.toast.com/tui-image-editor/v3.15.2/tui-image-editor.min.css',
          ],
        },
        'grapesjs-tabs': {
          tabsBlock: { category: 'Extra' }
        },
        'grapesjs-typed': {
          block: {
            category: 'Extra',
            content: {
              type: 'typed',
              'type-speed': 40,
              strings: [
                'Text row one',
                'Text row two',
                'Text row three',
              ],
            }
          }
        },
        'grapesjs-preset-webpage': {
          modalImportTitle: 'Import Template',
          modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
          modalImportContent: function (editor) {
            return editor.getHtml() + '<style>' + editor.getCss() + '</style>'
          },
        },
      }
    } as any);

    this.setHtml(this.input, true);

    // Let's add in this demo the possibility to test our newsletters
    var pnm = this.editor.Panels;
    var cmdm = this.editor.Commands;
    var md = this.editor.Modal;

    // Add info command
    var infoContainer = document.getElementById("info-panel");

    if (!!cmdm) {
      cmdm.add('open-info', {
        run: (editor: any, sender: any) => {
          var mdlClass = 'gjs-mdl-dialog-sm';
          sender.set('active', 0);
          var mdlDialog = document.querySelector('.gjs-mdl-dialog');
          mdlDialog.className += ' ' + mdlClass;
          infoContainer.style.display = 'block';
          md.open({
            title: 'About this demo',
            content: infoContainer,
          });
          md.getModel().once('change:open', () => {
            mdlDialog.className = mdlDialog.className.replace(mdlClass, '');
          })
        }
      });
    }

    if (!!pnm) {
      // Add info button
      const iconStyle = 'style="display: block; max-width: 22px"';

      pnm.addButton('options', [{
        id: 'view-info',
        label: `<svg ${iconStyle} viewBox="0 0 24 24">
            <path fill="currentColor" d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
        </svg>`,
        command: 'open-info',
        attributes: {
          'title': 'About',
          'data-tooltip-pos': 'bottom',
        },
      }]);

      // Beautify tooltips
      [
        ['sw-visibility', 'Show Borders'],
        ['preview', 'Preview'],
        ['fullscreen', 'Fullscreen'],
        ['export-template', 'Export'],
        ['undo', 'Undo'],
        ['redo', 'Redo'],
        ['gjs-open-import-template', 'Import'],
        ['gjs-toggle-images', 'Toggle images'],
        ['canvas-clear', 'Clear canvas']
      ].forEach((item) => {
        try {
          const button: any = pnm.getButton('options', item[0]);

          if (!!button) {
            button.set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom' });
          }
        } catch (e) {
          console.warn('updating editor button failed', e);
        }
      });

    }

    // [
    //   ['open-sm', 'Style Manager'],
    //   ['open-layers', 'Layers'],
    //   ['open-blocks', 'Blocks']
    // ].forEach(function(item) {
    //   pnm.getButton('views', item[0]).set('attributes', { title: item[1], 'data-tooltip-pos': 'bottom', title2: item[1] });
    //   console.log('views', item[0], pnm.getButton('views', item[0]).get('attributes'))
    // });

    var titles = document.querySelectorAll('*[title]');

    for (var i = 0; i < titles.length; i++) {
      var el = titles[i];
      var title = el.getAttribute('title');
      title = title ? title.trim() : '';
      if (!title)
        break;
      el.setAttribute('data-tooltip', title);
      el.setAttribute('title', '');
    }

    // Update canvas-clear command
    cmdm.add('canvas-clear', () => {
      if (confirm('Are you sure to clean the canvas?')) {
        this.editor.runCommand('core:canvas-clear');

        setTimeout(() => {
          localStorage.clear();
        }, 0);
      }
    });

    //this.editor.setDevice('mobilePortrait');

    this.editor.on('load', () => {
      try {
        //const deviceManager = this.editor.Devices;
        //const devices = deviceManager.getDevices();
        const $ = grapesjs.$;

        // Hide borders by default
        pnm.getButton('options', 'sw-visibility').set('active', 0);

        // Load and show settings and style manager
        const openTmBtn = pnm.getButton('views', 'open-tm');
        openTmBtn && openTmBtn.set('active', 1);
        const openSm = pnm.getButton('views', 'open-sm');
        openSm && openSm.set('active', 1);

        // Remove trait view
        //pnm.removeButton('views', 'open-tm');

        // Add Settings Sector
        const traitsSector = $(`
        <div class="gjs-sm-sector no-select">
          <div class="gjs-sm-sector-title"><span class="icon-settings fa fa-cog"></span> <span class="gjs-sm-sector-label">Settings</span></div>
          <div class="gjs-sm-properties" style="display: none;"></div>
        </div>`);

        if (!traitsSector) {
          return false;
        }

        const traitsProps = traitsSector.find('.gjs-sm-properties');

        if (!traitsProps) {
          return false;
        }

        traitsProps.append($('.gjs-trt-traits'));

        $('.gjs-sm-sectors').before(traitsSector);

        traitsSector.find('.gjs-sm-sector-title').on('click', function () {
          var traitStyle = traitsProps.get(0).style;
          const hidden = traitStyle.display == 'none';
          if (hidden) {
            traitStyle.display = 'block';
          } else {
            traitStyle.display = 'none';
          }
        });

        // Open block manager
        const openBlocksBtn = this.editor.Panels.getButton('views', 'open-blocks');
        openBlocksBtn && openBlocksBtn.set('active', 1);

      } catch (e) {
        console.warn('init newsletter editor failed:', e);
      }
    });

    this.loading = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      switch (this.mode) {
        case 'code':
          this.initCodeEditor();
          break;
        case 'text':
          this.initTextEditor();
          break;
        case 'newsletter':
          this.initNewsletterEditor();
          break;
        case 'wysiwyg':
          this.initWYSIWYGEditor();
          break;
      }
    });
  }

  ngOnInit() {
  }

  onCodeChanged(value) {
    console.log('editor: onCodeChanged', value);
  }

  onCodeEditorLoaded(event: any) {
    console.log('editor: onCodeEditorLoaded', event);
  }

  onCodeEditorModelChanged(event: any) {
    console.log('editor: onCodeEditorModelChanged', event);
  }

  onCodeEditorModelContentChanged(event: any) {
    console.log('editor: onCodeEditorModelContentChanged', event);
  }

  public async renderInput() {

    try {
      if (!this.input || !this.input.length) {
        return false;
      }

      this.blockDetectOnChange = true;

      await this.editor.isReady;

      this.zone.run(() => {
        this.editor.blocks.renderFromHTML(this.input);
        this.detectChanges();

        setTimeout(() => {
          this.blockDetectOnChange = false;
        }, 50);

      });
    } catch (e) {
      console.warn('render input failed', e);
    }

  }

  public async setHtml(html: string, blOverwriteCss: boolean = false) {
    html = (html || '').replace('```', '');

    const extract: any = await this.extractCSS(html);

    try {

      if (!!blOverwriteCss && !!extract.css) {
        this.cssBackup = `${extract.css || ''}`;
      } else
        if (!!this.cssBackup) {
          //this.editor.setStyle(`${this.cssBackup || ''}`);
        }

      await this.editor.setComponents(`${(extract.html || html) || ''}`);
    } catch (e) {
      console.warn('updating editor values failed', e);
    }
  }

  public setInput(input: string) {
    this.rebuild = true;

    this.zone.run(() => {
      this.input = input;

      switch (this.mode) {
        case 'code':
          this.codeModel.value = this.input;
          setTimeout(() => {
            this.rebuild = false;
            this.detectChanges();
          });
          break;
        default:
          this.rebuild = false;
          this.renderInput();
          break;
      }
    });
  }

}