import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from "src/app/services/core/view.service";
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'pipeline-intro-overlay',
  standalone: false,
  templateUrl: './intro-overlay.component.html',
  styleUrls: ['./intro-overlay.component.scss'],
})
export class IntroOverlayComponent implements AfterContentInit, OnInit {

  activeIndex: number = 0;

  @Input() aiSettings: aiSettings = {};

  @Input() aiSettingsOptions: aiSettingsOptions = {
    operations: ['text_generation'],
  };

  appConfig: pipelineAppConfig;

  @Input() config: introOverlayConfig;

  @Input() cards: any = {};

  @Input() loading: boolean = false;

  @Output() onAiSettingsChanged = new EventEmitter();
  @Output() onButtonClicked = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Output() onInputChanged = new EventEmitter();
  @Output() onInputValidated = new EventEmitter();
  @Output() onIntegrationChanged = new EventEmitter();
  @Output() onMediaChanged = new EventEmitter();
  @Output() onSkip = new EventEmitter();
  @Output() onSlideBack = new EventEmitter();
  @Output() onSlideNext = new EventEmitter();
  @Output() onSourceChanged = new EventEmitter();

  @Input() media: any = {};

  @Input() mediaInputInformationCardOptions: any = {
    hideDescription: true,
    hideHeader: true,
  };

  @Input() mediaList: mediaItem[] = [];

  @Input() mediaPickerOptions: mediaPickerOptions = {
    allowAuto: true,
    hideDescription: true,
    hideHeader: true,
    showGenerateOptions: true,
  };

  @Input() mediaTextOverlayCardOptions: any = {
    hideDescription: true,
    hideHeader: true,
  };

  @Input() search: searchOptions;

  @Input() sliderOptions: any = {
    allowTouchMove: false,
    autoHeight: false,
    navigation: false,
    speed: 1000,
    slidesPerView: 1,
    slidesPerGroup: 1,
  };

  @ViewChild('introOverlaySwiper') swiper: ElementRef | undefined;

  @Input() view: any;

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  async _onInputChanged(input: any, iInput: number, iGroup: number, event: any | null = null) {

    switch (input.type) {
      case 'email':
        // @todo validate email
        this.config.groups[iGroup].inputs[iInput].loading = true;

        const blValid: boolean = await this.tools.validateEmail(input.value);
        console.log('Email valid?', blValid);

        this.config.groups[iGroup].inputs[iInput].loading = false;

        this.onInputValidated.emit({
          input: input,
          response: {
            success: blValid,
          },
        });

        break;
      case 'url':
        this.config.groups[iGroup].inputs[iInput].loading = true;

        const validate: any = await this.tools.validateUrl(input.value);

        this.config.groups[iGroup].inputs[iInput].loading = false;

        this.onInputValidated.emit({
          input: input,
          response: validate,
        });

        input.valid = !!validate.success;
        break;
    }

    this.onInputChanged.emit({
      event: event,
      group_index: iGroup,
      index: iInput,
      input: input,
    });
  }

  _onIntegrationChanged(event: any = null) {
    this.onIntegrationChanged.emit(event);
  }

  _onMediaChanged(event: any = null) {
    this.view.mediaList = (event || []);
    this.onMediaChanged.emit(this.view.mediaList);
  }

  _onMediaInputInformationCardInputChanged(event: any = null) {
    if (!!this.search) {
      this.search.query = `${event || ''}`;
    }
  }

  _onSourceChanged(event: any = null) {
    this.onSourceChanged.emit(event);
  }

  aiCreate() {
    this.onCreate.emit();
  }

  aiSettingsChanged(event: any = null) {
    this.onAiSettingsChanged.emit(event);
  }

  async calcHidden() {
    //this.view.hidden = await this.introService.isIntroOverlayHidden(this.view.uid);
  }

  async calcSliderVars() {

    try {
      if (!!this.swiper && !!this.swiper.nativeElement) {
        this.activeIndex = await this.swiper.nativeElement.swiper.activeIndex;
      }
    } catch (e) {
      console.warn('calcSliderVars: get swiper activeIndex failed', e);
    }

    // calculate slider button states
    this.config.canSlideBack = !!this.config.groups && !!this.config.groups.length && !!this.activeIndex;
    this.config.canSlideDone = !!this.config.groups && !!this.config.groups.length && (this.config.groups.length === (this.activeIndex + 1));
    this.config.canSlideNext = !this.config.canSlideDone && (!!this.config.groups && !!this.config.groups.length && (this.config.groups.length > this.activeIndex));
    
    /*
    if (!!this.config.groups && !!this.config.groups.length) {
      console.log('active group:', this.config.groups[this.activeIndex]);
    }
    */
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.calcSliderVars();
  }

  dateChanged(event: any) {
    console.log('dateChanged: event', event);
    console.log('dateChanged: view.selected_date', this.view.selected_date);
  }

  destroy(event: any | null = null) {
    console.warn('destroy', event);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.view.hidden = true;
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  initSwiper() {
    try {
      if (!!this.sliderOptions && !!this.swiper && !!this.swiper.nativeElement && !!this.swiper.nativeElement.swiper) {
        Object.keys(this.sliderOptions).forEach((key: string) => {
          this.swiper.nativeElement.swiper[key] = this.sliderOptions[key];
        });
      }
    } catch (e) {
      console.warn('updating slider failed: ', e);
    }
  }

  ngAfterContentInit() {
    this.calcViewVars();

    setTimeout(() => {
      this.initSwiper();
    }, 250);
  }

  ngOnInit() {
    this.initEvents();
    this.calcHidden();
    this.calcViewVars();
  }

  async slideBack() {
    console.log('intro-overlay: slideBack: config', this.config);
    console.log('intro-overlay: slideBack: view', this.view);

    this.onSlideBack.emit(true);

    await this.swiper.nativeElement.swiper.slidePrev();

    this.calcSliderVars();
  }

  slidechangetransitionend(event: any) {
    this.calcSliderVars();
  }

  slidechangetransitionstart(event: any) {
    this.calcSliderVars();
  }

  async slideNext() {
    const group: introOverlayConfigGroup | null = this.config.groups[this.activeIndex];

    if (!group) {
      this.events.publish('error', 'error_input_group_not_found');
      return false;
    }

    const valid: boolean = await this.validateGroup(group);

    if (!valid) {
      this.events.publish('error', 'error_input_data_not_valid');
      return false;
    }

    this.onSlideNext.emit({
      index: this.activeIndex,
      group: group,
    });

    this.update()
      .then(async () => {
        try {
          await this.swiper.nativeElement.swiper.slideNext();
          this.calcSliderVars();
        } catch (e) {
          this.events.publish('error', e);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  slideDone() {
    return this.aiCreate();
  }

  startManually() {
    this.onSkip.emit();
  }

  toggleButton(button: button, iButton: number, iGroup: number) {

    if (!button) {
      return false;
    }

    const group: introOverlayConfigGroup = this.config.groups[iGroup];

    if (!!group && !!group.multiple) {
      button.checked = !button.checked;
    } else {
      group.buttons.forEach((_button: button, _iButton: number) => {
        _button.checked = (_iButton === iButton);
      });
    }

    try {
      if (!!group && !!group.buttonHandler) {
        group.buttonHandler({
          button: button,
          index: iButton,
        });
      }
    } catch (e) {
      console.warn('firing group button handler failed: ', e);
    }

    this.onButtonClicked.emit(button);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  async update() {
    return true;
  }

  async validateGroup(group: introOverlayConfigGroup) {
    let bl: boolean = true;

    if (!!group && !!group.inputs) {
      group.inputs.forEach((input: introOverlayConfigGroupInput) => {
        input.valid = !input.required || (!!input.required && !!input.value);
        if (!input.valid) {
          bl = false;
        }
      });
    }

    return bl;
  }

}