import { Injectable } from '@angular/core';

import { CrawlerService } from "src/app/services/utils/crawler.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { proxyUrl } from 'src/config/variables';

@Injectable({
    providedIn: 'root'
})
export class ApiExplorerService {

    proxyUrl: string;

    routesByIntegration: any = {};

    constructor(
        private crawler: CrawlerService,
        private tools: ToolsService,
    ) {
        this.proxyUrl = proxyUrl;
    }

    calculateApiUrl(integrationName: string, params: any = {}) {
        let apiUrl: string | null = null;

        const settings: any = this.routesByIntegration[integrationName];

        if (settings.hasOwnProperty('baseUrlCalculator')) {
            const calculatedApiUrl: any = settings.baseUrlCalculator(params);

            if (!!calculatedApiUrl) {
                apiUrl = calculatedApiUrl;
            }
        } else
            if (!!settings.baseUrl) {
                apiUrl = settings.baseUrl;
            }

        return apiUrl;
    }

    calculateFetchRouteHeaders(options: any) {
        const settings: any = this.routesByIntegration[options.plugin];

        if (settings.hasOwnProperty('headersCalculator')) {
            return settings.headersCalculator(options);
        }

        return [];
    }

    fetchRoute(options: any = {}) {
        return new Promise(async (resolve, reject) => {
            if (!options.apiUrl || !options.apiUrl.length) {
                reject('error_missing_api_url');
            } else {
                const url: string = `${options.apiUrl || options.baseUrl}${options.route}`;
                const headers: any = options.headers || this.calculateFetchRouteHeaders(options);
                const method: string = (options.method || 'GET');

                try {

                    const responseText: string | null = await this.crawler.fetch(url, {
                        method: method,
                        proxy: !!options.use_proxy,
                        headers: headers,
                    }) as string | null;

                    let responseJson: any | null = null;

                    try {
                        if (!!responseText && !!responseText.length) {
                            responseJson = this.tools.extractJson(responseText as string);
                        }
                    } catch (e) {
                        console.warn('parsing response to json failed', e);
                    }

                    console.log('api-explorer: responseJson', responseJson);

                    resolve({
                        headers: headers,
                        method: method,
                        //response: response,
                        response_json: responseJson,
                        response_text: responseText,
                        success: true,
                        url: url,
                    });

                } catch (e) {
                    reject(e);
                }
            }
        });
    }

    getRoutesByIntegration() {
        return this.routesByIntegration;
    }

}