import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavController } from "@ionic/angular";

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-header-add-button',
  standalone: false,
  templateUrl: './header-add-button.component.html',
  styleUrls: ['./header-add-button.component.scss']
})
export class HeaderAddButtonComponent implements OnInit {
  @Input() view: any;
  @ViewChild('headerAddButtonPopover') headerAddButtonPopover;

  appConfig: pipelineAppConfig;

  isHeaderAppButtonPopoverOpen: boolean = false;

  items: any[];

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private navCtrl: NavController,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.items = this.configService.getGlobalCreateItems();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  initEvents() {
    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ngOnInit() {
    this.initEvents();
    this.calcViewVars();
  }

  presentPopover(e: Event) {
    this.headerAddButtonPopover.event = e;
    this.isHeaderAppButtonPopoverOpen = true;
  }

  select(item: any, event: any) {

    if (!!item.url) {
      this.navCtrl.navigateForward(`${item.url}`);
    }

  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }
  
}
