import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class NewslettersService {

  _detailItem: any;

  constructor(
    private AppCMS: AppcmsService,
    private user: UserService,
  ) {

  }

  addCalendarToNewsletter(calendarId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        calendar: calendarId,
        newsletter: newsletterId,
      },
      ['newsletter', 'addCalendarToNewsletter'],
    );
  }

  addGroupToNewsletter(groupId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        group: groupId,
        newsletter: newsletterId,
      },
      ['newsletter', 'addGroupToNewsletter'],
    );
  }

  addPageToNewsletter(pageId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        page: pageId,
        newsletter: newsletterId,
      },
      ['newsletter', 'addPageToNewsletter'],
    );
  }

  addSourceToNewsletter(sourceId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        source: sourceId,
        newsletter: newsletterId,
      },
      ['newsletter', 'addSourceToNewsletter'],
    );
  }

  addNewsletterMenuItem(menuItem: any, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        menuItem: menuItem,
        newsletter: newsletterId,
      },
      ['newsletter', 'addNewsletterMenuItem'],
    );
  }

  convertPostToNewsletter(post: post) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        post: post,
      },
      ['newsletter', 'convertPostToNewsletter'],
      {},
    );
  }

  createNewNewsletterMenu(menuData: any, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        menuData: menuData,
        newsletter: newsletterId,
      },
      ['newsletter', 'menus', 'create'],
      {},
    );
  }

  delete(newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {},
      ['newsletter', 'delete', newsletterId],
      {},
    );
  }

  deletePageFromNewsletter(pageId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        page: pageId,
        newsletter: newsletterId,
      },
      ['newsletter', 'deletePageFromNewsletter'],
      {},
    );
  }

  deleteSourceFromNewsletter(sourceId: number, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        source: sourceId,
        newsletter: newsletterId,
      },
      ['newsletter', 'deleteSourceFromNewsletter'],
    );
  }

  deleteNewsletterComponentSettings(options: any) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['newsletter', 'deleteNewsletterComponentSettings'],
    );
  }

  deleteNewsletterMenu(menuId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        menu: menuId,
      },
      ['newsletter', 'deleteNewsletterMenu'],
    );
  }

  deleteNewsletterMenuItem(itemId: number, parentId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        item: itemId,
        parent: parentId,
      },
      ['newsletter', 'deleteNewsletterMenuItem'],
    );
  }

  detailItem(item: any | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  duplicate(newsletterId: number, newsletterData: newsletter = null) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        uid: newsletterId,
        data: newsletterData,
      },
      ['newsletter', 'sites', 'duplicate', newsletterId],
    );
  }

  generate(options: any = {}, config: any = {}, blForceRefresh: boolean = false) {

    let aiParams: any = Object.assign(options, {
      history: [
        {
          input: `${options.post_content || ''}`,
          role: 'user',
        },
      ],
      max_tokens: (32 * 1024),
      query: `Create an awesome and complete newsletter based on the given template. The topic should be "${options.query || ''}".`,
    });

    if (!!aiParams && !!aiParams.history && !!aiParams.history.length) {
      delete aiParams.post_content;
    }

    return this.AppCMS.loadPluginData(
      'pipeline',
      aiParams,
      ['newsletter', 'execute'],
      {},
      blForceRefresh
    );
  }

  get(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['newsletter', 'newsletters'],
      {},
      blForceRefresh
    );
  }

  getAll(blForceRefresh: boolean = false, options: any = {}) {
    return this.get(blForceRefresh, options);
  }

  getSettings(blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {},
      ['newsletter', 'settings'],
      {},
      blForceRefresh
    );
  }

  getSites(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['newsletter', 'sites'],
      {},
      blForceRefresh
    );
  }

  getNewsletterByUid(newsletterId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {},
      ['newsletter', newsletterId],
      {},
      blForceRefresh
    );
  }

  getNewsletterMenuByUid(menuId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {},
      ['newsletter', 'menus', menuId],
      {},
      blForceRefresh
    );
  }

  getNewsletterMenus(newsletterId: number, blForceRefresh: boolean = false, options: any = {}) {
    options = options || {};
    options.filter = options.filter || {};

    return this.AppCMS.loadPluginData(
      'pipeline',
      Object.assign(options, {
        filter: Object.assign(options.filter, {
          newsletter: newsletterId,
        })
      }),
      ['newsletter', 'menus'],
      {},
      blForceRefresh
    );
  }

  getNewsletterPages(newsletterId: number, blForceRefresh: boolean = false, options: any = {}) {
    options = options || {};
    options.filter = options.filter || {};

    return this.AppCMS.loadPluginData(
      'pipeline',
      Object.assign(options, {
        filter: Object.assign(options.filter, {
          newsletter: newsletterId,
        })
      }),
      ['newsletter', 'pages'],
      {},
      blForceRefresh
    );
  }

  getTemplates(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'newsletter',
      options,
      ['templates'],
      {},
      blForceRefresh
    );
  }

  submit(newsletter: newsletter) {
    return new Promise((resolve, reject) => {
      newsletter.active = !!newsletter.active;
      newsletter.user = newsletter.user || this.user.getUid();

      if (!newsletter.user) {
        reject('error_missing_user_uid');
      } else {
        this.AppCMS.loadPluginData(
          'pipeline',
          {
            newsletter: newsletter
          },
          ['newsletter', 'create'],
          {},
        )
          .then(resolve).catch(reject);
      }
    });
  }

  update(newsletter: newsletter, blRebuildNewsletter: boolean = false) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        newsletter: newsletter,
        rebuild_newsletter: !!blRebuildNewsletter,
      },
      ['newsletter', 'update', newsletter.uid],
      {},
    );
  }

  updateNewsletterComponent(component: any, area: string, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        area: area,
        component: component,
        newsletter: newsletterId,
      },
      ['newsletter', 'updateNewsletterComponent'],
      {},
    );
  }

  updateNewsletterComponentsOrders(componentsSortData: any, area: string, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        area: area,
        components: componentsSortData,
        newsletter: newsletterId,
      },
      ['newsletter', 'updateNewsletterComponentsOrders'],
      {},
    );
  }

  updateNewsletterExtensionSettings(extension: newsletterExtension, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        extension: extension,
        newsletter: newsletterId,
      },
      ['newsletter', 'updateNewsletterExtensionSettings'],
      {},
    );
  }

  updateNewsletterMenu(menu: newsletterMenu, newsletterId: number) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      {
        menu: menu,
        newsletter: newsletterId,
      },
      ['newsletter', 'updateNewsletterMenu'],
      {},
    );
  }

}