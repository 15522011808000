import { Injectable } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";

import { LoadingPage } from 'src/app/pages/core/loading/loading.page';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    constructor(
        private events: EventsService,
        private modalService: ModalService,
    ) {

    }

    dismiss() {
        this.modalService.dismiss();
    }

    present(loadingOptions: loadingOptions) {
        return new Promise(async (resolve, reject) => {
            loadingOptions.props = loadingOptions.props || {};
            loadingOptions.props.config = loadingOptions;
            loadingOptions.props.loadingService = this;

            if (!loadingOptions.props.hasOwnProperty('progress')) {
                loadingOptions.props.progress = 0;
            }

            const modal: any = await this.modalService.create({
                component: LoadingPage,
                componentProps: loadingOptions.props,
                animated: true,
                presentingElement: await this.modalService.getTop(),
                cssClass: 'loadingModal',
            });

            modal.onDidDismiss().then((response: any) => {
                if (!!loadingOptions.events && !!loadingOptions.events.hasOwnProperty('onDidDismiss')) {
                    loadingOptions.events.onDidDismiss(response);
                }
            });

            modal.onWillDismiss().then((response: any) => {
                if (!!loadingOptions.events && !!loadingOptions.events.hasOwnProperty('onWillDismiss')) {
                    loadingOptions.events.onWillDismiss(response);
                }
            });

            this.modalService.present(modal);

            resolve({

                // data:
                modal: modal,
                options: loadingOptions,

                dismiss: () => {
                    modal.dismiss();
                },

                // functions (modifiers):

                updateConfig: (config: any) => {
                    return this.updateConfig(config);
                },

            });
        });
    }

    updateConfig(config: loadingOptions) {
        this.events.publish('loading:component:update', (config || {}));
    }

}