import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { FoldersService } from 'src/app/services/utils/folders.service';

import { MailRulePage } from 'src/app/pages/extensions/mail/mail-rule/mail-rule.page';

@Injectable({
  providedIn: 'root'
})
export class MailCrawlerService {

  apiConfig: apiExplorerConfig = {
    baseUrl: '',
    routes: [
    ],
  };

  _detailItem: any | null;

  customSelectionOptions: selectionOption[] = [
    {
      icon: 'sync-outline',
      label: 'synchronize',
      uid: 'synchronize',
    },
    {
      icon: 'checkmark-outline',
      label: 'validate',
      uid: 'validate',
    },
  ];

  constructor(
    private AppCMS: AppcmsService,
    private folders: FoldersService,
    private modalService: ModalService,
  ) {

  }

  addRule(rule: rule = {}) {
    return this.editRule(rule);
  }

  detailItem(item: mailInbox | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  editRule(rule: rule) {
    return new Promise(async (resolve, reject) => {

      const modal: any = await this.modalService.create({
        component: MailRulePage,
        componentProps: {
          rule: rule,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      modal.onWillDismiss().then((response) => {
        resolve(response);
      });

      await this.modalService.present(modal);
    });
  }

  createCrawler(crawler: any, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      crawler: crawler,
    }), ['mail', 'crawlers', 'create']);
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'mail_inbox';
    return this.folders.create(folder);
  }

  createMailReplyMessage(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'createReplyMessage'], {}, blForceRefresh);
  }

  deleteCrawler(crawlerId: number, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      crawler: crawlerId,
    }), ['mail', 'crawlers', 'delete']);
  }

  deleteFolder(folderId: number) {
    return this.folders.delete(folderId);
  }

  deleteInboxMessage(messageId: number) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        message_uid: messageId,
      },
      ["mail", "delete"]
    );
  }

  extractArticles(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'extractArticles'], {}, blForceRefresh);
  }

  getBlacklist(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'blacklist'], {}, blForceRefresh);
  }

  getCrawlers(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'crawlers'], {}, blForceRefresh);
  }

  getCustomSelectionOptions() {
    return this.customSelectionOptions;
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.filter = options.filter || {};
    options.filter.location = options.filter.location || 'mail_inbox';
    return this.folders.get(options.filter, blForceRefresh, options);
  }

  getInbox(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'inbox'], {}, blForceRefresh);
  }

  getMailRules(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['mail', 'settings', 'rules'], {}, blForceRefresh);
  }

  moveFolder(items: any, options: any = {}) {

    if (!!items && (!!items.ID || !!items.uid)) {
      items = [items];
    }

    return this.folders.moveFolder(items as any, 'mail_inbox');
  }

  updateCrawler(crawler: any, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      crawler: crawler,
    }), ['mail', 'crawlers', 'update']);
  }

  updateMailSettings(config: mailSettingsConfig, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
      config: config,
    }), ['mail', 'settings', 'update']);
  }

}