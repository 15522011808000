import { Component, OnInit } from '@angular/core';
import { StatisticsService } from 'src/app/services/analytics/statistics.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-blog-admin-employee-motivation',
  standalone: false,
  templateUrl: './blog-admin-employee-motivation.page.html',
  styleUrls: ['./blog-admin-employee-motivation.page.scss'],
})
export class BlogAdminEmployeeMotivationPage implements OnInit {

  board: statisticsBoard;

  postsByUsers: any = {};
  
  state: state = {};
  
  view: any = {
    headline: 'blog_admin_employee_motivation_headline',
    hideOrderByBtn: true,
    hideSearch: true,
    mode: 'default', // default, race, race_win
    title: 'blog_admin_employee_motivation',
  };

  constructor(
    private events: EventsService,
    private statistics: StatisticsService,
    private userService: UserService,
    private modalService: ModalService,
  ) {
    this.loadStatistics();
  }

  calcMode() {
    let mode: string;

    Object.keys(this.postsByUsers).forEach((userName: string) => {
      let postsByUser = this.postsByUsers[userName];
      if((postsByUser > this.view.currentPostsByUser) && Math.abs(postsByUser - this.view.currentPostsByUser) < 5) {
        mode = 'race';
        this.view.headline = 'blog_admin_employee_motivation_headline_race';
        this.view.race_partner = userName;
        this.view.race_difference = Math.abs(postsByUser - this.view.currentPostsByUser) + 1;
      }
    });

    this.setMode(mode);
  }

  async dismiss(data: any|null = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  doRefresh(event: any|null = null) {
    this.loadStatistics(true)
    .then(() => {
      if(event) {
        event.target.complete();
      }
    })
    .catch((error: any) => {
      if(event) {
        event.target.complete();
      }
      this.events.publish('error', error);
    });
  }

  ionViewWillEnter() {
    this.loadStatistics(true);
  }

  loadCurrentPostsByUser() {
    let key = this.userService.getFirstname() + ' ' + this.userService.getLastname();
    this.view.currentPostsByUser = this.postsByUsers[key] || 0;
  }

  loadPostsByUsers() {
    let data = this.board.data && this.board.data.datasets ? this.board.data.datasets[0].data : null;
    let labels = this.board.data.labels;

    labels.forEach((label: string, index: number) => {
      this.postsByUsers[label] = data[index];
    });

    this.loadCurrentPostsByUser();
    this.calcMode();
  }

  async loadStatistics(blForceRefresh: boolean = false) {
    this.board = await this.statistics.get('postsByEmployee', blForceRefresh);
    this.loadPostsByUsers();
  }

  ngOnInit() {
  }

  setMode(mode: string = 'default') {
    
    switch(mode) {
      case 'race':
        break;
      case 'race_win':
        break;
      default:
        break;
    }

    this.view.mode = mode;
  }
}
