import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { UserService } from 'src/app/services/core/user.service';
import { ShopService } from "src/app/services/ecommerce/shop.service";
import { SourcesService } from 'src/app/services/pipeline/sources.service';

@Injectable({
  providedIn: 'root'
})
export class CreatorsService {

  constructor(
    private AppCMS: AppcmsService,
    private shop: ShopService,
    private sources: SourcesService,
    private userService: UserService,
  ) {
  }

  alreadyRequested() {
    let user: user = this.userService.getUser() || {};
    user.classifications = (user.classifications || {}) as userClassifications;

    return !!user.classifications.creatorPermissionRequested;
  }

  getBlogs(blForceRefresh: boolean = false, options: any = {}) {
    return new Promise((resolve, reject) => {
      this.sources.getSources(blForceRefresh, Object.assign(options, {
        filter: {
          user: this.userService.getUid(),
        }
      }))
        .then((blogs: source[]) => {
          if (blogs && blogs.length) {
            blogs = blogs.filter((blog: source) => {
              return blog.system !== 'woocommerce';
            })
          }
          resolve(blogs);
        })
        .catch(reject);
    });
  }

  getRequests(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      options,
      ['creators', 'requests'],
      {},
      blForceRefresh
    );
  }

  getSettings(setting: string | null = null, blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      'pipeline',
      Object.assign(options, {}),
      ['creators', 'settings'],
      {},
      blForceRefresh
    );
  }

  getShops(blForceRefresh: boolean = false) {
    return this.shop.getSources(blForceRefresh, {
      filter: {
        user: this.userService.getUid(),
      }
    });
  }

  async getSources(blForceRefresh: boolean = false) {
    let blogs: source[] = (await this.getBlogs(blForceRefresh) as source[]),
      shops: source[] = (await this.getShops(blForceRefresh) as source[]);
    return (blogs || []).concat(shops || []);
  }

  request() {
    let user: user = this.userService.getUser() || {};
    user.classifications = (user.classifications || {}) as userClassifications;
    user.classifications.creatorPermissionRequested = true;

    return this.userService.setUser(user, true);
  }

}
