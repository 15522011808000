import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from "src/app/services/core/modal.service";
import { GetgeniusService } from "src/app/services/getgenius/getgenius.service";

import { GetgeniusWalletPage } from 'src/app/pages/getgenius/wallet/getgenius-wallet.page';

@Component({
  selector: 'getgenius-tokens-badge',
  standalone: false,
  templateUrl: './getgenius-tokens-badge.component.html',
  styleUrls: ['./getgenius-tokens-badge.component.scss']
})
export class GetgeniusTokensBadgeComponent implements OnDestroy, OnInit {
  @Input() view: any;

  @ViewChild('walletsPopover') walletsPopover;

  isWalletsPopoverOpen: boolean = false;

  project: project;

  refresher: any;

  wallet: wallet;

  wallets: wallet[];

  constructor(
    private events: EventsService,
    private GetGenius: GetgeniusService,
    private modalService: ModalService,
  ) {
  }

  async doRefresh() {
    try {
      await this.load(true, true);
    } catch (e) {
      console.warn('loading wallets data failed', e);
    }
  }

  initEvents() {
    this.view.events = this.view.events || {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', async (project: project) => {
      this.project = project;
      
      // unset the wallet of the previous project
      await this.GetGenius.unsetWallet();

      this.doRefresh();
    });
  }

  initRefresher() {
    if (!this.refresher) {
      this.refresher = setInterval(() => {
        this.doRefresh();
      }, (15 * 1000));
    }
  }

  async load(blForceRefresh: boolean = false, blBackground: boolean = false) {
    try {
      await this.loadWallet(blForceRefresh, blBackground);
    } catch (e) {
      console.warn('loading wallets data failed', e);
    }
  }

  async loadWallet(blForceRefresh: boolean = false, blBackground: boolean = false) {
    try {

      if (!blBackground) {
        this.view.loadingWallet = true;
      }

      this.wallet = await this.GetGenius.getCurrentWallet(blForceRefresh);

      const walletInfo: any = await this.GetGenius.getWalletInfo(blForceRefresh);

      if (!walletInfo || !walletInfo.wallets || !walletInfo.wallets.length) {

        // unset wallet if not in response
        await this.GetGenius.unsetWallet();
        this.wallet = await this.GetGenius.getCurrentWallet(blForceRefresh);

        // create wallet if not exists
        await this.GetGenius.setupWallet();

        // load wallet info again
        const walletInfo: any = await this.GetGenius.getWalletInfo(blForceRefresh);

        // load new wallet after creation
        this.wallet = await this.GetGenius.getCurrentWallet(blForceRefresh);

        // update wallets list
        this.wallets = (!!walletInfo && !!walletInfo.wallets && !!walletInfo.wallets.length ? walletInfo.wallets : []);

      } else {

        // else, load wallets list
        this.wallets = (!!walletInfo && !!walletInfo.wallets && !!walletInfo.wallets.length ? walletInfo.wallets : []);

        if (!this.wallet || !this.wallet.uid) {
          // set first wallet to current wallet if no wallet is set in the user account

          await this.GetGenius.setCurrentWallet(walletInfo.wallets[0]);
          this.wallet = await this.GetGenius.getCurrentWallet(blForceRefresh);
        } else
          if (!!this.wallet && !!this.wallet.uid && !!walletInfo.wallets && !!walletInfo.wallets.length) {
            // else, update wallet from walletInfo if match found

            const matches: any[] = walletInfo.wallets.filter((_wallet: any) => {
              return _wallet.uid === this.wallet.uid;
            });

            if (!!matches[0]) {
              await this.GetGenius.setCurrentWallet(matches[0]);
              this.wallet = matches[0];
            }
          }
      }

      this.view.loadingWallet = false;
    } catch (e) {
      this.view.loadingWallet = false;

      console.warn('loading wallet info failed', e);
    }
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    if (!!this.refresher) {
      clearInterval(this.refresher);
    }
  }

  ngOnInit() {
    this.view = this.view || {};

    this.initEvents();
    this.initRefresher();
    this.load();
  }

  async openWallet() {

    if (!!this.view.loadingWallet) {
      return false;
    }

    const modal: any = await this.modalService.create({
      component: GetgeniusWalletPage,
    });

    modal.onWillDismiss().then((response: any) => {
      //console.log('getgenius: wallet dismissed', response);
    });

    this.modalService.present(modal);
  }

  pick(event: any | null = null) {

    if (!!this.view.loadingWallet) {
      return false;
    }

    this.walletsPopover.event = event;
    this.isWalletsPopoverOpen = true;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}