import { Injectable } from '@angular/core';

import { AppcmsService } from "src/app/services/core/appcms.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { CrawlerService } from "src/app/services/utils/crawler.service";
import { CsvService } from 'src/app/services/utils/csv.service';

import { DataImporterPage } from 'src/app/pages/core/data/data-importer/data-importer.page';

@Injectable({
  providedIn: 'root'
})
export class ImporterService {

  private csvSettings: any = {
    delimiter: ';',
  };

  private data: any;

  private headers: any = {
    csv: "data:text/csv;charset=utf-8,"
  };

  private columnParser: any;

  private itemParser: any;

  private output: string;

  constructor(
    private AppCMS: AppcmsService,
    private crawler: CrawlerService,
    private csv: CsvService,
    private modalService: ModalService,
  ) {

  }

  asCsv() {
    let csvContent = this.headers.csv;
    let delimiter: string = this.getCsvDelimiter();
    let rows = this.getData();
    let columnParser: any = this.getColumnParser();
    let itemParser: any = this.getItemParser();

    if (rows && rows.length) {
      rows = rows.map(itemParser);

      // add header keys
      let keys: string[] = Object.keys(rows[0]);

      csvContent += keys.join(delimiter) + "\r\n";

      // add data
      rows.forEach((item: any) => {
        let values = Object.values(item).map(columnParser);
        csvContent += values.join(delimiter) + "\r\n";
      });
    }

    this.setOutput(csvContent);
    return this;
  }

  calcMappings(response: any = {}) {
    response = response || {};

    if (!!response.data && !!response.data[0]) {
      response.dataKeys = Object.keys(response.data[0]);

      if (!response.mappingKeys) {
        response.mappingKeys = Object.values(response.data[0]);
      }
    } else {
      response.dataKeys = [];
      response.mappingKeys = [];
    }

    return response;
  }

  calculateImportUrl(options: any) {

    const uids: number[] = options.uids || (options.data || []).map((item: any) => {
      return item.uid || item.ID;
    });

    const params = new URLSearchParams({
      source: options.source,
      type: options.type,
      uids: uids,
    } as any);

    return `${this.AppCMS.getApiUrl()}/pipeline/import/execute.json?${params.toString()}`;
  }

  calculateSeperator(content: string) {
    const firstRow: string = content.split("\n")[0];
    let seperator: string | null = null;

    if (firstRow.indexOf(",") !== -1) {
      seperator = ',';
    } else
      if (firstRow.indexOf(";") !== -1) {
        seperator = ',';
      } else
        if (firstRow.indexOf("\t") !== -1) {
          seperator = '\t';
        }

    return seperator;
  }

  calcUseFirstRowAsFieldsChanged(options: any = {}) {
    //console.log('calcUseFirstRowAsFieldsChanged: data', options);

    // @debug
    let use_first_row_as_fields: boolean = true;

    return use_first_row_as_fields;
  }

  convertCsvContent(content: string, settings: any) {
    return new Promise((resolve, reject) => {

      if (!content || !content.length) {
        return false;
      }

      let response: any = {}, dataKeys: string[] = [];

      const list: any[] = this.csvToList(
        `${content || ''}`,
        `${settings.seperator || ','}`,
        (!!settings.quote_characters && !!settings.quote_character ? settings.quote_character : ''),
      );

      if (!!list && !!list[0]) {
        dataKeys = Object.keys(list[0]);
      }

      // If configured / detected, use first row as field mappings and remove it from list
      if (!!settings.use_first_row_as_fields && !!list && !!list[0]) {
        const first: any = list.shift();

        response.mappingKeys = Object.values(first);
        response.rowMappings = response.mappingKeys;
        response.data = list;
      } else {
        response.data = list;
      }

      response.previewGridWidth = (dataKeys.length * 200);
      response = this.calcMappings(response);

      resolve(response);
    });
  }

  csvToList(text: string, delimiter: string = ',', quoteChar: string = '"') {
    return this.csv.csvToList(text, delimiter, quoteChar);
  }

  download() {
    const output: string = this.getOutput();

    if (!!output) {
      var encodedUri = encodeURI(output);
      window.open(encodedUri);
    }
  }

  downloadBase64File(base64Data: string, fileName: string, contentType: string | null = null) {
    const dataSplit = base64Data.split('base64,');

    let linkSource: string;

    if (dataSplit.length === 2) {
      contentType = dataSplit[0];
      base64Data = dataSplit[1];
      linkSource = base64Data;
    } else {
      linkSource = `data:${contentType || 'webp'};base64,${base64Data}`;
    }

    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  executeServerSideImport(options: dataImportOptions) {
    return this.AppCMS.loadPluginData('pipeline', {
      config: options,
    }, ['import', 'execute']);
  }

  importUsingUI(options: dataImportOptions = {}) {
    return new Promise(async (resolve, reject) => {
      options = options || {};
      options.mode = options.mode || 'pick';
      options.service = this;

      const modal: any = await this.modalService.create({
        component: DataImporterPage,
        componentProps: options,
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: 'defaultModal',
      });

      modal.onWillDismiss().then(resolve);

      this.modalService.present(modal);
    });
  }

  getCsvDelimiter() {
    return this.getCsvOption('delimiter');
  }

  getCsvOption(key: string) {
    return this.csvSettings[key];
  }

  getData() {
    return this.data;
  }

  getFallbackColumnParser() {
    return (value: any) => {
      if (typeof value === 'object') {
        //value = JSON.stringify(value);
        value = '[KEIN ZUGRIFF]';
      }
      return value;
    }
  }

  getFallbackItemParser() {
    return (item: any) => {
      return item;
    }
  }

  getColumnParser() {
    if (!this.columnParser) {
      return this.getFallbackColumnParser();
    }
    return this.columnParser;
  }

  getItemParser() {
    if (!this.itemParser) {
      return this.getFallbackItemParser();
    }
    return this.itemParser;
  }

  getOutput() {
    return this.output;
  }

  loadUrl(url: string, settings: any, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      //console.log('importer-service: loadUrl url', url);
      //console.log('importer-service: loadUrl settings', settings);

      this.crawler.fetch(url, {
        proxy: true,
      })
        .then((content: string) => {
          settings.calculatedSeperator = this.calculateSeperator(content);

          this.calcUseFirstRowAsFieldsChanged({
            content: content,
          });

          this.convertCsvContent(content, settings)
            .then((data: any) => {
              //console.log('importer-service: loadUrl data', data);
              resolve(data);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  setCsvDelimiter(delimiter: string) {
    return this.setCsvOption('delimiter', delimiter);
  }

  setCsvOption(key: string, value: string) {
    this.csvSettings[key] = value;
    return this;
  }

  setData(data: any[]) {
    this.data = data;
    return this;
  }

  setItemParser(handler: any) {
    this.itemParser = handler;
    return this;
  }

  setOutput(output: string) {
    this.output = output;
    return this;
  }

}