<ion-item lines="none" class="window-buttons"
  [class.has-windowmanager]="view.useWindowManager && !!view.isDesktop" *ngIf="!!view.isModal">

  <!-- Dismiss -->
  <ion-button slot="start" (click)="dismiss()" icon-only>
    <ion-icon name="close"></ion-icon>
  </ion-button>

  <!-- Maximize -->
  <ion-button slot="start" (click)="maximize()" icon-only *ngIf="!!view.useWindowManager"
    [hidden]="!view.isDesktop">
    <ion-icon name="expand"></ion-icon>
  </ion-button>

  <!-- Minimize -->
  <ion-button slot="start" (click)="minimize()" icon-only *ngIf="!!view.useWindowManager"
    [hidden]="!view.isDesktop">
    <ion-icon name="remove"></ion-icon>
  </ion-button>

</ion-item>