import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ObjectsService } from 'src/app/services/core/objects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";

@Component({
  selector: 'app-create-object',
  standalone: false,
  templateUrl: './create-object.page.html',
  styleUrls: ['./create-object.page.scss'],
})
export class CreateObjectPage implements OnInit {

  avatarUrl: string = './assets/img/avatars/1.webp';

  fallbackImg: string = './assets/img/fallback.webp';

  introCard: introCardConfig = {
    uid: 'create_object_top_card',
    text: 'create_object_top_card_text',
    title: 'create_object_top_card_title',
  };

  mode: string = 'create';

  object: any = {};

  search: searchOptions = {
    itemsKey: 'projects',
    keys: ['title', 'email', 'firstname', 'lastname', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  type: any;

  view: any = {
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    route: 'create-object',
    title: 'create_object',
  };

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private objectsService: ObjectsService,
    private viewService: ViewService,
  ) {

  }

  async calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.introCard = {
      uid: `${this.mode}_object_top_card`,
      text: `${this.mode}_object_top_card_text`,
      title: `${this.mode}_object_top_card_title`,
    };
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  loadUI() {
    console.log('loadUI: type', this.type);
  }

  ngOnInit() {
    this.calcViewVars();
    this.loadUI();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });
  }

  parseToObject(object: any | null = null) {
    object = object || this.object;
    object.active = true;

    if (!!this.type && !!this.type.fields) {
      this.type.fields.forEach((field: any) => {
        if ((field.hasOwnProperty('name') || field.hasOwnProperty('uid')) && field.hasOwnProperty('value')) {
          object[field.uid || field.name] = field.value;
        }
      });
    }

    return object;
  }

  save(blDismiss: boolean = true) {
    const validate: any = this.validate();
    console.log('validate', validate);

    if (!!validate.error) {
      this.events.publish('error', validate.error);
      return false;
    }

    this.view.loading = true;

    this.objectsService.createObject(validate.object, this.type.uid)
      .then((response: any) => {
        this.events.publish('create-object:created', response);

        if (!!response && !!response.object) {
          this.object = response.object;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  thumbnailLoadingFailed(object: any) {
    object.photo = this.fallbackImg;
  }

  update(blDismiss: boolean = true) {
    const validate: any = this.validate();
    console.log('validate', validate);

    if (!!validate.error) {
      this.events.publish('error', validate.error);
      return false;
    }

    this.view.loading = true;

    this.objectsService.updateObject(validate.object, this.type.uid)
      .then((response: any) => {

        if (!!response && !!response.object) {
          this.object = response.object;
        }

        if (!!blDismiss) {
          this.dismiss(response, 'done');
        }

        this.view.loading = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  uploadPhoto() {

    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.object.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  validate() {
    let response: any = { error_count: 0 };

    if (!!this.type && !!this.type.fields) {
      this.type.fields.forEach((field: any) => {
        if (!!field.required && !field.value) {
          response.error = `field_is_required: ${field.name || field.uid}`;
          response.error_count++;
        }
      });
    }

    response.object = this.parseToObject();
    response.success = !!response.object && !response.error;

    return response;
  }

}