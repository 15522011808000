import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class AiSearchService {

    searchOptions: searchOptions;

    constructor(
        private aiBridge: AiBridgeService,
        private AppCMS: AppcmsService,
    ) {

    }

    addToSearchHistory(search: any) {
        return this.AppCMS.loadPluginData('pipeline', {
            search: search,
        }, ['ai', 'search_history', 'create']);
    }

    deleteSearchHistoryItem(item: searchOptions) {
        return this.AppCMS.loadPluginData('pipeline', {
            item_uid: item.uid,
        }, ['ai', 'search_history', 'delete']);
    }

    getSearchHistory(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'search_history'], {}, blForceRefresh);
    }

    getSearchOptions() {
        return this.searchOptions;
    }

    search(options: any = {}, params: any = {}, blForceRefresh: boolean = false) {
        return this.aiBridge.search(options, params, blForceRefresh);
    }

    setSearchOptions(options: searchOptions) {
        this.searchOptions = options;
        return this;
    }

}