import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { RulesService } from '../core/rules.service';

@Injectable({
  providedIn: 'root'
})
export class CpmService {

  conditionKeys: string[] = [
    'budget_perDay',
    'budget_total',
    'category',
    'duration',
    'external_link',
    'location',
    'tab',
    'users_count',
  ];

  operators: operator[];

  types: string[] = [
    'code',
    'text',
    'product',
    'profile',
    'shop',
    //'video',
    'website',
  ];

  constructor(
    private AppCMS: AppcmsService,
    private events: EventsService,
    private rulesService: RulesService,
    private userService: UserService,
  ) {
    this.operators = this.rulesService.getOperators();
  }

  createEntry(entry: cpmEntry) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entry,
      }, ['cpm', 'createEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

  createMainEntry(entry: cpmMainEntry) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entry,
      }, ['cpm', 'createMainEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

  deleteEntry(entryId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entryId,
      }, ['cpm', 'deleteEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

  deleteMainEntry(entryId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entryId,
      }, ['cpm', 'deleteMainEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

  getCpmPrice(filter: cpmFilters) {
    filter = JSON.parse(JSON.stringify(filter));

    // @debug
    // @todo update the cpm data
    if (filter.type === 'text') {
      filter.type = 'post';
    }

    return new Promise(async (resolve, reject) => {
      let cpmList: cpmData = await this.loadData();
      let cpmMainEntrySelect = cpmList.mainEntries.filter((mainEntry: cpmMainEntry) => {
        return (mainEntry.language === filter.language) && (mainEntry.type === filter.type);
      });
      let cpmMainEntry = cpmMainEntrySelect[0] || null;
      let cpmPrice: number;

      if (!cpmMainEntry) {
        reject('error_missing_main_cpm_entry');
      } else {
        cpmPrice = parseFloat(cpmMainEntry.price + '');
        resolve(cpmPrice as number);
      }

    });
  }

  getConditionKeys() {
    return this.conditionKeys;
  }

  getCostPerView(cpm: number) {
    return parseFloat((cpm / 1000).toFixed(2));
  }

  getFullEntry(entry: cpmEntry) {
    entry.active = !!entry.active;
    entry.factor = parseFloat(entry.factor + '');
    entry.uid = parseInt(entry.uid + '');
    return entry;
  }

  getFullMainEntry(entry: cpmMainEntry) {
    entry.active = !!entry.active;
    entry.uid = parseInt(entry.uid + '');
    return entry;
  }

  async getMatchingCpmEntriesForAd(ad: ad) {
    let data: cpmData = await this.loadData(),
      entries = data.entries || [];

    ad = JSON.parse(JSON.stringify(ad));

    if (ad.type === 'text') {
      ad.type = 'post';
    }

    let matching = entries.filter((entry: cpmEntry) => {
      let blMatch: boolean = entry.active && (entry.language === ad.language) && (!ad.type || !entry.type || (entry.type === ad.type));

      if (blMatch) {
        switch (entry.condition_key) {
          case 'budget_perDay':
            switch (entry.operator) {
              case 'equals':
                blMatch = !!(parseInt(entry.condition_value as string) === ad.budgetPerDay);
                break;
              case 'greater_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) <= ad.budgetPerDay);
                break;
              case 'smaller_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) >= ad.budgetPerDay);
                break;
              default: console.warn('unknown operator', entry.operator);
            }
            break;
          case 'budget_total':
            switch (entry.operator) {
              case 'equals':
                blMatch = !!(parseInt(entry.condition_value as string) === ad.stats.budgetVolume);
                break;
              case 'greater_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) <= ad.stats.budgetVolume);
                break;
              case 'smaller_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) >= ad.stats.budgetVolume);
                break;
              default: console.warn('unknown operator', entry.operator);
            }
            break;
          case 'category':
            blMatch = !!(entry.condition_value === ad.category);
            break;
          case 'duration':
            switch (entry.operator) {
              case 'equals':
                blMatch = !!(parseInt(entry.condition_value as string) === ad.stats.durationInDays);
                break;
              case 'greater_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) <= ad.stats.durationInDays);
                break;
              case 'smaller_or_equal':
                blMatch = !!(parseInt(entry.condition_value as string) >= ad.stats.durationInDays)
                break;
              default: console.warn('unknown operator', entry.operator);
            }
            break;
          case 'location':
            switch (entry.operator) {
              case 'equals':
                blMatch = !!(entry.condition_value === ad.location);
                break;
              case 'greater_or_equal':
                blMatch = !!(entry.condition_value <= ad.location);
                break;
              case 'smaller_or_equal':
                blMatch = !!(entry.condition_value >= ad.location);
                break;
              default: console.warn('unknown operator', entry.operator);
            }
            break;
          default:
            console.log('unknown condition_key', entry.condition_key);
            break;
        }
      }
      return blMatch;
    });

    return matching;
  }

  getOperators() {
    return this.operators;
  }

  getTypes() {
    return this.types;
  }

  loadData(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        user: this.userService.getUid(),
      }, ['cpm', 'data'], null, blForceRefresh)
        .then((cpmData: cpmData) => {
          if (cpmData && cpmData.entries) {
            cpmData.entries.forEach((entry: cpmEntry) => {
              entry = this.getFullEntry(entry);
            });
          }
          if (cpmData && cpmData.mainEntries) {
            cpmData.mainEntries.forEach((entry: cpmEntry) => {
              entry = this.getFullMainEntry(entry);
            });
          }
          resolve(cpmData);
        }).catch(reject);
    });
  }

  updateEntry(entry: cpmEntry) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entry,
      }, ['cpm', 'updateEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

  updateMainEntry(entry: cpmMainEntry) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        entry: entry,
      }, ['cpm', 'updateMainEntry'])
        .then((response) => {
          this.events.publish('cpm:refresh');
          resolve(response);
        })
        .catch(reject);
    });
  }

}
