import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { WindowManagerService } from 'src/app/services/core/window-manager.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
    providedIn: 'root'
})
export class AiTasksService {

    quickData: any;

    taskSavePaths: aiTaskSavePath[];

    constructor(
        private AppCMS: AppcmsService,
        private chooser: ChooserService,
        private configService: ConfigService,
        private events: EventsService,
        private windowManager: WindowManagerService,
    ) {

    }

    create(task: aiTask) {
        return this.AppCMS.loadPluginData('pipeline', {
            task: task,
        }, ['ai', 'tasks', 'create']);
    }

    delete(task: aiTask) {
        return this.AppCMS.loadPluginData('pipeline', {
            task_uid: task.uid,
        }, ['ai', 'tasks', 'delete']);
    }

    duplicate(taskId: number) {
        console.log('duplicateTask', taskId);

        return this.AppCMS.loadPluginData('pipeline', {
            task_uid: taskId,
        }, ['ai', 'tasks', 'duplicate']);
    }

    editTaskCronSettings(task: aiTask) {
        this.setQuickData(task);

        this.windowManager
            .open({
                route: '/ai/task/cron',
                uid: 'ai_task_cron',
            })
            .catch((error: any) => {
                this.events.publish('error', error);
            });
    }

    getQuickData() {

        if (!this.quickData) {
            this.quickData = {};
        }

        return this.quickData;
    }

    getTaskByUid(taskId: number, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', {}, ['ai', 'tasks', taskId], {}, blForceRefresh);
    }

    getTasks(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'tasks'], {}, blForceRefresh);
    }

    getTaskSavePaths() {

        if (!this.taskSavePaths) {
            const config = this.configService.getConfig();

            let taskSavePaths: aiTaskSavePath[] = [
                {
                    icon: 'hand-left-outline',
                    name: 'save_none',
                    description: 'ai_task_save_path_save_none_description',
                    type: 'save_none',
                    uid: 'save_none',
                },
                {
                    icon: 'calendar-outline',
                    name: 'ai_plan',
                    description: 'ai_task_save_path_ai_plan_description',
                    type: 'ai_plan',
                    uid: 'ai_plan',
                },
            ];

            if (config.usePostsAdmin) {
                taskSavePaths.push({
                    icon: 'create-outline',
                    name: 'post',
                    description: 'ai_task_save_path_post_description',
                    type: 'post',
                    uid: 'post',
                });
            }

            if (config.useAdsExtension) {
                taskSavePaths.push({
                    icon: 'cash-outline',
                    name: 'ad',
                    description: 'ai_task_save_path_ad_description',
                    type: 'ad',
                    uid: 'ad',
                });
            }

            if (config.useWhitelabelAppsExtension) {
                taskSavePaths.push(
                    {
                        icon: 'phone-portrait-outline',
                        name: 'app',
                        description: 'ai_task_save_path_app_description',
                        type: 'app',
                        uid: 'app',
                    });
            }

            if (config.useMediaExtension) {
                taskSavePaths.push({
                    icon: 'image-outline',
                    name: 'image',
                    description: 'ai_task_save_path_image_description',
                    type: 'image',
                    uid: 'image',
                });
            }

            if (config.useNewsletterExtension) {
                taskSavePaths.push({
                    icon: 'mail-outline',
                    name: 'newsletter',
                    description: 'ai_task_save_path_newsletter_description',
                    type: 'newsletter',
                    uid: 'newsletter',
                });
            }

            if (config.useShop) {
                taskSavePaths.push({
                    icon: 'cart-outline',
                    name: 'shop_product',
                    description: 'ai_task_save_path_shop_product_description',
                    type: 'shop_product',
                    uid: 'shop_product',
                });
            }

            if (config.useQuizExtension) {
                taskSavePaths.push({
                    icon: 'dice-outline',
                    name: 'quiz',
                    description: 'ai_task_save_path_quiz_description',
                    type: 'quiz',
                    uid: 'quiz',
                });
            }

            if (config.useSurveyExtension) {
                taskSavePaths.push({
                    icon: 'information-circle-outline',
                    name: 'survey',
                    description: 'ai_task_save_path_survey_description',
                    type: 'survey',
                    uid: 'survey',
                });
            }

            if (config.useMediaExtension) {
                taskSavePaths.push({
                    icon: 'film-outline',
                    name: 'video',
                    description: 'ai_task_save_path_video_description',
                    type: 'video',
                    uid: 'video',
                });
            }

            if (config.useWebsitesExtension) {
                taskSavePaths.push({
                    icon: 'desktop-outline',
                    name: 'website',
                    description: 'ai_task_save_path_website_description',
                    type: 'website',
                    uid: 'website',
                });
            }

            this.taskSavePaths = taskSavePaths;
        }

        return this.taskSavePaths;
    }

    getTaskSettings(taskId: number, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', {
            task_uid: taskId,
        }, ['ai', 'tasks', 'settings'], {}, blForceRefresh);
    }

    pickTaskSavePath(task: aiTask, options: any = {}) {
        return new Promise(async (resolve, reject) => {
            let paths: aiTaskSavePath[] = this.getTaskSavePaths();

            if (!!options.exclude && !!options.exclude.length) {
                paths = paths.filter((path: any) => {
                    return options.exclude.indexOf(path.uid) === -1;
                });
            }

            const chooseConfig: chooseConfig = {
                data: paths,
                labelKey: 'name',
                multiple: !!options.multiple,
                subLabelKey: 'description',
                title: 'choose_target',
                valueKey: 'uid',
            };

            const choose: chooseResponse = await this.chooser.choose(chooseConfig);

            if (choose && choose.data && choose.data.items) {
                resolve(choose.data.items);
            } else
                if (choose && choose.data && choose.data.item && choose.data.item.uid) {
                    resolve(choose.data.item.uid);
                } else {
                    resolve(false);
                }
        });
    }

    quick(data: any | null = null) {
        this.setQuickData(data || {});

        const url: string = (!!data && !!data.uid ? `/ai/automation/${data.uid}` : '/ai/task');

        //this.navCtrl.navigateForward(url);

        this.windowManager
            .open({
                route: url,
                uid: 'ai_task',
            })
            .catch((error: any) => {
                this.events.publish('error', error);
            });
    }

    setQuickData(data: any) {
        this.quickData = data;
        return this;
    }

    update(task: aiTask) {
        return this.AppCMS.loadPluginData('pipeline', {
            task: task,
        }, ['ai', 'tasks', 'update']);
    }

    updateTaskSettings(task: aiTask) {
        return this.AppCMS.loadPluginData('pipeline', {
            task: task,
        }, ['ai', 'tasks', 'settings', 'update']);
    }

}