import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from "src/app/services/core/config.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { SearchService } from 'src/app/services/core/search.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { SharingModalPage } from 'src/app/pages/core/modals/sharing-modal/sharing-modal.page';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private configService: ConfigService,
    private loading: LoadingController,
    private modalService: ModalService,
    private search: SearchService,
    private tools: ToolsService,
    private translation: TranslationService,
  ) {
  }

  getConnections(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: (typeof options === 'object' ? options : {}),
    }, ['sharing', 'connections'], {}, blForceRefresh);
  }

  getURLMetaData(url: string) {
    return new Promise((resolve, reject) => {
      let proxyUrl: string = `${this.search.getProxyUrl()}${url}`;

      this.AppCMS.loadUrl(proxyUrl, {}, true)
      .then((response: any) => {
        this.parseURLMetaDataResponse(response).then(resolve).catch(reject);
      })
      .catch((response: any) => {
        this.parseURLMetaDataResponse(response).then(resolve).catch(reject);
      });
    });
  }

  async internal(post: post) {
  }

  parseURLMetaDataResponse(response: any) {
    return new Promise((resolve, reject) => {
      let meta: any = {},
          metaExplode = (response || '').split('<meta ');

      metaExplode.shift();

      metaExplode.forEach((metaLine: string) => {
        metaLine = `<meta ${metaLine.replace('\\', '')}`;
        
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(metaLine, 'text/html'),
            tag = htmlDoc.getElementsByTagName('meta')[0],
            name = tag.getAttribute('name') || tag.getAttribute('property'),
            content = tag.getAttribute('content');

        if(!!name && !!content) {
          meta[name] = content;
        }

      });

      resolve(meta);
    });
  }

  pick(options: any = {}) {
    return new Promise(async (resolve, reject) => {

      const loading: any = await this.loading.create();
      loading.present();

      const sources: any[] = (!!options.data ? options.data : (await this.getConnections() as any[]));
      loading.dismiss();

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: sources,
        labelKey: 'name',
        multiple: !!options.multiple,
        service: this,
        subLabelKey: 'platform',
        valueKey: 'uid',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  share(message: string|null = null, title: string|null = null, image: string|null = null, url: string|null = null) {
    return new Promise((resolve, reject) => {
      this.translation.get('share')
        .subscribe(async (response: any) => {
          try {
            
            //Share.share({
              navigator.share({
                title: `${title}`,
                text: `${message}`,
                url: `${url}`,
                //dialogTitle: response || 'share'
            })
            .then(resolve)
            .catch((error: any) => {
              console.warn('sare error', error);
              
              this.shareUsingModal({
                message: message,
                title: title,
                image: image,
                url: url,
              })
              .then(resolve)
              .catch(reject);
            });
          } catch(e) {
            this.shareUsingModal({
              message: message,
              title: title,
              image: image,
              url: url,
            }).then(resolve).catch(reject);
          }
        });
    });
  }

  sharePost(post: post) {

    const slugExplode = this.tools.trim(`${post.url}`, '/').split('/'),
          slug = `${slugExplode[slugExplode.length-1] ||post.uid}`.replace('?p=', ''),
          url = `${this.configService.getOpenerURL()}${slug}`;

    this.translation.get([
      'share_post_text',
      'share_post_headline',
    ])
    .subscribe((translations: any) => {

      this.share(
        translations.share_post_text || 'share_post_text',
        translations.share_post_headline || 'share_post_headline',
        post.thumbnail,
        url,
      );
      
    });
  }

  shareProfile(profile: user) {
    const url = 'https://web.pipeline.page/profile/' + profile.uid;

    this.translation.get([
      'share_profile_headline',
      'share_profile_text',
    ])
    .subscribe((translations: any) => {
      this.share(
        translations.share_profile_text || 'share_profile_text',
        translations.share_profile_headline || "share_profile_headline",
        profile.photo,
        url,
      );
    });
  }

  async shareUsingModal(shareOptions: any) {
    const modal: any = await this.modalService.create({
      component: SharingModalPage,
      componentProps: {
        shareOptions: shareOptions,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'sharingModal'
    });

    this.modalService.present(modal);
  }

  shareViaEmail(message: string, subject: string, to: string[], css: string[], bcc: string[], files: string[]) {

    if(!files || !files[0]) {
      return false;
    }
    
    return this.share(message, subject, files[0], files[0]);
  }
  
}
