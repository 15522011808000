<pipeline-header [selectionOptions]="selectionOptions" [(view)]="view"></pipeline-header>

<ion-content fullscreen="true" #statisticsPage>

  <!-- Refresher -->
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>

  <!-- Select project card -->
  <div class="container" *ngIf="!view.project || !view.project.uid && !!appConfig.useProjectsExtension">
    <pipeline-select-project-card></pipeline-select-project-card>
  </div>

  <div class="container" *ngIf="!appConfig.useProjectsExtension || (!!view.project && !!view.project.uid)">

    <!-- Segment toolbar -->
    <ion-segment [(ngModel)]="view.segment" (ionChange)="segmentChanged()">
      <ion-segment-button *ngFor="let segment of segments; trackBy:trackItems" [value]="segment.uid" icon-only>
        <ion-icon *ngIf="!!segment.icon" [name]="segment.icon"></ion-icon>
        <ion-label [innerHTML]="segment.label|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>

    <ion-grid>
      <ion-row>

        <!-- Sidebar -->
        <ion-col class="sidebar" [size]="view.isDesktop ? 3 : 12">

          <!-- Connections -->
          <pipeline-connections-filter-card [(cards)]="cards" [(view)]="view"
            (onChanged)="onConnectionsFilterChanged($event)"></pipeline-connections-filter-card>

          <!-- Platforms -->
          <pipeline-platforms-filter-card [(cards)]="cards" [(view)]="view"></pipeline-platforms-filter-card>

        </ion-col>

        <!-- Content -->
        <ion-col class="content" [size]="view.isDesktop ? 9 : 12">

          <!-- Intro card -->
          <div class="ion-padding" [hidden]="view.introCard.hidden">
            <pipeline-intro-card class="introCard" [(view)]="view.introCard"></pipeline-intro-card>
          </div>

          <!-- Loading spinner -->
          <ion-spinner [hidden]="!view.loading"></ion-spinner>

          <!-- Overview -->
          <div [hidden]="!!view.loading || view.segment !== 'overview'">

            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'overview'|translate"></ion-card-title>
              </ion-card-header>

              <ion-grid>

                <ion-row class="topRow">

                  <!-- Contributions -->
                  <ion-col size="6">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.articles || '...' : '...'"></h3>
                      <p [innerHTML]="'articles_count'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Sources -->
                  <ion-col size="6">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.sources || '...' : '...'"></h3>
                      <p [innerHTML]="'sources'|translate"></p>
                    </ion-card>
                  </ion-col>

                </ion-row>

                <ion-row *ngIf="view.statistics && view.statistics.boards">
                  <ion-col *ngFor="let board of view.statistics.boards; trackBy:trackItems"
                    [hidden]="(view.segment && (view.segment !== board.segment)) || !board.data || !board.data.datasets || !board.data.datasets[0] || !board.data.datasets[0].data || !board.data.datasets[0].data[0]">
                    <ion-card [id]="board.indent">
                      <canvas #postsByCategoryChart *ngIf="board.indent === 'postsByCategory'"></canvas>
                      <canvas #postsByEmployeeChart *ngIf="board.indent === 'postsByEmployee'"></canvas>
                      <canvas #postsByGenreChart *ngIf="board.indent === 'postsByGenre'"></canvas>
                      <canvas #postsByHostChart *ngIf="board.indent === 'postsByHost'"></canvas>
                      <canvas #postsByRatingChart *ngIf="board.indent === 'postsByRating'"></canvas>
                      <canvas #postsByRegionChart *ngIf="board.indent === 'postsByRegion'"></canvas>
                      <canvas #postsByLanguageChart *ngIf="board.indent === 'postsByLanguage'"></canvas>
                      <canvas #ratingsByGenreChart *ngIf="board.indent === 'ratingsByGenre'"></canvas>
                      <canvas #sourcesByRatingChart *ngIf="board.indent === 'sourcesByRating'"></canvas>
                      <canvas #sourcesByRegionChart *ngIf="board.indent === 'sourcesByRegion'"></canvas>
                      <canvas #usersByCategoriesChart *ngIf="board.indent === 'usersByCategories'"></canvas>

                      <ion-card-header
                        *ngIf="board.data && board.data.datasets && board.data.datasets[0] && board.data.datasets[0].label">
                        <ion-card-subtitle [innerHTML]="board.data.datasets[0].label"></ion-card-subtitle>
                      </ion-card-header>

                      <ion-list *ngIf="board.data && board.data.labels">
                        <ion-item *ngFor="let label of board.data && board.data.labels; let i = index"
                          [hidden]="!board.expanded && i > 2">
                          <ion-label>
                            <p [innerHTML]="label|translate"></p>
                          </ion-label>
                          <ion-note slot="end"
                            *ngIf="board.data && board.data.datasets && board.data.datasets[0] && board.data.datasets[0].data && board.data.datasets[0].data[i]"
                            [innerHTML]="board.data.datasets[0].data[i]"></ion-note>
                        </ion-item>
                      </ion-list>

                      <ion-fab vertical="end" horizontal="center">
                        <ion-fab-button (click)="expandBoard(board)" color="primary" size="small" icon-only>
                          <ion-icon [name]="board.expanded ? 'arrow-up-outline' : 'arrow-down-outline'"></ion-icon>
                        </ion-fab-button>
                      </ion-fab>

                    </ion-card>
                  </ion-col>
                </ion-row>
              </ion-grid>

            </ion-card>

            <!-- Content Generation -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'content_generation'|translate"></ion-card-title>
              </ion-card-header>

              <ion-grid>
                <ion-row class="topRow">

                  <!-- Text generations -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.generated_texts || '...' : '...'"></h3>
                      <p [innerHTML]="'generated_texts'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Text generations -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.generated_images || '...' : '...'"></h3>
                      <p [innerHTML]="'generated_images'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Text generations -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.generated_videos || '...' : '...'"></h3>
                      <p [innerHTML]="'generated_videos'|translate"></p>
                    </ion-card>
                  </ion-col>

                </ion-row>
              </ion-grid>

            </ion-card>

            <!-- Social media -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'social_media'|translate"></ion-card-title>
              </ion-card-header>

              <ion-grid>
                <ion-row class="topRow">

                  <!-- Postings -->
                  <ion-col [size]="view.isDesktop ? 3 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.social_media_postings || '...' : '...'">
                      </h3>
                      <p [innerHTML]="'postings'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Reach -->
                  <ion-col [size]="view.isDesktop ? 3 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.reach || '...' : '...'"></h3>
                      <p [innerHTML]="'reach_count'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Comments -->
                  <ion-col [size]="view.isDesktop ? 3 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.social_media_comments || '...' : '...'">
                      </h3>
                      <p [innerHTML]="'comments'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Reactions -->
                  <ion-col [size]="view.isDesktop ? 3 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.reactions || '...' : '...'"></h3>
                      <p [innerHTML]="'reactions_count'|translate"></p>
                    </ion-card>
                  </ion-col>

                </ion-row>
              </ion-grid>

            </ion-card>

            <!-- Integrations -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'integrations'|translate"></ion-card-title>
              </ion-card-header>

              <ion-grid>
                <ion-row class="topRow">



                </ion-row>
              </ion-grid>

            </ion-card>

            <!-- System -->
            <ion-card>

              <ion-card-header>
                <ion-card-title [innerHTML]="'system'|translate"></ion-card-title>
              </ion-card-header>

              <ion-grid>
                <ion-row class="topRow">

                  <!-- GPU -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3
                        [innerHTML]="view.statistics ? view.statistics.counts.average_gpu_utilization || '...' : '...'">
                      </h3>
                      <p [innerHTML]="'average_gpu_utilization'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Memory -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.memory_usage || '...' : '...'"></h3>
                      <p [innerHTML]="'memory_usage'|translate"></p>
                    </ion-card>
                  </ion-col>

                  <!-- Network -->
                  <ion-col [size]="view.isDesktop ? 4 : 12">
                    <ion-card>
                      <h3 [innerHTML]="view.statistics ? view.statistics.counts.network || '...' : '...'"></h3>
                      <p [innerHTML]="'network_usage'|translate"></p>
                    </ion-card>
                  </ion-col>

                </ion-row>
              </ion-grid>

            </ion-card>

          </div>

          <!-- Social -->
          <div [hidden]="!!view.loading || view.segment !== 'social'">

            <!-- Growth bar -->
            <ion-grid class="statisticsGrid" *ngIf="!!view.grow && !!view.grow.likes">
              <ion-row>

                <ion-col>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-title [innerHTML]="view.grow.likes.total"></ion-card-title>
                      <ion-card-subtitle [innerHTML]="'total_likes_count'|translate"></ion-card-subtitle>
                    </ion-card-header>
                  </ion-card>
                </ion-col>

                <ion-col>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-title [innerHTML]="view.grow.likes.last_7_days"
                        [class.danger]="view.grow.likes.last_7_days < 0"
                        [class.success]="view.grow.likes.last_7_days > 0"></ion-card-title>
                      <ion-card-subtitle [innerHTML]="'grow_likes_last_7_days'|translate"></ion-card-subtitle>
                    </ion-card-header>
                  </ion-card>
                </ion-col>

                <ion-col>
                  <ion-card>
                    <ion-card-header>
                      <ion-card-title [innerHTML]="view.grow.likes.last_28_days"
                        [class.danger]="view.grow.likes.last_28_days < 0"
                        [class.success]="view.grow.likes.last_28_days > 0"></ion-card-title>
                      <ion-card-subtitle [innerHTML]="'grow_likes_last_28_days'|translate"></ion-card-subtitle>
                    </ion-card-header>
                  </ion-card>
                </ion-col>

              </ion-row>
            </ion-grid>

            <!-- Likes -->
            <div style="height:60vh;width:100%;" class="card ion-card">

              <ion-card-header>
                <ion-card-title [innerHTML]="'likes'|translate"></ion-card-title>
              </ion-card-header>

              <canvas #likesChart id="likesChart"></canvas>

            </div>

            <!-- Follower  -->
            <div style="height:60vh;width:100%;" class="card ion-card">

              <ion-card-header>
                <ion-card-title [innerHTML]="'follower'|translate"></ion-card-title>
              </ion-card-header>

              <canvas #followerChart id="followerChart"></canvas>

            </div>

            <!-- Following -->
            <div style="height:60vh;width:100%;" class="card ion-card">

              <ion-card-header>
                <ion-card-title [innerHTML]="'following'|translate"></ion-card-title>
              </ion-card-header>

              <canvas #followingChart id="followingChart"></canvas>

            </div>
          </div>

          <!-- Trends -->
          <div [hidden]="view.segment !== 'trends'">

            <pipeline-selection-toolbar *ngIf="!!view.trendsByPlatforms" [(items)]="view.trendsByPlatforms"
              [(view)]="view" [options]="selectionOptions" (onLanguageChanged)="onLanguageChanged($event)"
              (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

            <div class="loading-stage" [hidden]="!view.loading">
              <ion-spinner name="circular"></ion-spinner>
              <h3 [innerHTML]="'loading_trends_data'|translate"></h3>
            </div>

            <ion-grid *ngIf="!!view.trendsByPlatforms && !!view.trendsByPlatforms.length">
              <ion-row>

                <ion-col *ngFor="let platform of view.trendsByPlatforms; trackBy:trackItems">
                  <ion-card>

                    <ion-list>

                      <ion-item>

                        <ion-avatar slot="start">
                          <ion-img [src]="'./assets/img/integrations/icons/' + platform.uid + '.webp'"></ion-img>
                        </ion-avatar>

                        <ion-checkbox labelPlacement="start">
                          <ion-label [innerHTML]="platform.name|translate"></ion-label>
                        </ion-checkbox>

                      </ion-item>

                      <ion-item *ngFor="let trend of platform.trends">
                        <ion-label [innerHTML]="trend"></ion-label>
                      </ion-item>

                      <ion-chip *ngFor="let hashtag of platform.hashtags">
                        <ion-label [innerHTML]="hashtag"></ion-label>
                      </ion-chip>

                    </ion-list>

                  </ion-card>
                </ion-col>

              </ion-row>
            </ion-grid>

          </div>

        </ion-col>

      </ion-row>

    </ion-grid>

  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ion-toolbar class="container ion-no-border">

    <!-- View mode picker -->
    <ion-buttons slot="end">
      <pipeline-view-mode-picker [view]="view" (changed)="viewModeChanged($event)"></pipeline-view-mode-picker>
    </ion-buttons>

  </ion-toolbar>
</ion-footer>