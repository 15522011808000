import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { CpmService } from 'src/app/services/ads/cpm.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { FoldersService } from 'src/app/services/utils/folders.service';


@Injectable({
  providedIn: 'root'
})
export class AdsService {

  addAddons: adAddon[] = [
    {
      icon: 'text-outline',
      title: 'Redaktioneller Beitrag',
      type: 'content-creation',
      price: 400,
    },
    {
      icon: 'color-palette-outline',
      title: 'Grafische Erstellung',
      type: 'graphics-creation',
      price: 200,
    },
    {
      icon: 'language-outline',
      title: 'Übersetzung',
      type: 'translations',
      price: 40,
    },
    {
      icon: 'notifications-outline',
      title: 'Benachrichtigungen',
      type: 'push-notifications',
      price: 50,
    },
    {
      icon: 'newspaper-outline',
      title: 'Exklusives Interview',
      type: 'exclusive-interview',
      price: 300,
    },
    {
      icon: 'share-social-outline',
      title: 'Social Media Promo',
      type: 'social-media-promo',
      price: 200,
    },
    {
      icon: 'star-outline',
      title: 'Beitrag der Woche',
      type: 'post-of-the-week',
      price: 450,
    },
  ];

  adTypes: adType[] = [
    {
      icon: 'text-outline',
      title: 'Text-Beitrag',
      type: 'text',
    },
    {
      icon: 'person-outline',
      title: 'Profil',
      type: 'profile',
    },
    {
      icon: 'bag-handle-outline',
      title: 'Produkt',
      type: 'product',
    },
    {
      icon: 'storefront-outline',
      title: 'Shop',
      type: 'shop',
    },
    {
      icon: 'globe-outline',
      title: 'Webseite',
      type: 'website',
    },
    {
      icon: 'code-outline',
      title: 'Code',
      type: 'code',
    },
    /*
    {
      icon: 'heart-circle-outline',
      title: 'Story',
      type: 'story',
    },
    {
      icon: 'mic-outline',
      title: 'Podcast',
      type: 'podcast',
    },
    {
      icon: 'videocam-outline',
      title: 'Video',
      type: 'video',
    },
    */
  ];

  config: adsConfig = {
    amountUsers: 10000,
    basePricePerClick: 0.5,
  };

  locations: adLocationsConfig = {
    code: [
      {
        description: 'ad_location_posts_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
      {
        description: 'ad_location_banner_slim_1200_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.banner.slim.1200',
        name: 'ad_location_banner_slim_1200',
      },
      {
        description: 'ad_location_skyscraper_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.skyscraper',
        name: 'ad_location_skyscraper',
      },
      {
        description: 'ad_location_fireplace_3_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.fireplace.3',
        name: 'ad_location_fireplace_3',
      },
      {
        description: 'ad_location_fireplace_3_wide_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.fireplace.3_wide',
        name: 'ad_location_fireplace_3_wide',
      },
    ],
    text: [
      {
        description: 'ad_location_posts_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      /*
      {
        description: 'ad_location_posts_web_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      */
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    podcast: [
      {
        description: 'ad_location_posts_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcasts_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      {
        description: 'ad_location_posts_web_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/podcast/placement_podcast_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    products: [
      {
        description: 'ad_location_products_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_products_between',
      },
      {
        description: 'ad_location_products_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_products_small',
      },
      {
        description: 'ad_location_products_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_products_large',
      },
      {
        description: 'ad_location_products_related_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_products_related',
      },
      {
        description: 'ad_location_products_web_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_products_web',
      },
      {
        description: 'ad_location_products_default_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_products_default',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/product/placement_produkt_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    profile: [
      {
        description: 'ad_location_profiles_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.between',
        name: 'ad_location_profiles_between',
      },
      {
        description: 'ad_location_profiles_small_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.small',
        name: 'ad_location_profiles_small',
      },
      {
        description: 'ad_location_profiles_large_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.large',
        name: 'ad_location_profiles_large',
      },
      {
        description: 'ad_location_profiles_related_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_reader_aehnliche.svg',
        indent: 'ad.location.profiles.related',
        name: 'ad_location_profiles_related',
      },
      {
        description: 'ad_location_profiles_web_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_reader.svg',
        indent: 'ad.location.profiles.web',
        name: 'ad_location_profiles_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/profile/placement_profil_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    video: [
      {
        description: 'ad_location_posts_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_posts_related_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/video/placement_videos_in_reader_aehnliche.svg',
        indent: 'ad.location.posts.related',
        name: 'ad_location_posts_related',
      },
      {
        description: 'ad_location_posts_web_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_reader.svg',
        indent: 'ad.location.posts.web',
        name: 'ad_location_posts_web',
      },
      {
        description: 'ad_location_profiles_default_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_feed.svg',
        indent: 'ad.location.profiles.default',
        name: 'ad_location_profiles_default',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/video/placement_video_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
    ],
    website: [
      {
        description: 'ad_location_posts_between_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.between',
        name: 'ad_location_posts_between',
      },
      {
        description: 'ad_location_posts_small_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.small',
        name: 'ad_location_posts_small',
      },
      {
        description: 'ad_location_posts_large_description',
        device_groups: ['app', 'tablet', 'web_desktop', 'web_mobile'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_feed.svg',
        indent: 'ad.location.posts.large',
        name: 'ad_location_posts_large',
      },
      {
        description: 'ad_location_stories_between_description',
        device_groups: ['app', 'tablet'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.stories.between',
        name: 'ad_location_stories_between',
      },
      {
        description: 'ad_location_banner_slim_1200_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.banner.slim.1200',
        name: 'ad_location_banner_slim_1200',
      },
      {
        description: 'ad_location_skyscraper_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.skyscraper',
        name: 'ad_location_skyscraper',
      },
      {
        description: 'ad_location_fireplace_3_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.fireplace.3',
        name: 'ad_location_fireplace_3',
      },
      {
        description: 'ad_location_fireplace_3_wide_description',
        device_groups: ['web_desktop'],
        image: './assets/img/ad-manager/placements/post/placement_beitrag_in_story.svg',
        indent: 'ad.location.web.fireplace.3_wide',
        name: 'ad_location_fireplace_3_wide',
      },
    ],
  };

  locationTranslations: any;

  constructor(
    private AppCMS: AppcmsService,
    private basket: BasketService,
    private cpm: CpmService,
    private events: EventsService,
    private folders: FoldersService,
    private loadingCtrl: LoadingController,
    private userService: UserService,
  ) {
  }

  calcStats(ad: ad, adAddons: adAddon[] = null) {
    return new Promise(async (resolve, reject) => {
      try {

        //let diff: number = moment(ad.date_end).diff(moment(ad.date_start)),
        //durationInDays: number = (parseInt(moment.duration(diff).asHours() as any) / 24);
        let diff: number = 0, durationInDays: number = 0; // @todo rewrite this in JS-only

        ad.stats.durationInDays = durationInDays;

        let cpmPrice = await this.cpm.getCpmPrice(ad);

        let pricePerView: number = 0,
          budgetVolume: number = parseFloat((durationInDays * ad.budgetPerDay).toFixed(2));

        let factor = await this.getFactor(ad);

        if (factor !== 1 && cpmPrice) {
          cpmPrice = (parseFloat((cpmPrice as string)) * factor);
        }

        budgetVolume = parseFloat(budgetVolume.toFixed(2));

        let viewsOverTime = parseInt((budgetVolume / ((cpmPrice as number) / 1000)) + ''),
          viewsPerDay = parseInt((viewsOverTime / durationInDays) + '');

        if (cpmPrice) {
          pricePerView = this.cpm.getCostPerView(cpmPrice as number);
        } else {
          pricePerView = this.cpm.getCostPerView(
            ((budgetVolume / viewsOverTime) / factor) as number
          );
        }

        viewsOverTime = parseInt((budgetVolume / ((cpmPrice as number) / 1000)) + '');
        viewsPerDay = parseInt((viewsOverTime / durationInDays) + '');

        let stats: adStats = {
          budgetVolume: budgetVolume,
          durationInDays: durationInDays,
          pricePerView: pricePerView,
          viewsOverTime: viewsOverTime,
          viewsPerDay: viewsPerDay,
        };

        if (adAddons && adAddons.length) {
          let addon_price: number = 0;
          adAddons.forEach((adAddon: adAddon) => {
            if (adAddon.checked) {
              addon_price += adAddon.price;
            }
          });
          stats.addons = {
            price: addon_price,
          };
          stats.budgetVolume = stats.budgetVolume + addon_price;
        }

        if (cpmPrice) {
          stats.cpmPrice = cpmPrice as number;
        }

        resolve(stats);
      } catch (e) {
        reject(e);
      };
    });
  }

  async create(ad: ad) {
    ad = this.toDbAd(ad);

    const loading: any = await this.loadingCtrl.create({
      spinner: 'circular',
    });

    if (ad.stats) {
      ad.budgetVolume = ad.budgetVolume || ad.stats.budgetVolume;
      ad.pricePerView = ad.pricePerView || ad.stats.pricePerView;
      ad.viewsOverTime = ad.viewsOverTime || ad.stats.viewsOverTime;
      ad.viewsPerDay = ad.viewsPerDay || ad.stats.viewsPerDay;
    }

    return new Promise((resolve, reject) => {
      loading.present();

      ad.active = false;

      this.AppCMS.loadPluginData('pipeline', {
        ad: ad,
      }, ['ads', 'create'])
        .then((response: any) => {
          loading.dismiss();

          this.pay(ad)
            .then((payResponse: any) => {
              ad.active = true;

              // update ad here

              // return create response here
              resolve(response);
            })
            .catch(reject);
        })
        .catch((error) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  createFolder(folder: folder) {
    folder.location = folder.location || 'ads';
    return this.folders.create(folder);
  }

  async delete(adId: number) {

    const loading: any = await this.loadingCtrl.create({
      spinner: 'circular',
    });

    loading.present();

    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        ad: adId,

      }, ['ads', 'delete'])
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  getActive(blForceRefresh: boolean = false, options: any = {}) {
    return this.getAll(Object.assign(options, {
      active: true,
    }), blForceRefresh);
  }

  getAdAddons() {
    return this.addAddons;
  }

  getAdTypes() {
    return this.adTypes;
  }

  getAll(options: any = {}, blForceRefresh: boolean = false,) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', options, ['ads'], {}, blForceRefresh)
        .then((ads: ad[]) => {
          if (ads && ads.length) {
            ads.forEach((ad: ad) => {
              ad = this.getFullAd(ad);
            });
          }
          resolve(ads);
        })
        .catch(reject);
    });
  }

  async getFactor(ad: ad) {
    let factorAll: number = 0;
    const entries: cpmEntry[] = await this.cpm.getMatchingCpmEntriesForAd(ad);

    entries.forEach((entry: cpmEntry) => {
      factorAll += entry.factor;
    });

    return (factorAll / entries.length) || 1;
  }

  getFolders(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    options.location = options.location || 'ads';
    return this.folders.get(options, blForceRefresh, params);
  }

  getFullAd(ad: ad) {

    if (!!ad.photo && !ad.thumbnail) {
      ad.thumbnail = ad.photo;
    }

    //ad.date_end_formatted = moment(ad.date_end).format('DD.MM.YYYY');
    //ad.date_start_formatted = moment(ad.date_start).format('DD.MM.YYYY');

    return ad;
  }

  getLocations() {
    return this.locations;
  }

  getLocationTranslations() {
    if (!this.locationTranslations) {
      let locations = this.getLocations();
      let locationTranslations: any = {};

      Object.keys(locations).forEach((locationKey: string) => {
        locations[locationKey].forEach((location: adLocation) => {
          locationTranslations[location.indent] = location.name;
        });
      });

      this.locationTranslations = locationTranslations;
    }
    return this.locationTranslations;
  }

  pay(ad: ad, blPayAsAdmin: boolean = false) {
    return new Promise((resolve, reject) => {
      const isAdmin: boolean = this.userService.isType('Admin');

      // @debug
      //isAdmin = false;

      if (isAdmin && !blPayAsAdmin) {
        resolve({});
      } else {
        this.basket.add({
          amount: 1,
          name: 'Werbeanzeige: ' + ad.label,
          price: ad.budgetVolume,
        })
          .then(() => {
            this.events.publish('view:basket');
          })
          .catch(reject);
      }
    });
  }

  toDbAd(_ad: ad) {
    let ad: ad = JSON.parse(JSON.stringify(_ad));

    delete ad.date_end_formatted;
    delete ad.date_start_formatted;
    delete ad.post;
    delete ad.region;

    return (ad || {});
  }

  async update(_ad: ad) {
    const ad = this.toDbAd(_ad);

    const loading: any = await this.loadingCtrl.create({
      spinner: 'circular',
    });

    loading.present();

    if (ad.stats) {
      ad.budgetVolume = ad.budgetVolume || ad.stats.budgetVolume;
      ad.pricePerView = ad.pricePerView || ad.stats.pricePerView;
      ad.viewsOverTime = ad.viewsOverTime || ad.stats.viewsOverTime;
      ad.viewsPerDay = ad.viewsPerDay || ad.stats.viewsPerDay;
    }

    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        ad: ad,
      }, ['ads', 'update'])
        .then((response: any) => {
          loading.dismiss();
          resolve(response);
        })
        .catch((error) => {
          loading.dismiss();
          reject(error);
        });
    });
  }

  validateEmbedCode(code: string) {

    const blHasAnchor = (code.split('<a ').length > 1),
      blHasHref = (code.split('href=').length > 1),
      blHasIframe = (code.split('<iframe ').length > 1),
      blHasImage = (code.split('<img ').length > 1),
      blHasScript = (code.split('<script ').length > 1),
      blHasSource = (code.split('src=').length > 1),
      blHasClosingTag = (code.split('>').length > 1);

    const blSuccess = (
      !!blHasClosingTag &&
      (!!blHasSource || !!blHasHref) &&
      (!!blHasIframe || !!blHasScript || !!blHasAnchor || !!blHasImage)
    );

    return {
      success: blSuccess,
    };
  }

}