import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { ViewService } from 'src/app/services/core/view.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';

@Component({
  selector: 'pipeline-ai-text-to-audio-card',
  standalone: false,
  templateUrl: './ai-text-to-audio-card.component.html',
  styleUrls: ['./ai-text-to-audio-card.component.scss']
})
export class AiTextToAudioCardComponent implements AfterViewInit, OnDestroy, OnInit {

  aiSettings: aiSettings = {};

  @Input() autostart: boolean = false;
  @Input() input: string = '';
  @Input() options: aiTextToAudioOptions = {};

  fallbackImg: string = './assets/img/fallback.webp';

  @Input() size: number = 12;
  
  view: any = {
  };

  constructor(
    private dani: DaniService,
    private events: EventsService,
    private viewService: ViewService,
  ) {

  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  generate() {

    if (!this.input) {
      return false;
    }

    this.view.loadingTextToAudio = true;

    this.dani.executeCreateTextToAudio({
      input: this.input,
    })
      .then((response: any) => {
        this.view.loadingTextToAudio = false;

        if (!!response && !!response.error_message) {
          this.events.publish('error', response.error_message);
        } else
          if (!!response && !!response.url) {
            this.view.textToAudioUrl = response.url;
          }
      })
      .catch((error: any) => {
        this.view.loadingTextToAudio = false;
        this.events.publish('error', error);
      });

  }

  initEvents() {
    this.view.events = {};
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    if (!!this.autostart && !!this.input) {
      this.generate();
    }
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

}
