import { Component, NgZone, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { EventsService } from "src/app/services/core/events.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ViewService } from 'src/app/services/core/view.service';
import { ShopAdminService } from 'src/app/services/ecommerce/shop-admin.service';
import { ExternalService } from 'src/app/services/extensions/external.service';
import { CitiesService } from 'src/app/services/geo/cities.service';
import { CountriesService } from 'src/app/services/geo/countries.service';
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { ExportService } from 'src/app/services/utils/export.service';
import { ImporterService } from 'src/app/services/utils/importer.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-edit-shop-source',
  standalone: false,
  templateUrl: './edit-shop-source.page.html',
  styleUrls: ['./edit-shop-source.page.scss'],
})
export class EditShopSourcePage implements OnInit {

  fallbackImg: string = './assets/img/fallback.webp';

  item: source;

  languageOptions: languagesSelectOptions = {
    cached: false,
    default: null,
    multiple: false,
    mode: 'view',
  };

  pages: any = {};

  search: any = {
    cityQuery: '',
    countryQuery: '',
  };

  settings: any = {};

  shortlinkConfig: shortlinkConfig = {
    canInput: false,
    canRequest: true,
    hasInput: false,
  };

  source: source;

  state: state = {};

  view: any = {
    credentials: {
      ck: '',
      cs: '',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    permissions: {},
    quote_characters: [
      {
        indent: '"',
        label: '"',
      },
      {
        indent: "'",
        label: "'",
      },
    ],
    segment: 'general',
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    sourceFields: [],
    targetFields: [
      'active',
      'description',
      'image',
      'name',
      'original_uid',
      'price',
      'sku',
      'timestamp',
      'url',
    ],
    title: 'edit_shop_source',
  };

  constructor(
    private cities: CitiesService,
    private countries: CountriesService,
    private events: EventsService,
    private exportService: ExportService,
    private external: ExternalService,
    private importService: ImporterService,
    private media: MediaextendService,
    private modalService: ModalService,
    private navParams: NavParams,
    private shopAdmin: ShopAdminService,
    private tools: ToolsService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {

    if (!!this.item) {
      this.source = this.item;
    }

    const source: source = (this.navParams.get('source') || (this.shopAdmin.detailItem() || {})) as source;
    console.log('source', source);

    this.source = source;
    this.source.credentials = this.source.credentials || {};
    this.source.settings = this.source.settings || {};
    this.source.settings.csv_mappings = this.source.settings.csv_mappings || {};
    this.source.settings.permissions = this.source.settings.permissions || {};
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    if (!this.view.hasOwnProperty('systems')) {
      this.view.systems = this.shopAdmin.getSystems();
    }
  }

  convertToRegionalSource() {
    this.update();
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss({
      source: this.source,
    });
  }

  export() {

    if (!this.source || !this.source.uid) {
      return false;
    } else
      if (!this.view.exportUrl || !this.view.exportUrl.length) {
        return false;
      }

    const params: dataExportOptions = {
      data: (this.view.selectedItems || []),
      settings: this.settings,
      source: this.source.uid,
      type: 'shop_source',
      url: this.view.exportUrl,
    };

    this.view.loading = true;

    this.exportService.executeServerSideExport(params)
      .then((response: any) => {
        console.log('export response', response);

        this.view.loading = false;
        this.view.smart_link = (!!response.smart_link ? response.smart_link : null);

        if (!!this.view.smart_link && !!this.view.smart_link.url) {
          //this.browser.create(this.view.smart_link.url);
        }
      })
      .catch((error: any) => {
        console.log('export error', error);

        this.view.loading = false;

        this.events.publish('error', error);
      });
  }

  intervalChanged() {

  }

  ionViewWillEnter() {
    this.source.credentials = this.source.credentials || {};
  }

  ngOnInit() {
    this.calcViewVars();

    this.events.subscribe('window:resized', () => {
      this.calcViewVars();
    });

    this.validateUrl();
  }

  onCityQueryChanged() {
    if (this.search.cityQuery && (this.search.cityQuery.length > 2)) {
      this.cities.get(false, {
        query: this.search.cityQuery,
      })
        .then((cities: city[]) => {
          this.view.cities = cities;
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onCountryQueryChanged() {
    if (this.search.countryQuery && (this.search.countryQuery.length > 1)) {
      this.countries.getCountries(false, {
        query: this.search.countryQuery,
      })
        .then((countries: country[]) => {
          this.view.countries = countries;
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
    }
  }

  onMappingsChanged(event: any | null = null) {
    this.source.settings.csv_mappings = (event || {});
  }

  removeCity() {
    this.zone.run(() => {
      this.search.cityQuery = '';

      delete this.source.region;
      delete this.view.city;
    });
  }

  removeCountry() {
    this.zone.run(() => {
      this.search.countryQuery = '';
      delete this.source.country;
      delete this.view.country;

      this.removeCity();
    });
  }

  seperatorChanged() {
    this.validateUrl();
  }

  thumbnailLoadingFailed() {
    this.source.photo = this.fallbackImg;
  }

  update() {
    console.log('update', this.source);

    this.shopAdmin.updateSource(this.source)
      .then((response: any) => {
        this.dismiss(null, 'done');
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  updatedPermissions() {
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.source.photo = `${imageUrl || ''}`;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  useCity(city: city) {
    this.zone.run(() => {
      this.search.cityQuery = city.title;
      this.source.region = city.title;
      this.view.city = city;
      delete this.view.cities;
    });
  }

  useCountry(country: country) {
    this.zone.run(() => {
      this.search.countryQuery = country.name;
      this.source.country = country.indent;
      this.view.country = country;
      delete this.view.countries;
    });
  }

  useFirstRowAsFieldsChanged(event: any | null = null) {

  }

  validateImportFileUrl() {

  }

  validateUrl() {

    if (!this.source || !this.source.url || !this.source.url.length) {
      return false;
    }

    const validate: any = this.tools.validateUrl(this.source.url);
    console.log('validate', validate);

    if (!validate.success) {
      return false;
    }

    this.view.loading = true;

    this.external.verifySupportedShopURL(this.source.url, true)
      .then(async (response: any) => {
        console.log('shop verify response', response);

        switch (this.source.system) {
          case 'csv':

            if (!!this.source.settings.import_file_url) {

              const load: any = await this.importService.loadUrl(this.source.settings.import_file_url, {
                proxy: true,
                quote_character: this.source.settings.quote_character,
                quote_characters: this.source.settings.quote_characters,
                seperator: this.source.settings.seperator,
                use_first_row_as_fields: this.source.settings.use_first_row_as_fields,
              });

              console.log('csv load', load);

              this.view.sourceFields = (!!load && !!load.rowMappings ? load.rowMappings : []);
              console.log('this.view.sourceFields', this.view.sourceFields);
            }

            break;
        }

        this.view.loading = false;
        this.view.success = !!response.success;
        console.log('edit-shop-source: this.view.success', this.view.success);

        if (!!this.view.success) {
          await this.export();
        }
      })
      .catch((error: any) => {
        this.view.loading = false;
        this.view.success = false;
        console.warn('error', error);
      });
  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

}