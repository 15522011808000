import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class AiAssistantsService {

    constructor(
        private AppCMS: AppcmsService,
    ) {

    }

    create(assistant: aiAssistant) {
        return this.AppCMS.loadPluginData('pipeline', {
            assistant: assistant,
        }, ['ai', 'assistants', 'create']);
    }

    delete(assistant: aiAssistant) {
        return this.AppCMS.loadPluginData('pipeline', {
            assistant_uid: assistant.uid,
        }, ['ai', 'assistants', 'delete']);
    }

    duplicate(assistant: aiAssistant) {
        return this.AppCMS.loadPluginData('pipeline', {
            assistant_uid: assistant.uid,
        }, ['ai', 'assistants', 'duplicate']);
    }

    getAssistantByUid(assistantId: number | string, options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'assistants', assistantId], {}, blForceRefresh);
    }

    getAssistants(options: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'assistants'], {}, blForceRefresh);
    }

    update(assistant: aiAssistant) {
        return this.AppCMS.loadPluginData('pipeline', {
            assistant: assistant,
        }, ['ai', 'assistants', 'update']);
    }

}