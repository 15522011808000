import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';

import { InfoPopoverComponent } from './info-popover.component';

@NgModule({
  declarations: [
    InfoPopoverComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
  ],
  exports: [
    InfoPopoverComponent,
  ]
})
export class InfoPopoverComponentModule { }