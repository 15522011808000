<!-- Field type picker -->
<ion-popover #fieldTypePopover [isOpen]="isFieldTypePopoverOpen" (didDismiss)="isFieldTypePopoverOpen = false">
  <ng-template>

    <ion-list *ngIf="!!activeField && !!fieldTypes && !!fieldTypes.length">

      <!-- Field Label -->
      <ion-item>
        <ion-input [(ngModel)]="activeField.name" [label]="'field_name'|translate" labelPlacement="floating"
          [placeholder]="'field_name'|translate" (ionInput)="onFieldsChanged($event)"></ion-input>
      </ion-item>

      <!-- Field ID -->
      <ion-item>
        <ion-input [(ngModel)]="activeField.uid" [label]="'field_uid'|translate" labelPlacement="floating"
          [placeholder]="'field_uid'|translate" (ionInput)="onFieldsChanged($event)"></ion-input>
      </ion-item>

      <!-- Type -->
      <ion-item [disabled]="!!loading">
        <ion-select [(ngModel)]="activeField.type" [label]="'type'|translate" labelPlacement="floating"
          interface="popover" [okText]="'okay'|translate" [cancelText]="'cancel'|translate"
          (ionChange)="onFieldsChanged($event)">
          <ion-select-option *ngFor="let type of fieldTypes" [innerHTML]="type.name|translate"
            [value]="type.uid"></ion-select-option>
        </ion-select>
      </ion-item>

      <!-- Max length -->
      <ion-item>
        <ion-input [(ngModel)]="activeField.max_length" [label]="'max_length'|translate" labelPlacement="floating"
          (ionInput)="onFieldsChanged($event)" [placeholder]="'max_length'|translate"></ion-input>
      </ion-item>

      <!-- Is field required? -->
      <ion-item>
        <ion-checkbox justify="start" labelPlacement="end" [(ngModel)]="activeField.required"
          (ionChange)="onFieldsChanged($event)" [innerHTML]="'required'|translate"></ion-checkbox>
      </ion-item>

      <!-- Delete field -->
      <ion-item button (click)="deleteField(activeField)">
        <ion-icon color="danger" name="trash-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'delete'|translate"></ion-label>
      </ion-item>

    </ion-list>
  </ng-template>
</ion-popover>

<!-- Selection options popover -->
<pipeline-selection-options-picker [hidden]="!!loading || !!loading" [options]="selectionOptions" [(view)]="view"
  (onSelectionActionChanged)="runItemSelectionOption($event)">
</pipeline-selection-options-picker>

<!-- Loading spinner -->
<ion-spinner [hidden]="!loading && !loading"></ion-spinner>

<!-- Table grid -->
<ion-grid [hidden]="!!loading || !!loading">

  <!-- Top row -->
  <ion-row class="table-row top-row"
    [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + (view.firstColWidth || 50)) + 'px' : '100%')"
    [class.has-multiple-cols]="!!fields && (fields.length > 1)">

    <!-- first header col is empty -->
    <ion-col></ion-col>

    <!-- Iterate fields (top header bar )-->
    <ion-col *ngFor="let field of fields">
      <ion-item lines="none">

        <!-- Field select: View mode -->
        <ion-select *ngIf="mode === 'view'" [(ngModel)]="field.value"
          [label]="((field.name || field.uid) || 'field_name')|translate" labelPlacement="floating" interface="popover"
          [okText]="'okay'|translate" [cancelText]="'cancel'|translate">
          <ion-select-option [innerHTML]="'all'|translate" [value]="''"></ion-select-option>
          <ion-select-option *ngFor="let option of field.options" [innerHTML]="option|translate"
            [value]="option"></ion-select-option>
        </ion-select>

        <!-- Field input: Edit mode -->
        <ion-input [(ngModel)]="field.name" [placeholder]="'field_name'|translate" (ionInput)="onFieldsChanged($event)"
          *ngIf="mode === 'create' || mode === 'edit'"></ion-input>

        <!-- Pick field type -->
        <ion-button (click)="pickFieldType(field, $event)" fill="clear" icon-only color="dark"
          *ngIf="mode === 'create' || mode === 'edit'">
          <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>

      </ion-item>
    </ion-col>

    <!-- Add field button -->
    <ion-col *ngIf="mode === 'create' || mode === 'edit'">
      <ion-item button lines="none" (click)="addField($event)">
        <ion-icon name="add-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'add_field'|translate"></ion-label>
      </ion-item>
    </ion-col>

  </ion-row>

  <!-- Iterate items -->
  <div class="cdk-wrapper" *ngIf="!!items && !!items.length"
    [style.min-width]="(!!view.previewGridWidth ? (view.previewGridWidth + (view.firstColWidth || 50)) + 'px' : '100%')">
    
    <cdk-virtual-scroll-viewport [itemSize]="itemSize" [minBufferPx]="itemSize * 50" [maxBufferPx]="itemSize * 50"
      [style.min-width]="(!!view.previewGridWidth ? (view.previewGridWidth + (view.firstColWidth || 50)) + 'px' : '100%')"
      class="ion-content-scroll-host">

      <ion-row *cdkVirtualFor="let item of items; trackBy:trackItems" [hidden]="item.hidden"
        class="table-row mode-{{mode || 'view'}}"
        [style.width]="(!!view.previewGridWidth ? (view.previewGridWidth + (view.firstColWidth || 50)) + 'px' : '100%')"
        [class.has-multiple-cols]="!!fields && (fields.length > 1)">

        <!-- single selection -->
        <ion-col class="mode-{{mode || 'view'}}">
          <ion-item lines="none">

            <!-- Select item -->
            <ion-checkbox [(ngModel)]="item.checked" (ionChange)="onItemCheckboxClicked(item)"></ion-checkbox>

            <!-- Item options (in edit mode) -->
            <ion-button (click)="showItemOptions(item, $event)" fill="clear" icon-only color="dark"
              *ngIf="mode === 'create' || mode === 'edit'">
              <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
            </ion-button>

          </ion-item>
        </ion-col>

        <!-- Iterate fields -->
        <ion-col class="mode-{{mode || 'view'}}" *ngFor="let field of fields; let i = index;">

          <ion-item lines="none">

            <!-- Input based on field name -->
            <ion-input *ngIf="!itemHasValueObject && !fieldsHaveUid" [(ngModel)]="item[field]"
              (ionInput)="onItemInputChanged($event, i)" [disabled]="mode !== 'edit'"></ion-input>

            <!-- Input based on field uid -->
            <ion-input *ngIf="!itemHasValueObject && !!fieldsHaveUid" [(ngModel)]="item[field.uid]"
              (ionInput)="onItemInputChanged($event, i)" [disabled]="mode !== 'edit'"></ion-input>

            <!-- Input based on field name (in value object)-->
            <ion-input *ngIf="!!itemHasValueObject && !fieldsHaveUid" [(ngModel)]="item.value[field]"
              (ionInput)="onItemInputChanged($event, i)" [disabled]="mode !== 'edit'"></ion-input>

            <!-- Input based on field uid (in value object)-->
            <ion-input *ngIf="!!itemHasValueObject && !!fieldsHaveUid" [(ngModel)]="item.value[field.uid]"
              (ionInput)="onItemInputChanged($event, i)" [disabled]="mode !== 'edit'"></ion-input>

          </ion-item>

        </ion-col>

      </ion-row>

    </cdk-virtual-scroll-viewport>
  </div>

  <!-- No entries -->
  <pipeline-no-entries-card *ngIf="!items || !items.length"></pipeline-no-entries-card>

</ion-grid>