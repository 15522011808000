import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { EventsService } from "src/app/services/core/events.service";
import { MediaextendService } from 'src/app/services/media/mediaextend.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Component({
  selector: 'pipeline-media-text-overlay-card',
  standalone: false,
  templateUrl: './media-text-overlay-card.component.html',
  styleUrls: ['./media-text-overlay-card.component.scss']
})
export class MediaTextOverlayCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() disabled: boolean;
  @Input() items: mediaItem[];
  @Input() item: any;
  @Input() options: any = {};
  @Input() view: any = {};

  @Output() onMediaChanged = new EventEmitter();
  @Output() onOverlayFormViewChanged = new EventEmitter();
  @Output() onSectionsChanged = new EventEmitter();

  constructor(
    private events: EventsService,
    private media: MediaextendService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {

  }

  addSection() {
    this.view.sections = this.view.sections || [];

    this.view.sections.push({
      subtext: '',
      title: '',
    });

    this.onSectionsChanged.emit(this.view.sections);
  }

  calcViewVars() {
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.calcViewVars();
  }

  _onOverlayFormViewChanged(event: any | null = null) {
    this.onOverlayFormViewChanged.emit(event);
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleFineTune() {
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}