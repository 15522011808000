import { Injectable } from '@angular/core';

import { AiPlannerService } from 'src/app/services/ai/ai-planner.service';
import { AiBridgeService } from './ai-bridge.service';
import { AiConfigService } from './ai-config.service';
import { AiIdeasService } from './ai-ideas.service';
import { AiToolsService } from './ai-tools.service';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { TemplatesService } from "src/app/services/media/templates.service";
import { ToolsService } from 'src/app/services/utils/tools.service';

import { NewslettersService } from '../newsletters/newsletters.service';
import { PostsAdminService } from '../posts/posts-admin.service';

import { AiTaskExecutionSettingsPage } from 'src/app/pages/core/ai/ai-task-execution-settings/ai-task-execution-settings.page';

import { AutoProcessor, SamModel } from '@huggingface/transformers';

export class SegmentAnythingSingleton {
  static model_id: string = 'Xenova/slimsam-77-uniform';
  static model: any;
  static processor: any;

  static getInstance() {

    this.model ??= SamModel.from_pretrained(this.model_id, {
      dtype: 'fp16',
      device: 'webgpu',
    });

    this.processor ??= AutoProcessor.from_pretrained(this.model_id, [
    ] as any);

    return Promise.all([this.model, this.processor]);
  }
}

@Injectable({
  providedIn: 'root'
})
export class AiWorkerService {

  contextsConfig: any = {};

  defaultCallbacks: any = {
  };

  isDecoding: boolean = false;
  isEncoded: boolean = false;

  listeners: any = {};

  view: any = {};

  constructor(
    private aiBridge: AiBridgeService,
    private aiConfig: AiConfigService,
    private aiIdeas: AiIdeasService,
    private aiPlanner: AiPlannerService,
    private aiTools: AiToolsService,

    private AppCMS: AppcmsService,
    private events: EventsService,
    private toolsService: ToolsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private newsletters: NewslettersService,
    private postsAdmin: PostsAdminService,
    private templates: TemplatesService,
    private userService: UserService,
  ) {
    this.initConfig();
  }

  addEventListener(key: string, callback: any = null) {
    this.listeners[key] = callback || this.defaultCallbacks[key];
    console.log('ai-worker: addEventListener: listeners', this.listeners);
  }

  calcExecutionHistory(tasks: aiTask[], task: aiTask, index: number) {
    let history: aiExecutionHistoryItem[] = [];

    tasks.forEach((_task: aiTask, _index: number) => {

      if (index > _index) {
        const prompt: string = this.toolsService.stripHtml(_task.input);
        const input: string = this.fillVarsInText(prompt, (_task.inputs || []), _task);

        let historyItem: aiExecutionHistoryItem = {
          input: input,
        };

        if (!!_task.output) {
          historyItem.output = _task.output;
        }

        if (!!_task.promptType) {
          historyItem.role = _task.promptType;
        }

        history.push(historyItem);
      }
    });

    return history;
  }

  calcInputVars() {
    this.events.publish('ai:quick:calcInputVars');
  }

  doesAITaskHasMissingVariables(task: aiTask) {
    let bl: boolean = false;

    if (!!task.children) {
      task.children.forEach((child: aiTask) => {
        if (!!child.inputs) {
          child.inputs.forEach((input: aiTaskInput) => {
            if (!input.value && (input.name !== 'output')) {
              bl = true;
            }
          });
        }
      });
    }

    return bl;
  }

  executeAITask(task: aiTask | null = null, index: number = 0, options: any = {}) {
    return new Promise((resolve, reject) => {
      task = task || this.view.tasks[index];
      task.try = task.try || 0;
      task.loading = true;

      delete task.input_content;
      delete task.output_content;

      if (!task || !task.input) {
        task.loading = false;

        reject('error_missing_task_input');
      } else {

        // if previous task has output list, add it to current task and set loop mode
        if (!!this.view.tasks && !!this.view.tasks[index - 1] && !!this.view.tasks[index - 1].children && (!task.children || !task.children.length)) {

          task.children = this.view.tasks[index - 1].children.map((child: aiTask) => {
            child.done = false;
            child.loading = false;

            return child;
          });

          task.loop = true;
        }

        if (!!task && !!task.config && !!task.config.ai) {
          this.setConfig(task.config.ai);
        }

        const prompt: string = this.toolsService
          .stripHtml(`${task.input || ''}`)
          .replace(/{output}/g, (!!this.view.tasks[index - 1] && !!this.view.tasks[index - 1].output ? this.view.tasks[index - 1].output : ''));

        const filledInput: string = this.fillVarsInText(prompt, (task.inputs || []), task);

        console.log('ai-worker: executeAITask: task', task);

        if (filledInput.indexOf('{') !== -1) {
          console.error('error_missing_variable_in_prompt_found: task', task);
          console.error('error_missing_variable_in_prompt_found: filledInput', filledInput);

          reject('error_missing_variable_in_prompt_found');
        } else {

          if (!!this.view.tasks[index]) {
            this.view.tasks[index].input = this.toolsService.stripHtml(`${task.input || ''}`);
          }

          if (!!options.hasOwnProperty('onTaskUpdated')) {
            options.onTaskUpdated({
              task: task,
              index: index,
            });
          }

          if (!!task && !!task.promptType && (task.promptType === 'system')) {
            this.aiConfig.setBasePrompt(`${filledInput}`);
            resolve(task);
          } else
            if (task && task.children && task.children.length) {
              this.executeAITaskChildren(task, index, options).then(resolve).catch(reject);
            } else {

              const config: aiSettings = this.getConfig();

              const item: aiExecutionRequest = {
                config: config,
                history: this.calcExecutionHistory((this.view.tasks || []), task, index),
                post_content: `${filledInput}`,
              };

              const params: any = {
                //appendContext: false,
                context: 'ai_task_execution',
              };

              this.aiBridge.execute(item, true, config, params)
                .then((response: any) => {
                  console.log('ai-worker: response (a)', response);

                  task.loading = false;

                  if (!!options.hasOwnProperty('onTaskUpdated')) {
                    options.onTaskUpdated({
                      task: task,
                      index: index,
                    });
                  }

                  if (!!response && !!response.error && !!response.error.message) {
                    reject(response.error.message);
                  } else
                    if (!!response && !!response.output) {

                      const split: string[] = `${response.output || ''}`.split('```html');
                      const output: string = `${split[split.length - 1] || ''}`.replace('```', '');

                      task.output = `${output || response.output}`;

                      if (!!this.view.tasks[index]) {
                        this.view.tasks[index].output = task.output;
                      }

                      if (!!options.hasOwnProperty('onTaskUpdated')) {
                        options.onTaskUpdated({
                          task: task,
                          index: index,
                        });
                      }

                      this.calcInputVars();

                      if (!!task.loop) {

                        const listItems: string[] = this.toolsService.textToList(`${task.output}`);
                        console.log('listItems', listItems);

                        const children: any[] = listItems.map((line: string, _index: number) => {
                          return {
                            active: !_index,
                            loading: !_index,
                            name: this.toolsService.stripHtml(`${line || ''}`),
                            value: line,
                          };
                        });

                        console.log('children', children);

                        if (!!this.view.tasks[index + 1]) {
                          this.view.tasks[index + 1].children = children;

                          if (task.promptType !== 'function') {
                            const lookup: any = this.aiTools.lookupImagesOnChildren(this.view.tasks[index + 1].children, (index + 1));
                          }

                          if (!!this.view.tasks[index + 1].children) {
                            this.view.tasks[index + 1].loop = true;
                          }

                          this.fireUpdateEvent('tasks:updated', { tasks: (this.view.tasks || []), indexes: [index + 1] });
                        } else {
                          task.children = children;

                          if (task.promptType !== 'function') {
                            this.aiTools.lookupImagesOnChildren(task.children, index)
                              .then(() => {
                                if (!!task.save_paths) {
                                  this.saveTask(task);
                                }
                              })
                              .catch((error: any) => {
                                console.log('[ AI WORKER ] Loading task images failed: ', error);
                              });
                          }

                          if (!!options.hasOwnProperty('onTaskUpdated')) {
                            options.onTaskUpdated({
                              task: task,
                              index: index,
                            });
                          }

                          this.executeAITaskChildren(task, index, options).then(resolve).catch(reject);
                        }

                      }
                    }

                  resolve(task);
                })
                .catch((error: any) => {
                  task.loading = false;

                  this.fireUpdateEvent(error, { task: task }, 'error');

                  console.warn('> task error', error);

                  if (task.try > 3) {
                    task.loading = false;
                    reject(error);
                  } else {
                    task.try++;

                    console.warn(`> retrying task (${task.try} / 3)`, task);
                    this.executeAITask(task, index, options).then(resolve).catch(reject);
                  }

                  // handle retrying the process here
                  task.loading = false;
                  reject(error);
                });
            }
        }
      }

    });
  }

  executeAITaskChildren(task: aiTask, taskIndex: number, options: any = {}) {
    return new Promise((resolve, reject) => {
      task.childIndex = task.childIndex || 0;

      if (!!task && !!task.config && !!task.config.ai) {
        this.setConfig(task.config.ai);
      }

      this.view.tasks[taskIndex] = task;

      if (!!options.hasOwnProperty('onTaskUpdated')) {
        options.onTaskUpdated({
          task: task,
          index: taskIndex,
        });
      }

      if (!task.children[task.childIndex]) {
        reject('missing_child_task');
      } else
        if (!task.children[task.childIndex].value) {
          reject('missing_child_task_value');
        } else
          if (task.promptType === 'function') {
            this.executeAiTaskFunctionChildren(task, taskIndex).then(resolve).catch(reject);
          } else {
            task.children[task.childIndex] = task.children[task.childIndex] || {};
            task.children[task.childIndex].loading = true;

            if (!!options.hasOwnProperty('onTaskChildUpdated')) {
              options.onTaskChildUpdated({
                task: task.children[task.childIndex],
                childIndex: task.childIndex,
                taskIndex: taskIndex
              });
            }

            let command: string | null = (!!task && !!task.input ? task.input : null);

            if (!!task && !!task.input && !!task.inputs) {
              command = this.fillVarsInText(task.input, task.inputs, {
                output: `${task.children[task.childIndex].output || task.children[task.childIndex].value}`,
              });
            }

            if (!!options.hasOwnProperty('onTaskUpdated')) {
              options.onTaskUpdated({
                task: task,
                index: taskIndex,
              });
            }

            const value: string = `${(task.children[task.childIndex].output || task.children[task.childIndex].value) || task.children[task.childIndex]}`;

            if (this.view.tasks.length > 1) {
              command = `${command || task.input}`.replace(/{output}/g, value).replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
            } else {
              command = value.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "");
            }

            // if parent of child task has no input but save path is specified, only save the children
            if (!command && !!task.save_path) {

              if (!!task.children[task.childIndex]) {
                task.children[task.childIndex].done = true;
                task.children[task.childIndex].loading = false;

                this.view.tasks[taskIndex] = task;

                if (!!options.hasOwnProperty('onTaskChildUpdated')) {
                  options.onTaskChildUpdated({
                    task: task.children[task.childIndex],
                    childIndex: task.childIndex,
                    taskIndex: taskIndex
                  });
                }

                if (!!options.hasOwnProperty('onTaskUpdated')) {
                  options.onTaskUpdated({
                    task: task,
                    index: taskIndex,
                  });
                }

                this.saveTaskChild(task.children[task.childIndex], task);
              }

              // if next child task exists, go to next task
              if (task.children.hasOwnProperty(task.childIndex + 1)) {
                task.childIndex++;

                this.view.tasks[taskIndex].childIndex = task.childIndex;

                if (!!options.hasOwnProperty('onTaskUpdated')) {
                  options.onTaskUpdated({
                    task: task,
                    index: taskIndex,
                  });
                }

                this.executeAITaskChildren(task, taskIndex, options).then(resolve).catch(reject);
              } else {

                // task finished processing
                task.loading = false;
                this.view.tasks[taskIndex].loading = false;


                if (!!options.hasOwnProperty('onTaskUpdated')) {
                  options.onTaskUpdated({
                    task: task,
                    index: taskIndex,
                  });
                }

                resolve(task);
              }

            } else
              if (!command) {
                reject('missing_child_task_command');
              } else {

                let history: aiExecutionHistoryItem[] = [];
                const basePrompt: string | null = this.aiConfig.getBasePrompt();

                if (!!basePrompt) {
                  history.push({
                    input: basePrompt,
                    role: 'system',
                  });
                }

                const config: any = this.getConfig();

                const item: any = {
                  config: config,
                  history: (history || []),
                  post_content: command,
                };

                const params: any = {
                  //appendContext: false,
                  context: 'ai_task_execution',
                };

                this.aiBridge.execute(item, true, config, params)
                  .then((response: any) => {
                    task.children[task.childIndex].done = true;
                    task.children[task.childIndex].loading = false;
                    task.childIndex++;

                    this.view.tasks[taskIndex] = task;

                    // send event that current task child changed
                    if (!!options.hasOwnProperty('onTaskChildUpdated')) {
                      options.onTaskChildUpdated({
                        task: task.children[task.childIndex],
                        childIndex: task.childIndex,
                        taskIndex: taskIndex
                      });
                    }

                    if (!!options.hasOwnProperty('onTaskUpdated')) {
                      options.onTaskUpdated({
                        task: task,
                        index: taskIndex,
                      });
                    }

                    if (!!response && !!response.output && !!response.output.error) {
                      reject('error_pipeline_ai_resource_is_busy');
                    } else
                      if (!!response && !!response.output) {

                        const split: string[] = `${response.output || ''}`.split('```html'),
                          output: string = `${split[split.length - 1] || ''}`.replace('```', '');

                        task.children[task.childIndex - 1].output = (output || response.output);
                        task.output = (output || response.output);

                        this.view.tasks[taskIndex] = task;

                        // send event that last task child changed
                        if (!!options.hasOwnProperty('onTaskChildUpdated')) {
                          options.onTaskChildUpdated({
                            task: task.children[task.childIndex - 1],
                            childIndex: task.childIndex - 1,
                            taskIndex: taskIndex
                          });
                        }

                        if (!!options.hasOwnProperty('onTaskUpdated')) {
                          options.onTaskUpdated({
                            task: task,
                            index: taskIndex,
                          });
                        }

                        this.calcInputVars();

                        if (!!task.save_paths) {
                          this.saveTaskChild(task.children[task.childIndex - 1], task);
                        }
                      }

                    if (task.childIndex === task.children.length) {
                      task.loading = false;

                      this.view.tasks[taskIndex].loading = false;

                      if (!!options.hasOwnProperty('onTaskUpdated')) {
                        options.onTaskUpdated({
                          task: task,
                          index: taskIndex,
                        });
                      }

                      resolve(task);
                    } else {
                      this.executeAITaskChildren(task, taskIndex, options).then(resolve).catch(reject);
                    }
                  })
                  .catch((error: any) => {
                    task.children[task.childIndex].done = false;
                    task.children[task.childIndex].loading = false;
                    task.childIndex++;

                    this.view.tasks[taskIndex] = task;

                    if (!!options.hasOwnProperty('onTaskChildUpdated')) {
                      options.onTaskChildUpdated({
                        task: task.children[task.childIndex],
                        childIndex: task.childIndex,
                        taskIndex: taskIndex
                      });
                    }

                    if (!!options.hasOwnProperty('onTaskUpdated')) {
                      options.onTaskUpdated({
                        task: task,
                        index: taskIndex,
                      });
                    }

                    reject(error);
                  });
              }

          }
    });
  }

  executeAiTaskFunctionChildren(task: aiTask, taskIndex: number, options: any = {}) {
    return new Promise((resolve, reject) => {
      task.children[task.childIndex].execute = true;
      task.children[task.childIndex].loading = true;

      if (!!options.hasOwnProperty('onTaskChildUpdated')) {
        options.onTaskChildUpdated({
          task: task.children[task.childIndex],
          childIndex: task.childIndex,
          taskIndex: taskIndex
        });
      }

      if (!!options.hasOwnProperty('onTaskUpdated')) {
        options.onTaskUpdated({
          task: task,
          index: taskIndex,
        });
      }

      resolve(task);
    });
  }

  executeAITaskSet(options: any = {}, task: aiTask | null = null, index: number = 0, responses: any = []) {
    return new Promise((resolve, reject) => {
      task = task || ((!!this.view.tasks && !!this.view.tasks[index] ? this.view.tasks[index] : null));

      if (!task) {
        reject('error_missing_task');
      }

      delete task.input_content;
      delete task.output_content;

      this.view.forceStopTaskExecution = false;

      this.setActiveTaskIndex(index);

      this.executeAITask(task, index, options)
        .then((response: any) => {
          responses.push(response);

          if (!!this.view.forceStopTaskExecution) {
            // if process has been interrupted
            resolve('stop');
          } else
            if (index === (this.view.tasks.length - 1)) {
              // if no next steps exist
              this.calcInputVars();
              resolve(response);
            } else
              if (!!this.view.tasks[index + 1]) {
                // if next steps exist 
                this.executeAITaskSet(options, this.view.tasks[index + 1], (index + 1), responses).then(resolve).catch(reject);
              } else {
                // if no tasks history is set, but generation process is done
                this.calcInputVars();
                resolve(response);
              }

        })
        .catch((error: any) => {
          console.warn('> execute failed', error);

          reject(error);
        });
    });
  }

  executeLocal(item: aiExecutionRequest, blForceRefresh: boolean = false, config: any | null = null, params: any = {}) {
    return this.aiBridge.executeLocal(item, blForceRefresh, config, params);
  }

  fillVarsInText(command: string, inputs: aiTaskInput[], task: aiTask = null) {
    command = `${command}`.replace(/{{/g, '{').replace(/}}/g, '}');

    if (!!command && !!inputs && !!inputs.length) {

      inputs.forEach((input: any) => {

        if ((input.name === 'output') && !!task && !!task.output) {
          input.value = task.output;
        }

        command = command.replaceAll(`{${input.name}}`, `${input.value}`);
      });

      if (command.indexOf('{') !== -1) {
        this.events.publish('toast', {
          icon: 'warning-outline',
          message: 'warning_missing_variable_in_prompt_found'
        });
      }
    }

    return command;
  }

  fireUpdateEvent(message: any, data: any = {}, type: string = 'info') {
    let iTry: number = (!!data && !!data.task && !!data.task.try ? data.task.try : 0),
      iAllTasks: number = 0;

    if (!!this.view.tasks && !!this.view.tasks.length) {
      this.view.tasks.forEach((task: aiTask) => {
        iAllTasks += (!!task.children && !!task.children.length ? task.children.length + 1 : 1);
      });
    }

    data.progress = ((100 / iAllTasks) * (data.index || 0));

    this.events.publish('ai:response', {
      data: data,
      message: message,
      try: iTry,
      type: type,
    });
  }

  fixMissingVariables(task: aiTask) {
    let foundVars: any = {}, missing: any[] = [];

    if (task && task.children && task.children.length) {
      task.children.forEach((child: aiTask, iChild: number) => {
        if (!!child && !!child.inputs && !!child.inputs.length) {
          child.inputs.forEach((input: aiTaskInput, iInput: number) => {
            if (!!input.uid && !!input.value) {
              foundVars[input.uid] = input.value;
            } else
              if (!!input.uid && !input.value && (input.uid !== 'output')) {
                missing.push({
                  child_index: iChild,
                  input_index: iInput,
                  uid: input.uid,
                });
              }
          });
        }
      });
    }

    if (!!missing && !!missing.length) {
      missing.forEach((item: any) => {
        if (!!item.uid && !!foundVars[item.uid]) {
          task.children[item.child_index].inputs[item.input_index].value = foundVars[item.uid];
        }
      });
    }

    return task;
  }

  imageToDepth(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.imageToDepth(url, options, blBackground, loadingModal, loadingOptions);
  }

  /*
  imageToMask(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.imageToMask(url, options, blBackground, loadingModal, loadingOptions);
  }

  imageToSegmentation(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.imageToSegmentation(url, options, blBackground, loadingModal, loadingOptions);
  }
  */

  getConfig() {
    return this.aiConfig.get();
  }

  getSegmentAnythingInstance() {
    return SegmentAnythingSingleton.getInstance();
  }

  getTrainingLog(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'training', 'log'], {}, blForceRefresh);
  }

  getTrainingTasks(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'training', 'tasks'], {}, blForceRefresh);
  }

  async initConfig() {
    return this.aiConfig.init();
  }

  async postMessage(event: any = null) {
    console.log('ai-worker: postMessage: event', event);
    console.log('ai-worker: postMessage: listeners', this.listeners);

    if (this.listeners.hasOwnProperty('message')) {
      const exec: any = await this.listeners.message(event);
      console.log('ai-worker: postMessage: exec', exec);

      return exec;
    }
  }

  removeBackground(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.removeBackground(url, options, blBackground, loadingModal, loadingOptions);
  }

  requestMissingVariables(task: aiTask) {
    return new Promise(async (resolve, reject) => {

      const modal: any = await this.modalService.create({
        component: AiTaskExecutionSettingsPage,
        componentProps: {
          aiWorker: this,
          task: task,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
        cssClass: "defaultModal",
      });

      modal.onWillDismiss().then((response: any) => {
        resolve(response);
      });

      await this.modalService.present(modal);

    });
  }

  saveImage(task: aiTask) {
    return this.saveMedia(task, 'image');
  }

  saveTask(task: aiTask) {
    console.log('[ AI WORKER ] saveTask: task', task);

    if (!task.save_paths) {
      console.warn('no save paths found for task', task);
    }

    task.save_paths.forEach((path: any) => {
      path = (!!path && !!path.uid ? path.uid : path);

      switch (path) {
        case 'ai_idea':
          this.aiIdeas.create(task);
          break;
        case 'ai_plan':
          this.aiPlanner.addPlan(task);
          break;
        case 'app':
          this.events.publish('error', 'not_implemented');
          break;
        case 'image':
          this.saveImage(task);
          break;
        case 'newsletter':
          this.saveNewsletter(task);
          break;
        case 'post':
          this.savePost(task);
          break;
        case 'quiz':
          this.events.publish('error', 'not_implemented');
          break;
        case 'survey':
          this.events.publish('error', 'not_implemented');
          break;
        case 'video':
          this.saveVideo(task);
          break;
        case 'website':
          this.events.publish('error', 'not_implemented');
          break;
        default:
          console.warn('> unsupported save_path', path, task);
          break;
      }
    });
  }

  saveTaskChild(child: any, task: aiTask) {
    console.log('[ AI WORKER ] saveTaskChild: child', child);
    console.log('[ AI WORKER ] saveTaskChild: task', task);

    let childTask: aiTask = {
      input: this.toolsService.stripHtml(`${child.name || ''}`),
      output: child.output,
      save_paths: task.save_paths,
      save_params: task.save_params,
    };

    const additionalKeys: string[] = ['media', 'name', 'photo', 'logo_bg_src', 'source'];

    additionalKeys.forEach((key: string) => {
      if (!!child[key] || !!task[key]) {
        childTask[key] = (child[key] || task[key]);
      }
    });

    return this.saveTask(childTask);
  }

  saveMedia(task: aiTask, type: string = 'image') {
    console.log('saveMedia: task', task);
    console.log('saveMedia: type', type);

    if (!task.save_params || !task.save_params.templates || !task.save_params.templates[type] || !task.save_params.templates[type].length) {
      console.warn('> cannot save media: missing template config', task);
      return false;
    }

    // remove preview view variables from template for saving
    task.save_params.templates[type] = task.save_params.templates[type].map((template: mediaTemplate) => {
      delete template.view;
      return template;
    });

    const project: project = (!!this.view.project ? this.view.project : null);

    let _meta: any = {
      avatar_src: (!!project && !!project.photo ? project.photo : null),
      logo_src: (!!project && !!project.photo ? project.photo : null),
    };

    let media: any = JSON.parse(JSON.stringify(task));

    if (!!media.media && !!media.media.length) {
      _meta.media = media.media;
    }

    media.title = `${media.input || media.title}`;
    media.subtext = `${media.output || media.subtext}`;

    if (!!media.photo) {
      _meta.bg_src = `${media.photo || media.thumbnail}`;
    }

    if (!!this.view.project) {

      if (!!this.view.project.photo) {
        media.avatar = this.view.project.photo || media.avatar;
        media.logo_src = this.view.project.photo || media.logo_src;
      }

      if (!!this.view.project.title) {
        media.source = `${this.view.project.title || media.source}`;
        media.name = `${this.view.project.title || media.name}`;
      }

      if (!!this.view.project.config && !!this.view.project.config.Comp && !!this.view.project.config.Comp.logo_bg_color) {
        media.logo_bg_color = `${this.view.project.config.Comp.logo_bg_color || media.logo_bg_color}`;
      }

    }

    console.log('saveMedia: media', media);

    // @todo use all templates if only one idea should be rendered (limited to random one for performance reason in AI quick mode)
    let template: mediaTemplate = task.save_params.templates[type][Math.floor(Math.random() * task.save_params.templates[type].length)];

    if (!!template.config && !!template.config.Comp) {

      if (!!template.config.Comp.title && !!task.input) {
        template.config.Comp.title.text = task.input;
      }

      if (!!template.config.Comp.subtext && !!task.output) {
        template.config.Comp.subtext.text = task.output;
      }

    }

    template.config.aspect_ratio = template.config.aspect_ratio || '1x1';

    if (!!template.config && !!template.config.aspect_ratios && !!template.config.aspect_ratios[template.config.aspect_ratio]) {
      template.config.aspect_ratios[template.config.aspect_ratio] = this.templates.applyDataToCustomLayers(media, template, template.config.aspect_ratio);
    }

    template.config._meta = _meta;

    console.log('saveMedia: template', template);

    let mediaItem: mediaQueueItem = {
      active: false,
      custom: !!template.custom,
      name: task.input,
      template_uid: template.uid,
      type: type,
      value: JSON.stringify(template.config),
      user_uid: this.userService.getUid(),
    };

    if (!!task.project_uid) {
      mediaItem.project_uid = task.project_uid;
    }

    console.log('saveMedia: mediaItem', mediaItem);

    this.media.create(mediaItem)
      .catch((error: any) => {
        console.warn('> saving draft failed', error);
      });
  }

  saveNewsletter(task: aiTask) {

    let post: any = {
      active: true,
      type: 'draft',
      name: `${task.input}`,
      post_content: `${task.output}`,
      public: false,
      user: this.userService.getUid(),
    };

    if (!!task.project_uid) {
      post.project_uid = task.project_uid;
    }

    if (!!task.photo || !!task.thumbnail) {
      post.photo = `${task.photo || task.thumbnail}`;
      post.thumbnail = `${task.thumbnail || task.photo}`;
    }

    return this.newsletters.convertPostToNewsletter(post);
  }

  savePost(task: aiTask) {

    let post: any = {
      active: true,
      type: 'draft',
      name: `${task.input}`,
      post_content: `${task.output}`,
      public: false,
      user: this.userService.getUid(),
    };

    if (!!task.project_uid) {
      post.project_uid = task.project_uid;
    }

    if (!!task.photo || !!task.thumbnail) {
      post.photo = `${task.photo || task.thumbnail}`;
      post.thumbnail = `${task.thumbnail || task.photo}`;
    }

    return this.postsAdmin.submitPost(post);
  }

  saveVideo(task: aiTask) {
    return this.saveMedia(task, 'video');
  }

  segmentAnything(url: string, options: any = {}, blBackground: boolean = false, loadingModal: any = null, loadingOptions: loadingOptions | null = null) {
    return this.aiBridge.segmentAnything(url, options, blBackground, loadingModal, loadingOptions);
  }

  setActiveTaskIndex(index: number) {

    if (!this.view.tasks || !this.view.tasks.length) {
      return false;
    }

    this.view.tasks.forEach((task: aiTask, _index: number) => {
      task.active = (_index === index);
    });
  }

  setConfig(config: aiSettings) {
    return this.aiConfig.set(config);
  }

  setCurrentProject(project: project) {
    this.view.project = project;
    return this;
  }

  setCurrentTask(task: aiTask) {
    this.view.task = task;

    if (!!task.children) {
      this.view.tasks = task.children;
    } else {
      this.view.tasks = [];
    }

    return this;
  }

  shouldUseLocalEngine(context: string, bl: boolean | null = null) {
    console.log('ai-worker: shouldUseLocalEngine: context', context);
    console.log('ai-worker: shouldUseLocalEngine: contextsConfig', this.contextsConfig);

    if (bl !== null) {
      this.contextsConfig[context] = this.contextsConfig[context] || {};
      this.contextsConfig[context].mode = (bl ? 'local' : 'remote');
      return this;
    }

    return this.contextsConfig && this.contextsConfig[context] && this.contextsConfig[context].mode && (this.contextsConfig[context].mode === 'local');
  }

  stopSet(handler: any) {
    return new Promise((resolve, reject) => {
      this.view.forceStopTaskExecution = true;

      if (!!this.view.tasks && !!this.view.tasks.length) {
        this.view.tasks.forEach((task: aiTask) => {
          task.loading = false;

          if (!!task.children && !!task.children.length) {
            task.children.forEach((child: aiTask) => {
              child.loading = false;
            });
          }

        });
      }

      resolve(true);
    });
  }

  translate(content: string, source: string, target: string, blForceRefresh: boolean = false) {
    return this.aiBridge.execute({
      context: 'text_translation',
      post_content: `Translate the following input from language "${source}" to "${target}":\n${content}`,
    }, blForceRefresh);
  }

  validateSet(task: aiTask) {
    return new Promise((resolve, reject) => {
      task = this.fixMissingVariables(task);

      const blMissingVars: boolean = this.doesAITaskHasMissingVariables(task);

      if (!!blMissingVars) {
        this.requestMissingVariables(task).then((validate: any) => {
          console.log('requestMissingVariables: validate', validate);

          resolve({
            success: !!validate,
            task: task,
          });
        }).then(resolve).catch(reject);
      } else {
        resolve({
          success: true,
          task: task,
        });
      }
    });
  }

}