import { Injectable } from "@angular/core";

import { CacheService } from 'src/app/services/core/cache.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { fromEvent, Observable, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class ViewService {

  private appConfig: pipelineAppConfig;

  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  private variableMappings: any[];

  constructor(
    private cache: CacheService,
    private configService: ConfigService,
    private events: EventsService,
    private modalService: ModalService,
    private tools: ToolsService,
    private userService: UserService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  public addVariableMapping(key: string, value: string | number | boolean): void {

    if (!key.startsWith('${') || !key.endsWith('}')) {
      throw new Error('Variable key must be in format ${variableName}');
    }

    if (!this.variableMappings) {
      this.initVariableMappings();
    }

    const existingIndex = this.variableMappings.findIndex(m => m.key === key);

    if (existingIndex >= 0) {
      this.variableMappings[existingIndex].value = value;
    } else {
      this.variableMappings.push({ key, value });
    }
  }

  calcScreenSizeVars(view: any | null = null) {
    view = view || {};

    view.isDesktop = this.tools.isDesktop();
    view.isLandscape = this.tools.isLandscape();
    view.isModal = this.modalService.isModal();
    view.isPortrait = !view.isLandscape;
    view.isTablet = this.tools.isTablet();
    view.isUltraWide = this.tools.isUltraWide();

    view.sidebarSize = (!!view.isUltraWide ? 2 : (window.innerWidth > 768 ? 3 : 12));

    return view;
  }

  calcVars(view: any | null = null) {
    view = view || {};
    //view.expertMode = await this.isExpertMode();

    view.isAdmin = this.userService.isType('Admin');
    view.isModal = this.modalService.isModal();
    view.isWeb = this.tools.isWeb();

    return this.calcScreenSizeVars(view);
  }

  getVariableMappings() {

    if (!this.variableMappings) {
      this.initVariableMappings();
    }

    return this.variableMappings;
  }

  init() {
    this.resizeObservable$ = fromEvent(window, 'resize');

    this.resizeSubscription$ = this.resizeObservable$
      .pipe(debounceTime(1000))
      .subscribe((event: any) => {
        this.events.publish('window:resized', event);
      });
  }

  initVariableMappings() {

    if (!this.variableMappings) {
      this.variableMappings = [];
    }

    this.addVariableMapping('${appName}', this.appConfig.appName);
    this.addVariableMapping('${appVersion}', this.appConfig.appVersionName);
  }

  async isExpertMode(bl: boolean | null = null) {

    // update expert mode if bl is not null
    if (bl !== null) {
      const save: any = await this.cache.set('VIEW_MODE', !!bl);
      this.events.publish('view:mode:changed', bl);
      return save;
    }

    // else, return expert mode
    bl = false;

    try {
      let settings: any = await this.cache.get('VIEW_MODE', -1);
      bl = (!!settings && !!settings.data);
    } catch (e) {
      console.log('loading view mode settings failed', e);
    }

    return bl;
  }

}