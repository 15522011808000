import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class AiIdeasService {

    constructor(
        private AppCMS: AppcmsService,
    ) {

    }

    create(idea: any) {
        idea = JSON.parse(JSON.stringify(idea));

        delete idea.inputs;
        delete idea.loading;
        delete idea.loop;
        delete idea.promptType;
        delete idea.save_path;
        delete idea.save_paths;
        delete idea.try;
        delete idea.type;

        return this.AppCMS.loadPluginData('pipeline', {
            idea: (idea as aiIdea),
        }, ['ai', 'ideas', 'create']);
    }

    createContentIdeas(config: any = {}, blForceRefresh: boolean = false) {
        return this.AppCMS.loadPluginData('pipeline', { config: config }, ['ai', 'createContentIdeas'], {}, blForceRefresh);
    }

    deepLoadIdea(idea: aiIdea, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', Object.assign(options, {
            idea: idea,
        }), ['ai', 'deepLoadIdea']);
    }

    delete(idea: aiIdea) {
        return this.AppCMS.loadPluginData('pipeline', {
            idea_uid: idea.uid,
        }, ['ai', 'ideas', 'delete']);
    }

    getIdeas(blForceRefresh: boolean = false, options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'ideas'], {}, blForceRefresh);
    }

    update(idea: aiIdea) {
        return this.AppCMS.loadPluginData('pipeline', {
            idea: idea,
        }, ['ai', 'ideas', 'update']);
    }

}