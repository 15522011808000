import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ItemReorderEventDetail } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { DaniService } from 'src/app/services/getgenius/dani.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { ClipboardService } from "src/app/services/utils/clipboard.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { AiTasksService } from "src/app/services/ai/ai-tasks.service";
import { AiToolsService } from "src/app/services/ai/ai-tools.service";
import { AiWorkerService } from 'src/app/services/ai/ai-worker.service';

import { AiToolsPage } from '../ai-tools/ai-tools.page';

@Component({
  selector: 'app-ai-task-benchmark',
  standalone: false,
  templateUrl: './ai-task-benchmark.page.html',
  styleUrls: ['./ai-task-benchmark.page.scss'],
})
export class AiTaskBenchmarkPage implements OnInit {

  aiSettings: aiSettings = {
    context: 'text_generation',
  };

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  appConfig: pipelineAppConfig;

  browserConfig: aiBrowserConfig = {
    use_proxy: true,
  };

  buttons: button[] = [
  ];

  cards: any = {
    ai: { open: true },
    source: { open: true },
  };

  fallbackImg: string = './assets/img/fallback.webp';

  introCard: introCardConfig = {
    uid: 'ai_task_benchmark_top_card',
    lottieSrc: './assets/lottie/ai_chip.json',
    text: 'ai_task_benchmark_top_card_text',
    title: 'ai_task_benchmark_top_card_title',
  };

  introOverlayConfig: introOverlayConfig = {
    allowManually: true,
    headline: 'ai_task_benchmark_helper_text',
    input_placeholder: 'ai_task_benchmark_helper_input_placeholder',
    showAiCreate: true,
    showAiSettings: true,
    showInput: true,
    showIntegrations: false,
  };

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  tableViewOptions: tableViewOptions = {
  };

  tasks: aiTask[] = [];

  view: any = {
    canRun: false,
    canSave: false,
    hideOrderByBtn: true,
    hideSearch: true,
    progress: 0,
    promptFunctionActions: [],
    promptTypes: [],
    route: 'ai/task/benchmark',
    showMenuButton: true,
    showProjectsSelect: true,
    task: {},
    tasks: [],
    title: 'create_benchmark',
    triggerEvents: [
      'email_received',
      'find_content_in_documents',
      'find_content_in_emails',
      'find_content_in_posts',
      'find_content_in_social_media_comments',
      'find_content_in_urls',
    ],
    triggerEventTypes: [
      {
        photo: './assets/img/icon.webp',
        label: 'general',
        uid: 'general',
      }
    ],
  };

  constructor(
    private aiTasks: AiTasksService,
    private aiTools: AiToolsService,
    public aiWorker: AiWorkerService,

    private clipboard: ClipboardService,
    private configService: ConfigService,
    private dani: DaniService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
    private viewService: ViewService,

    private route: ActivatedRoute,
  ) {
    this.appConfig = this.configService.getConfig();

    this.aiSettings = this.aiWorker.getConfig();
    this.view.data = this.aiTasks.getQuickData();
    this.view.promptFunctionActions = this.aiTools.getPromptFunctionActions();
    this.view.promptTypes = this.aiTools.getPromptTypes();

    this.view.taskUidFromRoute = this.route.snapshot.paramMap.get('taskId');
  }

  aiSettingsChanged(event: any | null = null) {
    console.log('aiSettingsChanged', event);
  }

  async addTask(options: any = {}) {

    const task: aiTask = Object.assign({
      active: false,
      input: '',
      output: '',
      promptType: 'user',
      type: 'custom',
    }, options);

    this.tasks.push(task);

    this.tasks.forEach((task: aiTask, index: number) => {
      task.active = (index === (this.tasks.length - 1));
    });
  }

  aiCreate() {

    if (!this.view.aiCreateInput || !this.view.aiCreateInput.length) {
      return false;
    }

    this.view.generating = true;
    this.view.loading = true;

    this.dani.executeCreateAutomation({
      input: this.view.aiCreateInput
    })
      .then(async (response: any) => {
        this.view.generating = false;
        this.view.loading = false;

        if (!!response && !!response.data) {

          this.tasks = (response.data || []).map((task: aiTask, index: number) => {
            task.active = task.active || (index === 0);
            task.promptType = task.promptType || 'user';
            task.type = task.type || 'custom';

            return task;
          });

          // show tasks UI
          this.calcInputVars();
          this.startManually();
        }
      })
      .catch((error: any) => {
        this.view.generating = false;
        this.view.loading = false;

        this.events.publish('error', error);
      });
  }

  animateProgress() {
    this.stopProgressAnimation();

    if (!this.view.progressAnimation) {
      this.view.progressAnimation = setInterval(() => {
        if (this.view.progress < 100) {
          this.view.progress += 0.001;
        } else {
          clearInterval(this.view.progressAnimation);
          this.view.progressAnimation = null;
        }
      }, 500);
    }

  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 6 : 12);
  }

  calcInputVars() {
    let inputs: any[] = [];

    this.tasks.forEach((task: aiTask) => {

      if ((!task.input || !task.input.length) && !!task.inputs) {
        return false;
      }

      const varsInTaskInput = [...new Set(`${task.input}`.match(/\{(.*?)\}/g))];

      if (!!varsInTaskInput) {
        task.inputs = varsInTaskInput.map((input: any, index: number) => {
          input = input.replace(/{/g, '').replace(/}/g, '');

          return {
            name: input,
            uid: input,
            value: (task.inputs && !!task.inputs[index] ? task.inputs[index].value : ''),
          };
        });
      } else {
        delete task.inputs;
      }

      inputs = inputs.concat(task.inputs);
    });

    this.view.inputs = inputs;
  }

  calcSelectedItems(item: any | null = null) {

    if (this.view.task && this.view.task.length) {
      this.view.selectedItems = this.view.task.filter((_item: any) => {
        return !!_item._checked || (!!item && (_item.uid === item.uid && !!item._checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.calcViewVars();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    this.view.canRun = false;
    this.view.canSave = false;
  }

  copyText(text: string) {
    this.clipboard.copyText(text);
  }

  deleteSavedTask(task: aiTask, index: number) {
    this.aiTasks.delete(task)
      .then(() => {
        this.loadTasks(true);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  deleteTask(task: aiTask, index: number) {
    this.tasks = this.tasks.filter((_task: aiTask, _index: number) => {
      return _index !== index;
    });
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh() {
    this.load(true)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  editTask(task: aiTask, index: number) {
    this.calcInputVars();
    this.setActiveTaskIndex(index);
  }

  expandView(task: aiTask, index: number, key: string = 'input') {
    task.fullscreenView = !task.fullscreenView;
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    this.tasks = ev.detail.complete();
  }

  handleSetStateDone(response: any) {
    console.info('handleSetStateDone', response);

    this.stopSet();
  }

  handleSetStateError(response: any) {
    console.error('handleSetStateError', response);

    this.stopSet();
  }

  initEvents() {
    this.view.events = {};

    this.view.events.aiQuickCalcInputVars = this.events.subscribe('ai:quick:calcInputVars', () => {
      this.calcInputVars();
    });

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', (project: project) => {
      this.view.project = project;
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.calcColSize();
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    this.events.stop(this.view.events);
  }

  itemInfo(task: aiTask, event: any | null = null) {
  }

  async load(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!!this.view.project && !!this.view.project.uid) {
      this.loadIdeaData(blForceRefresh);
      this.loadTasks(blForceRefresh);
    }

    console.log('ai-benchmark: view', this.view);
  }

  async loadIdeaData(blForceRefresh: boolean = false) {
    this.view.data = this.aiTasks.getQuickData();

    // load remote task if no current data is set
    if ((!this.view.data || !this.view.data.uid) && !!this.view.taskUidFromRoute) {
      try {
        const remoteTask: aiTask = await this.aiTasks.getTaskByUid(parseInt(this.view.taskUidFromRoute));

        if (!!remoteTask && !!remoteTask.uid) {
          this.view.data = remoteTask;
          this.view.title = `${remoteTask.name || 'automation'}`;
        }

      } catch (e) {
        this.events.publish('error', e);
      }
    }

    if (!!this.view.data && !!this.view.data.uid) {
      this.view.task = this.view.data;
      this.view.title = `${this.view.task.name || 'automation'}`;

      this.tasks = (this.view.data.children || []).map((task: aiTask, index: number) => {

        if (!task.hasOwnProperty('active')) {
          task.active = (index === 0);
        }

        if (!task.hasOwnProperty('input') && !!task.name) {
          task.input = task.name;
        }

        task.promptType = task.promptType || 'user';
        task.type = task.type || 'custom';

        return task;
      });

      this.startManually();
    } else
      if (!!this.view.data && !!this.view.data.idea) {

        if (!!this.tasks && !!this.tasks[0]) {
          this.tasks[0].input = this.view.data.idea.input;
          this.tasks[0].output = this.view.data.idea.output;
        }

        this.calcInputVars();
      }

    this.calcViewVars();
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    await this.loadProject();

    if (!this.view.project || !this.view.project.uid) {
      return false;
    }

    this.view.loading = true;

    try {
      this.view.integrations = await this.integrations.getEnabled({}, blForceRefresh);
      this.view.integrations_backup = (this.view.integrations || []);

      this.view.loading = false;

      this.view.triggerEventTypes = [
        {
          photo: './assets/img/icon.webp',
          label: 'general',
          uid: 'general',
        }
      ].concat(this.view.integrations || []);

    } catch (e) {
      this.view.loading = false;
      console.warn('> loading integrations failed', e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  async loadTasks(blForceRefresh: boolean = false) {
    console.log('ai-benchmark: tasks', this.tasks);

    if (!!this.tasks && !!this.tasks.length) {
      this.startManually();
    }
  }

  ngOnInit() {
    this.calcColSize();

    this.aiSettings = this.aiSettings || {};
    this.aiSettings.context = 'text_generation';

    this.load();
    this.loadIntegrations();
  }

  onBrowserPageDone(event: any | null = null) {
    console.log('ai-task: onPageDone', event);
  }

  onBrowserPageLoaded(event: any | null = null) {
    console.log('ai-task: onPageLoaded', event);
  }

  onBrowserPageRendered(event: any | null = null) {
    console.log('ai-task: onPageRendered', event);
  }

  onCaptionChanged(caption: string | null = null) {
    console.log('onCaptionChanged', caption);
  }

  onEditorInputChanged(value: string, task: aiTask, index: number | null = null, key: string = 'input') {

    if (!!value && (index !== null) && !!task.children && !!task.children[index]) {
      // update child key
      task.children[index][key] = value;
    } else
      if (!!value && !!task) {
        // update main key
        task[key] = value;
      }

    this.calcViewVars();
  }

  onItemChecked(item: any, event: any | null = null) {
    this.calcSelectedItems();
  }

  onItemsChanged(items: any[] = []) {
    this.view.selectedItems = (items || []);
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  onOverlayInputChanged(event: any = null) {
    
    if (!event || !event.input || (!!event.input.name && (event.input.name !== 'input')) || !event.input.hasOwnProperty('value')) {
      return false;
    }

    this.view.aiCreateInput = event.input.value;
  }

  onTaskAiSettingsChanged(event: any | null = null) {
    this.view.aiSettingsTask.config = this.view.aiSettingsTask.config || {};
    this.view.aiSettingsTask.config.ai = Object.assign(this.view.aiSettingsTask.config.ai || {}, event || {});

    this.tasks[this.view.aiSettingsTaskIndex] = this.view.aiSettingsTask;
  }

  onTaskChildClick(child: any, task: aiTask, iChild: number) {

    if (!!child.loading) {
      return false;
    }

    this.view.rebuild = true;

    task.uiChildIndex = iChild;

    if (!task.loading) {
      task.childIndex = task.uiChildIndex;
    }

    // set child to active 
    if (!!task.children && !!task.children.length) {
      task.children.forEach((child: any, _iChild: number) => {
        task.children[_iChild].active = (_iChild === iChild);
      });
    }

    if (task.promptType === 'function') {
      task.children[iChild].execute = false;

      setTimeout(() => {
        task.children[iChild].execute = true;
      }, 250);
    }

    setTimeout(() => {

      if (!!child.output) {
        task.output = child.output;
      }

      this.view.rebuild = false;
    }, 250);
  }

  onTaskPromptTypeChanged(task: aiTask) {
  }

  // update task child editor if update received
  async onTaskChildUpdated(child: any, iChild: number, iTask: number) {
    console.log('onTaskChildUpdated', child, iChild, iTask);

    /*
    console.log('editorList', this.editorList);

    let taskEditor: any = this.editorList.get(index);
    console.log('task editor', taskEditor);
    */

    /*
    if(!!taskEditor) {
      taskEditor.setInput()
    }
    */
  }

  // update task if update received
  async onTaskUpdated(task: aiTask, index: number = 0) {
    this.tasks[index] = task;
  }

  async optimizeTaskInputPrompt(task: aiTask, event: any | null = null) {

    if (!task.input || !task.input.length) {
      return false;
    }

    task.loadingInput = true;

    const systemPrompt: string = `Optimize only the following AI prompt and return the improved prompt only.
    Please always try to improve the existing variables / add more variables ({...}) if provided based on the provided user prompt for a better detailed prompt and more configuration options to make the prompt reusable.`;

    const history: aiExecutionHistoryItem[] = [
      {
        role: 'system',
        content: systemPrompt,
      }
    ];

    try {

      const optimize: any = await this.aiTools.optimizePrompt(task.input, {
        history: history
      });

      if (!!optimize && !!optimize.output) {
        task.input = `${optimize.output || ''}`;
        this.calcInputVars();
      }

      task.loadingInput = false;
    } catch (e) {
      task.loadingInput = false;
      this.events.publish('error', e);
    }
  }

  async pickTaskShortcode(task: aiTask) {
    this.setTaskType(task, 'shortcode');

    this.view.toolsMenuModal = await this.modalService.create({
      backdropDismiss: false,
      component: AiToolsPage,
      componentProps: {
        executeTool: false,
        mode: 'pick',
        multiple: false,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    this.view.toolsMenuModal.onWillDismiss().then((response: any) => {
      if (!response || !response.data) {
        this.setTaskType(task, 'custom');
      } else {

        if (!!response.data.name) {
          task.name = response.data.name;
        }

        if (!!response.data.promptType) {
          task.promptType = response.data.promptType;
        }

        if (!!response && !!response.data && !!response.data.input && !!response.data.input.length) {
          task.input = `${response.data.input}`;
        }

        if (!!response && !!response.data && !!response.data.output && !!response.data.output.length) {
          task.output = `${response.data.output}`;
        }
      }

      this.calcInputVars();
    });

    this.modalService.present(this.view.toolsMenuModal);
  }

  run(task: aiTask | null = null) {
    this.aiWorker.executeAITask(task)
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  runSavedTask(task: aiTask) {
    this.view.task = JSON.parse(JSON.stringify(task));

    if (!!this.view.task && !!this.view.task.children) {
      this.tasks = this.view.task.children;
    }
  }

  save() {

    if (!this.view.isSaveMode) {
      this.view.isSaveMode = true;
      return false;
    }

    let task: aiTask = JSON.parse(JSON.stringify(this.view.task));
    task.children = JSON.parse(JSON.stringify(this.tasks));

    if (!!task.uid) {
      return this.update(task);
    }

    this.aiTasks.create(task)
      .then(() => {
        this.view.isSaveMode = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  setActiveTaskIndex(index: number) {

    if (!this.tasks || !this.tasks.length) {
      return false;
    }

    this.tasks.forEach((task: aiTask, _index: number) => {
      task.active = (_index === index);
    });

  }

  setTaskType(task: aiTask, type: string) {
    task.type = type;
  }

  startManually() {
    this.view.startManually = true;

    if (!this.tasks || !this.tasks.length) {
      this.addTask();
    }
  }

  stopProgressAnimation() {
    try {
      if (!!this.view.progressAnimation) {
        clearInterval(this.view.progressAnimation);
        this.view.progressAnimation = null;
      }
    } catch (e) {
      console.warn('> stopping progress animation failed', e);
    }

    this.view.progress = 0;
  }

  stopTask(task: aiTask) {

  }

  stopSet() {
    this.stopProgressAnimation();

    this.aiWorker.stopSet(this.view.setHandler)
      .then(() => {
        this.view.running = false;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  toggleShouldPause(task: aiTask, i: number) {
    task.shouldPause = !task.shouldPause;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update(task: aiTask | null = null) {
    task = task || JSON.parse(JSON.stringify(this.view.task));

    this.aiTasks.update(task)
      .then(() => {
        this.view.isSaveMode = false;
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}