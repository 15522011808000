import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { AlertService } from 'src/app/services/core/alert.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from "src/app/services/core/projects.service";
import { ViewService } from 'src/app/services/core/view.service';
import { IntegrationsService } from "src/app/services/integrations/integrations.service";
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-integration-setup',
  standalone: false,
  templateUrl: './integration-setup.page.html',
  styleUrls: ['./integration-setup.page.scss'],
})
export class IntegrationSetupPage implements OnInit {

  appConfig: pipelineAppConfig;

  buttons: button[] = [
    /*
    {
      handler: (item: any, event: any|null = null) => {
        console.log('edit event', event);
        this.edit(item);
      },
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
    {
      handler: (item: any, event: any|null = null) => {
        console.log('delete event', event);
        this.delete(item);
      },
      color: 'danger',
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    }
    */
  ];

  fallbackImg: string = './assets/img/fallback.webp';

  icons: any = {
    account_name: 'text-outline',
    api_token: 'lock-closed-outline',
    api_key: 'lock-closed-outline',
    api_version: 'git-branch-outline',
  };

  integrationName: string;

  introCard: introCardConfig = {
    uid: 'integration_setup_top_card',
    text: 'integration_setup_top_card_text',
    title: 'integration_setup_top_card_title',
  };

  mode: string = 'edit';

  options: dynamicItemOptions = {
    labelKey: 'key',
    showPhoto: true,
    valueKey: 'value',
  };

  paginationConfig: paginationConfig = {

  };

  state: state = {};

  view: any = {
    colSize: (window.innerWidth >= 768 ? 3 : 12),
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    integration: {},
    route: 'integration/setup',
    showBackButton: true,
    verified: false,
  };

  constructor(
    private alertService: AlertService,
    private configService: ConfigService,
    private events: EventsService,
    public integrations: IntegrationsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private projects: ProjectsService,
    private route: ActivatedRoute,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();

    const integrationName: string = this.route.snapshot.paramMap.get('integrationName') || this.integrationName;
    this.viewService.addVariableMapping('${integrationName}', this.tools.capitalize(`${integrationName || ''}`.replace('integration_', '')));
    this.view.integrationName = integrationName;
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
  }

  delete(item: any) {
    console.log('delete', item);
  }

  dismiss(data: any | null = null, role: string | null = 'dismiss') {
    this.calcViewVars();

    if (!!this.view.isModal) {
      this.modalService.dismiss(data, role);
    } else {
      this.navCtrl.navigateBack('/integrations');
    }
  }

  doRefresh(event: any | null = null) {
    this.loadIntegrationSetup(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
  }

  edit(item: any) {
    console.log('edit', item);
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.loadIntegrationSetup(true);
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
      this.view.colSize = (!!this.view.isDesktop ? 3 : 12);
    });
  }

  ionViewDidEnter() {
    this.loadIntegrationSetup();
  }

  ionViewWillLeave() {
    this.integrations.detailItem({} as any);
  }

  loadIntegrationSetup(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      await this.loadProject();

      if (!this.view.project || !this.view.project.uid) {
        this.view.integration = {};
        this.view.title = 'select_project';
        resolve(this.view);
      } else {
        new Promise(async (resolve, reject) => {
          let integration: any = this.integrations.detailItem();

          if (!!integration) {
            resolve(integration);
          } else
            if (!integration && !!this.view.integrationName) {
              integration = await this.integrations.getByIndent(this.view.integrationName, blForceRefresh);
              resolve(integration);
            } else {
              reject('missing_integration_data');
            }
        })
          .then((integration: integration) => {

            if (!!integration && !!integration.uid) {
              this.view.integration = integration;
              this.view.title = `${integration.name}`;

              if (!this.view.integrationName) {
                this.viewService.addVariableMapping('${integrationName}', this.tools.capitalize(`${this.view.title || ''}`.replace('integration_', '')));
                this.view.integrationName = this.view.title;
              }
            }

            this.prepareSettings();

            resolve(this.view);
          })
          .catch(reject);
      }
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();
    this.initEvents();

    this.prepareSettings();
  }

  onButtonClick(button, item) {
    if (!!button && !!button.label && (button.label === 'delete')) {

      try {
        this.alertService.requestConfirm()
          .then((bl: boolean) => {
            if (!!bl) {
              button.handler(item, button);
            }
          })
          .catch((e: any) => {
            console.warn('executing button action failed', e);
          });
      } catch (e) {
        console.warn('executing button action failed', e);
      }

      return false;
    }

    try {
      button.handler(item, button);
    } catch (e) {
      console.warn('executing button action failed', e);
    }

  }

  onItemChecked(item: any) {
    console.log('onItemChecked', item);
  }

  onItemsChanged(items: any) {
    if (!!this.view.integration) {
      this.view.integration.settings = (items || []);
    }
  }

  prepareSettings() {
    const hiddenKeys: string[] = ['enabled'];

    if (!!this.view.integration && !!this.view.integration.settings && !!this.view.integration.settings.length) {
      this.view.integration.settings = this.view.integration.settings.filter((setting: any) => {

        if (!!this.icons[setting.key]) {
          setting.icon = this.icons[setting.key];
        }

        return !!setting.key && (hiddenKeys.indexOf(setting.key) === -1);
      });
    }

  }

  save() {
    console.log('save', this.view.integration);
  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
    item.thumbnail = this.fallbackImg;

    if (!!this.options.photoKey) {
      item[this.options.photoKey] = item.photo;
    }
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {
    this.integrations.update(this.view.integration)
      .then((response: any) => {
        this.dismiss(response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

}