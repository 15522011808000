import { Injectable } from '@angular/core';

import { AccountsService } from 'src/app/services/core/accounts.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from 'src/app/services/core/modal.service';

import { LoginPage } from 'src/app/pages/core/account/login/login.page';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private accountsService: AccountsService,
    public events: EventsService,
    private modalService: ModalService,
  ) {

  }

  show(blShowLoginAlert: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.accountsService.setMultiMode(true);

      const loginModal: any = await this.modalService.create({
        component: LoginPage,
        animated: true,
        canDismiss: true,
        presentingElement: document.getElementById('ion-router-outlet-content'),
        cssClass: 'defaultModal'
      });

      loginModal.onWillDismiss().then(resolve);

      this.modalService.present(loginModal);

      if (!!blShowLoginAlert) {
        this.events.publish('error', 'error_action_requires_user_login');
      }

    });

  }

}
