import { Component, OnInit, ViewChild } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { ProjectPage } from '../project/project.page';

import { DynamicViewPageComponent } from 'src/app/components/generic/dynamic/dynamic-view-page/dynamic-view-page.component';

@Component({
  selector: 'app-projects',
  standalone: false,
  templateUrl: './projects.page.html',
  styleUrls: ['./projects.page.scss'],
})
export class ProjectsPage implements OnInit {
  @ViewChild(DynamicViewPageComponent) viewPage: any;

  buttons: button[] = [
    {
      icon: 'create-outline',
      label: 'edit',
      uid: 'edit',
    },
  ];

  cta: any = {
    handler: () => {
    },
    icon: 'add-outline',
    label: 'create',
  };

  headerOptions: selectionOption[] = [
    {
      icon: 'cloud-download-outline',
      label: 'export',
      uid: 'export',
    },
    {
      icon: 'cloud-upload-outline',
      label: 'import',
      uid: 'import',
    },
  ];

  introCard: introCardConfig = {
    uid: 'projects_admin_top_card',
    text: 'projects_admin_top_card_text',
    title: 'projects_admin_top_card_title',
  };

  options: dynamicItemOptions = {
    labelKey: 'title',
    showPhoto: true,
  };

  pages: any = {};

  paginationConfig: paginationConfig = {
    itemsKey: 'projects',
    limit: 100,
  };

  search: searchOptions = {
    itemsKey: 'projects',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'search-outline',
      label: 'analyze',
      uid: 'analyze',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    },
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
  ];

  state: state = {};

  view: any = {
    forceProjectToBeSet: false,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    itemSize: 64,
    multiple: true,
    options: {

    },
    projects: [],
    route: 'projects',
    key: ['description', 'title', 'host', 'url', 'uid'],
    showMenuButton: true,
    //showPagination: true,
    showProjectsSelect: false,
    showViewModeSelect: true,
    title: 'projects_admin',
    viewMode: 'list',
  };

  constructor(
    private events: EventsService,
    public service: ProjectsService,
    private tools: ToolsService,
  ) {

  }

  deleteSelected(event: any | null = null) {
    console.log('delete selected: event', event);
    console.log('delete selected: view', this.view);

    this.tools.bulk({
      action: 'delete',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
        item.hidden = true;
      },
      service: this.service,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  doRefresh() {
    try {
      if (!!this.viewPage) {
        this.viewPage.doRefresh();
      }
    } catch (e) {
      console.error('refreshing view page failed', e);
    }
  }

  duplicateSelected(event: any | null = null) {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    /*
    this.duplicator.duplicateUsingUI({
      data: (this.view.selectedItems || []),
      service: this.service,
      source: 'projects',
      type: 'project',
    })
      .then((response: any) => {
        console.log('duplicate response', response);

        if (!!event) {
          event.target.complete();
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);

        if (!!event) {
          event.target.complete();
        }

      });
    */

    this.tools.bulk({
      action: 'duplicate',
      identifier: 'uid',
      items: this.view.selectedItems,
      onItemResponse: (response: any, item: any) => {
      },
      service: this.service,
    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ngOnInit() {

    this.pages = {
      add: ProjectPage,
      edit: ProjectPage,
    };

    this.view.viewType = 'list';
  }

  onSelectionActionChanged(event: any | null = null) {

    if (!event || !event.option || !event.option.uid) {
      return false;
    }

    this.view.selectedItems = (!!event && !!event.item ? [event.item] : (!!event && !!event.items ? event.items : (this.view.selectedItems || [])));

    switch (event.option.uid) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }
  }

}