<div class="editor-wrapper" [class.loading]="!!loading">

    <!-- Loading spinner -->
    <div class="loading-overlay" [hidden]="!loading">
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-spinner></ion-spinner>
    </div>

    <!-- Mode: Code -->
    <div class="mode-code-wrapper" *ngIf="mode === 'code' && !rebuild">
        <ngs-code-editor [codeModel]="codeModel" (codeModelChanged)="onCodeEditorModelChanged($event)"
            (loaded)="onCodeEditorLoaded($event)" (modelContentChanged)="onCodeEditorModelContentChanged($event)"
            [options]="codeOptions" [theme]="codeTheme" (valueChanged)="onCodeChanged($event)">
        </ngs-code-editor>
    </div>

    <!-- Mode: Text -->
    <div class="mode-text-wrapper editorJsInstance" *ngIf="(!mode || mode === 'text') && !rebuild" id="editorJs"
        #editorJs></div>

    <!-- Mode: Newsletter or WYSIWYG -->
    <div class="mode-newsletter-wrapper" *ngIf="(mode === 'newsletter' || mode === 'wysiwyg') && !rebuild">
        <div id="blocks"></div>
        <div id="gjs" #gjs></div>
        <div id="info-panel" style="display:none"></div>
    </div>

</div>