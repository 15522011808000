import { Component, OnInit } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ModalService } from "src/app/services/core/modal.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { PostsAdminService } from 'src/app/services/posts/posts-admin.service';

@Component({
  selector: 'app-posts-admin-settings',
  standalone: false,
  templateUrl: './posts-admin-settings.page.html',
  styleUrls: ['./posts-admin-settings.page.scss'],
})
export class PostsAdminSettingsPage implements OnInit {

  state: state = {};

  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    languages: [],
    monetarization: {
      mode: 'out_of_all',
      modes: ['automated', 'most_read', 'out_of_all'],
      rate: 10,
    },
    title: 'settings',
  };

  constructor(
    private events: EventsService,
    private modalService: ModalService,
    private postsAdmin: PostsAdminService,
    private translations: TranslationService,
  ) {
  }

  async dismiss(data: any | null = null, role: string | null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  ionViewWillEnter() {
    this.loadSettings()
      .then(() => {
        this.updateMonetarizationSettings();
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadSettings() {
    return new Promise((resolve, reject) => {
      this.postsAdmin.getSettings()
        .then((settings: postsAdminSettings) => {
          this.view.settings = settings;
          resolve(settings);
        })
        .catch(reject);
    });
  }

  ngOnInit() {
  }

  updateLanguage() {

  }

  updateMonetarizationSettings() {
    if (this.view.monetarization.mode) {
      let key = `creators_monetarization_rate_mode_${this.view.monetarization.mode}_text`;

      this.translations.get(key)
        .subscribe((translation: string) => {
          this.view.monetarization.description = translation
            .replace('$ 1', '$1')
            .replace('$1', `${this.view.monetarization.rate}%`);
        });
    }
  }

}
