import { AfterContentInit, Component, OnInit } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from "src/app/services/core/language.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from 'src/app/services/core/projects.service';
import { ViewService } from 'src/app/services/core/view.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';

@Component({
  selector: 'app-data-editor',
  standalone: false,
  templateUrl: './data-editor.page.html',
  styleUrls: ['./data-editor.page.scss'],
})
export class DataEditorPage implements AfterContentInit, OnInit {

  aiSettings: aiSettings = {};

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'text_geneation',
    ],
  };

  appConfig: pipelineAppConfig;

  buttons: button[] = [
  ];

  cards: any = {
    filters: { open: false },
    item_attributes: { open: true },
    output: { open: true },
    source: { open: true },
  };

  data: any; // provided by modalCtrl

  public dataEditorService: any;

  fallbackAvatarImg: string = './assets/img/avatars/1.webp';
  fallbackImg: string = './assets/img/fallback.webp';

  introCard: introCardConfig = {
    uid: 'data_editor_top_card',
    text: 'data_editor_top_card_text',
    title: 'data_editor_top_card_title',
  };

  introOverlayConfig: introOverlayConfig = {
    allowManually: false,
    headline: 'data_editor_ai_helper_text',
    showAiSettings: false,
    showInput: false,
    showIntegrations: true,
  };

  items: any[];

  manuallyItems: any = {};

  optionsByKey: any = {
    active: ['true', 'false'],
    external: ['true', 'false'],
    local: ['true', 'false'],
    private: ['true', 'false'],
    public: ['true', 'false'],
    sponsored: ['true', 'false'],
    translated: ['true', 'false'],
    vip: ['true', 'false'],
  };

  search: searchOptions = {
    itemsKey: 'items',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  selectionOptions: selectionOption[] = [
    {
      icon: 'trash-outline',
      label: 'delete',
      uid: 'delete',
    },
    {
      icon: 'copy-outline',
      label: 'duplicate',
      uid: 'duplicate',
    }
  ];

  service: any; // provided by modalCtrl

  settings: any = {
    format: 'csv',
    seperator: ',',
  };

  source: any; // provided by modalCtrl

  splineOptions: splineOptions = {
    url: './assets/spline/dani/dani_working.splinecode',
  };

  state: state = {};

  tableViewOptions: tableViewOptions = {
  };

  type: any; // provided by modalCtrl

  view: any = {
    buttonAction: 'export',
    canGenerate: false,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    inputModes: [
      {
        icon: 'text-outline',
        uid: 'text',
        name: 'input_mode_text',
      },
      {
        icon: 'images-outline',
        uid: 'media',
        name: 'input_mode_media',
      }
    ],
    multiple: true,
    newValues: {},
    output: '',
    phase: 'edit',
    progress: 0,
    route: 'ai/items',
    sections: [],
    seperators: [
      {
        indent: ',',
        label: ',',
      },
      {
        indent: ';',
        label: ';',
      },
      {
        indent: '\t',
        label: 'tab',
      },
    ],
    showBackButton: true,
    showMenuButton: false,
    showProjectsSelect: true,
    showSplineView: false,
    showViewModeSelect: true,
    tab: 'item',
    title: 'data_editor',
    viewType: 'list',
    viewTypes: [
      { icon: 'list', label: 'list', uid: 'list', expertMode: false },
      { icon: 'apps', label: 'table', uid: 'table', expertMode: false },
    ],
  };

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private language: LanguageService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private viewService: ViewService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.dataEditorService = this;
  }

  calcFilters() {
    const blockKeys: string[] = ['checked', 'field_optimizations', 'image', 'photo', 'timestamp', 'uid', 'variations'];
    let filters: any[] = [], valuesByKey: any = {};

    if (!this.data || !this.data[0]) {
      return false;
    }

    const keys: string[] = Object.keys(this.data[0]).filter((key: string) => {
      return blockKeys.indexOf(key) === -1;
    });

    this.data.forEach((item: any) => {
      keys.forEach((key: string) => {
        valuesByKey[key] = valuesByKey[key] || [];
        if (!!item[key] && (valuesByKey[key].indexOf(item[key]) === -1)) {
          valuesByKey[key].push(item[key]);
        }
      });
    });

    keys.forEach((key: string) => {

      const values: any[] = (valuesByKey[key] || []).map((value: string) => {
        return { label: value, value: value };
      });

      if (!!values && !!values.length) {
        filters.push({
          label: key,
          name: key,
          type: 'select',
          values: values,
          uid: key,
        });
      }
    });

    this.view.filters = (filters || []);
  }

  calcOptions() {

    if (!!this.view.filters && !!this.view.filters.length) {
      this.view.filters.forEach((filter: any) => {
        if (!!filter.uid && !!filter.values && !!filter.values.length) {

          const foundValues: string[] = filter.values.map((value: any) => {
            return (value.label || value.uid);
          });

          if (!!foundValues && !!foundValues.length) {
            this.optionsByKey[filter.uid] = this.optionsByKey[filter.uid] || [];

            foundValues.forEach((value: string) => {
              if (this.optionsByKey[filter.uid].indexOf(value) === -1) {
                this.optionsByKey[filter.uid].push(value);
              }
            });
          }
        }
      });
    }

    console.log('calculated optionsByKey', this.optionsByKey);
  }

  calcParams() {
    return {
      data: this.data,
      settings: this.settings,
      //service: this.service,
      source: this.source,
      type: this.type,
    };
  }

  calcSelectedItems(item: any | null = null) {

    if (this.data && this.data.length) {
      this.view.selectedItems = this.data.filter((_item: any) => {
        return !!_item._checked || (!!item && (_item.uid === item.uid && !!item._checked));
      });
    } else {
      this.view.selectedItems = [];
    }

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);

    this.calcViewVars();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);

    // skip intro view if data is provided
    if (!this.view.startManually && (!!this.service && !!this.items && !!this.items.length)) {
      this.view.startManually = true;
    }

    if (!!this.data && !!this.data[0]) {
      const keys: string[] = Object.keys(this.data[0]);

      this.view.dataKeys = keys;
      this.view.mappingKeys = this.view.dataKeys;
      this.view.rowMappings = this.view.dataKeys;

      this.view.previewGridWidth = (this.view.dataKeys.length * 200);
    }

    console.log('data-editor: calculated view', this.view);
  }

  dismiss(data: any | null = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any | null = null) {
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

    this.events.subscribe('window:resized', () => {
      this.view = this.viewService.calcScreenSizeVars(this.view);
    });
  }

  ionViewWillEnter() {
    this.initEvents();
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async load() {
    await this.loadProject();
    await this.loadLanguages();

    // if all params are provided, skip intro config view
    if (!!this.data && !!this.service && !!this.source && !!this.type) {
      this.calcFilters();
      this.calcOptions();
      this.startManually();
    }

    if (!!this.view.project) {
      await this.loadIntegrations();
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  async loadIntegrations(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled({}, blForceRefresh)
        .then((integrations: integration[]) => {
          this.view.integrations = (integrations || []);
          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadLanguages() {
    try {
      this.view.languages = await this.language.getLanguages();

      this.optionsByKey.language = (this.view.languages || []).map((language: language) => {
        return language.indent;
      });
    } catch (e) {
      this.events.publish(e);
    }
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngAfterContentInit() {
  }

  ngOnInit() {
    this.view.viewType = 'list';

    this.loadCards();
    this.calcViewVars();
    this.load();
  }

  onFiltersChanged(event: any | null = null) {
    console.log('onFiltersChanged', event);
    this.calcViewVars();
  }

  onFormatChanged() {
    console.log('onFormatChanged', this.settings.format);

    switch (this.settings.format) {
      case 'csv':
        this.view.seperator = 'comma';
        break;
      case 'tsv':
        this.view.seperator = 'tab';
    }
  }

  async onIntegrationChanged(event: any | null = null) {
    console.log('onIntegrationChanged', event);

    this.view.integrationName = (!!this.view.integration ? `${this.view.integration.name}`.replace('integration_', '') : null);
    this.view.startManually = true;

    //await this.loadSettings();
    //await this.loadRoutes();
  }

  onItemChecked(item: any, event: any | null = null) {
    this.calcSelectedItems();
  }

  onItemsChanged(items: any[] = []) {
    console.log('onItemsChanged: items', items);

    this.view.selectedItems = (items || []);
    console.log('onItemsChanged: view.selectedItems', this.view.selectedItems);

    this.calcSelectedItems();
  }

  onLanguageChange(event: string | null = null) {
    this.view.language = event;
    this.saveFilters();
  }

  onSelectionActionChanged(event: any | null = null) {
    console.log('onSelectionActionChanged', event);
  }

  saveFilters() {
    try {
      this.view.filters = this.view.filters || {};
      this.view.filters.language = this.view.filters.language || this.view.language;
      this.view.filters.mediaType = this.view.filters.mediaType || this.view.mediaType;

      //this.media.saveFilters(this.view.filters);
    } catch (e) {
      console.warn('saving media filters failed', e);
    }
  }

  selectAll(event: any | null = null) {

    if (!this.data || !this.data.length) {
      return false;
    }

    this.data.forEach((item: any) => {
      item._checked = event.detail.value === 'on';
    });

    this.calcViewVars();
  }

  startManually() {
    this.view.canGenerate = true;
    this.view.canSlideBack = false;
    this.view.canSlideNext = true;

    this.view.startManually = true;
  }

  thumbnailLoadingFailed(item: any, key: string = 'photo') {
    item[key] = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  update() {

  }

  viewModeChanged(event: any | null = null) {
    this.calcViewVars();
  }

  writeManuallyDataItem(key: string, i: number) {
    this.manuallyItems[key] = !this.manuallyItems[key];
  }

}